import { ListItem } from '@mui/material';
import React from 'react';

import { OUR_EXPERTISE } from '../../MOCK';
import { Slider } from '../Slider';
import { Content, DescriptionList, HeaderContent, OurExpertiseTitle } from './styles';

const OurExpertiseItemsSlider = () => {
  return (
    <Slider
      settings={{
        autoplay: false,
      }}
      isArrowBox
      width='100%'
      margin='0px'
      wrapperProps={{
        marginBottom: '68px',
      }}
    >
      {OUR_EXPERTISE.map(({ Icon, ...item }) => {
        return (
          <Content key={item.id}>
            <HeaderContent className='headerContent'>
              <OurExpertiseTitle variant='body3'>{item.title}</OurExpertiseTitle>
              <Icon />
            </HeaderContent>
            <DescriptionList>
              {item.description.map((desc, index) => {
                return (
                  <ListItem disablePadding key={index}>
                    {desc}
                  </ListItem>
                );
              })}
            </DescriptionList>
          </Content>
        );
      })}
    </Slider>
  );
};

export default OurExpertiseItemsSlider;
