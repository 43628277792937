export const Clutch = ({ width = 208, height = 94 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 208 94'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M54.0801 0.5H63.6481V60.5H54.0801V0.5Z' fill='#17313B' />
      <path
        d='M96.9287 41.4859C96.9287 50.7817 89.4407 51.6268 86.9447 51.6268C81.1207 51.6268 80.2887 46.1338 80.2887 43.1761V19.9366H70.7207V42.7535C70.7207 48.2465 72.3847 53.3169 75.7127 56.2747C78.6247 59.2324 82.3687 60.5 86.9447 60.5C90.2727 60.5 94.4327 59.6549 96.9287 57.1197V60.5H106.497V19.9366H96.9287V41.4859Z'
        fill='#17313B'
      />
      <path
        d='M125.216 5.14783H115.648V19.9366H108.576V29.2323H115.648V60.4999H125.216V29.2323H132.288V19.9366H125.216V5.14783Z'
        fill='#17313B'
      />
      <path
        d='M162.24 48.2465C160.16 49.9366 157.248 51.2042 154.336 51.2042C147.68 51.2042 143.104 46.1338 143.104 39.3732C143.104 32.6127 147.68 27.9648 154.336 27.9648C157.248 27.9648 160.16 28.8098 162.24 30.9225L163.488 32.1901L170.144 25.8521L168.48 24.5845C164.736 21.2042 159.744 19.0916 154.336 19.0916C142.688 19.0916 133.952 27.9648 133.952 39.7958C133.952 51.6267 142.688 60.5 154.336 60.5C159.744 60.5 164.736 58.3873 168.48 55.007L170.144 53.7394L163.488 46.9789L162.24 48.2465Z'
        fill='#17313B'
      />
      <path
        d='M203.84 23.3169C200.928 20.3592 198.016 19.0915 193.44 19.0915C190.112 19.0915 186.784 19.9366 184.288 22.4718V0.5H174.72V60.5H184.288V38.1056C184.288 28.8099 190.528 27.9648 193.024 27.9648C198.848 27.9648 198.432 33.4577 198.432 36.4155V60.0774H208V36.838C208 31.3451 206.752 26.2746 203.84 23.3169Z'
        fill='#17313B'
      />
      <path
        d='M153.92 46.5564C157.596 46.5564 160.576 43.5296 160.576 39.7958C160.576 36.0621 157.596 33.0353 153.92 33.0353C150.244 33.0353 147.264 36.0621 147.264 39.7958C147.264 43.5296 150.244 46.5564 153.92 46.5564Z'
        fill='#EF4335'
      />
      <path
        d='M40.768 45.7112C37.44 49.0915 32.448 51.2042 27.04 51.2042C16.64 51.2042 9.152 42.7535 9.152 31.3451C9.152 19.9366 16.64 11.4859 27.456 11.4859C32.448 11.4859 37.44 13.5986 41.184 17.4014L42.432 18.669L48.672 12.331L47.424 11.0634C42.016 5.57042 34.944 2.61268 27.456 2.61268C11.648 2.19014 0 14.8662 0 31.3451C0 47.8239 11.648 60.5 27.04 60.5C34.528 60.5 42.016 57.5422 47.008 52.0493L48.256 50.7817L42.016 44.4436L40.768 45.7112Z'
        fill='#17313B'
      />
      <path
        d='M12.634 75.1H3.812V79.66C4.1932 79.1978 4.73777 78.8244 5.44571 78.54C6.15365 78.2378 6.90697 78.0867 7.70568 78.0867C9.15787 78.0867 10.3468 78.3978 11.2726 79.02C12.1984 79.6422 12.87 80.4422 13.2875 81.42C13.705 82.38 13.9138 83.4111 13.9138 84.5133C13.9138 86.5578 13.3148 88.2022 12.1167 89.4467C10.9368 90.6911 9.24863 91.3133 7.05219 91.3133C4.98282 91.3133 3.33096 90.8067 2.0966 89.7933C0.862237 88.78 0.163371 87.4556 0 85.82H3.70308C3.86645 86.5311 4.2295 87.1 4.79222 87.5267C5.3731 87.9533 6.10827 88.1667 6.99774 88.1667C8.06873 88.1667 8.87651 87.8378 9.42108 87.18C9.96565 86.5222 10.2379 85.6511 10.2379 84.5667C10.2379 83.4644 9.95657 82.6289 9.39385 82.06C8.84928 81.4733 8.0415 81.18 6.97051 81.18C6.20811 81.18 5.57278 81.3667 5.06451 81.74C4.55624 82.1133 4.1932 82.6111 3.97537 83.2333H0.326743V71.82H12.634V75.1Z'
        fill='#151515'
      />
      <path
        d='M19.1432 91.5C18.4534 91.5 17.8816 91.2956 17.4278 90.8867C16.9921 90.46 16.7743 89.9356 16.7743 89.3133C16.7743 88.6911 16.9921 88.1756 17.4278 87.7667C17.8816 87.34 18.4534 87.1267 19.1432 87.1267C19.8148 87.1267 20.3684 87.34 20.8041 87.7667C21.2398 88.1756 21.4576 88.6911 21.4576 89.3133C21.4576 89.9356 21.2398 90.46 20.8041 90.8867C20.3684 91.2956 19.8148 91.5 19.1432 91.5Z'
        fill='#151515'
      />
      <path
        d='M24.2966 81.34C24.2966 78.2644 24.8593 75.8556 25.9848 74.1133C27.1284 72.3711 29.0162 71.5 31.6483 71.5C34.2804 71.5 36.1592 72.3711 37.2846 74.1133C38.4282 75.8556 39 78.2644 39 81.34C39 84.4333 38.4282 86.86 37.2846 88.62C36.1592 90.38 34.2804 91.26 31.6483 91.26C29.0162 91.26 27.1284 90.38 25.9848 88.62C24.8593 86.86 24.2966 84.4333 24.2966 81.34ZM35.2425 81.34C35.2425 80.0244 35.1517 78.9222 34.9702 78.0333C34.8068 77.1267 34.4619 76.3889 33.9355 75.82C33.4272 75.2511 32.6648 74.9667 31.6483 74.9667C30.6318 74.9667 29.8603 75.2511 29.3339 75.82C28.8256 76.3889 28.4807 77.1267 28.2992 78.0333C28.1358 78.9222 28.0541 80.0244 28.0541 81.34C28.0541 82.6911 28.1358 83.8289 28.2992 84.7533C28.4626 85.66 28.8074 86.3978 29.3339 86.9667C29.8603 87.5178 30.6318 87.7933 31.6483 87.7933C32.6648 87.7933 33.4363 87.5178 33.9627 86.9667C34.4891 86.3978 34.834 85.66 34.9974 84.7533C35.1608 83.8289 35.2425 82.6911 35.2425 81.34Z'
        fill='#151515'
      />
      <path
        d='M60.8697 69.6304L64.9044 76.9141L72.7394 78.698L67.398 84.9835L68.2056 93.3697L60.8697 89.9707L53.5338 93.3697L54.3414 84.9835L49 78.698L56.835 76.9141L60.8697 69.6304Z'
        fill='#EF4335'
      />
      <path
        d='M94.105 69.6304L98.1398 76.9141L105.975 78.698L100.633 84.9835L101.441 93.3697L94.105 89.9707L86.7692 93.3697L87.5767 84.9835L82.2354 78.698L90.0703 76.9141L94.105 69.6304Z'
        fill='#EF4335'
      />
      <path
        d='M127.34 69.6304L131.375 76.9141L139.21 78.698L133.869 84.9835L134.676 93.3697L127.34 89.9707L120.005 93.3697L120.812 84.9835L115.471 78.698L123.306 76.9141L127.34 69.6304Z'
        fill='#EF4335'
      />
      <path
        d='M160.575 69.6304L164.609 76.9141L172.444 78.698L167.103 84.9835L167.911 93.3697L160.575 89.9707L153.239 93.3697L154.046 84.9835L148.705 78.698L156.54 76.9141L160.575 69.6304Z'
        fill='#EF4335'
      />
      <path
        d='M193.81 69.6304L197.845 76.9141L205.68 78.698L200.338 84.9835L201.146 93.3697L193.81 89.9707L186.474 93.3697L187.282 84.9835L181.94 78.698L189.775 76.9141L193.81 69.6304Z'
        fill='#EF4335'
      />
    </svg>
  );
};
