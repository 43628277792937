import {
  DesignerIcon,
  DeveloperIcon,
  DevopsIcon,
  ProjectManagerIcon,
  QualityIcon,
  TeamLeadIcon,
} from '../assets/icons';

export const OUR_TEAM_LIST = [
  {
    id: 1,
    icon: <TeamLeadIcon />,
    title: 'Team Lead',
  },
  {
    id: 2,
    icon: <DevopsIcon />,
    title: 'DevOps',
  },
  {
    id: 3,
    icon: <ProjectManagerIcon />,
    title: 'Project managers',
  },
  {
    id: 4,
    icon: <DesignerIcon />,
    title: 'Designers',
  },
  {
    id: 5,
    icon: <DeveloperIcon />,
    title: 'Developers',
  },
  {
    id: 6,
    icon: <QualityIcon />,
    title: 'Quality assurance',
  },
];
