/* eslint-disable unicorn/prevent-abbreviations */
import Box from '@mui/material/Box';
import React, { Children, useMemo, useState } from 'react';
import ReactSlickSlider from 'react-slick';

import Portal from '../Portal';
import { SliderArrowButton } from './SliderArrowButton';
import { styles } from './styles';

const Arrow = ({ portalId, ...rest }) => {
  return (
    <Portal portalId={portalId}>
      <SliderArrowButton {...rest} />
    </Portal>
  );
};

export const Slider = ({
  isButtonTransparent = false,
  arrows = true,
  isArrowsInBox = false,
  settings,
  wrapperProps,
  children,
  width = '',
  margin,
  speedSlide = 500,
  adaptiveHeight,
  isArrowBox = false,
  portalIdNext = 'mal-next-arrow',
  portalIdPrev: portalIdPrevious = 'mal-prev-arrow',
}) => {
  const [currentSlide, setCurrentSlide] = useState(1);

  const defaultSettings = {
    dots: false,
    arrows,
    infinite: false,
    adaptiveHeight,
    speed: speedSlide,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: isArrowBox ? (
      <Arrow
        portalId={portalIdNext}
        isVertical={settings?.vertical}
        isButtonTransparent={isButtonTransparent}
        isArrowsInBox={isArrowsInBox}
      />
    ) : (
      <SliderArrowButton
        isVertical={settings?.vertical}
        isButtonTransparent={isButtonTransparent}
        isArrowsInBox={isArrowsInBox}
      />
    ),
    prevArrow: isArrowBox ? (
      <Arrow
        portalId={portalIdPrevious}
        direction='prev'
        isVertical={settings?.vertical}
        isButtonTransparent={isButtonTransparent}
        isArrowsInBox={isArrowsInBox}
      />
    ) : (
      <SliderArrowButton
        direction='prev'
        isVertical={settings?.vertical}
        isButtonTransparent={isButtonTransparent}
        isArrowsInBox={isArrowsInBox}
      />
    ),
    afterChange: isArrowBox
      ? (index) => {
          setCurrentSlide(index + 1);
        }
      : undefined,
    appendDots: (dots) => (
      <Box
        sx={{
          zIndex: 10,
          bottom: 6,
        }}
      >
        {dots}
      </Box>
    ),
    customPaging: () => (
      <Box
        sx={{
          backgroundColor: 'white',
          zIndex: 10,
          borderRadius: '15px',
        }}
      />
    ),
    ...settings,
  };

  const getSlidesToShowCount = () => {
    const currentChildrenLength = children?.length;
    return currentChildrenLength <= defaultSettings.slidesToShow
      ? currentChildrenLength
      : defaultSettings.slidesToShow;
  };

  const childrenLength = Children.toArray(children).length;

  const childrenSlide = useMemo(() => {
    if (childrenLength >= 10) {
      return childrenLength;
    }
    return `0${childrenLength}`;
  }, [childrenLength]);

  const countSlides = useMemo(() => {
    if (currentSlide >= 10) {
      return currentSlide;
    }
    return `0${currentSlide}`;
  }, [currentSlide]);

  return (
    <Box
      sx={{
        position: 'relative',
        width: {
          width,
        },
        margin: {
          margin,
        },
        height: 'auto',
        overflow: 'initial',

        '.slick-list, .slick-slider, .slick-track': {
          display: 'flex',
          position: 'relative',
        },

        '.slick-list': {
          overflow: 'hidden',
          margin: 0,
          padding: 0,
          width: '100%',
        },

        '.slick-slide': {
          height: '100%',
          minHeight: '1px',

          '& > div': {
            height: '100%',
          },
        },

        ...wrapperProps,
      }}
    >
      <ReactSlickSlider {...defaultSettings} slidesToShow={getSlidesToShowCount()}>
        {children}
      </ReactSlickSlider>
      {isArrowBox && (
        <Box sx={styles.sliderArrows}>
          <div className='arrowsBlock'>
            <div id={portalIdPrevious} />
            <div id={portalIdNext} />
          </div>
          <div className='range'>
            {countSlides}
            <input
              onChange={(e) => e.target.value}
              value={currentSlide}
              type='range'
              min={1}
              max={Children.toArray(children).length}
            />
            {childrenSlide}
          </div>
        </Box>
      )}
    </Box>
  );
};
