import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import { motion } from 'framer-motion';

import theme from '../../theme';
import palette from '../../theme/palette';

export const StyledAdvantages = styled(Box)({
  marginTop: '65px',
  [theme.breakpoints.down('xl')]: {
    marginTop: '48px',
  },
});

export const AdvantagesSubTitle = styled(Typography)({
  marginTop: '24px',
});

export const AdvantagesContent = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexWrap: 'wrap',
  marginTop: '16px',
  position: 'relative',
});

export const AdvantagesItemWrapper = styled(motion.div)({
  maxWidth: '580px',
  width: '100%',

  [theme.breakpoints.down('xl')]: {
    maxWidth: '100%',
  },
});

export const AdvantagesItem = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  height: '160px',
  marginTop: '16px',
  padding: '8px 0',

  '& .icon': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '124px',
    width: '100%',
  },

  '& .line': {
    width: '1px',
    height: '100%',
    backgroundColor: palette.amethystSmoke,
  },

  '& .text': {
    maxWidth: '360px',
    width: '100%',
    marginLeft: '24px',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '26px',
    letterSpacing: '0.15px',
  },

  '&:hover': {
    backgroundColor: palette.primary,

    '& .icon': {
      '& svg path': {
        fill: palette.purpleBlue,
      },
    },

    '& .text': {
      color: palette.secondary,
    },
  },

  [theme.breakpoints.down('xl')]: {
    marginTop: '24px',
    border: `1px solid ${palette.amethystSmoke}`,

    '& .text': {
      maxWidth: '100%',
      marginRight: '24px',
    },
  },

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    height: 'auto',
    padding: '8px 16px 24px',

    '& .icon': {
      margin: '30px auto',

      '& svg path': {
        fill: palette.purpleBlue,
      },
    },

    '& .line': {
      width: '100%',
      height: '1px',
    },

    '& .text': {
      margin: '24px 0 0 0',
    },

    '&:hover': {
      backgroundColor: palette.secondary,

      '& .text': {
        color: palette.primary,
      },
    },
  },
});
