import Box from '@mui/material/Box';
import { styled } from '@mui/system';

import theme from '../../theme';

export const StyledContainer = styled(Box)({
  display: 'flex',
  maxWidth: '1180px',
  width: '100%',
  margin: '0 auto',
  padding: '0',

  '&.column': {
    flexDirection: 'column',
  },

  [theme.breakpoints.down('xl')]: {
    maxWidth: '100%',
    padding: '0 24px',
  },

  [theme.breakpoints.down('md')]: {
    padding: '0 16px',
  },
});
