import { useRef } from 'react';
import useBreakpoint from 'use-breakpoint';

import { BREAKPOINTS } from '../../constants';
import Container from '../Container';
import { InfoBlockText, InfoBlockTitle, StyledInfoBlock } from './styles';

const InfoBlock = ({ currentSlideNumber }) => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  const isTablet = ['xxxs', 'xxs', 'xs', 'sm', 'md', 'lg'].includes(breakpoint);
  const reference = useRef();

  return (
    <Container direction='column'>
      <StyledInfoBlock ref={reference}>
        <InfoBlockText
          initial={{
            y: !isTablet ? 400 : 0,
            opacity: !isTablet ? 0 : 1,
          }}
          animate={{
            ...(currentSlideNumber === 12 && {
              y: [400, 200, 200, 0],
              opacity: [0, 0.8, 1, 1],
            }),
          }}
          transition={{
            type: 'keyframes',
            times: [0, 0.3, 0.6, 1],
            duration: 2,
          }}
          viewport={{
            once: true,
            amount: 1,
          }}
        >
          <InfoBlockTitle variant='body4'>
            We <strong>listen, empathize,</strong> learn and figure everything out before we{' '}
            <span>create.</span>
          </InfoBlockTitle>
        </InfoBlockText>
        <InfoBlockText
          initial={{
            y: !isTablet ? 400 : 0,
            opacity: !isTablet ? 0 : 1,
          }}
          animate={{
            ...(currentSlideNumber === 12 && {
              y: [400, 200, 200, 0],
              opacity: [0, 0.4, 0.6, 1],
            }),
          }}
          transition={{
            type: 'keyframes',
            times: [0, 0.3, 0.6, 1],
            duration: 2,
          }}
          viewport={{
            once: true,
            amount: 1,
          }}
        >
          <InfoBlockTitle variant='body4'>
            We identify your goals <span>together</span>, then use our <strong>expertise</strong>{' '}
            and <strong>experience</strong> to find the best solution, realistic and impactful.
          </InfoBlockTitle>
        </InfoBlockText>
      </StyledInfoBlock>
      <StyledInfoBlock className='bottomText'>
        <InfoBlockText
          className='bottomInfoBlockText'
          initial={{
            y: !isTablet ? 400 : 0,
            opacity: !isTablet ? 0 : 1,
          }}
          animate={{
            ...(currentSlideNumber === 12 && {
              y: [400, 200, 200, 0],
              opacity: [0, 0.4, 0.6, 1],
            }),
          }}
          transition={{
            type: 'keyframes',
            delay: 1,
            times: [0, 0.3, 0.6, 1],
            duration: 2,
          }}
        >
          <InfoBlockTitle variant='body4'>
            <strong>Our developers</strong> are straightforward, collaborative, and{' '}
            <span>love</span> the work they are doing.
          </InfoBlockTitle>
        </InfoBlockText>
      </StyledInfoBlock>
    </Container>
  );
};

export default InfoBlock;
