import Typography from '@mui/material/Typography';
import { motion, useScroll, useTransform } from 'framer-motion';
import { useRef } from 'react';
import useBreakpoint from 'use-breakpoint';

import { BREAKPOINTS } from '../../constants';
import { OUR_WORK_LEFT_LIST, OUR_WORK_RIGHT_LIST } from '../../MOCK';
import theme from '../../theme';
import BackgroundTitle from '../BackgroundTitle';
import Container from '../Container';
import { SliderAdaptiveContainer, SliderBackground } from '../Slider/styles';
import OurWorkItems from './OurWorkItems';
import OurWorkSlider from './OurWorkSlider';
import { OurWorkContainer, OurWorkContent, OurWorkSubTitle, StyledOurWork } from './styles';

function useParallax(value, distance) {
  return useTransform(value, [0, 1], [-distance, distance]);
}

const OurWork = () => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  const isTablet = ['xxxs', 'xxs', 'xs', 'sm', 'md', 'lg'].includes(breakpoint);
  const isMobile = ['xxxs', 'xxs', 'xs', 'sm'].includes(breakpoint);

  const reference = useRef(null);
  const listInnerReference = useRef(null);

  const { scrollYProgress } = useScroll({
    container: listInnerReference,
    target: reference,
  });

  const y = useParallax(scrollYProgress, isTablet ? 0 : 300);

  const scrollIsDisabled = useRef(true);

  // eslint-disable-next-line unicorn/prevent-abbreviations
  const onWheel = (e) => {
    if (scrollIsDisabled.current) {
      e.stopPropagation();
    }
  };

  const onScroll = () => {
    if (listInnerReference.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerReference.current;
      if (Math.ceil(scrollTop + clientHeight) === scrollHeight + 1) {
        scrollIsDisabled.current = false;
      } else if (Math.ceil(scrollTop + clientHeight) === scrollHeight) {
        scrollIsDisabled.current = false;
      } else if (scrollTop === 0) {
        scrollIsDisabled.current = false;
      } else {
        scrollIsDisabled.current = true;
      }
    }
  };

  return (
    <StyledOurWork onWheel={onWheel} onScroll={onScroll} ref={listInnerReference}>
      <BackgroundTitle maxWidth='800px'>Our work</BackgroundTitle>
      <Container direction='column'>
        <Typography variant='h2' className='blockTitle'>
          Our work
        </Typography>
        <OurWorkSubTitle variant='body2'>
          Only innovative solutions, no WordPress or Magento
        </OurWorkSubTitle>
        {isMobile ? (
          <SliderAdaptiveContainer>
            <OurWorkSlider />
            <SliderBackground />
          </SliderAdaptiveContainer>
        ) : (
          <OurWorkContainer>
            <OurWorkContent
              ref={reference}
              sx={{
                [theme.breakpoints.down('xl')]: {
                  marginTop: '32px',
                },
              }}
            >
              {OUR_WORK_LEFT_LIST.map((item) => {
                return <OurWorkItems item={item} key={item.id} />;
              })}
            </OurWorkContent>
            <motion.div
              style={{
                y,
                margin: isTablet ? '20px 0px 0px 0px' : '300px 0px',
              }}
            >
              <OurWorkContent>
                {OUR_WORK_RIGHT_LIST.map((item) => {
                  return (
                    <OurWorkItems
                      item={item}
                      key={item.id}
                      sx={{
                        marginTop: '-68px',
                        marginBottom: '94px',
                        [theme.breakpoints.down('xl')]: {
                          '&:last-child': {
                            marginBottom: '26px',
                          },
                        },
                      }}
                    />
                  );
                })}
              </OurWorkContent>
            </motion.div>
          </OurWorkContainer>
        )}
      </Container>
    </StyledOurWork>
  );
};

export default OurWork;
