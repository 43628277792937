import React from 'react';

export const LogoIcon = () => {
  return (
    <svg
      width='142'
      height='43'
      viewBox='0 0 142 43'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M46 29.6987H49.4113V23.0586C49.4113 21.8343 50.2762 21.0205 51.4133 21.0205C52.5344 21.0205 53.2871 21.7119 53.2871 22.7993V29.6987H56.5944V22.9434C56.5944 21.7983 57.3231 21.0205 58.5643 21.0205C59.6534 21.0205 60.4702 21.6326 60.4702 22.8642V29.6987H63.8735V22.2592C63.8735 19.861 62.288 18.4927 59.9977 18.4927C58.196 18.4927 56.7946 19.3209 56.2901 20.5884H56.162C55.7696 19.3065 54.5123 18.4927 52.8227 18.4927C51.1651 18.4927 49.9078 19.2849 49.3953 20.5884H49.2512V18.6367H46V29.6987Z'
        fill='#151515'
      />
      <path
        d='M70.1821 29.9075C71.9999 29.9075 73.1771 29.1945 73.7777 28.1647H73.8738V29.6987H77.1089V22.2376C77.1089 19.6017 74.6265 18.4927 71.8878 18.4927C68.9409 18.4927 67.003 19.7602 66.5306 21.7767L69.6857 22.0071C69.9179 21.2726 70.6466 20.7324 71.8718 20.7324C73.0329 20.7324 73.6976 21.2582 73.6976 22.1656V22.2088C73.6976 22.9218 72.8568 23.0154 70.7187 23.2026C68.2843 23.4043 66.0981 24.1389 66.0981 26.6091C66.0981 28.7984 67.8358 29.9075 70.1821 29.9075ZM71.1591 27.7902C70.1101 27.7902 69.3573 27.3509 69.3573 26.5083C69.3573 25.644 70.1501 25.2191 71.3513 25.0679C72.096 24.9743 73.3132 24.8158 73.7216 24.571V25.7449C73.7216 26.9044 72.6566 27.7902 71.1591 27.7902Z'
        fill='#151515'
      />
      <path d='M82.3015 15.0347H78.8902V29.7839H82.3015V15.0347Z' fill='#151515' />
      <path
        d='M90.1313 30C93.1742 30 95.2243 28.6677 95.7047 26.6152L92.5496 26.4279C92.2053 27.2705 91.3244 27.7098 90.1873 27.7098C88.4816 27.7098 87.4006 26.6944 87.4006 25.0452V25.038H95.7768V24.1953C95.7768 20.436 93.2463 18.5779 89.9951 18.5779C86.3756 18.5779 84.0293 20.8897 84.0293 24.3034C84.0293 27.8107 86.3436 30 90.1313 30ZM87.4006 23.1367C87.4727 21.8764 88.5377 20.8681 90.0512 20.8681C91.5326 20.8681 92.5576 21.8188 92.5657 23.1367H87.4006Z'
        fill='#151515'
      />
      <path
        d='M109.168 18.8906H105.557L103.01 26.8054H102.882L100.328 18.8906H96.7243L101.025 29.784H104.868L109.168 18.8906Z'
        fill='#151515'
      />
      <path d='M111.021 29.784H114.432V18.8906H111.021V29.784Z' fill='#151515' />
      <path
        d='M122.829 29.784C126.104 29.784 128.162 28.4402 128.322 25.898H125.103C124.903 27.079 124.038 27.314 122.869 27.314C121.275 27.314 120.242 26.5389 120.242 24.4216C120.242 22.3331 121.283 21.1376 122.869 21.1376C124.118 21.1376 124.919 21.8793 125.103 22.9812H128.322C128.178 20.4246 126.024 18.7466 122.813 18.7466C119.081 18.7466 116.775 21.0727 116.775 24.4648C116.775 27.828 119.041 29.784 122.829 29.784Z'
        fill='#151515'
      />
      <path
        d='M134.024 23.5574C134.032 22.1314 135.001 21.296 136.394 21.296C137.78 21.296 138.597 22.0954 138.589 23.4565V29.784H142V22.9092C142.008 20.3166 140.31 18.7466 137.748 18.7466C135.882 18.7466 134.633 19.5388 134.072 20.8423H133.928V15.2033H130.613V29.784H134.024V23.5574Z'
        fill='#151515'
      />
      <path d='M111.021 16.94H114.432V12H111.021V16.94Z' fill='#151515' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0 0.00132362L42 0V42L0 42.0013V0.00132362ZM27.0523 31.4961C27.0603 30.046 28.0256 29.1964 29.4137 29.1964C30.7938 29.1964 31.6076 30.0093 31.5996 31.3936V38H34.9981V30.837C35.0061 28.2003 33.3148 26.6036 30.7619 26.6036C28.9032 26.6036 27.6586 27.4092 27.1002 28.7349H26.9566V23.0001H23.6539V38H27.0523V31.4961ZM3.99902 38.0001H7.39749V31.2472C7.39749 30.0021 8.25907 29.1744 9.3919 29.1744C10.5088 29.1744 11.2587 29.8775 11.2587 30.9835V38.0001H14.5534V31.13C14.5534 29.9654 15.2794 29.1744 16.5159 29.1744C17.6009 29.1744 18.4146 29.797 18.4146 31.0494V38.0001H21.8051V30.4342C21.8051 27.9952 20.2255 26.6036 17.9439 26.6036C16.1489 26.6036 14.7529 27.4459 14.2503 28.735H14.1226C13.7317 27.4313 12.4792 26.6036 10.796 26.6036C9.14459 26.6036 7.8921 27.4093 7.38154 28.735H7.23794V26.7501H3.99902V38.0001ZM38.9988 3L32.999 3.00022V9L38.9988 8.99978V3Z'
        fill='#151515'
      />
    </svg>
  );
};
