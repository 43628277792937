export const PROJECTS_COUNTER = [
  {
    id: 1,
    quantity: '50+',
    text: 'experts in our team',
  },
  {
    id: 2,
    quantity: '55+',
    text: 'start-ups launched',
  },
  {
    id: 3,
    quantity: '30+',
    text: 'countries worldwide',
  },
  {
    id: 4,
    quantity: '92%',
    text: 'of long-term clients',
  },
  {
    id: 5,
    quantity: '307',
    text: 'projects completed',
  },
];
