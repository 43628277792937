import TeamBannerImage from '../../assets/images/team_banner.png';
import Container from '../Container';
import { StyledTeamBanner } from './styles';

const TeamBanner = () => {
  return (
    <StyledTeamBanner>
      <Container>
        <img src={TeamBannerImage} alt='Team' />
      </Container>
    </StyledTeamBanner>
  );
};

export default TeamBanner;
