export const ProjectManagerIcon = ({ width = 78, height = 123 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 78 123'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_202_1050)'>
        <path
          d='M64.825 67.0703L77.185 92.4503C76.621 93.6343 75.9159 94.7457 75.085 95.7603C74.6256 96.1279 74.0751 96.3639 73.4922 96.4431C72.9093 96.5224 72.3158 96.4419 71.775 96.2103C70.725 95.6003 63.835 85.7003 63.835 85.7003L64.825 67.0703Z'
          fill='#91B3FA'
        />
        <path
          d='M72.9249 97.0002C72.4357 97.0079 71.953 96.8872 71.5249 96.6502C70.4349 96.0202 64.5849 87.6502 63.4249 85.9902C63.3567 85.902 63.3246 85.7912 63.3349 85.6802L64.3349 67.0502C64.3224 66.9846 64.323 66.9171 64.3366 66.8517C64.3502 66.7862 64.3766 66.7241 64.4142 66.6689C64.4518 66.6136 64.4999 66.5663 64.5558 66.5297C64.6117 66.493 64.6742 66.4677 64.7399 66.4552C64.8056 66.4428 64.873 66.4433 64.9385 66.4569C65.0039 66.4705 65.066 66.4969 65.1213 66.5345C65.1765 66.5721 65.2238 66.6202 65.2605 66.6761C65.2971 66.732 65.3224 66.7946 65.3349 66.8602L77.6949 92.2402C77.7292 92.3085 77.7471 92.3838 77.7471 92.4602C77.7471 92.5366 77.7292 92.612 77.6949 92.6802C77.1065 93.9348 76.3532 95.1052 75.4549 96.1602C74.7232 96.7037 73.8364 96.9981 72.9249 97.0002ZM64.3449 85.5602C67.6049 90.2502 71.3449 95.3902 72.0249 95.7802C72.4784 95.9624 72.9722 96.0204 73.4555 95.9484C73.9389 95.8763 74.3943 95.6768 74.7749 95.3702C75.5176 94.4827 76.1396 93.5008 76.6249 92.4502L65.2249 69.0002L64.3449 85.5602Z'
          fill='#263238'
        />
        <path
          d='M61.2342 104.791H32.1442L30.5742 183.971H37.6242L46.6942 123.781L55.7542 183.971H62.8042L61.2342 104.791Z'
          fill='#263238'
        />
        <path
          d='M62.8145 184.471H55.7645C55.6451 184.47 55.5298 184.428 55.4384 184.351C55.3471 184.274 55.2855 184.168 55.2645 184.051L46.6945 127.141L38.1245 184.051C38.1034 184.168 38.0418 184.274 37.9505 184.351C37.8591 184.428 37.7438 184.47 37.6245 184.471H30.5744C30.5074 184.472 30.4408 184.459 30.3789 184.433C30.317 184.407 30.261 184.369 30.2144 184.321C30.1279 184.235 30.0747 184.122 30.0645 184.001L31.6345 104.821C31.6395 104.691 31.6941 104.567 31.7871 104.476C31.8801 104.385 32.0043 104.333 32.1345 104.331H61.2245C61.3554 104.331 61.481 104.382 61.5745 104.473C61.668 104.565 61.7218 104.69 61.7245 104.821L63.3145 184.001C63.316 184.068 63.3035 184.134 63.2777 184.196C63.2518 184.258 63.2133 184.314 63.1645 184.361C63.1165 184.401 63.0608 184.432 63.0006 184.451C62.9405 184.47 62.8772 184.477 62.8145 184.471ZM56.1945 183.471H62.3045L60.7444 105.291H32.6345L31.0645 183.471H37.1745L46.1745 123.711C46.1934 123.591 46.254 123.483 46.3456 123.404C46.4372 123.325 46.5537 123.281 46.6745 123.281C46.7947 123.28 46.911 123.323 47.0013 123.402C47.0917 123.482 47.1497 123.591 47.1645 123.711L56.1945 183.471Z'
          fill='#263238'
        />
        <path
          d='M51.8743 61.9404L46.7242 63.0304L41.5643 61.9404L28.6143 67.0704L32.1443 104.79H61.2943L64.8242 67.0704L51.8743 61.9404Z'
          fill='#91B3FA'
        />
        <path
          d='M61.2943 105.29H32.1443C32.0216 105.289 31.9039 105.242 31.8136 105.159C31.7232 105.076 31.6665 104.962 31.6543 104.84L28.1243 67.1204C28.112 67.0118 28.1356 66.9021 28.1916 66.8082C28.2476 66.7143 28.3328 66.6413 28.4343 66.6004L41.3843 61.4804C41.4786 61.4505 41.5799 61.4505 41.6743 61.4804L46.6743 62.5504L51.7243 61.4804C51.8186 61.4505 51.9199 61.4505 52.0143 61.4804L65.0643 66.6004C65.1638 66.6439 65.2472 66.7174 65.3027 66.8107C65.3583 66.9039 65.3833 67.0122 65.3743 67.1204L61.8443 104.84C61.838 104.906 61.8188 104.97 61.7877 105.028C61.7566 105.087 61.7143 105.138 61.6631 105.18C61.612 105.222 61.5531 105.253 61.4897 105.272C61.4264 105.291 61.36 105.297 61.2943 105.29ZM32.6043 104.29H60.8443L64.2943 67.4004L51.8343 62.4604L46.8343 63.5204C46.768 63.5304 46.7006 63.5304 46.6343 63.5204L41.6343 62.4604L29.1543 67.4004L32.6043 104.29Z'
          fill='#263238'
        />
        <path
          d='M43.5049 69.7702L45.6149 74.9802L43.9549 97.4402L46.6049 102.31L49.7149 97.2202L47.1649 75.2002L49.3749 70.6602L46.4849 66.4502L43.5049 69.7702Z'
          fill='#263238'
        />
        <path
          d='M46.6147 102.82C46.5287 102.815 46.4452 102.789 46.3719 102.743C46.2986 102.698 46.2377 102.635 46.1947 102.56L43.5347 97.6804C43.4855 97.5995 43.4645 97.5046 43.4747 97.4104L45.0647 75.0604L43.0647 70.0004C43.032 69.9136 43.023 69.8196 43.0389 69.7281C43.0547 69.6367 43.0947 69.5512 43.1547 69.4804L46.1547 66.1504C46.2091 66.0979 46.2733 66.0567 46.3437 66.0292C46.414 66.0018 46.4892 65.9886 46.5647 65.9904C46.6395 65.996 46.7121 66.0176 46.7777 66.0538C46.8433 66.0901 46.9003 66.1401 46.9447 66.2004L49.8347 70.4204C49.8786 70.4964 49.9017 70.5826 49.9017 70.6704C49.9017 70.7582 49.8786 70.8444 49.8347 70.9204L47.6847 75.3304L50.2147 97.2004C50.231 97.3108 50.2099 97.4234 50.1547 97.5204L47.0447 102.62C46.9958 102.687 46.9307 102.741 46.8555 102.776C46.7803 102.811 46.6975 102.826 46.6147 102.82ZM44.4647 97.3304L46.6347 101.33L49.1947 97.1204L46.6647 75.2704C46.6561 75.1743 46.6733 75.0776 46.7147 74.9904L48.8047 70.7104L46.4347 67.2704L44.0647 69.8804L46.0647 74.7904C46.0798 74.8663 46.0798 74.9445 46.0647 75.0204L44.4647 97.3304Z'
          fill='#263238'
        />
        <path
          d='M51.7344 61.9405L46.5744 68.4705L41.7344 61.9405V56.0605H51.7344V61.9405Z'
          fill='white'
        />
        <path
          d='M46.5742 69.0005C46.4983 68.999 46.4237 68.9802 46.3562 68.9456C46.2886 68.9109 46.2298 68.8613 46.1843 68.8006L41.3443 62.2705C41.2773 62.1851 41.2419 62.0791 41.2442 61.9706V56.0605C41.2442 55.9279 41.2969 55.8008 41.3907 55.707C41.4845 55.6132 41.6116 55.5605 41.7442 55.5605H51.7442C51.8769 55.5605 52.004 55.6132 52.0978 55.707C52.1916 55.8008 52.2442 55.9279 52.2442 56.0605V61.9406C52.2465 62.0522 52.2113 62.1613 52.1443 62.2505L46.9743 68.7805C46.9304 68.8469 46.8711 68.9017 46.8014 68.94C46.7317 68.9783 46.6538 68.9991 46.5742 69.0005ZM42.2343 61.8105L46.5843 67.6805L51.2343 61.8006V56.5605H42.2343V61.8105Z'
          fill='#263238'
        />
        <path
          d='M41.1847 61.0205L39.8047 62.7205L41.3647 72.3505L46.5747 68.4705L52.2347 72.2005L53.1747 62.7205L51.8147 61.3605L46.4847 66.4505L41.1847 61.0205Z'
          fill='#91B3FA'
        />
        <path
          d='M41.3648 72.8502C41.3019 72.8607 41.2377 72.8607 41.1748 72.8502C41.0956 72.8149 41.0261 72.761 40.9721 72.6931C40.9182 72.6252 40.8814 72.5453 40.8648 72.4602L39.3148 62.8302C39.3047 62.7596 39.3093 62.6876 39.3282 62.6188C39.3471 62.55 39.38 62.4858 39.4248 62.4302L40.7948 60.7402C40.8388 60.6841 40.895 60.6388 40.9591 60.6076C41.0232 60.5764 41.0935 60.5602 41.1648 60.5602C41.2354 60.5543 41.3065 60.5647 41.3724 60.5907C41.4383 60.6167 41.4973 60.6576 41.5448 60.7102L46.5448 65.7802L51.5448 61.0302C51.6383 60.9386 51.7639 60.8873 51.8948 60.8873C52.0257 60.8873 52.1513 60.9386 52.2448 61.0302L53.5948 62.4002C53.6484 62.4512 53.6897 62.5137 53.7157 62.583C53.7417 62.6523 53.7516 62.7266 53.7448 62.8002L52.8048 72.2802C52.7972 72.3656 52.7673 72.4474 52.7182 72.5176C52.669 72.5878 52.6024 72.6438 52.5248 72.6802C52.4497 72.7214 52.3654 72.7429 52.2798 72.7429C52.1942 72.7429 52.1099 72.7214 52.0348 72.6802L46.6648 69.1502L41.7348 72.8202C41.6202 72.8784 41.4873 72.8891 41.3648 72.8502ZM40.3648 62.8502L41.7548 71.4502L46.3048 68.0602C46.3895 67.9999 46.4908 67.9675 46.5948 67.9675C46.6988 67.9675 46.8001 67.9999 46.8848 68.0602L51.8848 71.3302L52.7148 62.9102L51.8648 62.0702L46.8648 66.8202C46.7713 66.9118 46.6457 66.9632 46.5148 66.9632C46.3839 66.9632 46.2583 66.9118 46.1648 66.8202L41.2448 61.8202L40.3648 62.8502Z'
          fill='#263238'
        />
        <path
          d='M28.6143 67.0703L26.1443 88.4703L11.0343 85.8503L10.6543 90.3303L27.7743 99.4703L31.2643 97.4003L28.6143 67.0703Z'
          fill='#91B3FA'
        />
        <path
          d='M27.7746 100C27.694 100.001 27.6147 99.9803 27.5446 99.9405L10.4246 90.8005C10.3375 90.7557 10.2655 90.6863 10.2175 90.601C10.1695 90.5157 10.1476 90.4181 10.1546 90.3205L10.5346 85.8405C10.5392 85.7708 10.5595 85.7031 10.5942 85.6424C10.6288 85.5818 10.6769 85.5298 10.7346 85.4905C10.7891 85.4477 10.8519 85.4167 10.9191 85.3995C10.9862 85.3823 11.0562 85.3792 11.1246 85.3905L25.7146 87.9205L28.1246 67.0005C28.1246 66.8679 28.1773 66.7407 28.271 66.6469C28.3648 66.5532 28.492 66.5005 28.6246 66.5005C28.7572 66.5005 28.8844 66.5532 28.9781 66.6469C29.0719 66.7407 29.1246 66.8679 29.1246 67.0005L31.7746 97.3305C31.7849 97.4237 31.7676 97.5179 31.725 97.6014C31.6823 97.685 31.6161 97.7542 31.5346 97.8005L28.0646 99.9005C27.9815 99.9646 27.8796 99.9998 27.7746 100ZM11.1846 90.0005L27.7646 98.8605L30.7646 97.0905L28.5546 72.0005L26.6446 88.5105C26.6252 88.6457 26.5533 88.7678 26.4446 88.8505C26.3911 88.8913 26.3296 88.9204 26.2641 88.9359C26.1987 88.9514 26.1307 88.953 26.0646 88.9405L11.4846 86.4205L11.1846 90.0005Z'
          fill='#263238'
        />
        <path d='M64.8549 74.9404H52.5049V94.8204H64.8549V74.9404Z' fill='#263238' />
        <path
          d='M64.8549 95.3205H52.5049C52.3723 95.3205 52.2451 95.2678 52.1513 95.174C52.0576 95.0803 52.0049 94.9531 52.0049 94.8205V75.0005C52.0049 74.8679 52.0576 74.7407 52.1513 74.6469C52.2451 74.5532 52.3723 74.5005 52.5049 74.5005H64.8549C64.9875 74.5005 65.1147 74.5532 65.2084 74.6469C65.3022 74.7407 65.3549 74.8679 65.3549 75.0005V94.8705C65.3424 94.9943 65.2843 95.1089 65.1919 95.1921C65.0994 95.2753 64.9793 95.3211 64.8549 95.3205ZM53.0049 94.3205H64.3549V75.4505H53.0049V94.3205Z'
          fill='#263238'
        />
        <path
          d='M67.145 83.2905L76.435 90.0405C77.12 91.3879 77.2803 92.9416 76.885 94.4005C76.5125 95.3201 75.8647 96.1021 75.0305 96.6391C74.1962 97.1762 73.2163 97.4422 72.225 97.4005C70.425 97.4005 67.2949 91.1005 66.9949 90.6505C66.6949 90.2005 63.835 85.6505 63.835 85.6505L67.145 83.2905Z'
          fill='#91B3FA'
        />
        <path
          d='M72.2244 97.9102C70.9344 97.9102 69.3344 95.9802 66.8544 91.4402C66.7144 91.1802 66.6144 91.0002 66.5744 90.9402C66.2744 90.4802 63.5744 86.1502 63.4044 85.9402C63.3371 85.8328 63.3131 85.7039 63.3373 85.5796C63.3614 85.4552 63.4319 85.3446 63.5344 85.2702L66.8444 82.8602C66.9316 82.8017 67.0343 82.7705 67.1394 82.7705C67.2444 82.7705 67.3471 82.8017 67.4344 82.8602L76.7244 89.6202C76.7919 89.6587 76.8446 89.7185 76.8744 89.7902C77.6367 91.2573 77.8084 92.9605 77.3544 94.5502C76.953 95.5729 76.2437 96.4455 75.3247 97.0475C74.4056 97.6494 73.3223 97.9509 72.2244 97.9102ZM64.5044 85.8202C65.2044 86.9302 67.1644 90.0202 67.4044 90.3802C67.4044 90.4502 67.5644 90.6602 67.7344 90.9602C70.5644 96.1402 71.7344 96.9602 72.2244 96.9602C73.1138 96.9997 73.9937 96.7634 74.7438 96.2837C75.4938 95.804 76.0773 95.1042 76.4144 94.2802C76.7647 92.9914 76.6331 91.619 76.0444 90.4202L67.1444 83.9602L64.5044 85.8202Z'
          fill='#263238'
        />
        <path
          d='M65.6342 81.1804L61.5742 83.4404L61.7242 86.9004C61.7242 86.9004 66.7242 87.9004 67.1442 87.5004C67.5642 87.1004 67.5942 80.2804 65.6342 81.1804Z'
          fill='white'
        />
        <path
          d='M66.455 88.1003C64.8263 88.0156 63.2091 87.7779 61.625 87.3903C61.5161 87.3664 61.418 87.3075 61.3458 87.2226C61.2736 87.1378 61.2311 87.0316 61.225 86.9203L61.075 83.4603C61.0698 83.3668 61.0916 83.2737 61.1376 83.1922C61.1837 83.1107 61.2522 83.0441 61.335 83.0003L65.395 80.7503C65.6754 80.6121 65.9978 80.5858 66.2969 80.6768C66.5959 80.7678 66.8491 80.9693 67.005 81.2403C67.955 82.6003 68.135 87.2403 67.465 87.8703C67.405 87.9403 67.245 88.1003 66.455 88.1003ZM62.205 86.4903C63.7128 86.8535 65.2546 87.058 66.805 87.1003C67.015 86.4203 66.945 82.5003 66.125 81.6803C66.0952 81.6417 66.0523 81.6153 66.0044 81.6061C65.9565 81.5969 65.907 81.6055 65.865 81.6303L62.085 83.7303L62.205 86.4903Z'
          fill='#263238'
        />
        <path
          d='M39.7249 41.4902C39.7249 41.4902 37.0949 42.3702 36.6249 43.9002C36.1549 45.4302 38.6249 49.0802 38.6249 49.0802L39.4449 43.4302L39.7249 41.4902Z'
          fill='#263238'
        />
        <path
          d='M38.6245 49.5803C38.542 49.5804 38.4606 49.5604 38.3874 49.5221C38.3143 49.4837 38.2515 49.4282 38.2046 49.3603C37.9446 48.9603 35.6146 45.4603 36.1446 43.7503C36.6746 42.0403 39.2646 41.1203 39.5646 41.0203C39.6456 40.993 39.7323 40.9869 39.8163 41.0027C39.9003 41.0185 39.9789 41.0555 40.0445 41.1103C40.11 41.1637 40.1601 41.2334 40.19 41.3125C40.2198 41.3915 40.2283 41.477 40.2145 41.5603L39.1146 49.1503C39.1006 49.2492 39.0567 49.3415 38.9888 49.4148C38.9208 49.4881 38.8321 49.5388 38.7345 49.5603L38.6245 49.5803ZM39.1045 42.2903C38.3145 42.6703 37.3345 43.2903 37.1045 44.0503C36.8745 44.8103 37.5845 46.3403 38.3245 47.6503L39.1045 42.2903Z'
          fill='#263238'
        />
        <path
          d='M42.5948 39.7003C41.4686 39.9682 40.4608 40.5968 39.7248 41.4903C37.7948 43.8903 38.5348 46.9903 38.7248 49.9003C38.8348 51.5503 39.4248 60.4803 40.8348 61.3103C41.6784 61.7177 42.6031 61.9293 43.5398 61.9293C44.4766 61.9293 45.4013 61.7177 46.2448 61.3103C48.5517 60.2551 50.6912 58.867 52.5948 57.1903C53.4148 56.2503 53.4248 52.7303 53.4248 52.7203C53.4248 52.2803 54.0548 51.4403 54.2148 51.0103C54.5888 50.0461 54.8375 49.0379 54.9548 48.0103C55.1219 46.3714 54.7243 44.7246 53.8277 43.3425C52.9312 41.9604 51.5895 40.9259 50.0248 40.4103C47.6597 39.4987 45.0898 39.2531 42.5948 39.7003Z'
          fill='white'
        />
        <path
          d='M43.335 62.4302C42.3799 62.4466 41.4376 62.2085 40.605 61.7402C40.165 61.4802 39.005 60.8102 38.255 50.1102V49.9302C38.255 49.4602 38.165 48.9302 38.125 48.5002C37.865 45.9602 37.605 43.3402 39.345 41.1802C40.1459 40.1894 41.2485 39.4871 42.485 39.1802C45.235 38.4602 48.485 39.3302 50.215 39.9102C51.8818 40.4658 53.3107 41.5705 54.268 43.0437C55.2253 44.517 55.6544 46.2714 55.485 48.0202C55.3691 49.0932 55.1101 50.1459 54.715 51.1502C54.6165 51.3908 54.496 51.6218 54.355 51.8402C54.1836 52.104 54.0491 52.39 53.955 52.6902C53.955 53.3102 53.955 56.4602 53.005 57.4902C51.049 59.2249 48.8446 60.6574 46.465 61.7402C45.4768 62.1726 44.4134 62.407 43.335 62.4302ZM42.725 40.1802C41.7 40.4231 40.783 40.9957 40.115 41.8102C38.625 43.6502 38.855 45.9602 39.115 48.4002C39.165 48.8902 39.215 49.4002 39.245 49.8602V50.0402C39.925 59.7202 40.905 60.7602 41.095 60.8802C41.8752 61.2545 42.7296 61.4488 43.595 61.4488C44.4604 61.4488 45.3147 61.2545 46.095 60.8802C48.3347 59.8529 50.4131 58.5055 52.265 56.8802C52.745 56.3302 52.965 54.1302 52.965 52.7402C53.0339 52.2533 53.2191 51.7903 53.505 51.3902C53.6133 51.2219 53.707 51.0445 53.785 50.8602C54.1506 49.9366 54.3894 48.9678 54.495 47.9802C54.6569 46.454 54.2892 44.9186 53.4535 43.6314C52.6178 42.3441 51.3649 41.3834 49.905 40.9102C48.285 40.3402 45.225 39.5302 42.725 40.1802Z'
          fill='#263238'
        />
        <path
          d='M41.9143 39.3104C41.9143 39.3104 39.2143 40.0204 38.9743 41.6704C38.7343 43.3204 40.6243 45.0804 45.0943 45.9004C49.5643 46.7204 50.2643 46.6104 50.2643 46.6104C50.2643 46.6104 48.6143 47.6104 49.2043 48.2504L50.6143 49.6604L50.3843 51.9004C50.3843 51.9004 51.4443 53.1904 51.6743 52.2504C51.9043 51.3104 52.3843 50.1304 52.7343 50.0204C53.0843 49.9104 54.2343 51.0204 54.2343 51.0204C54.5979 49.3024 54.7258 47.5429 54.6143 45.7904C54.3843 43.3204 53.4743 42.2304 50.0443 40.4204C46.6143 38.6104 41.9143 39.3104 41.9143 39.3104Z'
          fill='#263238'
        />
        <path
          d='M51.3543 53.0903C50.7843 53.0903 50.2243 52.4903 49.9943 52.2103C49.914 52.1083 49.8747 51.9799 49.8843 51.8503L50.0943 49.8503L48.8543 48.6103C48.7557 48.5116 48.6804 48.3922 48.6337 48.2608C48.587 48.1293 48.5701 47.9891 48.5843 47.8503C48.6373 47.5405 48.7839 47.2544 49.0043 47.0303C48.2043 46.9403 46.9543 46.7503 45.0043 46.3903C39.8243 45.3903 38.2343 43.2903 38.4743 41.6003C38.7543 39.6003 41.6643 38.8603 41.7843 38.8303H41.8443C42.0443 38.8303 46.7443 38.1203 50.2743 39.9803C53.8043 41.8403 54.8643 43.0803 55.1143 45.7503C55.227 47.553 55.0958 49.3627 54.7243 51.1303C54.7055 51.2182 54.6629 51.2991 54.6012 51.3644C54.5394 51.4296 54.4609 51.4767 54.3743 51.5003C54.2892 51.5236 54.1995 51.5247 54.1138 51.5037C54.028 51.4827 53.9491 51.4403 53.8843 51.3803C53.5812 51.0692 53.246 50.791 52.8843 50.5503C52.5739 51.1269 52.3419 51.7424 52.1943 52.3803C52.1693 52.5474 52.095 52.7032 51.981 52.8279C51.867 52.9526 51.7185 53.0405 51.5543 53.0803C51.4889 53.0966 51.421 53.1 51.3543 53.0903ZM50.9043 51.7303C50.9997 51.8321 51.1034 51.9257 51.2143 52.0103C51.4143 51.3003 51.8943 49.7703 52.5743 49.5403C52.7143 49.4903 53.0543 49.3803 53.9043 50.0503C54.1445 48.6573 54.215 47.2403 54.1143 45.8303C53.9143 43.6903 53.2643 42.6703 49.8143 40.8303C46.6943 39.2003 42.3843 39.7303 42.0143 39.8303C41.3343 40.0103 39.6243 40.7003 39.4743 41.7603C39.3243 42.8203 40.6943 44.6003 45.1843 45.4303C46.8259 45.8168 48.4997 46.0511 50.1843 46.1303C50.2954 46.1194 50.4069 46.146 50.5011 46.2058C50.5954 46.2656 50.6669 46.3552 50.7043 46.4603C50.7436 46.5657 50.7462 46.6813 50.7118 46.7883C50.6773 46.8954 50.6077 46.9877 50.5143 47.0503C50.0443 47.3503 49.5743 47.8103 49.5143 47.9803L50.9143 49.3303C50.9657 49.3846 51.0049 49.4493 51.029 49.5201C51.0532 49.5908 51.0618 49.6659 51.0543 49.7403L50.9043 51.7303Z'
          fill='#263238'
        />
        <path d='M42.615 55.6602L41.085 55.4202L42.145 51.4302L41.795 49.4302' fill='white' />
        <path
          d='M42.6249 55.9103L41.0649 55.6703C41.0304 55.6648 40.9975 55.6522 40.9682 55.6332C40.939 55.6143 40.914 55.5894 40.8949 55.5603C40.8805 55.5289 40.873 55.4948 40.873 55.4603C40.873 55.4258 40.8805 55.3916 40.8949 55.3603L41.8949 51.4203L41.5549 49.4203C41.5451 49.3863 41.5426 49.3507 41.5476 49.3157C41.5526 49.2807 41.565 49.2472 41.5838 49.2174C41.6027 49.1875 41.6277 49.162 41.6572 49.1425C41.6867 49.123 41.72 49.11 41.7548 49.1043C41.7897 49.0986 41.8254 49.1004 41.8595 49.1095C41.8936 49.1186 41.9254 49.1348 41.9529 49.1571C41.9803 49.1794 42.0026 49.2073 42.0185 49.2388C42.0344 49.2704 42.0433 49.305 42.0449 49.3403L42.3949 51.3403C42.4051 51.3762 42.4051 51.4143 42.3949 51.4503L41.3949 55.1803L42.6449 55.3703C42.7104 55.3825 42.7687 55.4194 42.8077 55.4734C42.8468 55.5273 42.8637 55.5942 42.8549 55.6603C42.8551 55.7233 42.8315 55.784 42.7888 55.8303C42.7462 55.8767 42.6876 55.9052 42.6249 55.9103Z'
          fill='#263238'
        />
        <path
          d='M40.5042 51.7803C40.7638 51.7803 40.9742 51.3326 40.9742 50.7803C40.9742 50.228 40.7638 49.7803 40.5042 49.7803C40.2446 49.7803 40.0342 50.228 40.0342 50.7803C40.0342 51.3326 40.2446 51.7803 40.5042 51.7803Z'
          fill='#263238'
        />
        <path
          d='M44.6145 52.3105C44.8741 52.3105 45.0845 51.8628 45.0845 51.3105C45.0845 50.7583 44.8741 50.3105 44.6145 50.3105C44.355 50.3105 44.1445 50.7583 44.1445 51.3105C44.1445 51.8628 44.355 52.3105 44.6145 52.3105Z'
          fill='#263238'
        />
        <path
          d='M41.6846 56.8401C41.6846 56.8401 43.0946 58.4801 45.6846 56.6001L41.6846 56.8401Z'
          fill='white'
        />
        <path
          d='M43.3446 57.7505C43.0017 57.7558 42.6613 57.6921 42.3435 57.5633C42.0257 57.4345 41.737 57.2431 41.4946 57.0005C41.4604 56.9504 41.4456 56.8895 41.453 56.8293C41.4604 56.769 41.4895 56.7135 41.5348 56.6731C41.5801 56.6327 41.6385 56.6102 41.6992 56.6097C41.7599 56.6092 41.8187 56.6309 41.8646 56.6705C41.9246 56.7305 43.1746 58.1105 45.5346 56.4005C45.5615 56.3814 45.5919 56.3677 45.6241 56.3603C45.6563 56.3529 45.6896 56.352 45.7221 56.3576C45.7546 56.3632 45.7858 56.3751 45.8137 56.3928C45.8415 56.4105 45.8657 56.4335 45.8846 56.4605C45.9035 56.4866 45.917 56.5162 45.9243 56.5476C45.9315 56.579 45.9324 56.6115 45.9268 56.6433C45.9212 56.675 45.9092 56.7053 45.8917 56.7323C45.8741 56.7593 45.8513 56.7825 45.8246 56.8005C45.1224 57.3771 44.2523 57.7104 43.3446 57.7505Z'
          fill='#263238'
        />
        <path
          d='M39.6846 48.7204C39.6846 48.7204 40.2646 47.7804 41.3246 48.6104L39.6846 48.7204Z'
          fill='white'
        />
        <path
          d='M39.6846 49.0003C39.639 49.0148 39.5901 49.0148 39.5446 49.0003C39.4895 48.9652 39.4503 48.9101 39.4354 48.8466C39.4204 48.783 39.4309 48.7162 39.4646 48.6603C39.6611 48.3779 39.9579 48.1811 40.2946 48.1103C40.5079 48.0817 40.7249 48.1 40.9304 48.1639C41.136 48.2278 41.3251 48.3358 41.4846 48.4803C41.5281 48.5281 41.5523 48.5905 41.5523 48.6553C41.5523 48.72 41.5281 48.7824 41.4846 48.8303C41.4621 48.8542 41.435 48.8732 41.4049 48.8863C41.3748 48.8993 41.3424 48.906 41.3096 48.906C41.2768 48.906 41.2443 48.8993 41.2142 48.8863C41.1841 48.8732 41.157 48.8542 41.1346 48.8303C41.0301 48.731 40.9055 48.6555 40.7691 48.6089C40.6328 48.5623 40.4879 48.5457 40.3446 48.5603C40.245 48.5798 40.1504 48.619 40.0662 48.6757C39.982 48.7324 39.9101 48.8053 39.8546 48.8903C39.835 48.919 39.8099 48.9436 39.7807 48.9625C39.7515 48.9814 39.7188 48.9942 39.6846 49.0003Z'
          fill='#263238'
        />
        <path
          d='M43.5645 48.8405C44.1011 48.6452 44.6892 48.6443 45.2264 48.838C45.7636 49.0317 46.2159 49.4077 46.5045 49.9005'
          fill='white'
        />
        <path
          d='M46.5048 50.1505C46.4598 50.1506 46.4157 50.1386 46.3769 50.1157C46.3382 50.0928 46.3064 50.0599 46.2848 50.0205C46.0269 49.577 45.6202 49.2393 45.1369 49.0672C44.6536 48.8951 44.125 48.8998 43.6448 49.0805C43.5811 49.0999 43.5124 49.094 43.4529 49.0643C43.3934 49.0345 43.3475 48.983 43.3248 48.9205C43.3049 48.8581 43.3093 48.7904 43.3371 48.7311C43.3649 48.6718 43.4141 48.6251 43.4748 48.6005C44.0693 48.3899 44.7186 48.3924 45.3114 48.6076C45.9043 48.8229 46.4039 49.2375 46.7248 49.7805C46.7403 49.81 46.7497 49.8422 46.7525 49.8754C46.7554 49.9086 46.7516 49.942 46.7413 49.9736C46.7311 50.0053 46.7146 50.0346 46.6929 50.0598C46.6712 50.085 46.6446 50.1057 46.6148 50.1205C46.5818 50.141 46.5436 50.1514 46.5048 50.1505Z'
          fill='#263238'
        />
        <path
          d='M50.9843 53.7103C50.9843 53.7103 50.8543 50.0903 52.7343 50.0203C54.6143 49.9503 54.2343 51.0203 54.1143 52.6703C54.1172 53.2052 53.9556 53.7279 53.6516 54.1679C53.3475 54.6079 52.9156 54.9439 52.4143 55.1303'
          fill='white'
        />
        <path
          d='M52.4146 55.6305C52.306 55.6322 52.2 55.5977 52.1131 55.5326C52.0262 55.4674 51.9634 55.3752 51.9346 55.2705C51.8975 55.1432 51.9124 55.0064 51.9762 54.8901C52.04 54.7738 52.1473 54.6876 52.2746 54.6505C52.6755 54.4928 53.0191 54.2172 53.2601 53.8601C53.5012 53.503 53.6282 53.0813 53.6246 52.6505C53.6246 52.4305 53.6246 52.2205 53.6746 52.0305C53.7346 51.4305 53.7846 50.8705 53.6146 50.6905C53.4446 50.5105 53.3546 50.5105 52.7546 50.5405C51.6246 50.5405 51.4546 52.9005 51.4846 53.7105C51.4873 53.7762 51.477 53.8417 51.4543 53.9034C51.4316 53.9651 51.397 54.0217 51.3524 54.07C51.3078 54.1183 51.2542 54.1573 51.1945 54.1849C51.1348 54.2124 51.0703 54.2279 51.0046 54.2305C50.8727 54.2333 50.7448 54.1849 50.6478 54.0954C50.5508 54.0058 50.4923 53.8822 50.4846 53.7505C50.4698 53.0792 50.5302 52.4084 50.6646 51.7505C51.0646 49.9305 52.0046 49.5105 52.7146 49.4905C53.0041 49.4446 53.3002 49.4629 53.5819 49.5441C53.8636 49.6253 54.124 49.7675 54.3446 49.9605C54.8246 50.4605 54.7546 51.2105 54.6746 52.0705C54.6746 52.2638 54.6546 52.4638 54.6146 52.6705C54.6109 53.3108 54.4103 53.9345 54.04 54.4568C53.6697 54.9792 53.1476 55.375 52.5446 55.5905L52.4146 55.6305Z'
          fill='#263238'
        />
        <path
          d='M11.0641 85.8504L8.70413 83.3404L4.87413 82.4604C4.87413 82.4604 4.29413 83.3204 4.64413 83.9204C5.23731 84.6299 5.90817 85.2705 6.64413 85.8305L3.26413 85.7505C3.26413 85.7505 0.864135 83.4005 0.554135 84.5005C0.244135 85.6005 1.27413 88.0904 2.83413 89.2004C4.39413 90.3104 10.6641 90.3305 10.6641 90.3305L11.0641 85.8504Z'
          fill='white'
        />
        <path
          d='M10.6545 90.8305C9.99454 90.8305 4.17455 90.7705 2.53455 89.6005C0.89455 88.4305 -0.295451 85.6805 0.0645486 84.3705C0.098532 84.2212 0.172223 84.0838 0.277836 83.9729C0.383449 83.8621 0.517068 83.7817 0.664555 83.7405C1.46455 83.5205 2.71454 84.5405 3.46454 85.2605H5.21454C4.83498 84.9274 4.49899 84.5478 4.21454 84.1305C4.08606 83.8028 4.04164 83.4481 4.0853 83.0987C4.12897 82.7494 4.25933 82.4166 4.46454 82.1305C4.52193 82.0467 4.60304 81.982 4.69747 81.9446C4.79189 81.9071 4.89533 81.8988 4.99454 81.9205L8.82454 82.8005C8.9225 82.8207 9.01068 82.8736 9.07454 82.9505L11.4446 85.4605C11.4911 85.5136 11.5266 85.5754 11.5489 85.6424C11.5712 85.7094 11.5799 85.7801 11.5745 85.8505L11.1946 90.3205C11.1962 90.3905 11.1831 90.4601 11.1561 90.5247C11.1291 90.5893 11.0889 90.6476 11.038 90.6956C10.9871 90.7437 10.9267 90.7806 10.8606 90.8038C10.7945 90.827 10.7243 90.8361 10.6545 90.8305ZM1.06455 84.7205C1.09426 85.5115 1.29782 86.2862 1.66077 86.9897C2.02372 87.6931 2.53715 88.3079 3.16455 88.7905C4.24455 89.5605 8.28454 89.7905 10.2445 89.7905L10.5645 86.0005L8.46454 83.7605L5.06455 83.0005C5.01196 83.0977 4.97954 83.2044 4.96923 83.3144C4.95892 83.4244 4.97093 83.5353 5.00455 83.6405C5.56374 84.3019 6.19801 84.8959 6.89455 85.4105C6.97812 85.4742 7.03953 85.5625 7.07004 85.6631C7.10056 85.7636 7.09863 85.8712 7.06455 85.9705C7.02485 86.068 6.95705 86.1514 6.86979 86.2101C6.78252 86.2689 6.67975 86.3004 6.57454 86.3005L3.20455 86.2205C3.07775 86.2178 2.95653 86.1678 2.86455 86.0805C2.34773 85.5265 1.73869 85.0663 1.06455 84.7205Z'
          fill='#263238'
        />
        <path
          d='M71.9347 0.470215H28.4347C27.0635 0.470215 25.7485 1.01491 24.7789 1.98447C23.8094 2.95404 23.2647 4.26905 23.2647 5.64021V27.0002C23.2633 27.6795 23.3962 28.3524 23.6555 28.9802C23.9149 29.6081 24.2956 30.1786 24.776 30.6589C25.2563 31.1392 25.8268 31.52 26.4546 31.7794C27.0825 32.0387 27.7554 32.1715 28.4347 32.1702H62.0647V39.5702L66.9947 32.1702H71.9947C73.3484 32.144 74.6379 31.5878 75.5859 30.621C76.534 29.6543 77.0649 28.3542 77.0647 27.0002V5.64021C77.0621 4.27675 76.5221 2.96927 75.5617 2.00141C74.6013 1.03356 73.2981 0.483373 71.9347 0.470215Z'
          fill='white'
        />
        <path
          d='M62.0647 40H61.9247C61.8216 39.9683 61.7313 39.9048 61.6666 39.8185C61.6019 39.7323 61.5662 39.6278 61.5647 39.52V32.62H28.4346C26.9395 32.6201 25.5048 32.0296 24.4429 30.977C23.381 29.9245 22.7778 28.4951 22.7646 27V5.64C22.7752 4.14224 23.3769 2.70927 24.4388 1.65299C25.5007 0.596699 26.9369 0.00259984 28.4346 0H71.9346C73.4376 0.00264374 74.8783 0.600865 75.941 1.66362C77.0038 2.72638 77.602 4.16703 77.6046 5.67V27C77.602 28.503 77.0038 29.9436 75.941 31.0064C74.8783 32.0691 73.4376 32.6674 71.9346 32.67H67.2146L62.4346 39.85C62.3862 39.8986 62.3285 39.9369 62.2649 39.9627C62.2014 39.9885 62.1332 40.0012 62.0647 40ZM28.4346 1C27.1969 1.00264 26.0106 1.49551 25.1354 2.37073C24.2602 3.24595 23.7673 4.43225 23.7646 5.67V27C23.7646 28.2386 24.2567 29.4264 25.1325 30.3022C26.0083 31.178 27.1961 31.67 28.4346 31.67H62.0647C62.1973 31.67 62.3244 31.7227 62.4182 31.8164C62.512 31.9102 62.5647 32.0374 62.5647 32.17V37.92L66.5647 31.92C66.6082 31.8504 66.6686 31.7928 66.7403 31.7526C66.8119 31.7124 66.8925 31.6909 66.9747 31.69H71.9747C73.2089 31.6768 74.388 31.1766 75.2552 30.2982C76.1223 29.4197 76.6074 28.2343 76.6046 27V5.64C76.5941 4.40746 76.0978 3.22884 75.2234 2.36008C74.349 1.49133 73.1672 1.00259 71.9346 1H28.4346Z'
          fill='#263238'
        />
        <path
          d='M40.5743 18.0202C41.6347 18.0202 42.4943 17.1606 42.4943 16.1002C42.4943 15.0398 41.6347 14.1802 40.5743 14.1802C39.5139 14.1802 38.6543 15.0398 38.6543 16.1002C38.6543 17.1606 39.5139 18.0202 40.5743 18.0202Z'
          fill='#263238'
        />
        <path
          d='M50.1846 18.0202C51.245 18.0202 52.1046 17.1606 52.1046 16.1002C52.1046 15.0398 51.245 14.1802 50.1846 14.1802C49.1243 14.1802 48.2646 15.0398 48.2646 16.1002C48.2646 17.1606 49.1243 18.0202 50.1846 18.0202Z'
          fill='#263238'
        />
        <path
          d='M59.7843 18.0202C60.8446 18.0202 61.7043 17.1606 61.7043 16.1002C61.7043 15.0398 60.8446 14.1802 59.7843 14.1802C58.7239 14.1802 57.8643 15.0398 57.8643 16.1002C57.8643 17.1606 58.7239 18.0202 59.7843 18.0202Z'
          fill='#263238'
        />
      </g>
      <defs>
        <clipPath id='clip0_202_1050'>
          <rect width='78' height='123' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
