export const TechnologyNextIcon = ({ width = 142, height = 85 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 142 85'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g>
        <rect width='142' height='85' fill='white' />
        <path
          d='M33.5742 22.3689H60.2997V24.4813H36.0241V40.38H58.8521V42.4924H36.0241V59.9476H60.5781V62.06H33.5742V22.3689ZM62.6938 22.3689H65.5334L78.1167 39.8241L90.9783 22.3689L108.472 0.196777L79.7313 41.6585L94.5417 62.06H91.5908L78.1167 43.493L64.5869 62.06H61.6916L76.6134 41.6585L62.6938 22.3689ZM95.5996 24.4813V22.3689H126.055V24.4813H112.025V62.06H109.575V24.4813H95.5996Z'
          fill='#151515'
        />
        <path
          d='M0.139648 22.3689H3.20194L45.4292 85.2079L27.9787 62.06L2.70084 25.3707L2.58948 62.06H0.139648V22.3689Z'
          fill='#151515'
        />
        <path
          d='M125.809 59.3102C126.315 59.3102 126.684 58.9245 126.684 58.4261C126.684 57.9277 126.315 57.5421 125.809 57.5421C125.308 57.5421 124.933 57.9277 124.933 58.4261C124.933 58.9245 125.308 59.3102 125.809 59.3102ZM128.216 56.9843C128.216 58.4558 129.282 59.417 130.838 59.417C132.494 59.417 133.495 58.4261 133.495 56.7055V50.6476H132.16V56.6996C132.16 57.6548 131.678 58.1651 130.826 58.1651C130.063 58.1651 129.545 57.6904 129.527 56.9843H128.216ZM135.241 56.9072C135.336 58.438 136.617 59.417 138.524 59.417C140.562 59.417 141.837 58.3905 141.837 56.7529C141.837 55.4654 141.11 54.7534 139.346 54.344L138.399 54.1126C137.279 53.8516 136.826 53.5015 136.826 52.8904C136.826 52.1191 137.529 51.6147 138.584 51.6147C139.585 51.6147 140.276 52.1072 140.401 52.8963H141.7C141.623 51.4545 140.347 50.4399 138.602 50.4399C136.725 50.4399 135.473 51.4545 135.473 52.9794C135.473 54.2372 136.182 54.9848 137.738 55.3468L138.846 55.6138C139.984 55.8808 140.484 56.2724 140.484 56.925C140.484 57.6845 139.698 58.2363 138.625 58.2363C137.475 58.2363 136.677 57.7201 136.564 56.9072H135.241Z'
          fill='#151515'
        />
        <rect
          width='142'
          height='85'
          fill='#5A32FB'
          style={{
            mixBlendMode: 'color',
          }}
        />
      </g>
      <defs>
        <clipPath id='clip0_352_511'>
          <rect width='142' height='85' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
