import {
  EyeIcon,
  JiraIcon,
  RefreshIcon,
  ServerIcon,
  SettingsIcon,
  ShieldIcon,
} from '../assets/icons';
import { BookIcon } from '../assets/icons/BookIcon';

export const INFRASTRUCTURE_LIST = [
  {
    id: 1,
    icon: <ServerIcon />,
    title: 'Demo Server',
  },
  {
    id: 2,
    icon: <JiraIcon />,
    title: 'Jira/Bitbucket/ Confluence/etc',
  },
  {
    id: 3,
    icon: <SettingsIcon />,
    title: 'Continuous Development',
  },
  {
    id: 4,
    icon: <RefreshIcon />,
    title: 'Continuous Integration',
  },
  {
    id: 5,
    icon: <EyeIcon />,
    title: "Tracking systems (at client's request)",
  },
  {
    id: 6,
    icon: <ShieldIcon />,
    title: 'Encrypted protocol (HTTPS)',
  },
  {
    id: 7,
    icon: <BookIcon />,
    title: 'Logging system',
  },
];
