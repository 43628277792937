import Box from '@mui/material/Box';
import React, { useMemo } from 'react';

import { SliderSmallArrow } from '../../assets/icons';
import { styles } from './styles';

const RotateArrow = () => {
  return (
    <Box
      sx={{
        transform: 'rotate(180deg)',
        height: '20px',
      }}
    >
      <SliderSmallArrow />
    </Box>
  );
};

export const SliderArrowButton = ({ direction = 'next', onClick, isVertical }) => {
  const iconComponent = useMemo(() => {
    if (direction === 'next') {
      return isVertical ? null : (
        <Box>
          <SliderSmallArrow />
        </Box>
      );
    }
    return isVertical ? null : <RotateArrow />;
  }, [isVertical, direction]);

  return (
    <Box as='button' onClick={onClick} sx={styles.arrowButton(direction === 'next', isVertical)}>
      {iconComponent}
    </Box>
  );
};
