import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';

import theme from '../../theme';
import palette from '../../theme/palette';

export const ModalOverlay = styled('div')({
  overflowY: 'scroll',
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: 6,
});

export const ModalWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  position: 'fixed',
  top: '50%',
  left: '50%',
  width: '400px',
  padding: '24px',
  background: palette.secondary,
  borderRadius: '14px',
  transform: 'translate(-50%, -50%)',
  zIndex: 7,

  '& > h2': {
    marginTop: 0,
  },

  '&.success > h2': {
    color: 'green',
  },

  '&.error > h2': {
    color: 'red',
  },

  [theme.breakpoints.down('sm')]: {
    width: '92%',
  },
});

export const ModalText = styled(Typography)({
  marginTop: '16px',
});

export const ModalBackdrop = styled('div')({
  position: 'fixed',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  background: palette.overlay,
});
