const palette = {
  primary: '#151515',
  secondary: '#FFF',
  black: '#000',
  amethystSmoke: '#9A9AAF',
  porcelain: '#EBF2F7',
  carbonGrey: '#5B5B5B',
  mediumGreen: '#1FAD3F',
  carminePink: '#EF4335',
  purpleBlue: '#5A32FB',
  blueChalk: '#EDEDF8',
  lightBlack: 'rgba(0, 0, 0, 0.1)',
  mediumBlack: 'rgba(21, 21, 21, 0.8)',
  lightGray: 'rgba(0, 0, 0, 0.5)',
  overlay: 'rgba(0, 0, 0, 0.8)',
};

export default palette;
