import { StyledButton } from './styles';

const Button = ({ title, className, type, disabled, onClick }) => {
  return (
    <StyledButton
      variant={'contained'}
      className={className}
      type={type || 'button'}
      disabled={disabled}
      onClick={onClick}
    >
      {title}
    </StyledButton>
  );
};

export default Button;
