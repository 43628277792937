import React from 'react';

export const SmartphoneIcon = () => {
  return (
    <svg width='34' height='42' viewBox='0 0 34 42' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12.25 7V35H29.75V7H12.25ZM10.5 3.5L31.5 3.5C31.9641 3.5 32.4093 3.68437 32.7374 4.01256C33.0656 4.34075 33.25 4.78587 33.25 5.25V36.75C33.25 37.2141 33.0656 37.6592 32.7374 37.9874C32.4093 38.3156 31.9641 38.5 31.5 38.5H10.5C10.0359 38.5 9.59075 38.3156 9.26256 37.9874C8.93437 37.6592 8.75 37.2141 8.75 36.75L8.75 5.25C8.75 4.78587 8.93437 4.34075 9.26256 4.01256C9.59075 3.68437 10.0359 3.5 10.5 3.5ZM21 29.75C21.4641 29.75 21.9092 29.9344 22.2374 30.2626C22.5656 30.5907 22.75 31.0359 22.75 31.5C22.75 31.9641 22.5656 32.4093 22.2374 32.7374C21.9092 33.0656 21.4641 33.25 21 33.25C20.5359 33.25 20.0908 33.0656 19.7626 32.7374C19.4344 32.4093 19.25 31.9641 19.25 31.5C19.25 31.0359 19.4344 30.5907 19.7626 30.2626C20.0908 29.9344 20.5359 29.75 21 29.75Z'
        fill='#151515'
      />
    </svg>
  );
};
