import { createTheme } from '@mui/material/styles';

import palette from './palette';

const theme = createTheme({
  breakpoints: {
    values: {
      xxs: 320,
      xs: 375,
      sm: 481,
      md: 641,
      lg: 901,
      xl: 1200,
    },
  },

  typography: {
    color: palette.primary,
    fontFamily: ['Poppins, sans-serif'].join(','),
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '22px',

    h1: {
      fontSize: '82px',
      fontWeight: 500,
      lineHeight: '90px',
      letterSpacing: '-0.02em',

      '& strong': {
        color: palette.purpleBlue,
        fontSize: '82px',
        fontWeight: 600,
        lineHeight: '90px',
      },

      '@media (max-width: 900px)': {
        fontSize: '34px',
        lineHeight: '42px',

        '& strong': {
          fontSize: '34px',
          lineHeight: '42px',
        },
      },
    },

    h2: {
      margin: 0,
      padding: 0,
      fontSize: '48px',
      fontWeight: 600,
      lineHeight: '72px',
      letterSpacing: '-0.02em',

      '& strong': {
        fontSize: '48px',
        fontWeight: 600,
        lineHeight: '72px',
      },

      '@media (max-width: 900px)': {
        fontSize: '34px',
        lineHeight: '40px',

        '& strong': {
          fontSize: '34px',
          lineHeight: '40px',
        },
      },
    },

    h3: {
      margin: 0,
      padding: 0,
      fontSize: '36px',
      fontWeight: 400,
      lineHeight: '52px',
    },

    h4: {
      margin: 0,
      padding: 0,
      fontSize: '32px',
      fontWeight: 400,
      lineHeight: '42px',

      '& strong': {
        color: palette.primary,
        fontSize: '32px',
        fontWeight: 600,
        lineHeight: '42px',
      },

      '@media (max-width: 900px)': {
        fontSize: '20px',
        fontWeight: 500,
        lineHeight: '28px',

        '& strong': {
          fontSize: '20px',
          fontWeight: 500,
          lineHeight: '28px',
        },
      },
    },

    body1: {
      margin: 0,
      padding: 0,
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '22px',
      letterSpacing: '0.15px',

      '& strong': {
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '22px',
      },
    },

    body2: {
      margin: 0,
      padding: 0,
      fontSize: '20px',
      fontWeight: 400,
      lineHeight: '26px',
    },

    body3: {
      margin: 0,
      padding: 0,
      fontSize: '20px',
      fontWeight: 600,
      lineHeight: '24px',
    },

    body4: {
      margin: 0,
      padding: 0,
      fontSize: '40px',
      fontWeight: 400,
      lineHeight: '48px',
      letterSpacing: '0.15px',

      '& strong': {
        fontSize: '40px',
        fontWeight: 600,
        lineHeight: '48px',
      },

      '& span': {
        color: palette.purpleBlue,
        fontSize: '40px',
        fontWeight: 600,
        lineHeight: '48px',
      },
    },

    subtitle1: {
      margin: 0,
      padding: 0,
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '26px',
    },

    subtitle2: {
      margin: 0,
      padding: 0,
      fontSize: '24px',
      fontWeight: 400,
      lineHeight: '32px',

      '& strong': {
        fontSize: '24px',
        fontWeight: 600,
        lineHeight: '32px',
      },
    },
  },

  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          height: '100%',
          '& *': {
            margin: 0,
            padding: 0,
            listStyle: 'none',
            outline: 'none',
            boxSizing: 'border-box',
          },

          '& a': {
            color: 'inherit',
            textDecoration: 'none',
          },
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '21px',
      },
    },

    MuiToolbar: {
      styleOverrides: {
        regular: {
          minHeight: 64,
        },
      },
    },
  },
});

export default theme;
