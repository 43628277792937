export const CartIcon = ({ color = '#151515', width = 42, height = 42 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 42 42'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7 28L7 7H3.5V3.5H8.75C9.21413 3.5 9.65925 3.68437 9.98744 4.01256C10.3156 4.34075 10.5 4.78587 10.5 5.25V26.25H32.2665L35.7665 12.25L14 12.25V8.75H38.01C38.276 8.75001 38.5385 8.81066 38.7776 8.92734C39.0166 9.04402 39.2259 9.21366 39.3896 9.42338C39.5532 9.63309 39.6669 9.87735 39.722 10.1376C39.7771 10.3978 39.7721 10.6672 39.7075 10.9253L35.3325 28.4253C35.2377 28.8037 35.0192 29.1396 34.7116 29.3796C34.4041 29.6196 34.0251 29.75 33.635 29.75H8.75C8.28587 29.75 7.84075 29.5656 7.51256 29.2374C7.18437 28.9093 7 28.4641 7 28ZM10.5 40.25C9.57174 40.25 8.6815 39.8812 8.02513 39.2249C7.36875 38.5685 7 37.6783 7 36.75C7 35.8217 7.36875 34.9315 8.02513 34.2751C8.6815 33.6187 9.57174 33.25 10.5 33.25C11.4283 33.25 12.3185 33.6187 12.9749 34.2751C13.6313 34.9315 14 35.8217 14 36.75C14 37.6783 13.6313 38.5685 12.9749 39.2249C12.3185 39.8812 11.4283 40.25 10.5 40.25ZM31.5 40.25C30.5717 40.25 29.6815 39.8812 29.0251 39.2249C28.3687 38.5685 28 37.6783 28 36.75C28 35.8217 28.3687 34.9315 29.0251 34.2751C29.6815 33.6187 30.5717 33.25 31.5 33.25C32.4283 33.25 33.3185 33.6187 33.9749 34.2751C34.6313 34.9315 35 35.8217 35 36.75C35 37.6783 34.6313 38.5685 33.9749 39.2249C33.3185 39.8812 32.4283 40.25 31.5 40.25Z'
        fill={color}
      />
    </svg>
  );
};
