import palette from '../../theme/palette';

export const ReactNativeIcon = ({ color = palette.amethystSmoke, width = 76, height = 76 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 76 76'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M34.9261 20.0234L40.9126 30.4885L33.3545 30.2157L34.9261 20.0234Z' fill={color} />
      <path d='M37.8083 21.7676L42.0754 30.4885L33.3545 30.2074L37.8083 21.7676Z' fill={color} />
      <path d='M37.0452 23.5117L40.9126 30.4885L33.3545 30.4128L37.0452 23.5117Z' fill={color} />
      <path d='M40.9335 22.9302L42.0754 30.4883L33.3545 30.3971L40.9335 22.9302Z' fill={color} />
      <path d='M38.1004 32.8139L42.6568 30.5676L33.3545 30.4883L38.1004 32.8139Z' fill={color} />
      <path
        d='M10.0986 27C10.2955 33.5091 22.7143 38.6279 38.0056 38.6279V36.1131C25.6972 36.0509 12.7863 32.2304 12.5795 27H10.0986Z'
        fill={color}
      />
      <path
        d='M51.9593 3.21457C46.3112 0.166935 35.7513 8.41508 28.1221 21.6869L30.2663 22.9304C36.4595 12.2779 46.1587 2.96128 50.7213 5.36806L51.9593 3.21457Z'
        fill={color}
      />
      <path
        d='M10.0986 27C10.2955 20.4909 22.7143 15.3721 38.0056 15.3721V17.8868C25.6972 17.9491 12.7863 21.7699 12.5795 27H10.0986Z'
        fill={color}
      />
      <path
        d='M24.2913 2.58154C18.8356 6.00056 20.668 19.4264 28.3021 32.8141L30.4478 31.5595C24.3557 20.7529 21.1705 7.54328 25.5299 4.75409L24.2913 2.58154Z'
        fill={color}
      />
      <path
        d='M24.2914 51.4186C18.8357 47.9996 20.6678 34.5734 28.3021 21.186L30.4478 22.4403C24.3558 33.2473 21.1706 46.4568 25.5299 49.2463L24.2914 51.4186Z'
        fill={color}
      />
      <path
        d='M24.0518 2.63306C29.7001 -0.414627 40.2601 7.83333 47.889 21.1056L45.7444 22.3488C39.5512 11.6965 29.852 2.38038 25.2894 4.78689L24.0518 2.63306Z'
        fill={color}
      />
      <path
        d='M66.4942 27C66.2932 20.4909 53.6151 15.3721 38.0059 15.3721V17.8868C50.5703 17.9491 63.7505 21.7699 63.9617 27H66.4942Z'
        fill={color}
      />
      <path
        d='M24.0518 51.2331C24.0845 51.2533 24.1173 51.2731 24.1504 51.2921C29.7094 54.5448 40.274 46.2789 47.889 32.9138L45.7769 31.6514C39.5957 42.3881 29.8752 51.7736 25.4011 49.1559C25.3558 49.1295 25.3112 49.1018 25.2672 49.073L24.0518 51.2331Z'
        fill={color}
      />
      <path
        d='M51.9593 51.3556C46.329 54.4596 35.805 46.0541 28.2023 32.5296L28.1221 32.3871L30.2302 31.0698C36.393 41.9977 46.1384 51.6137 50.7168 49.1657L51.9593 51.3556Z'
        fill={color}
      />
      <path
        d='M66.4917 26.4185C66.4933 26.4718 66.4942 26.5254 66.4942 26.5791C66.4942 33.3103 53.7393 38.6278 38.0059 38.6278V36.0567C50.6712 35.9931 63.9619 32.0228 63.9619 26.6096C63.9619 26.5459 63.96 26.4821 63.9562 26.4185H66.4917Z'
        fill={color}
      />
      <path
        d='M51.7775 51.4186C57.135 48.0236 55.3337 34.7037 47.8336 21.4224L47.7 21.186L45.5635 22.379L45.5778 22.4045C51.6485 33.1543 54.8642 46.4232 50.5829 49.2489L51.7775 51.4186Z'
        fill={color}
      />
      <path
        d='M52.1049 3.1626C57.9015 6.51586 55.9545 19.6839 47.8434 32.8137L45.5635 31.5836C52.0368 20.9845 55.4205 8.02892 50.7889 5.29306L52.1049 3.1626Z'
        fill={color}
      />
      <path
        d='M4.89279 75.9327L3.16488 72.8808H2.42434V75.9327H0.853516V68.101H3.79321C4.3991 68.101 4.91523 68.2094 5.34159 68.4264C5.76796 68.6358 6.08587 68.9238 6.29531 69.2903C6.51223 69.6494 6.6207 70.0533 6.6207 70.5021C6.6207 71.0182 6.47109 71.4857 6.17189 71.9046C5.87268 72.316 5.42762 72.6003 4.83669 72.7574L6.71046 75.9327H4.89279ZM2.42434 71.7027H3.73711C4.16347 71.7027 4.48138 71.6017 4.69082 71.3997C4.90027 71.1903 5.00499 70.9023 5.00499 70.5358C5.00499 70.1767 4.90027 69.8999 4.69082 69.7055C4.48138 69.5035 4.16347 69.4025 3.73711 69.4025H2.42434V71.7027Z'
        fill={color}
      />
      <path
        d='M9.38788 69.3689V71.3324H12.0246V72.5778H9.38788V74.6536H12.3612V75.9327H7.81705V68.0898H12.3612V69.3689H9.38788Z'
        fill={color}
      />
      <path
        d='M18.3666 74.4404H15.2474L14.7312 75.9327H13.0819L15.8981 68.0898H17.727L20.5433 75.9327H18.8827L18.3666 74.4404ZM17.9402 73.1837L16.807 69.9074L15.6737 73.1837H17.9402Z'
        fill={color}
      />
      <path
        d='M20.9901 72.0056C20.9901 71.2352 21.1621 70.547 21.5062 69.9411C21.8578 69.3277 22.3328 68.8527 22.9312 68.5161C23.5371 68.172 24.214 68 24.962 68C25.8372 68 26.6039 68.2244 27.2622 68.6732C27.9204 69.122 28.3805 69.7429 28.6423 70.5358H26.8358C26.6563 70.1618 26.402 69.8813 26.0728 69.6943C25.7512 69.5072 25.3772 69.4137 24.9508 69.4137C24.4945 69.4137 24.0869 69.5222 23.7278 69.7391C23.3762 69.9486 23.0995 70.2478 22.8975 70.6367C22.703 71.0257 22.6058 71.482 22.6058 72.0056C22.6058 72.5217 22.703 72.978 22.8975 73.3745C23.0995 73.7634 23.3762 74.0664 23.7278 74.2833C24.0869 74.4928 24.4945 74.5975 24.9508 74.5975C25.3772 74.5975 25.7512 74.504 26.0728 74.317C26.402 74.1225 26.6563 73.8382 26.8358 73.4642H28.6423C28.3805 74.2646 27.9204 74.8892 27.2622 75.338C26.6114 75.7793 25.8447 76 24.962 76C24.214 76 23.5371 75.8317 22.9312 75.4951C22.3328 75.151 21.8578 74.676 21.5062 74.0701C21.1621 73.4642 20.9901 72.7761 20.9901 72.0056Z'
        fill={color}
      />
      <path
        d='M35.0891 68.101V69.3689H33.0022V75.9327H31.4314V69.3689H29.3444V68.101H35.0891Z'
        fill={color}
      />
      <path
        d='M45.1585 75.9327H43.5877L40.0308 70.5582V75.9327H38.46V68.0898H40.0308L43.5877 73.4755V68.0898H45.1585V75.9327Z'
        fill={color}
      />
      <path
        d='M51.2887 74.4404H48.1694L47.6533 75.9327H46.0039L48.8202 68.0898H50.6491L53.4654 75.9327H51.8048L51.2887 74.4404ZM50.8623 73.1837L49.729 69.9074L48.5958 73.1837H50.8623Z'
        fill={color}
      />
      <path
        d='M59.6232 68.101V69.3689H57.5363V75.9327H55.9655V69.3689H53.8785V68.101H59.6232Z'
        fill={color}
      />
      <path d='M62.1158 68.101V75.9327H60.545V68.101H62.1158Z' fill={color} />
      <path
        d='M70.4784 68.101L67.6061 75.9327H65.6986L62.8263 68.101H64.5093L66.6636 74.3282L68.8066 68.101H70.4784Z'
        fill={color}
      />
      <path
        d='M72.7667 69.3689V71.3324H75.4034V72.5778H72.7667V74.6536H75.74V75.9327H71.1958V68.0898H75.74V69.3689H72.7667Z'
        fill={color}
      />
    </svg>
  );
};
