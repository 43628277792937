import React from 'react';

export const PwaIcon = () => {
  return (
    <svg
      width='308'
      height='116'
      viewBox='0 0 308 116'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_157_228)'>
        <path
          d='M226.681 95.2215L235.578 72.7159H261.271L249.077 38.5724L264.326 0L308 116H275.792L268.327 95.2215H226.681Z'
          fill='#3D3D3D'
        />
        <path
          d='M199.202 116L245.955 0H214.962L182.977 74.9604L160.232 0H136.404L111.987 74.9604L94.7642 40.8049L79.1777 88.8308L95.0048 116H125.51L147.575 48.7781L168.63 116H199.202Z'
          fill='#5A0FC8'
        />
        <path
          d='M29.4224 76.176H48.516C53.7299 76.2493 58.9287 75.5992 63.9641 74.2443L68.903 59.026L82.7028 16.4941C81.6422 14.8155 80.4343 13.2346 79.0934 11.7703C72.0191 3.92343 61.6561 0 48.0047 0H0V116H29.4224V76.176ZM54.688 26.6878C57.4552 29.4719 58.8388 33.2007 58.8388 37.8744C58.8388 42.548 57.6237 46.2809 55.1934 49.073C52.5264 52.1379 47.6137 53.6704 40.4551 53.6704H29.4224V22.5056H40.5393C47.2006 22.5056 51.9189 23.8996 54.6941 26.6878H54.688Z'
          fill='#3D3D3D'
        />
      </g>
      <defs>
        <clipPath id='clip0_157_228'>
          <rect width='308' height='116' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
