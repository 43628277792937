import React from 'react';

export const BarChartIcon = () => {
  return (
    <svg width='42' height='42' viewBox='0 0 42 42' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M3.5 22.75H14V36.75H3.5L3.5 22.75ZM28 14L38.5 14V36.75H28V14ZM15.75 5.25L26.25 5.25V36.75H15.75V5.25ZM7 26.25L7 33.25H10.5V26.25H7ZM19.25 8.75V33.25H22.75V8.75H19.25ZM31.5 17.5V33.25H35V17.5H31.5Z'
        fill='#151515'
      />
    </svg>
  );
};
