import Typography from '@mui/material/Typography';

import { PRICES_LIST } from '../../MOCK';
import Container from '../Container';
import { PricesContent, PricesDescription, PricesItem, StyledPrices } from './styles';

const Prices = () => {
  return (
    <Container>
      <StyledPrices>
        <Typography variant='h2'>Estimated prices for project development</Typography>
        <PricesContent>
          {PRICES_LIST.map((item) => {
            return (
              <PricesItem key={item.id}>
                <div className='pricesTop'>
                  <div className='icon'>{item.icon}</div>
                  <div className='price'>{item.price}</div>
                </div>
                <div className='line' />
                <div className='text'>{item.text}</div>
              </PricesItem>
            );
          })}
        </PricesContent>
        <PricesDescription variant='body1'>
          Malevich is European Union registered company - MalevichStudio OÜ (16358962). It has an EU
          jurisdiction and is connected to SEPA.
        </PricesDescription>
      </StyledPrices>
    </Container>
  );
};

export default Prices;
