import React from 'react';

import { OUR_WORK_LIST } from '../../MOCK';
import { Slider } from '../Slider';
import OutWorkSliderContent from './OutWorkSliderContent';

const OurWorkSlider = () => {
  return (
    <>
      <Slider
        settings={{
          autoplay: false,
        }}
        isArrowBox
        portalIdNext='ourwork-next-arrow'
        portalIdPrev='ourwork-prev-arrow'
        width='100%'
        margin='0px'
        wrapperProps={{
          marginBottom: '68px',
        }}
      >
        {OUR_WORK_LIST.map((item) => {
          return <OutWorkSliderContent item={item} key={item.id} />;
        })}
      </Slider>
    </>
  );
};

export default OurWorkSlider;
