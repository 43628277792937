import React from 'react';

export const StatisticsPwaIcon = () => {
  return (
    <svg
      width='580'
      height='336'
      viewBox='0 0 580 336'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect opacity='0.4' x='106' y='280' width='48' height='32' fill='#5A32FB' />
      <rect opacity='0.5' x='170' y='240' width='48' height='72' fill='#5A32FB' />
      <rect opacity='0.6' x='234' y='184' width='48' height='128' fill='#5A32FB' />
      <rect opacity='0.7' x='298' y='144' width='48' height='168' fill='#5A32FB' />
      <rect opacity='0.8' x='362' y='104' width='48' height='208' fill='#5A32FB' />
      <rect x='426' y='24' width='48' height='288' fill='#5A32FB' />
      <path
        d='M346.992 0.517709C346.725 0.034049 346.117 -0.141931 345.633 0.124678L337.751 4.46928C337.268 4.73589 337.092 5.34409 337.358 5.82777C337.625 6.31145 338.233 6.48741 338.717 6.2208L345.723 2.35894L349.585 9.36509C349.851 9.84877 350.46 10.0247 350.943 9.75812C351.427 9.49151 351.603 8.88329 351.336 8.39963L346.992 0.517709ZM79.0057 207.471C140.822 207.119 200.184 182.016 247.902 143.809C295.616 105.603 331.753 54.2433 347.077 1.27837L345.155 0.722527C329.962 53.2373 294.091 104.263 246.652 142.247C199.216 180.23 140.278 205.122 78.9943 205.471L79.0057 207.471Z'
        fill='#EF4335'
      />
    </svg>
  );
};
