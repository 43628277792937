import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';
import '@fontsource/poppins/900.css';
import '@fontsource/poppins/400.css';

import clsx from 'clsx';
import React, { useEffect, useState } from 'react';

import AchieveGoals from '../components/AchieveGoals';
import Advantages from '../components/Advantages';
import InfoBlock from '../components/InfoBlock';
import Infrastructure from '../components/Infrastructure';
import ModalError from '../components/Modals/ModalError';
import ModalSuccess from '../components/Modals/ModalSuccess';
import OurExpertise from '../components/OurExpertise';
import OurTeam from '../components/OurTeam';
import OurWork from '../components/OurWork';
import Platform from '../components/Platform';
import PreviewHeader from '../components/PreviewHeader';
import Prices from '../components/Prices';
import TeamBanner from '../components/TeamBanner';
import TechnologyStack from '../components/TechnologyStack';
import WhoWeAre from '../components/WhoWeAre';
import WhyMalevich from '../components/WhyMalevich';
import WorkPrinciples from '../components/WorkPrinciples';
import { ModalProvider } from '../context/modals';
import Footer from './footer';
import Header from './header';
import { SlideSection, SlideSectionCenter, StyledLayout } from './styles';

const Layout = ({ currentSlideNumber, parallaxScrollTo }) => {
  const [isLastSlide, setIsLastSlide] = useState(false);

  const handleClickHireUs = () => {
    parallaxScrollTo(13);
  };

  useEffect(() => {
    setIsLastSlide(true);
  }, [isLastSlide, currentSlideNumber]);
  return (
    <ModalProvider>
      <StyledLayout>
        <PreviewHeader className={clsx('slide-background', 'slide-background-translate')} />
        <Header handleClickHireUs={handleClickHireUs} />
        <SlideSectionCenter className='slide-background'>
          <WhoWeAre />
          <Platform />
        </SlideSectionCenter>
        <SlideSectionCenter className='slide-background'>
          <OurExpertise />
        </SlideSectionCenter>
        <SlideSectionCenter className='slide-background'>
          <TechnologyStack />
        </SlideSectionCenter>
        <SlideSectionCenter className='slide-background'>
          <Advantages currentSlideNumber={currentSlideNumber} />
        </SlideSectionCenter>
        <SlideSectionCenter className='slide-background'>
          <WorkPrinciples />
        </SlideSectionCenter>
        <SlideSectionCenter className='slide-background'>
          <OurTeam currentSlideNumber={currentSlideNumber} />
          <Infrastructure />
        </SlideSectionCenter>
        <SlideSection
          sx={{
            '@media (min-width: 1201px) and (min-height: 753px)': {
              height: 'unset',
            },
          }}
          className='slide-background'
        >
          <OurWork />
        </SlideSection>
        <SlideSectionCenter className='slide-background'>
          <Prices />
        </SlideSectionCenter>
        <SlideSection
          sx={{
            '@media (min-width: 1201px) and (min-height: 753px)': {
              height: 'unset',
            },
          }}
          className='slide-background'
        >
          <AchieveGoals />
        </SlideSection>
        <SlideSectionCenter className='slide-background'>
          <WhyMalevich currentSlideNumber={currentSlideNumber} />
        </SlideSectionCenter>
        <SlideSectionCenter className='slide-background'>
          <TeamBanner />
        </SlideSectionCenter>
        <SlideSectionCenter className='slide-background'>
          <InfoBlock currentSlideNumber={currentSlideNumber} />
        </SlideSectionCenter>
        <SlideSectionCenter className='slide-background'>
          <Footer />
        </SlideSectionCenter>
      </StyledLayout>
      <ModalSuccess />
      <ModalError />
    </ModalProvider>
  );
};

export default Layout;
