import React from 'react';

export const SmartphoneFillIcon = () => {
  return (
    <svg width='58' height='58' viewBox='0 0 58 58' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M14.4997 4.83301L43.4997 4.83301C44.1406 4.83301 44.7553 5.08762 45.2085 5.54083C45.6617 5.99405 45.9163 6.60873 45.9163 7.24967V50.7497C45.9163 51.3906 45.6617 52.0053 45.2085 52.4585C44.7553 52.9117 44.1406 53.1663 43.4997 53.1663H14.4997C13.8587 53.1663 13.244 52.9117 12.7908 52.4585C12.3376 52.0053 12.083 51.3906 12.083 50.7497L12.083 7.24967C12.083 6.60873 12.3376 5.99405 12.7908 5.54083C13.244 5.08762 13.8587 4.83301 14.4997 4.83301ZM28.9997 41.083C28.3587 41.083 27.744 41.3376 27.2908 41.7908C26.8376 42.244 26.583 42.8587 26.583 43.4997C26.583 44.1406 26.8376 44.7553 27.2908 45.2085C27.744 45.6617 28.3587 45.9163 28.9997 45.9163C29.6406 45.9163 30.2553 45.6617 30.7085 45.2085C31.1617 44.7553 31.4163 44.1406 31.4163 43.4997C31.4163 42.8587 31.1617 42.244 30.7085 41.7908C30.2553 41.3376 29.6406 41.083 28.9997 41.083Z'
        fill='#5A32FB'
      />
    </svg>
  );
};
