import Typography from '@mui/material/Typography';

import WhyMalevichImg from '../../assets/images/why_we_banner.png';
import { WHY_MALEVICH_LIST } from '../../MOCK';
import BackgroundTitle from '../BackgroundTitle';
import Container from '../Container';
import { StyledWhyMalevich, WhyMalevichContent, WhyMalevichImage } from './styles';
import WhyMalevichItems from './WhyMalevichItems';

const WhyMalevich = ({ currentSlideNumber }) => {
  return (
    <StyledWhyMalevich>
      <BackgroundTitle maxWidth='740px'>Why Malevich</BackgroundTitle>
      <Container direction='column'>
        <Typography variant='h2' className='title'>
          Why Malevich
        </Typography>
        <WhyMalevichContent>
          {WHY_MALEVICH_LIST.map((item, index) => {
            return (
              <WhyMalevichItems
                key={item.id}
                item={item}
                index={index}
                currentSlideNumber={currentSlideNumber}
              />
            );
          })}
        </WhyMalevichContent>
        <WhyMalevichImage src={WhyMalevichImg} alt='Why Malevich' />
      </Container>
    </StyledWhyMalevich>
  );
};

export default WhyMalevich;
