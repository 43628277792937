import { Link } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';

import theme from '../../theme';
import palette from '../../theme/palette';

export const StyledFooter = styled(Box)({
  position: 'relative',
  margin: '30px 0 64px',

  '& .title': {
    marginTop: '92px',
    textTransform: 'uppercase',
  },

  [theme.breakpoints.down('xl')]: {
    margin: '64px 0 0',

    '& .title': {
      marginTop: '177px',
    },
  },

  [theme.breakpoints.down('lg')]: {
    '& .title': {
      marginTop: '197px',
    },
  },
});

export const FooterContent = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '48px',
  zIndex: 1,
  [theme.breakpoints.down('xl')]: {
    maxWidth: '100%',
    flexDirection: 'column-reverse',
  },
});

export const FooterTitle = styled(Typography)({
  fontWeight: 500,
  lineHeight: '26px',
  textTransform: 'uppercase',
});

export const FooterInfo = styled(Box)({
  '& p': {
    marginTop: '16px',
  },
});

export const FooterLinks = styled(Box)({
  marginTop: '24px',

  '& p': {
    marginTop: '16px',

    '& a': {
      color: palette.purpleBlue,
      fontWeight: 600,
    },
  },

  [theme.breakpoints.down('xl')]: {
    '& p a': {
      color: palette.secondary,
    },
  },
});

export const ContactsDescription = styled(Box)({
  fontSize: 14,
  fontWeight: 400,
  lineHeight: '26px',
  letterSpacing: '0.15px',
  marginBottom: 19,

  [theme.breakpoints.down('xl')]: {
    marginTop: 26,
  },
});

export const StyledLink = styled(Link)({
  fontSize: 20,
  fontWeight: 600,
  lineHeight: '26px',
  letterSpacing: '0.15px',
  textDecoration: 'none',
  color: palette.primary,

  [theme.breakpoints.down('xl')]: {
    color: palette.secondary,
  },
});

export const LeftBlock = styled(Box)({
  maxWidth: '375px',
  width: '100%',
  marginRight: '165px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  [theme.breakpoints.down('xl')]: {
    backgroundColor: palette.primary,
    color: palette.secondary,
    padding: '26px',
    width: 'calc(100vw - 26px)',
    maxWidth: 'calc(100vw - 26px)',
    margin: '0px -26px',
  },

  [theme.breakpoints.down('md')]: {
    padding: '26px 16px',
    width: 'calc(100vw - 16px)',
    maxWidth: 'calc(100vw - 16px)',
    margin: '0px -16px',
  },
});

export const RightBlock = styled(Box)({
  maxWidth: '630px',
  width: '100%',
});

export const FooterArrowHeading = styled(Box)({
  display: 'flex',
  marginBottom: '50px',

  '& svg': {
    display: 'flex',
    flexShrink: 0,
    width: '8px',
    height: '82px',
    marginRight: '26px',
  },
});
