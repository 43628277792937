export const NewspaperIcon = ({ color = '#151515', width = 42, height = 42 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 42 42'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M3.5 6.98775C3.5032 6.52816 3.68708 6.08828 4.0119 5.76313C4.33672 5.43798 4.77642 5.25367 5.236 5.25L36.764 5.25C37.723 5.25 38.5 6.02875 38.5 6.98775V35.0122C38.4968 35.4718 38.3129 35.9117 37.9881 36.2369C37.6633 36.562 37.2236 36.7463 36.764 36.75H5.236C4.77542 36.7495 4.33387 36.5662 4.00836 36.2404C3.68284 35.9146 3.5 35.4728 3.5 35.0122L3.5 6.98775ZM7 8.75L7 33.25H35V8.75H7ZM10.5 12.25H21V22.75H10.5V12.25ZM14 15.75V19.25H17.5V15.75H14ZM10.5 26.25H31.5V29.75H10.5V26.25ZM24.5 12.25H31.5V15.75H24.5V12.25ZM24.5 19.25H31.5V22.75H24.5V19.25Z'
        fill={color}
      />
    </svg>
  );
};
