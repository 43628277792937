import React from 'react';

import { Error, InputBase, InputWrapper } from './styles';

const CustomInput = ({
  inputType = 'text',
  placeholder,
  inputStyle,
  name,
  value,
  onChange,
  onBlur,
  touched,
  error,
  inputWrapperWidth,
}) => {
  return (
    <InputWrapper className={inputWrapperWidth}>
      <InputBase
        placeholder={placeholder}
        type={inputType}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        error={Boolean(touched && error)}
        className={inputStyle}
      />
      {touched && error && <Error>{error}</Error>}
    </InputWrapper>
  );
};

export default CustomInput;
