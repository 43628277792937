/* eslint-disable no-param-reassign */
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import React, { useEffect, useRef, useState } from 'react';
import useBreakpoint from 'use-breakpoint';

import { BREAKPOINTS } from '../../constants';
import useDragList from '../../hooks/use-drag-list';
import Container from '../Container';
import OurExpertiseItems from './OurExpertiseItems';
import OurExpertiseItemsSlider from './OurExpertiseItemsSlider';
import {
  OurExpertiseAdaptiveContainer,
  OurExpertiseContainer,
  OurExpertiseSliderBackground,
  Tooltip,
  Wrapper,
} from './styles';

const OurExpertise = () => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  const isMobile = ['xxxs', 'xxs', 'xs', 'sm'].includes(breakpoint);
  const expertiseReference = useDragList();

  const containerReference = useRef(null);
  const tooltipReference = useRef(null);

  const [expertiseStyles, setExpertiseStyles] = useState({});

  // eslint-disable-next-line unicorn/prevent-abbreviations
  const handleMouseMove = (ref) => (e) => {
    const { pageX, pageY } = e;
    if (ref.current) {
      // eslint-disable-next-line radix
      if (!Number.parseInt(ref.current.style.opacity)) {
        ref.current.style.opacity = 1;
      }

      ref.current.style.top = `${pageY + 20}px`;
      ref.current.style.left = `${pageX + 20}px`;
    }
  };
  const handleMouseLeave = (reference) => () => {
    reference.current.style.opacity = 0;
  };

  useEffect(() => {
    if (containerReference.current) {
      setExpertiseStyles({
        marginLeft: `${containerReference.current.offsetLeft * -1}px`,
        paddingLeft: `${containerReference.current.offsetLeft}px`,
        width: `calc(100% + ${containerReference.current.offsetLeft * 2}px)`,
      });
    }
  }, []);

  return (
    <Container ref={containerReference}>
      <Box width='100%'>
        <Wrapper>
          <Typography variant='h2'>Our expertise</Typography>
          {isMobile ? (
            <OurExpertiseAdaptiveContainer>
              <OurExpertiseItemsSlider />
              <OurExpertiseSliderBackground />
            </OurExpertiseAdaptiveContainer>
          ) : (
            <OurExpertiseContainer
              onMouseMove={handleMouseMove(tooltipReference)}
              onMouseLeave={handleMouseLeave(tooltipReference)}
              ref={expertiseReference}
              sx={expertiseStyles}
            >
              <OurExpertiseItems />
            </OurExpertiseContainer>
          )}
          <Tooltip ref={tooltipReference}>Drag</Tooltip>
        </Wrapper>
      </Box>
    </Container>
  );
};

export default OurExpertise;
