export const DeveloperIcon = ({ width = 100, height = 100 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 100 100'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_203_1275)'>
        <path d='M77.2098 126.903H75.3498L72.0498 85.1729H73.9098L77.2098 126.903Z' fill='white' />
        <path
          d='M77.2096 127.403H75.3596C75.2337 127.403 75.1122 127.356 75.0195 127.271C74.9268 127.186 74.8697 127.068 74.8596 126.943L71.5596 85.2129C71.5504 85.1438 71.5573 85.0735 71.5799 85.0075C71.6024 84.9416 71.64 84.8818 71.6896 84.8328C71.7349 84.7822 71.7904 84.7418 71.8525 84.7142C71.9145 84.6866 71.9817 84.6725 72.0496 84.6729H73.9096C74.0356 84.6725 74.1571 84.7196 74.2498 84.8049C74.3425 84.8902 74.3995 85.0073 74.4096 85.1328L77.7096 126.863C77.7152 126.932 77.7063 127.001 77.6837 127.067C77.6611 127.132 77.6251 127.192 77.5781 127.243C77.5311 127.293 77.4741 127.334 77.4107 127.361C77.3473 127.389 77.2788 127.403 77.2096 127.403ZM75.8196 126.403H76.6696L73.4196 85.6729H72.5596L75.8196 126.403Z'
          fill='#263238'
        />
        <path d='M55.7801 126.903H53.9201L50.6201 85.1729H52.4701L55.7801 126.903Z' fill='white' />
        <path
          d='M55.7797 127.403H53.9198C53.7938 127.403 53.6723 127.356 53.5796 127.271C53.4869 127.186 53.4298 127.068 53.4198 126.943L50.1198 85.2128C50.1142 85.1439 50.123 85.0746 50.1457 85.0092C50.1683 84.9438 50.2043 84.8839 50.2513 84.8331C50.2983 84.7824 50.3553 84.7419 50.4187 84.7143C50.4821 84.6868 50.5506 84.6726 50.6198 84.6729H52.4697C52.5957 84.6725 52.7172 84.7196 52.8099 84.8049C52.9026 84.8902 52.9597 85.0073 52.9697 85.1328L56.2698 126.863C56.279 126.932 56.2721 127.002 56.2495 127.068C56.2269 127.134 56.1894 127.194 56.1398 127.243C56.0945 127.293 56.039 127.334 55.9769 127.361C55.9149 127.389 55.8477 127.403 55.7797 127.403ZM54.3797 126.403H55.2398L52.0098 85.6729H51.1597L54.3797 126.403Z'
          fill='#263238'
        />
        <path d='M29.9102 126.903H31.7601L35.0602 85.1729H33.2101L29.9102 126.903Z' fill='white' />
        <path
          d='M31.7598 127.403H29.9098C29.8406 127.403 29.7722 127.389 29.7088 127.361C29.6453 127.334 29.5883 127.293 29.5413 127.243C29.4943 127.192 29.4584 127.132 29.4357 127.067C29.4131 127.001 29.4043 126.932 29.4098 126.863L32.7098 85.1328C32.7199 85.0073 32.777 84.8902 32.8697 84.8049C32.9624 84.7196 33.0839 84.6725 33.2098 84.6729H35.0698C35.1377 84.6725 35.2049 84.6866 35.267 84.7142C35.329 84.7418 35.3846 84.7822 35.4298 84.8328C35.4794 84.8818 35.517 84.9416 35.5396 85.0075C35.5621 85.0735 35.5691 85.1438 35.5598 85.2129L32.2598 126.943C32.2497 127.068 32.1927 127.186 32.1 127.271C32.0073 127.356 31.8858 127.403 31.7598 127.403ZM30.4498 126.403H31.2998L34.5198 85.6729H33.6698L30.4498 126.403Z'
          fill='#263238'
        />
        <path d='M51.3398 126.903H53.1998L56.4998 85.1729H54.6398L51.3398 126.903Z' fill='white' />
        <path
          d='M53.2002 127.403H51.3402C51.2723 127.403 51.2051 127.389 51.143 127.362C51.0809 127.334 51.0254 127.294 50.9802 127.243C50.9311 127.193 50.8932 127.134 50.8691 127.068C50.8449 127.003 50.8351 126.933 50.8402 126.863L54.1502 85.133C54.1579 85.0065 54.2143 84.888 54.3075 84.8022C54.4007 84.7165 54.5235 84.6702 54.6502 84.673H56.5001C56.5693 84.6728 56.6378 84.6869 56.7012 84.7145C56.7647 84.7421 56.8217 84.7825 56.8686 84.8333C56.9156 84.884 56.9516 84.944 56.9742 85.0093C56.9969 85.0747 57.0057 85.144 57.0001 85.213L53.7002 126.943C53.6901 127.069 53.633 127.186 53.5403 127.271C53.4476 127.356 53.3261 127.403 53.2002 127.403ZM51.8802 126.403H52.7402L55.9602 85.673H55.1102L51.8802 126.403Z'
          fill='#263238'
        />
        <path
          d='M77.0002 80.0529L83.1102 47.1829C83.1955 46.7263 83.1791 46.2566 83.0621 45.8072C82.9452 45.3577 82.7306 44.9395 82.4336 44.5824C82.1367 44.2254 81.7647 43.9381 81.3441 43.7411C80.9235 43.5442 80.4646 43.4423 80.0002 43.4429H57.7702C57.0147 43.4433 56.2843 43.7136 55.7104 44.2049C55.1365 44.6963 54.757 45.3765 54.6402 46.1229L49.4902 79.9129L77.0002 80.0529Z'
          fill='white'
        />
        <path
          d='M76.9998 80.5532L49.4898 80.4632C49.4178 80.4645 49.3465 80.45 49.2808 80.4206C49.2152 80.3912 49.1568 80.3477 49.1098 80.2932C49.0615 80.2395 49.0258 80.1758 49.005 80.1066C48.9843 80.0375 48.9791 79.9646 48.9898 79.8932L54.1498 46.1032C54.2774 45.2358 54.7139 44.4435 55.379 43.8722C56.044 43.3008 56.893 42.9887 57.7698 42.9932H79.9998C80.5368 42.9947 81.0669 43.114 81.5527 43.3427C82.0386 43.5715 82.4683 43.904 82.8117 44.3169C83.155 44.7299 83.4035 45.2131 83.5396 45.7325C83.6758 46.252 83.6963 46.795 83.5998 47.3232L77.4898 80.1932C77.4606 80.2996 77.3963 80.3931 77.3074 80.4584C77.2185 80.5237 77.11 80.5571 76.9998 80.5532ZM50.0698 79.4632L76.5898 79.5932L82.5898 47.1232C82.6584 46.7396 82.6422 46.3456 82.5423 45.9688C82.4424 45.5921 82.2612 45.2419 82.0115 44.9426C81.7619 44.6434 81.4497 44.4024 81.097 44.2367C80.7442 44.0709 80.3595 43.9844 79.9698 43.9832H57.7698C57.1324 43.979 56.5151 44.2055 56.0317 44.6209C55.5483 45.0363 55.2315 45.6125 55.1398 46.2432L50.0698 79.4632Z'
          fill='#263238'
        />
        <path d='M76.9995 80.0532H30.4395V86.4032H76.9995V80.0532Z' fill='white' />
        <path
          d='M76.9999 86.9132H30.4199C30.2873 86.9132 30.1601 86.8606 30.0664 86.7668C29.9726 86.673 29.9199 86.5458 29.9199 86.4132V80.0532C29.9199 79.9206 29.9726 79.7934 30.0664 79.6997C30.1601 79.6059 30.2873 79.5532 30.4199 79.5532H76.9799C77.1125 79.5532 77.2397 79.6059 77.3335 79.6997C77.4272 79.7934 77.4799 79.9206 77.4799 80.0532V86.4632C77.4679 86.5836 77.4126 86.6956 77.3244 86.7783C77.2361 86.8611 77.1208 86.909 76.9999 86.9132ZM30.9399 85.9132H76.4999V80.5532H30.9399V85.9132Z'
          fill='#263238'
        />
        <path
          d='M61.9 23.1831L56.75 24.2731L51.59 23.1831L40.46 28.3131L42.17 62.0731H71.32L73.05 28.3131L61.9 23.1831Z'
          fill='#91B3FA'
        />
        <path
          d='M71.3195 62.5728H42.1695C42.0426 62.5707 41.9211 62.5214 41.8286 62.4345C41.7362 62.3476 41.6794 62.2293 41.6695 62.1028L39.9595 28.3328C39.955 28.2344 39.9802 28.1369 40.032 28.0531C40.0837 27.9693 40.1595 27.903 40.2495 27.8628L51.4195 22.7328C51.5233 22.6978 51.6357 22.6978 51.7395 22.7328L56.7895 23.8028L61.8395 22.7328C61.9399 22.6978 62.0491 22.6978 62.1495 22.7328L73.2995 27.8628C73.3895 27.903 73.4653 27.9693 73.517 28.0531C73.5688 28.1369 73.594 28.2344 73.5895 28.3328L71.8595 62.1028C71.8557 62.1694 71.8386 62.2346 71.8093 62.2945C71.7799 62.3544 71.7389 62.4079 71.6886 62.4517C71.6382 62.4955 71.5796 62.5287 71.5163 62.5496C71.4529 62.5704 71.386 62.5783 71.3195 62.5728ZM42.6495 61.5728H70.8495L72.5295 28.5728L61.8495 23.6528L56.8495 24.7128C56.7832 24.7228 56.7158 24.7228 56.6495 24.7128L51.6495 23.6528L40.9795 28.5728L42.6495 61.5728Z'
          fill='#263238'
        />
        <path
          d='M68.3602 34.8131L67.9102 49.7531L72.3602 53.3731L76.0502 50.8831C76.0502 50.8831 77.3702 33.0431 76.5402 31.0931C75.9402 29.7131 72.4302 27.9931 71.6102 27.6431'
          fill='#91B3FA'
        />
        <path
          d='M72.3601 53.8732C72.2446 53.8704 72.1329 53.8319 72.0401 53.7632L67.5901 50.1432C67.5297 50.094 67.4818 50.0312 67.4505 49.9599C67.4192 49.8886 67.4054 49.8109 67.4101 49.7332L67.8601 34.8032C67.8652 34.6731 67.9198 34.5499 68.0127 34.4588C68.1057 34.3677 68.23 34.3156 68.3601 34.3132C68.4258 34.3157 68.4903 34.3312 68.55 34.3588C68.6097 34.3863 68.6633 34.4253 68.7079 34.4736C68.7525 34.5219 68.7871 34.5785 68.8098 34.6402C68.8325 34.7019 68.8428 34.7675 68.8401 34.8332L68.4201 49.5232L72.4201 52.7532L75.6001 50.6032C76.1201 43.4732 76.6801 32.6032 76.1101 31.2932C75.6501 30.2232 72.7101 28.6532 71.4401 28.0932C71.3731 28.0721 71.3113 28.0371 71.2587 27.9905C71.2061 27.9439 71.1638 27.8868 71.1348 27.8228C71.1058 27.7588 71.0906 27.6894 71.0902 27.6192C71.0898 27.5489 71.1042 27.4793 71.1324 27.415C71.1607 27.3507 71.2022 27.293 71.2543 27.2458C71.3063 27.1986 71.3677 27.1629 71.4345 27.1411C71.5013 27.1192 71.5719 27.1116 71.6418 27.1189C71.7117 27.1261 71.7793 27.148 71.8401 27.1832C71.8401 27.1832 76.2701 29.1232 77.0301 30.9032C77.8801 32.9032 76.7201 49.0832 76.5801 50.9032C76.5748 50.9791 76.5522 51.0527 76.514 51.1186C76.4759 51.1845 76.4233 51.2407 76.3601 51.2832L72.6701 53.7632C72.5825 53.8345 72.473 53.8733 72.3601 53.8732Z'
          fill='#263238'
        />
        <path
          d='M53.5303 31.0129L55.6403 36.2229L53.9803 54.7229L56.6403 59.6029L59.7403 54.5029L57.1903 36.4429L59.4003 31.9029L56.5103 27.6929L53.5303 31.0129Z'
          fill='#263238'
        />
        <path
          d='M56.6404 60.1032C56.5521 60.1017 56.4659 60.0768 56.3903 60.0312C56.3148 59.9855 56.2527 59.9207 56.2104 59.8432L53.5504 54.9632C53.5004 54.8789 53.4794 54.7806 53.4904 54.6832L55.1404 36.3032L53.0804 31.2032C53.0476 31.1164 53.0387 31.0224 53.0545 30.9309C53.0703 30.8395 53.1103 30.754 53.1704 30.6832L56.1704 27.3532C56.2233 27.2987 56.2873 27.2562 56.358 27.2286C56.4287 27.201 56.5046 27.189 56.5804 27.1932C56.6551 27.1988 56.7277 27.2204 56.7933 27.2567C56.8589 27.2929 56.9159 27.3429 56.9604 27.4032L59.8504 31.6232C59.8942 31.6992 59.9173 31.7854 59.9173 31.8732C59.9173 31.961 59.8942 32.0472 59.8504 32.1232L57.7104 36.5232L60.2304 54.4632C60.2477 54.5781 60.2228 54.6953 60.1604 54.7932L57.0604 59.8932C57.0136 59.9606 56.9505 60.0151 56.8772 60.0518C56.8038 60.0885 56.7224 60.1062 56.6404 60.1032ZM54.4904 54.6232L56.6604 58.6232L59.2204 54.4232L56.7004 36.5332C56.6705 36.4389 56.6705 36.3376 56.7004 36.2432L58.7904 31.9632L56.4204 28.4632L54.0804 31.0832L56.0804 35.9932C56.0948 36.0726 56.0948 36.1539 56.0804 36.2332L54.4904 54.6232Z'
          fill='#263238'
        />
        <path
          d='M61.7598 23.1832L56.5998 29.7132L51.7598 23.1832V17.3032H61.7598V23.1832Z'
          fill='white'
        />
        <path
          d='M56.5999 30.2132C56.5239 30.2117 56.4494 30.1929 56.3818 30.1582C56.3142 30.1236 56.2554 30.074 56.2099 30.0132L51.3599 23.4632C51.2929 23.3778 51.2576 23.2717 51.2599 23.1632V17.2832C51.2599 17.1506 51.3126 17.0234 51.4063 16.9296C51.5001 16.8359 51.6273 16.7832 51.7599 16.7832H61.7599C61.8925 16.7832 62.0197 16.8359 62.1134 16.9296C62.2072 17.0234 62.2599 17.1506 62.2599 17.2832V23.1632C62.2621 23.2748 62.2269 23.3839 62.1599 23.4732L56.9899 30.0032C56.9459 30.0664 56.8877 30.1183 56.82 30.1548C56.7522 30.1913 56.6768 30.2113 56.5999 30.2132ZM52.2599 23.0232L56.6099 28.8932L61.2599 23.0132V17.8032H52.2599V23.0232Z'
          fill='#263238'
        />
        <path
          d='M51.2101 22.2632L49.8301 23.9632L51.3901 33.5932L56.6001 29.7132L62.2601 33.4332L63.2001 23.9632L61.8401 22.6032L56.5101 27.6932L51.2101 22.2632Z'
          fill='#91B3FA'
        />
        <path
          d='M51.4195 34.0929C51.3566 34.1033 51.2924 34.1033 51.2295 34.0929C51.1503 34.0576 51.0808 34.0037 51.0268 33.9358C50.9729 33.8679 50.936 33.788 50.9195 33.7029L49.3695 24.0729C49.3594 24.0022 49.3639 23.9303 49.3829 23.8615C49.4018 23.7926 49.4347 23.7285 49.4795 23.6729L50.8495 21.9829C50.8935 21.9268 50.9496 21.8814 51.0137 21.8503C51.0779 21.8191 51.1482 21.8029 51.2195 21.8029C51.2901 21.7969 51.3611 21.8074 51.427 21.8334C51.493 21.8594 51.552 21.9003 51.5995 21.9529L56.5995 27.0129L61.5995 22.2729C61.6929 22.1813 61.8186 22.13 61.9495 22.13C62.0804 22.13 62.206 22.1813 62.2995 22.2729L63.6495 23.6429C63.7031 23.6939 63.7444 23.7564 63.7704 23.8257C63.7963 23.895 63.8063 23.9692 63.7995 24.0429L62.8595 33.5229C62.8519 33.6082 62.822 33.6901 62.7728 33.7603C62.7237 33.8304 62.6571 33.8865 62.5795 33.9229C62.5044 33.964 62.4201 33.9856 62.3345 33.9856C62.2489 33.9856 62.1646 33.964 62.0895 33.9229L56.7195 30.3929L51.7895 34.0629C51.6748 34.121 51.542 34.1318 51.4195 34.0929ZM50.4195 24.0929L51.8095 32.6929L56.3595 29.3029C56.4442 29.2426 56.5455 29.2102 56.6495 29.2102C56.7534 29.2102 56.8548 29.2426 56.9395 29.3029L61.9395 32.5729L62.7695 24.1529L61.9195 23.3129L56.9195 28.0629C56.826 28.1545 56.7004 28.2058 56.5695 28.2058C56.4386 28.2058 56.3129 28.1545 56.2195 28.0629L51.2995 23.0629L50.4195 24.0929Z'
          fill='#263238'
        />
        <path
          d='M54.5297 0.552999C53.3687 0.572411 52.2455 0.969045 51.3297 1.683C48.9297 3.613 49.9497 8.753 48.5097 11.283C47.2097 13.543 46.9797 20.173 48.1797 21.283C48.9073 21.8626 49.7568 22.2698 50.6643 22.4742C51.5719 22.6786 52.5139 22.6747 53.4197 22.463C55.9005 21.9333 58.2896 21.0404 60.5097 19.813C61.5097 19.073 62.2697 15.633 62.2697 15.623C62.3697 15.193 63.1697 14.513 63.4197 14.123C63.9935 13.2639 64.4509 12.3324 64.7797 11.353C65.7797 8.033 65.2597 3.933 62.3097 2.143C60.3297 0.893003 56.9297 0.462999 54.5297 0.552999Z'
          fill='white'
        />
        <path
          d='M51.9699 23.083C50.4624 23.1425 48.9878 22.632 47.8399 21.653C46.3799 20.303 46.7499 13.353 48.0699 11.033C48.6161 9.7677 48.9181 8.41052 48.9599 7.033C49.2099 4.803 49.4699 2.503 51.0199 1.253C52.0164 0.469359 53.2423 0.0338112 54.5099 0.0130066C57.0299 -0.0869934 60.5099 0.383006 62.6099 1.65301C65.9099 3.65301 66.3199 8.133 65.2999 11.433C64.9594 12.4592 64.4817 13.4347 63.8799 14.333C63.7273 14.5443 63.5602 14.7448 63.3799 14.933C63.1528 15.1513 62.9576 15.4004 62.7999 15.673C62.6099 16.533 61.8899 19.383 60.7999 20.153C58.5212 21.4272 56.0637 22.3508 53.5099 22.893C53.0032 23.0021 52.4878 23.0657 51.9699 23.083ZM54.5499 1.08301C53.4999 1.09016 52.4812 1.44144 51.6499 2.08301C50.4199 3.08301 50.1799 5.16301 49.9599 7.19301C49.903 8.69297 49.5637 10.1688 48.9599 11.543C47.6999 13.743 47.5999 20.053 48.5399 20.923C49.2231 21.4399 50.0129 21.7978 50.8519 21.9708C51.6909 22.1438 52.558 22.1275 53.3899 21.923C55.7958 21.4096 58.1136 20.5472 60.2699 19.363C60.8199 18.953 61.4799 17.103 61.8399 15.473C62.0178 15.0126 62.2982 14.5988 62.6599 14.263C62.806 14.1213 62.9398 13.9674 63.0599 13.803C63.6108 12.9809 64.0513 12.0899 64.3699 11.153C65.2699 8.15301 64.9499 4.243 62.1099 2.523C60.1999 1.363 56.8599 0.933001 54.5699 1.023L54.5499 1.08301Z'
          fill='#263238'
        />
        <path
          d='M59.2298 3.37321C57.1348 2.32119 54.8241 1.77004 52.4798 1.76321C52.1699 1.73759 51.8678 1.65272 51.5898 1.51321C52.4513 0.900253 53.4733 0.552649 54.5298 0.513208C56.9298 0.413208 60.3298 0.853208 62.3498 2.08321C65.2998 3.87321 65.8398 7.97321 64.8198 11.2932C64.4868 12.2709 64.0297 13.2018 63.4598 14.0632L63.2698 14.3032H62.1098C62.1098 14.3032 60.1098 14.5032 59.9898 13.9632C59.9392 13.3609 59.9392 12.7555 59.9898 12.1532C59.9898 11.7532 57.6498 10.4732 57.8898 9.36321C58.1298 8.25321 60.4198 5.82321 60.4198 5.82321C60.4198 5.82321 61.8798 4.86321 59.2298 3.37321Z'
          fill='#263238'
        />
        <path
          d='M61.2801 14.8929C60.4501 14.8929 59.6601 14.7328 59.5101 14.1328C59.4347 13.6894 59.4347 13.2363 59.5101 12.7929C59.5101 12.6229 59.5101 12.4628 59.5101 12.3428C59.3612 12.1845 59.2008 12.0374 59.0301 11.9029C58.2501 11.2429 57.1901 10.3228 57.4101 9.30285C57.6801 8.10285 59.7601 5.75285 60.0001 5.49285C60.0282 5.45755 60.062 5.42714 60.1001 5.40285C60.1561 5.36406 60.2029 5.31356 60.2375 5.25485C60.272 5.19614 60.2934 5.13063 60.3001 5.06285C60.3001 4.93285 60.1801 4.48285 58.9901 3.81285C57.049 2.82672 54.9072 2.29982 52.7301 2.27285H52.4501C52.0902 2.23583 51.7406 2.13063 51.4201 1.96285C51.3411 1.92564 51.2736 1.86802 51.2243 1.79594C51.1751 1.72386 51.146 1.63993 51.1401 1.55285C51.1296 1.46571 51.1437 1.37738 51.1808 1.29785C51.2179 1.21832 51.2766 1.15077 51.3501 1.10285C52.2969 0.465518 53.4089 0.118009 54.5501 0.10285C57.0701 0.00285039 60.5501 0.47285 62.6501 1.74285C65.9501 3.74285 66.3601 8.22285 65.3401 11.5228C64.9996 12.5491 64.5219 13.5246 63.9201 14.4228L63.7201 14.6829C63.6693 14.7436 63.6069 14.7934 63.5364 14.8295C63.466 14.8656 63.389 14.8871 63.3101 14.8929H62.1201C61.8501 14.8729 61.5601 14.8929 61.2801 14.8929ZM60.4601 13.7929C60.9862 13.9031 61.5272 13.9233 62.0601 13.8528H63.0601C63.616 13.0286 64.0599 12.1341 64.3801 11.1929C65.2801 8.19285 64.9601 4.28285 62.1201 2.56285C60.2101 1.40285 56.8701 0.972849 54.5801 1.06285C54.1015 1.07914 53.6272 1.15986 53.1701 1.30285C55.3802 1.372 57.5467 1.93583 59.5101 2.95285C60.7101 3.62285 61.3101 4.31285 61.3301 5.04285C61.3305 5.26909 61.2773 5.49221 61.1747 5.69386C61.0721 5.89552 60.9232 6.06995 60.7401 6.20285C59.8002 7.18702 59.0177 8.31022 58.4201 9.53285C58.3301 9.96285 59.2301 10.7429 59.7101 11.1529C60.1901 11.5629 60.4901 11.8229 60.5301 12.1529C60.5491 12.3925 60.5491 12.6332 60.5301 12.8729C60.4588 13.1744 60.4219 13.483 60.4201 13.7929H60.4601Z'
          fill='#263238'
        />
        <path
          d='M51.1101 16.4029H51.0201L49.5801 15.8429C49.5174 15.8156 49.4673 15.7656 49.4401 15.7029C49.4255 15.6698 49.418 15.6341 49.418 15.5979C49.418 15.5617 49.4255 15.526 49.4401 15.4929L51.3101 11.8629L51.3901 9.8629C51.39 9.82963 51.3968 9.7967 51.4101 9.76619C51.4234 9.73569 51.4429 9.70828 51.4673 9.68571C51.4918 9.66314 51.5206 9.64591 51.5521 9.6351C51.5836 9.62428 51.617 9.62013 51.6501 9.62291C51.683 9.62419 51.7152 9.63194 51.7451 9.64571C51.7749 9.65948 51.8017 9.67899 51.824 9.70314C51.8463 9.72728 51.8636 9.7556 51.875 9.78644C51.8863 9.81729 51.8914 9.85006 51.8901 9.8829L51.8101 11.8829C51.8159 11.916 51.8159 11.9498 51.8101 11.9829L50.0201 15.4629L51.2101 15.9229C51.2713 15.9494 51.32 15.9985 51.3461 16.0599C51.3721 16.1213 51.3736 16.1904 51.3501 16.2529C51.327 16.297 51.2926 16.3341 51.2505 16.3605C51.2083 16.3868 51.1598 16.4015 51.1101 16.4029Z'
          fill='#263238'
        />
        <path
          d='M50.5602 11.0229C50.4402 11.5629 50.1402 11.9629 49.8802 11.9029C49.6202 11.8429 49.5202 11.3629 49.6402 10.8229C49.7602 10.2829 50.0602 9.89287 50.3102 9.95287C50.5602 10.0129 50.6802 10.4629 50.5602 11.0229Z'
          fill='#263238'
        />
        <path
          d='M54.4196 12.463C54.2996 13.003 53.9996 13.393 53.7496 13.333C53.4996 13.273 53.3896 12.793 53.4996 12.253C53.6096 11.713 53.9196 11.323 54.1796 11.383C54.4396 11.443 54.5796 11.893 54.4196 12.463Z'
          fill='#263238'
        />
        <path
          d='M52.05 18.4628C51.5913 18.48 51.1358 18.3786 50.7277 18.1684C50.3195 17.9581 49.9725 17.6463 49.72 17.2628C49.7046 17.2333 49.6951 17.2011 49.6923 17.1679C49.6895 17.1347 49.6933 17.1013 49.7035 17.0697C49.7138 17.038 49.7303 17.0087 49.752 16.9835C49.7737 16.9582 49.8002 16.9376 49.83 16.9228C49.8582 16.9072 49.8891 16.8974 49.9211 16.8939C49.9531 16.8905 49.9854 16.8936 50.0162 16.9029C50.047 16.9122 50.0756 16.9276 50.1003 16.9482C50.125 16.9688 50.1453 16.9942 50.16 17.0228C50.16 17.0928 51.16 18.7128 53.8 17.5428C53.8613 17.5187 53.9294 17.5187 53.9907 17.5428C54.0519 17.5669 54.1017 17.6134 54.13 17.6728C54.1433 17.703 54.1504 17.7355 54.1511 17.7685C54.1517 17.8014 54.1458 17.8342 54.1337 17.8648C54.1216 17.8955 54.1036 17.9235 54.0806 17.9472C54.0577 17.9708 54.0303 17.9897 54 18.0028C53.3877 18.2861 52.7244 18.4426 52.05 18.4628Z'
          fill='#263238'
        />
        <path
          d='M51.4198 9.23316C51.3471 9.22898 51.279 9.19671 51.2298 9.14317C51.1501 9.02298 51.0455 8.92143 50.9229 8.84548C50.8004 8.76954 50.6628 8.72099 50.5198 8.70316C50.3133 8.70016 50.1133 8.77516 49.9598 8.91316C49.9383 8.94182 49.9109 8.96552 49.8794 8.98267C49.848 8.99981 49.8132 9.01 49.7775 9.01253C49.7418 9.01507 49.7059 9.00991 49.6724 8.99738C49.6388 8.98486 49.6083 8.96527 49.583 8.93994C49.5577 8.91461 49.5381 8.88414 49.5255 8.85059C49.513 8.81703 49.5079 8.78117 49.5104 8.74544C49.5129 8.70972 49.5231 8.67495 49.5403 8.6435C49.5574 8.61205 49.5811 8.58465 49.6098 8.56316C49.8588 8.32949 50.1882 8.20059 50.5297 8.20316C50.7443 8.22048 50.9525 8.28505 51.1392 8.39224C51.3259 8.49943 51.4866 8.64659 51.6098 8.82317C51.6401 8.85954 51.6594 8.90376 51.6655 8.95069C51.6717 8.99762 51.6644 9.04534 51.6445 9.08829C51.6246 9.13123 51.5929 9.16765 51.5531 9.1933C51.5133 9.21894 51.4671 9.23277 51.4198 9.23316Z'
          fill='#263238'
        />
        <path
          d='M56.1401 11.6031C56.0864 11.6016 56.0344 11.5834 55.9915 11.5512C55.9485 11.519 55.9166 11.4742 55.9001 11.4231C55.7428 10.936 55.4191 10.5198 54.9858 10.2474C54.5525 9.97499 54.0372 9.86372 53.53 9.93309C53.4652 9.9417 53.3995 9.92453 53.3472 9.88527C53.2948 9.846 53.2599 9.78778 53.25 9.7231C53.2459 9.69023 53.2483 9.65686 53.2571 9.62492C53.2659 9.59298 53.281 9.56312 53.3015 9.53707C53.3219 9.51101 53.3474 9.48929 53.3763 9.47316C53.4053 9.45702 53.4371 9.44681 53.47 9.4431C54.095 9.36069 54.7291 9.49956 55.2624 9.83562C55.7958 10.1717 56.1947 10.6838 56.3901 11.2831C56.4056 11.348 56.3955 11.4164 56.362 11.4741C56.3285 11.5318 56.2741 11.5744 56.2101 11.5931L56.1401 11.6031Z'
          fill='#263238'
        />
        <path
          d='M60.8301 15.5231C60.8301 15.5231 61.9901 12.0831 63.7801 12.6831C65.5701 13.2831 64.8301 14.1431 64.1301 15.6831C63.9443 16.1868 63.6071 16.6206 63.1648 16.925C62.7226 17.2294 62.1969 17.3894 61.6601 17.3831'
          fill='#B2B2B2'
        />
        <path
          d='M61.7401 17.8631H61.6101C61.4775 17.8512 61.3551 17.787 61.2698 17.6848C61.1844 17.5826 61.1432 17.4507 61.1551 17.3181C61.1671 17.1855 61.2312 17.0631 61.3334 16.9777C61.4356 16.8924 61.5675 16.8512 61.7001 16.8631C62.1373 16.8593 62.563 16.7223 62.9203 16.4704C63.2776 16.2185 63.5497 15.8636 63.7001 15.4531C63.7901 15.2431 63.8801 15.0631 63.9701 14.8831C64.2301 14.3431 64.4801 13.8831 64.3901 13.6131C64.3001 13.3431 64.2101 13.3531 63.6401 13.1631C62.5601 12.8031 61.5801 14.9131 61.3201 15.6831C61.3054 15.7522 61.2763 15.8174 61.2345 15.8744C61.1928 15.9314 61.1395 15.979 61.078 16.0139C61.0166 16.0488 60.9484 16.0702 60.8781 16.0769C60.8078 16.0835 60.7368 16.0752 60.6699 16.0524C60.6031 16.0297 60.5418 15.993 60.4901 15.9448C60.4384 15.8966 60.3975 15.838 60.3701 15.7729C60.3428 15.7078 60.3295 15.6376 60.3312 15.5669C60.3329 15.4963 60.3496 15.4268 60.3801 15.3631C60.5942 14.7437 60.883 14.1526 61.2401 13.6031C62.2401 12.0431 63.2401 11.9831 63.9601 12.2131C64.2442 12.2719 64.5121 12.3918 64.7452 12.5645C64.9784 12.7371 65.1711 12.9585 65.3101 13.2131C65.5801 13.8531 65.2501 14.5231 64.8701 15.3031C64.7768 15.4764 64.6868 15.6564 64.6001 15.8431C64.3802 16.4273 63.9901 16.932 63.4802 17.2921C62.9704 17.6522 62.3642 17.8511 61.7401 17.8631Z'
          fill='#263238'
        />
        <path
          d='M74.0298 70.6332L71.3198 62.0732H42.1698L41.1698 66.5632C40.7863 66.5571 40.4044 66.6145 40.0398 66.7332L29.4798 69.1332C27.9107 69.4685 26.5357 70.4055 25.6498 71.7432C25.2588 72.3208 25.0371 72.9963 25.0098 73.6932C25.0098 75.9232 26.6798 116.853 26.6798 116.853H34.1998L38.9498 80.0532H47.1798C47.6398 90.9632 48.6098 116.853 48.6098 116.853H56.1298L60.8798 80.0532H72.0398L71.9698 79.8232H72.0398L74.0298 70.6332Z'
          fill='#263238'
        />
        <path
          d='M56.1501 117.353H48.63C48.5008 117.353 48.3766 117.303 48.2834 117.213C48.1902 117.124 48.1352 117.002 48.13 116.873C48.13 116.623 47.18 91.5928 46.72 80.5528H39.42L34.72 116.923C34.7053 117.042 34.6473 117.152 34.5569 117.231C34.4666 117.31 34.3502 117.354 34.23 117.353H26.7101C26.5808 117.353 26.4566 117.303 26.3634 117.213C26.2702 117.124 26.2152 117.002 26.2101 116.873C26.1401 115.203 24.54 75.8728 24.54 73.6928C24.5644 72.8994 24.8141 72.1294 25.26 71.4728C26.2151 70.0245 27.7027 69.0112 29.4001 68.6528L39.9601 66.2428C40.2329 66.1553 40.5146 66.0983 40.8 66.0728L41.7 61.9728C41.7227 61.8615 41.7826 61.7612 41.8699 61.6885C41.9571 61.6158 42.0665 61.575 42.1801 61.5728H71.32C71.427 61.5721 71.5313 61.6058 71.6176 61.6688C71.704 61.7317 71.768 61.8208 71.8 61.9228L74.51 70.4828C74.53 70.5649 74.53 70.6506 74.51 70.7328L72.51 79.9228C72.5301 79.9942 72.5333 80.0693 72.5194 80.1422C72.5055 80.215 72.4749 80.2837 72.4301 80.3428C72.383 80.4043 72.3225 80.4543 72.2531 80.4889C72.1838 80.5236 72.1075 80.542 72.03 80.5428H61.3401L56.64 116.913C56.6276 117.034 56.5706 117.146 56.48 117.227C56.3894 117.309 56.2718 117.353 56.1501 117.353ZM49.1501 116.353H55.75L60.45 79.9928C60.4646 79.8725 60.5222 79.7616 60.6124 79.6807C60.7025 79.5998 60.8189 79.5543 60.94 79.5528H71.6L73.4901 70.7228L70.94 62.6428H42.58L41.6801 66.7428C41.6567 66.8547 41.595 66.955 41.5055 67.0263C41.416 67.0975 41.3044 67.1352 41.19 67.1328C40.8812 67.1307 40.574 67.1779 40.28 67.2728L29.66 69.6928C28.2241 69.9973 26.9644 70.8515 26.1501 72.0728C25.8042 72.5709 25.6066 73.1569 25.58 73.7628C25.58 75.8228 27.04 111.823 27.23 116.423H33.83L38.53 80.0628C38.5446 79.9425 38.6023 79.8316 38.6924 79.7507C38.7825 79.6698 38.8989 79.6243 39.0201 79.6228H47.25C47.3792 79.6227 47.5035 79.6726 47.5967 79.7621C47.6899 79.8515 47.7449 79.9736 47.75 80.1028C48.12 89.9928 48.98 112.763 49.11 116.353H49.1501Z'
          fill='#263238'
        />
        <path
          d='M40.4604 28.313L31.9503 46.093L13.6604 50.203L13.6104 53.643H36.1604L41.7303 49.753L40.4604 28.313Z'
          fill='#91B3FA'
        />
        <path
          d='M36.1703 54.1528H13.6104C13.4768 54.1502 13.3497 54.0953 13.2562 53.9999C13.1627 53.9046 13.1103 53.7763 13.1104 53.6428L13.1703 50.1928C13.1703 50.0801 13.209 49.9709 13.2801 49.8835C13.3511 49.7961 13.4501 49.7358 13.5604 49.7128L31.6104 45.6628L40.0103 28.0928C40.0591 27.9931 40.1396 27.9125 40.2391 27.8637C40.3387 27.8148 40.4517 27.8004 40.5604 27.8228C40.6692 27.843 40.7679 27.8995 40.8406 27.983C40.9132 28.0665 40.9554 28.1722 40.9604 28.2828L42.2303 49.7228C42.2349 49.8078 42.218 49.8926 42.1813 49.9695C42.1447 50.0464 42.0893 50.1128 42.0204 50.1628L36.4203 54.0628C36.3456 54.1128 36.2598 54.1437 36.1703 54.1528ZM14.1204 53.1528H36.0103L41.2203 49.4628L40.0804 30.2328L32.4103 46.2328C32.3766 46.3016 32.3275 46.3617 32.2668 46.4086C32.2061 46.4554 32.1355 46.4876 32.0604 46.5028L14.1603 50.5028L14.1204 53.1528Z'
          fill='#263238'
        />
        <path
          d='M67.0701 32.7632L62.6201 35.5532L67.0701 52.2532C67.0701 52.2532 72.0701 55.4832 73.1501 54.6432C74.2673 53.5135 75.2412 52.2506 76.0501 50.8832L67.0701 32.7632Z'
          fill='#91B3FA'
        />
        <path
          d='M72.64 55.2631C70.99 55.2631 68.12 53.5331 66.8 52.6731C66.747 52.6417 66.701 52.5998 66.6649 52.5499C66.6288 52.5 66.6033 52.4433 66.59 52.3831L62.13 35.6831C62.1025 35.5785 62.1091 35.4679 62.1486 35.3672C62.1882 35.2666 62.2587 35.1811 62.35 35.1231L66.81 32.3431C66.868 32.304 66.934 32.2783 67.0032 32.2679C67.0724 32.2575 67.143 32.2627 67.21 32.2831C67.3436 32.3254 67.4551 32.4189 67.52 32.5431L76.52 50.6631C76.5621 50.7361 76.5843 50.8189 76.5843 50.9031C76.5843 50.9874 76.5621 51.0701 76.52 51.1431C75.6918 52.5667 74.6835 53.8775 73.52 55.0431C73.2622 55.2189 72.9501 55.2969 72.64 55.2631ZM67.5 51.9431C69.9 53.4731 72.42 54.5631 72.85 54.2431C73.8702 53.2322 74.7543 52.0926 75.48 50.8531L66.87 33.4631L63.19 35.7631L67.5 51.9431Z'
          fill='#263238'
        />
        <path
          d='M67.0695 32.763L65.2895 27.463L61.8395 22.603C61.8395 22.603 59.9895 23.703 60.1595 24.223C60.1595 24.223 57.7695 26.953 57.7695 27.813C57.7695 28.673 58.6495 32.073 59.4095 31.903C60.1695 31.733 62.6195 35.553 62.6195 35.553L67.0695 32.763Z'
          fill='white'
        />
        <path
          d='M62.6195 36.0531C62.5355 36.0549 62.4525 36.0346 62.3788 35.9942C62.3051 35.9539 62.2433 35.8949 62.1995 35.8231C61.1995 34.2731 59.8395 32.5231 59.4095 32.3931C59.3016 32.4122 59.1907 32.4048 59.0862 32.3717C58.9817 32.3386 58.8868 32.2808 58.8095 32.2031C57.9195 31.4931 57.2695 28.5431 57.2695 27.8131C57.2695 26.9131 58.8495 24.9631 59.6595 24.0331C59.7495 23.5031 60.3995 22.8831 61.5895 22.1731C61.6958 22.1049 61.8247 22.0814 61.9483 22.1076C62.0718 22.1338 62.18 22.2077 62.2495 22.3131L65.6895 27.1731C65.7167 27.2143 65.7401 27.2578 65.7595 27.3031L67.5395 32.6031C67.5761 32.7098 67.5763 32.8255 67.5401 32.9322C67.5039 33.039 67.4334 33.1307 67.3395 33.1931L62.8795 35.9731C62.8019 36.0233 62.7119 36.051 62.6195 36.0531ZM59.4195 31.3931C59.7895 31.3931 60.6595 31.7431 62.7295 34.8631L66.4295 32.5531L64.7995 27.6931L61.6695 23.2731C61.2721 23.5129 60.9173 23.817 60.6195 24.1731C60.6247 24.242 60.6159 24.3112 60.5935 24.3765C60.5712 24.4418 60.5358 24.5019 60.4895 24.5531C59.5578 25.5046 58.7937 26.6068 58.2295 27.8131C58.3469 29.0765 58.7321 30.3003 59.3595 31.4031L59.4195 31.3931Z'
          fill='#263238'
        />
        <path
          d='M13.6603 50.203L6.32031 49.853L3.07031 53.793H13.6103L13.6603 50.203Z'
          fill='#B2B2B2'
        />
        <path
          d='M13.6099 54.2929H3.06988C2.97537 54.291 2.88323 54.2629 2.80377 54.2116C2.72431 54.1604 2.66066 54.0881 2.6199 54.0028C2.57764 53.9181 2.56082 53.823 2.57147 53.729C2.58212 53.6349 2.61978 53.546 2.6799 53.4728L5.9299 49.5429C5.98139 49.4853 6.04462 49.4394 6.11535 49.4083C6.18608 49.3773 6.26266 49.3618 6.3399 49.3628L13.6899 49.7029C13.819 49.708 13.9411 49.763 14.0306 49.8562C14.1201 49.9494 14.17 50.0736 14.1699 50.2029L14.1099 53.7929C14.1099 53.8585 14.097 53.9235 14.0718 53.9842C14.0467 54.0449 14.0099 54.1 13.9634 54.1464C13.917 54.1928 13.8619 54.2297 13.8012 54.2548C13.7406 54.2799 13.6756 54.2929 13.6099 54.2929ZM4.12988 53.2929H13.1299V50.6729L6.50989 50.3729L4.12988 53.2929Z'
          fill='#263238'
        />
        <path d='M135.59 53.6831H-35.5V56.2631H135.59V53.6831Z' fill='white' />
        <path
          d='M99.7095 57H0.290546C0.213488 57 0.139591 56.9411 0.085103 56.8364C0.0306152 56.7316 0 56.5895 0 56.4413V53.5587C0 53.4105 0.0306152 53.2684 0.085103 53.1636C0.139591 53.0588 0.213488 53 0.290546 53H99.7095C99.7865 53 99.8604 53.0588 99.9149 53.1636C99.9694 53.2684 100 53.4105 100 53.5587V56.4413C100 56.5895 99.9694 56.7316 99.9149 56.8364C99.8604 56.9411 99.7865 57 99.7095 57ZM0.581091 55.8827H99.4189V54.1173H0.581091V55.8827Z'
          fill='#263238'
        />
        <path d='M27.0002 34.1729H1.9502L4.13022 53.6929H29.1802L27.0002 34.1729Z' fill='white' />
        <path
          d='M29.1803 54.1929H4.13024C4.00759 54.191 3.8899 54.1442 3.79955 54.0612C3.7092 53.9782 3.6525 53.8649 3.64025 53.7429L1.42025 34.2329C1.41233 34.1631 1.41889 34.0925 1.43954 34.0253C1.46019 33.9582 1.49447 33.8961 1.54025 33.8429C1.58766 33.7889 1.64615 33.7458 1.71172 33.7164C1.7773 33.6871 1.84842 33.6722 1.92025 33.6729H26.9202C27.0446 33.6722 27.1648 33.718 27.2572 33.8012C27.3497 33.8844 27.4078 33.9991 27.4202 34.1229L29.6002 53.6329C29.6075 53.7034 29.5996 53.7746 29.5772 53.8419C29.5548 53.9091 29.5183 53.9708 29.4702 54.0229C29.3972 54.1126 29.2943 54.173 29.1803 54.1929ZM4.58025 53.1929H28.5803L26.5102 34.6729H2.51025L4.58025 53.1929Z'
          fill='#263238'
        />
        <path d='M47.21 52.2427H29.25V53.6827H47.21V52.2427Z' fill='white' />
        <path
          d='M47.2197 54.1929H29.2197C29.0871 54.1929 28.9599 54.1403 28.8662 54.0465C28.7724 53.9527 28.7197 53.8255 28.7197 53.6929V52.2529C28.7197 52.1203 28.7724 51.9932 28.8662 51.8994C28.9599 51.8056 29.0871 51.7529 29.2197 51.7529H47.2197C47.3523 51.7529 47.4795 51.8056 47.5733 51.8994C47.6671 51.9932 47.7197 52.1203 47.7197 52.2529V53.6929C47.7197 53.8255 47.6671 53.9527 47.5733 54.0465C47.4795 54.1403 47.3523 54.1929 47.2197 54.1929ZM29.7597 53.1929H46.7597V52.7529H29.7597V53.1929Z'
          fill='#263238'
        />
      </g>
      <defs>
        <clipPath id='clip0_203_1275'>
          <rect width='100' height='100' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
