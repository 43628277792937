import Typography from '@mui/material/Typography';
import React, { useEffect, useRef } from 'react';

import { useModalContext } from '../../../context/modals';
import { ModalBackdrop, ModalOverlay, ModalText, ModalWrapper } from '../styles';

const ModalSuccess = () => {
  const intervalSuccessId = useRef();

  const { modals, updateModalState } = useModalContext();

  const toggleSuccessModal = () => {
    updateModalState('modalSuccess', {
      isOpen: false,
    });
  };

  useEffect(() => {
    if (modals.modalSuccess.isOpen) {
      intervalSuccessId.current = setTimeout(() => {
        updateModalState('modalSuccess', {
          isOpen: false,
        });
      }, 3000);
    } else {
      clearTimeout(intervalSuccessId.current);
    }
  }, [modals.modalSuccess.isOpen]);

  return (
    <>
      {modals.modalSuccess.isOpen && (
        <ModalOverlay>
          <ModalWrapper className='success'>
            <Typography variant='h2'>Success!</Typography>
            <ModalText variant='body1'>
              Your request has been successfully created, we will contact you shortly.
            </ModalText>
          </ModalWrapper>
          <ModalBackdrop onClick={toggleSuccessModal} />
        </ModalOverlay>
      )}
    </>
  );
};

export default ModalSuccess;
