// eslint-disable-next-line object-curly-newline
import { FireIcon, ListIcon, PuzzleIcon, ReceiptIcon, TeamIcon, TimerIcon } from '../assets/icons';

export const ADVANTAGES_LIST = [
  {
    id: 1,
    icon: <ReceiptIcon />,
    text: (
      <>
        <strong>We will increase the average check in the work on the project.</strong> You can
        complement your services with quality development and design services provided by our team.
      </>
    ),
  },
  {
    id: 2,
    icon: <FireIcon />,
    text: (
      <>
        <strong>We help to avoid missing deadlines.</strong> A team of over 50 experts will complete
        all project development processes on time.
      </>
    ),
  },
  {
    id: 3,
    icon: <TimerIcon />,
    text: (
      <>
        <strong>We deliver projects fast.</strong> The client gets completely ready-to-use project,
        thoroughly tested by our QA.
      </>
    ),
  },
  {
    id: 4,
    icon: <TeamIcon />,
    text: (
      <>
        <strong>We will help you improve your production capacity.</strong> You will be able to work
        on more projects at the same time, thanks to the redistribution of development tasks between
        experts in our team.
      </>
    ),
  },
  {
    id: 5,
    icon: <PuzzleIcon />,
    text: (
      <>
        <strong>We execute any complexity custom projects.</strong> From a simple website to complex
        business solutions for small and medium-sized businesses
      </>
    ),
  },
  {
    id: 6,
    icon: <ListIcon />,
    text: (
      <>
        <strong>We will develop a technical requirements for the project.</strong> Let's take on the
        routine of writing a high-quality, thorough teсhnical documentation for project development.
      </>
    ),
  },
];
