export const TeamLeadIcon = ({ width = 100, height = 100 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 100 100'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_202_895)'>
        <path
          d='M43.8807 90.77H14.7907L13.2207 169.96H20.2707L29.3407 109.76L38.4007 169.96H45.4607L43.8807 90.77Z'
          fill='#263238'
        />
        <path
          d='M45.5015 170.46H38.4015C38.2819 170.459 38.1667 170.415 38.0768 170.336C37.9869 170.257 37.9282 170.149 37.9115 170.03L29.3415 113.12L20.7715 170C20.7525 170.119 20.6919 170.228 20.6003 170.307C20.5088 170.386 20.3922 170.429 20.2715 170.43H13.2715C13.1368 170.428 13.0081 170.374 12.9115 170.28C12.822 170.182 12.7722 170.053 12.7715 169.92L14.3415 90.7302C14.3466 90.6002 14.4012 90.477 14.4941 90.3859C14.5871 90.2948 14.7114 90.2427 14.8415 90.2402H43.9315C44.0624 90.2402 44.1881 90.2915 44.2816 90.3831C44.375 90.4747 44.4289 90.5994 44.4315 90.7302L45.9615 170C45.9615 170.134 45.9092 170.262 45.8157 170.357C45.7222 170.453 45.595 170.508 45.4615 170.51L45.5015 170.46ZM38.8815 169.46H44.9915L43.4315 91.2702H15.2815L13.7315 169.46H19.8415L28.8415 109.69C28.8604 109.571 28.9211 109.462 29.0126 109.384C29.1042 109.305 29.2207 109.261 29.3415 109.26C29.4611 109.261 29.5763 109.305 29.6662 109.384C29.7561 109.463 29.8147 109.572 29.8315 109.69L38.8815 169.46Z'
          fill='#263238'
        />
        <path
          d='M34.5208 47.9199L29.3708 49.0099L24.2108 47.9199L11.2607 53.0499L14.7907 90.7699H43.9407L47.4707 53.0499L34.5208 47.9199Z'
          fill='#91B3FA'
        />
        <path
          d='M43.9415 91.2699H14.7915C14.6681 91.2703 14.5492 91.2242 14.4584 91.1408C14.3676 91.0574 14.3115 90.9428 14.3014 90.8199L10.7714 53.0999C10.7597 52.9926 10.7838 52.8845 10.8399 52.7923C10.8959 52.7001 10.9808 52.6289 11.0814 52.5899L24.0315 47.4599C24.1258 47.43 24.2271 47.43 24.3215 47.4599L29.3714 48.5199L34.4215 47.4599C34.5158 47.43 34.6171 47.43 34.7114 47.4599L47.7114 52.5899C47.8111 52.6303 47.895 52.7017 47.9509 52.7936C48.0067 52.8855 48.0315 52.9929 48.0214 53.0999L44.4914 90.8199C44.4865 90.8862 44.4682 90.9508 44.4375 91.0098C44.4069 91.0688 44.3645 91.1209 44.3131 91.163C44.2616 91.2051 44.2022 91.2363 44.1383 91.2547C44.0744 91.2731 44.0074 91.2783 43.9415 91.2699ZM15.2515 90.2699H43.5015L46.9515 53.3799L34.5015 48.4499L29.5015 49.4499C29.4363 49.47 29.3666 49.47 29.3014 49.4499L24.3014 48.4499L11.8014 53.3799L15.2515 90.2699Z'
          fill='#263238'
        />
        <path
          d='M26.1514 55.7602L28.2614 60.9602L26.6013 83.4202L29.2513 88.3002L32.3614 83.2002L29.8113 61.1902L32.0214 56.6402L29.1313 52.4302L26.1514 55.7602Z'
          fill='#263238'
        />
        <path
          d='M29.2612 88.8002C29.1742 88.799 29.0892 88.7742 29.0152 88.7284C28.9412 88.6826 28.8811 88.6176 28.8412 88.5402L26.1812 83.6702C26.1319 83.5857 26.1109 83.4876 26.1212 83.3902L27.7512 61.0002L25.6912 56.0002C25.6574 55.9136 25.6479 55.8193 25.6637 55.7277C25.6796 55.636 25.7202 55.5505 25.7812 55.4802L28.7812 52.1502C28.8336 52.0945 28.8973 52.0505 28.9679 52.0212C29.0386 51.9918 29.1147 51.9779 29.1912 51.9802C29.2671 51.9856 29.3408 52.0082 29.4067 52.0463C29.4725 52.0844 29.5288 52.1371 29.5712 52.2002L32.4612 56.4102C32.506 56.4878 32.5295 56.5757 32.5295 56.6652C32.5295 56.7548 32.506 56.8427 32.4612 56.9202L30.3112 61.3202L32.8412 83.2002C32.8594 83.3087 32.8342 83.4201 32.7712 83.5102L29.6712 88.6102C29.6232 88.6725 29.5608 88.7222 29.4894 88.7553C29.4181 88.7884 29.3398 88.8038 29.2612 88.8002ZM27.1112 83.3202L29.2812 87.3202L31.8412 83.1102L29.3112 61.2602C29.3024 61.1673 29.3197 61.0738 29.3612 60.9902L31.5012 56.6902L29.1312 53.2402L26.7912 55.8602L28.7912 60.7802C28.8055 60.8529 28.8055 60.9276 28.7912 61.0002L27.1112 83.3202Z'
          fill='#263238'
        />
        <path d='M34.3809 47.92L29.2209 54.45L24.3809 47.92V42.04H34.3809V47.92Z' fill='white' />
        <path
          d='M29.2207 55.0003C29.1443 55.0011 29.0689 54.9834 29.0009 54.9485C28.933 54.9137 28.8745 54.8628 28.8307 54.8003L23.9907 48.2703C23.9244 48.1883 23.889 48.0857 23.8907 47.9803V42.0503C23.8907 41.9177 23.9433 41.7905 24.0371 41.6967C24.1309 41.603 24.2581 41.5503 24.3907 41.5503H34.3907C34.5233 41.5503 34.6505 41.603 34.7442 41.6967C34.838 41.7905 34.8907 41.9177 34.8907 42.0503V47.9303C34.8929 48.0419 34.8577 48.151 34.7907 48.2403L29.6207 54.7603C29.5792 54.8304 29.5209 54.889 29.451 54.9309C29.3812 54.9729 29.302 54.9967 29.2207 55.0003ZM24.8807 47.8103L29.2307 53.6803L33.8807 47.8003V42.6003H24.8807V47.8103Z'
          fill='#263238'
        />
        <path
          d='M23.8312 47.0103L22.4512 48.7003L24.0012 58.3303L29.2212 54.4503L34.8812 58.1803L35.8112 48.7003L34.4612 47.3403L29.1312 52.4303L23.8312 47.0103Z'
          fill='#91B3FA'
        />
        <path
          d='M24.0111 58.8403C23.9482 58.8507 23.884 58.8507 23.8211 58.8403C23.7422 58.8073 23.6726 58.7554 23.6185 58.6891C23.5644 58.6229 23.5276 58.5442 23.5111 58.4603L21.9611 48.8203C21.9512 48.7512 21.9559 48.6808 21.9748 48.6137C21.9938 48.5465 22.0266 48.484 22.0711 48.4303L23.4411 46.7303C23.4869 46.6762 23.5435 46.6323 23.6072 46.6013C23.6709 46.5703 23.7403 46.553 23.8111 46.5503C23.9523 46.5497 24.0884 46.6034 24.1911 46.7003L29.1911 51.7703L34.1911 47.0203C34.2846 46.9287 34.4102 46.8774 34.5411 46.8774C34.672 46.8774 34.7977 46.9287 34.8911 47.0203L36.2411 48.3803C36.2941 48.4313 36.3344 48.4941 36.3587 48.5636C36.3831 48.6331 36.3907 48.7073 36.3811 48.7803L35.4511 58.2603C35.4427 58.3457 35.4124 58.4275 35.3631 58.4978C35.3139 58.5681 35.2474 58.6245 35.1701 58.6617C35.0927 58.6989 35.0071 58.7155 34.9215 58.71C34.8359 58.7045 34.7531 58.6771 34.6811 58.6303L29.3111 55.0903L24.3811 58.7703C24.2693 58.8349 24.1388 58.8595 24.0111 58.8403ZM23.0111 48.8403L24.4011 57.4403L28.9511 54.0503C29.0373 53.9939 29.1381 53.9639 29.2411 53.9639C29.3441 53.9639 29.4449 53.9939 29.5311 54.0503L34.5311 57.3303L35.3611 48.9103L34.5011 48.0003L29.5011 52.7503C29.4555 52.7969 29.401 52.8339 29.3409 52.8591C29.2808 52.8844 29.2163 52.8974 29.1511 52.8974C29.0859 52.8974 29.0214 52.8844 28.9613 52.8591C28.9012 52.8339 28.8467 52.7969 28.8011 52.7503L23.8811 47.7503L23.0111 48.8403Z'
          fill='#263238'
        />
        <path
          d='M37.2812 27.8799C37.2812 27.8799 39.9113 28.7599 40.3813 30.2899C40.8513 31.8199 38.3813 35.4599 38.3813 35.4599L37.5612 29.8099L37.2812 27.8799Z'
          fill='#263238'
        />
        <path
          d='M38.3814 36H38.2714C38.173 35.9767 38.0841 35.924 38.0162 35.849C37.9484 35.774 37.9049 35.6803 37.8914 35.58L36.7914 28C36.7779 27.9152 36.7864 27.8283 36.8162 27.7478C36.8459 27.6672 36.896 27.5956 36.9614 27.54C37.0279 27.4869 37.1065 27.4509 37.1902 27.4352C37.2739 27.4195 37.3602 27.4246 37.4414 27.45C37.7414 27.55 40.3314 28.45 40.8614 30.19C41.3914 31.93 39.0614 35.4 38.8014 35.79C38.7533 35.8561 38.6901 35.9097 38.617 35.9462C38.5439 35.9828 38.4631 36.0012 38.3814 36ZM37.9014 28.71L38.6814 34.07C39.4214 32.77 40.1214 31.19 39.9014 30.47C39.6814 29.75 38.6914 29.05 37.9014 28.67V28.71Z'
          fill='#263238'
        />
        <path
          d='M34.4107 26.0801C35.5394 26.3479 36.5483 26.9806 37.2807 27.8801C39.2107 30.2701 38.4707 33.3801 38.2807 36.2801C38.1707 37.9301 37.5808 46.8701 36.1708 47.6901C35.3272 48.0975 34.4025 48.3091 33.4657 48.3091C32.529 48.3091 31.6043 48.0975 30.7608 47.6901C28.4498 46.6423 26.3093 45.2535 24.4107 43.5701C23.5907 42.6301 23.5807 39.1101 23.5807 39.1101C23.5807 38.6601 22.9508 37.8201 22.7908 37.3901C22.4098 36.4281 22.1608 35.4189 22.0507 34.3901C21.8842 32.7525 22.2822 31.1071 23.1788 29.7267C24.0755 28.3463 25.4169 27.3137 26.9808 26.8001C29.3439 25.8805 31.915 25.6314 34.4107 26.0801Z'
          fill='white'
        />
        <path
          d='M33.6606 48.81C32.5909 48.7937 31.5346 48.5695 30.5506 48.15C28.1693 47.0702 25.9645 45.6374 24.0106 43.9C23.1106 42.9 23.0606 39.73 23.0606 39.11C22.9687 38.8058 22.834 38.5162 22.6606 38.25C22.522 38.0336 22.4015 37.8062 22.3006 37.57C21.9063 36.5619 21.6474 35.506 21.5306 34.43C21.363 32.6815 21.7928 30.9278 22.7499 29.455C23.7069 27.9822 25.1348 26.8771 26.8006 26.32C28.5006 25.74 31.8006 24.88 34.5306 25.6C35.7695 25.9014 36.8736 26.6047 37.6706 27.6C39.4106 29.76 39.1506 32.39 38.8906 34.92C38.8906 35.4 38.8006 35.92 38.7606 36.36V36.53C38.0106 47.23 36.8506 47.91 36.4106 48.16C35.568 48.6173 34.6187 48.8417 33.6606 48.81ZM32.2206 26.32C30.4787 26.3846 28.7578 26.722 27.1206 27.32C25.6623 27.7862 24.4082 28.7386 23.5674 30.018C22.7266 31.2973 22.35 32.8264 22.5006 34.35C22.607 35.3342 22.8458 36.2996 23.2106 37.22C23.2886 37.4043 23.3822 37.5816 23.4906 37.75C23.7747 38.151 23.9598 38.6136 24.0306 39.1C24.0306 40.49 24.2506 42.69 24.7306 43.25C26.5825 44.8753 28.6609 46.2227 30.9006 47.25C31.68 47.6271 32.5347 47.823 33.4006 47.823C34.2664 47.823 35.1211 47.6271 35.9006 47.25C36.0906 47.14 37.0706 46.09 37.7506 36.41V36.24C37.7506 35.75 37.8306 35.24 37.8806 34.7699C38.1306 32.3299 38.3606 30.02 36.8806 28.18C36.2125 27.3655 35.2956 26.7928 34.2706 26.55C33.5994 26.3883 32.9108 26.3111 32.2206 26.32Z'
          fill='#263238'
        />
        <path
          d='M35.0907 25.7003C35.0907 25.7003 37.7907 26.4003 38.0307 28.0503C38.2707 29.7003 36.3807 31.4603 31.9107 32.2803C27.4407 33.1003 26.7407 32.9903 26.7407 32.9903C26.7407 32.9903 28.3907 34.0503 27.8007 34.6403L26.3907 36.0503L26.6207 38.2803C26.6207 38.2803 25.5607 39.5803 25.3307 38.6403C25.1007 37.7003 24.6207 36.5203 24.2707 36.4003C23.9207 36.2803 22.7707 37.4003 22.7707 37.4003C22.4067 35.6857 22.2789 33.9294 22.3907 32.1803C22.6207 29.7103 23.5307 28.6103 26.9607 26.8103C30.3907 25.0103 35.0907 25.7003 35.0907 25.7003Z'
          fill='#263238'
        />
        <path
          d='M25.6509 39.4801H25.4809C25.3179 39.4378 25.1707 39.3492 25.0571 39.225C24.9435 39.1007 24.8684 38.9462 24.8409 38.7801C24.6992 38.1405 24.4597 37.5266 24.1309 36.9601C23.7699 37.194 23.4347 37.4655 23.1309 37.7701C23.0691 37.8346 22.9901 37.8801 22.9034 37.9014C22.8166 37.9226 22.7255 37.9187 22.6409 37.8901C22.5543 37.8664 22.4758 37.8194 22.414 37.7541C22.3523 37.6889 22.3097 37.6079 22.2909 37.5201C21.9185 35.7526 21.7873 33.9428 21.9009 32.1401C22.1509 29.4801 23.1909 28.2401 26.7409 26.3701C30.2909 24.5001 34.9709 25.1901 35.1709 25.2201H35.2309C35.3509 25.2201 38.2309 26.0401 38.5309 27.9901C38.7809 29.6801 37.1909 31.8301 32.0109 32.7901C30.0609 33.1501 28.8109 33.3301 28.0109 33.4201C28.2315 33.648 28.3779 33.9373 28.4309 34.2501C28.445 34.3873 28.4281 34.526 28.3813 34.6558C28.3346 34.7857 28.2593 34.9033 28.1609 35.0001L26.9209 36.2401L27.1309 38.2401C27.1411 38.3728 27.1019 38.5045 27.0209 38.6101C26.7809 38.8801 26.2209 39.4801 25.6509 39.4801ZM24.2209 35.8901C24.2902 35.8758 24.3616 35.8758 24.4309 35.8901C25.1309 36.1201 25.6109 37.7101 25.7809 38.3601C25.8977 38.2752 26.005 38.178 26.1009 38.0701L25.8909 36.0701C25.8834 35.9957 25.892 35.9206 25.9161 35.8498C25.9403 35.7791 25.9794 35.7143 26.0309 35.6601L27.4409 34.2501C27.1633 33.9016 26.8244 33.6068 26.4409 33.3801C26.3449 33.3172 26.2729 33.2237 26.2366 33.1148C26.2003 33.0059 26.2018 32.888 26.2409 32.7801C26.2829 32.673 26.3606 32.5838 26.4608 32.5273C26.5609 32.4708 26.6775 32.4506 26.7909 32.4701C28.475 32.3793 30.148 32.1417 31.7909 31.7601C36.2809 30.9401 37.6709 29.2701 37.5009 28.0901C37.3309 26.9101 35.6409 26.3401 34.9609 26.1601C34.5909 26.1601 30.2709 25.5701 27.1609 27.1601C23.7109 28.9701 23.0609 29.9901 22.8609 32.1601C22.7605 33.5734 22.8311 34.9937 23.0709 36.3901C23.3875 36.0994 23.7924 35.9233 24.2209 35.8901Z'
          fill='#263238'
        />
        <path d='M34.3809 42.0401L35.9109 41.8101L34.8509 37.8101L35.2109 35.8101' fill='white' />
        <path
          d='M34.3809 42.3003C34.3146 42.3003 34.251 42.2739 34.2041 42.227C34.1572 42.1801 34.1309 42.1166 34.1309 42.0503C34.1309 41.984 34.1572 41.9204 34.2041 41.8735C34.251 41.8266 34.3146 41.8003 34.3809 41.8003L35.6309 41.6103L34.6309 37.8803C34.6206 37.8443 34.6206 37.8062 34.6309 37.7703L34.9809 35.7703C34.9856 35.7375 34.997 35.7061 35.0144 35.678C35.0319 35.6499 35.0549 35.6257 35.0822 35.6069C35.1094 35.5881 35.1402 35.5752 35.1727 35.5689C35.2051 35.5625 35.2386 35.563 35.2708 35.5703C35.3036 35.575 35.335 35.5864 35.3631 35.6038C35.3912 35.6213 35.4155 35.6443 35.4342 35.6715C35.453 35.6988 35.466 35.7296 35.4723 35.7621C35.4786 35.7945 35.4781 35.828 35.4709 35.8603L35.1309 37.8003L36.1309 41.8003C36.1452 41.8316 36.1527 41.8657 36.1527 41.9003C36.1527 41.9348 36.1452 41.9689 36.1309 42.0003C36.1118 42.0294 36.0868 42.0543 36.0575 42.0732C36.0282 42.0922 35.9953 42.1048 35.9608 42.1103L34.4308 42.3403L34.3809 42.3003Z'
          fill='#263238'
        />
        <path
          d='M36.5013 38.1602C36.7608 38.1602 36.9713 37.7124 36.9713 37.1602C36.9713 36.6079 36.7608 36.1602 36.5013 36.1602C36.2417 36.1602 36.0312 36.6079 36.0312 37.1602C36.0312 37.7124 36.2417 38.1602 36.5013 38.1602Z'
          fill='#263238'
        />
        <path
          d='M31.9111 37.6899C31.9111 38.2499 32.1211 38.6899 32.3811 38.6899C32.6411 38.6899 32.8511 38.2499 32.8511 37.6899C32.8511 37.1299 32.6411 36.6899 32.3811 36.6899C32.1211 36.6899 31.9111 37.1399 31.9111 37.6899Z'
          fill='#263238'
        />
        <path
          d='M35.3213 43.2202C35.3213 43.2202 33.9113 44.8702 31.3213 42.9902L35.3213 43.2202Z'
          fill='white'
        />
        <path
          d='M33.6613 44.13C32.7554 44.0899 31.8862 43.7605 31.1813 43.19C31.1269 43.1515 31.0901 43.0931 31.0788 43.0274C31.0676 42.9618 31.0828 42.8944 31.1213 42.84C31.1597 42.7856 31.2182 42.7488 31.2839 42.7375C31.3495 42.7262 31.4169 42.7415 31.4713 42.78C33.8513 44.51 35.1213 43.07 35.1313 43.06C35.1546 43.0358 35.1826 43.0165 35.2135 43.0034C35.2444 42.9903 35.2777 42.9835 35.3113 42.9835C35.3449 42.9835 35.3782 42.9903 35.4091 43.0034C35.44 43.0165 35.468 43.0358 35.4913 43.06C35.5371 43.1067 35.5628 43.1695 35.5628 43.235C35.5628 43.3004 35.5371 43.3633 35.4913 43.41C35.2501 43.6465 34.9638 43.8322 34.6494 43.9559C34.335 44.0796 33.999 44.1388 33.6613 44.13Z'
          fill='#263238'
        />
        <path
          d='M37.3207 35.11C37.3207 35.11 36.7407 34.17 35.6807 34.99L37.3207 35.11Z'
          fill='white'
        />
        <path
          d='M37.3209 35.3603C37.2786 35.3597 37.2371 35.3484 37.2004 35.3274C37.1637 35.3064 37.1329 35.2764 37.1109 35.2403C36.9956 35.0718 36.8203 34.9537 36.6209 34.9103C36.4768 34.897 36.3315 34.9152 36.1951 34.9635C36.0587 35.0119 35.9344 35.0892 35.8309 35.1903C35.8046 35.2106 35.7746 35.2256 35.7425 35.2344C35.7105 35.2431 35.677 35.2455 35.6441 35.2413C35.6111 35.2371 35.5793 35.2265 35.5504 35.21C35.5216 35.1935 35.4962 35.1715 35.4759 35.1453C35.4555 35.119 35.4405 35.089 35.4318 35.0569C35.423 35.0249 35.4207 34.9914 35.4249 34.9585C35.429 34.9255 35.4397 34.8937 35.4561 34.8648C35.4726 34.836 35.4946 34.8106 35.5209 34.7903C35.6818 34.648 35.8711 34.5415 36.0762 34.4777C36.2813 34.4139 36.4976 34.3944 36.7109 34.4203C37.0355 34.5121 37.316 34.7181 37.5009 35.0003C37.5328 35.0583 37.5422 35.126 37.5275 35.1905C37.5127 35.255 37.4748 35.3119 37.4209 35.3503C37.3882 35.3581 37.3545 35.3615 37.3209 35.3603Z'
          fill='#263238'
        />
        <path
          d='M33.441 35.2199C32.9043 35.0246 32.3162 35.0237 31.779 35.2174C31.2418 35.4111 30.7895 35.7871 30.501 36.2799'
          fill='white'
        />
        <path
          d='M30.5006 36.5301C30.4646 36.5404 30.4265 36.5404 30.3906 36.5301C30.3608 36.5153 30.3342 36.4947 30.3125 36.4695C30.2908 36.4442 30.2743 36.4149 30.2641 36.3833C30.2538 36.3516 30.25 36.3182 30.2529 36.285C30.2557 36.2519 30.2651 36.2196 30.2806 36.1901C30.596 35.6491 31.0897 35.2346 31.6771 35.0175C32.2645 34.8004 32.9091 34.7943 33.5006 35.0001C33.5629 35.0227 33.6137 35.0691 33.6418 35.1291C33.6699 35.1891 33.6731 35.2578 33.6506 35.3201C33.628 35.3824 33.5816 35.4332 33.5216 35.4614C33.4616 35.4895 33.3929 35.4926 33.3306 35.4701C32.8504 35.2895 32.3218 35.2848 31.8385 35.4568C31.3552 35.6289 30.9485 35.9667 30.6906 36.4101C30.6705 36.4436 30.6428 36.4718 30.6098 36.4927C30.5768 36.5135 30.5394 36.5263 30.5006 36.5301Z'
          fill='#263238'
        />
        <path
          d='M26.0208 40.0902C26.0208 40.0902 26.1508 36.4702 24.2708 36.4002C22.3908 36.3302 22.7708 37.4002 22.8808 39.0502C22.8796 39.5873 23.0427 40.1118 23.3484 40.5534C23.6541 40.995 24.0877 41.3323 24.5908 41.5202'
          fill='white'
        />
        <path
          d='M24.5908 42.0002H24.5008C23.8962 41.7903 23.372 41.3973 23.001 40.8758C22.6301 40.3543 22.4308 39.7302 22.4308 39.0902C22.4308 38.8802 22.4308 38.6702 22.3708 38.4802C22.2908 37.6202 22.2208 36.8802 22.7008 36.3702C22.9215 36.1771 23.1819 36.035 23.4635 35.9538C23.7452 35.8726 24.0413 35.8543 24.3308 35.9002C25.0408 35.9002 25.9808 36.3402 26.3308 38.1602C26.4652 38.818 26.5256 39.4889 26.5108 40.1602C26.5083 40.2258 26.4927 40.2904 26.4652 40.35C26.4377 40.4097 26.3986 40.4634 26.3503 40.508C26.302 40.5526 26.2454 40.5872 26.1837 40.6099C26.1221 40.6325 26.0565 40.6428 25.9908 40.6402C25.9251 40.6376 25.8606 40.6221 25.8009 40.5946C25.7413 40.567 25.6876 40.528 25.643 40.4797C25.5984 40.4314 25.5638 40.3748 25.5411 40.3131C25.5184 40.2514 25.5082 40.1858 25.5108 40.1202C25.5108 39.3102 25.3708 36.9902 24.2408 36.9502C23.6408 36.9502 23.4408 37.0402 23.3808 37.1102C23.3208 37.1802 23.2608 37.8502 23.3208 38.4402C23.3208 38.6402 23.3208 38.8502 23.3708 39.0702C23.3699 39.5003 23.4981 39.9209 23.7388 40.2774C23.9795 40.6339 24.3215 40.9101 24.7208 41.0702C24.8481 41.1073 24.9555 41.1935 25.0192 41.3097C25.083 41.426 25.098 41.5629 25.0608 41.6902C25.0239 41.7839 24.9589 41.8639 24.8748 41.9194C24.7907 41.9749 24.6915 42.0031 24.5908 42.0002Z'
          fill='#263238'
        />
        <path
          d='M47.5013 53.0503L57.7513 65.0503L69.7513 54.3903L73.5013 57.0603L60.3513 76.3903C60.1349 76.7126 59.8548 76.9872 59.5284 77.1974C59.2019 77.4075 58.836 77.5488 58.453 77.6124C58.07 77.6761 57.6781 77.6609 57.3012 77.5676C56.9243 77.4744 56.5705 77.3052 56.2614 77.0703L45.8613 69.2803L47.5013 53.0503Z'
          fill='#91B3FA'
        />
        <path
          d='M57.9712 78.1501C57.248 78.1494 56.545 77.9104 55.9712 77.4701L45.5712 69.6801C45.504 69.6269 45.4506 69.5581 45.4158 69.4797C45.381 69.4014 45.3657 69.3157 45.3712 69.2301L46.9712 53.0001C46.9812 52.9032 47.0199 52.8116 47.0822 52.7367C47.1446 52.6619 47.2278 52.6074 47.3212 52.5801C47.4146 52.5505 47.5145 52.5489 47.6088 52.5755C47.703 52.6022 47.7873 52.6559 47.8512 52.7301L57.7712 64.3901L69.3812 54.0701C69.4702 54.002 69.5792 53.9651 69.6912 53.9651C69.8033 53.9651 69.9123 54.002 70.0012 54.0701L73.7812 56.6901C73.8892 56.765 73.9634 56.8793 73.9877 57.0084C74.012 57.1376 73.9846 57.2711 73.9112 57.3801L60.7712 76.7101C60.5079 77.0846 60.1707 77.4012 59.7802 77.6402C59.3898 77.8793 58.9545 78.0358 58.5012 78.1001C58.326 78.1297 58.1489 78.1464 57.9712 78.1501ZM46.3913 69.0501L56.5612 76.6701C56.816 76.8652 57.1081 77.0058 57.4195 77.0832C57.7309 77.1606 58.0548 77.1732 58.3712 77.1201C58.6867 77.0675 58.9883 76.9518 59.258 76.78C59.5278 76.6081 59.7602 76.3837 59.9412 76.1201L72.8012 57.1901L69.7512 55.0801L58.0512 65.4801C58.0025 65.5235 57.9455 65.5566 57.8836 65.5772C57.8217 65.5978 57.7563 65.6056 57.6912 65.6001C57.5567 65.5919 57.4309 65.5308 57.3412 65.4301L47.8512 54.2701L46.3913 69.0501Z'
          fill='#263238'
        />
        <path
          d='M69.7109 54.4402C69.7109 54.4402 71.301 52.9102 72.041 52.2802C72.781 51.6502 80.041 52.7002 80.041 52.7002C80.041 52.7002 82.8809 50.8102 83.4109 51.5402C83.9409 52.2702 82.361 55.3302 81.511 56.2702C80.661 57.2102 73.511 57.0602 73.511 57.0602L69.7109 54.4402Z'
          fill='white'
        />
        <path
          d='M74.9611 57.5803H73.4911C73.3905 57.581 73.2924 57.5495 73.2111 57.4903L69.4311 54.8703C69.3706 54.828 69.3202 54.7727 69.2836 54.7086C69.247 54.6445 69.225 54.5731 69.2193 54.4994C69.2135 54.4258 69.2242 54.3519 69.2504 54.2828C69.2767 54.2138 69.3179 54.1515 69.3711 54.1003C69.4311 54.0403 70.9711 52.5503 71.7111 51.9203C72.4511 51.2903 77.0911 51.7903 79.9111 52.2003C81.3611 51.2003 83.1511 50.3703 83.7911 51.2703C84.6311 52.4503 82.4811 55.9403 81.8711 56.6203C81.1311 57.4303 77.5011 57.5803 74.9611 57.5803ZM73.6611 56.5803C76.9311 56.6503 80.6611 56.4103 81.1211 55.9603C82.0211 54.9603 83.2011 52.3303 82.9711 51.8403C82.0141 52.1151 81.1111 52.5513 80.3011 53.1303C80.1992 53.2012 80.0737 53.2299 79.9511 53.2103C76.7211 52.7403 72.8811 52.4103 72.3311 52.7003C71.7811 52.9903 71.0211 53.9003 70.5011 54.4003L73.6611 56.5803Z'
          fill='#263238'
        />
        <path
          d='M28.9609 77.9402C28.9609 77.9402 30.5509 76.4102 31.2809 75.7802C32.0109 75.1502 39.2809 76.2002 39.2809 76.2002C39.2809 76.2002 42.121 74.3102 42.641 75.0402C43.161 75.7702 41.5909 78.8302 40.7509 79.7702C39.9109 80.7102 29.5809 81.2702 29.5809 81.2702L28.9609 77.9402Z'
          fill='white'
        />
        <path
          d='M29.5708 81.77C29.4529 81.77 29.3387 81.7291 29.2475 81.6543C29.1564 81.5796 29.0939 81.4756 29.0708 81.36L28.5008 78C28.4875 77.9184 28.4942 77.8349 28.5203 77.7565C28.5464 77.6781 28.5912 77.6072 28.6508 77.55C28.7108 77.49 30.2508 76 30.9908 75.37C31.7308 74.74 36.3708 75.24 39.1808 75.65C40.6408 74.65 42.4308 73.82 43.0708 74.72C43.9108 75.9 41.7608 79.39 41.1508 80.07C40.2608 81.07 32.7908 81.57 29.6208 81.74L29.5708 81.77ZM29.5008 78.12L29.9808 80.75C34.7208 80.48 39.8008 79.92 40.3808 79.43C41.2708 78.43 42.4508 75.81 42.2208 75.32C41.2598 75.5842 40.355 76.0213 39.5508 76.61C39.4489 76.6809 39.3234 76.7096 39.2008 76.69C35.9608 76.22 32.1308 75.89 31.5808 76.18C31.0408 76.65 29.9408 77.69 29.5008 78.12Z'
          fill='#263238'
        />
        <path
          d='M11.261 53.0503L6.99097 55.0003L5.50098 85.0003L29.881 81.8003L29.661 77.0903H14.001L15.711 65.7603C15.5995 63.108 15.3156 60.4657 14.861 57.8503'
          fill='#91B3FA'
        />
        <path
          d='M5.50102 85.5201C5.43855 85.5221 5.3763 85.5117 5.31792 85.4894C5.25954 85.467 5.20621 85.4333 5.16103 85.3901C5.10644 85.3416 5.06363 85.2812 5.03591 85.2136C5.00818 85.146 4.99626 85.073 5.00102 85.0001L6.50102 55.0001C6.50649 54.909 6.53626 54.8211 6.58727 54.7455C6.63827 54.6699 6.70865 54.6093 6.79103 54.5701L11.071 52.6501C11.1306 52.6219 11.1952 52.606 11.261 52.6034C11.3269 52.6008 11.3926 52.6115 11.4542 52.6348C11.5158 52.6581 11.572 52.6937 11.6196 52.7393C11.6672 52.7848 11.7051 52.8395 11.731 52.9001C11.7851 53.0208 11.7892 53.158 11.7424 53.2817C11.6955 53.4054 11.6015 53.5055 11.481 53.5601L7.48103 55.3601L5.99101 84.5001L29.321 81.4301L29.141 77.6601H14.001C13.9294 77.6595 13.8587 77.644 13.7933 77.6148C13.7279 77.5855 13.6692 77.5431 13.621 77.4901C13.5762 77.4346 13.5433 77.3704 13.5244 77.3016C13.5055 77.2327 13.5009 77.1608 13.511 77.0901L15.221 65.7501C15.0944 63.1586 14.8106 60.5772 14.371 58.0201C14.3564 57.9564 14.3546 57.8903 14.3659 57.8259C14.3772 57.7615 14.4012 57.7 14.4366 57.645C14.472 57.59 14.5181 57.5426 14.5721 57.5057C14.6261 57.4688 14.6869 57.4431 14.751 57.4301C14.8154 57.4154 14.882 57.4135 14.9471 57.4247C15.0122 57.4358 15.0744 57.4597 15.1302 57.4951C15.186 57.5304 15.2342 57.5764 15.2721 57.6305C15.31 57.6846 15.3368 57.7456 15.351 57.8101C15.8128 60.455 16.1001 63.1275 16.211 65.8101L14.581 76.6601H29.581C29.7102 76.66 29.8345 76.7099 29.9277 76.7994C30.0209 76.8889 30.0758 77.011 30.081 77.1401L30.301 81.8401C30.307 81.9663 30.2649 82.0902 30.1833 82.1866C30.1016 82.2831 29.9865 82.3451 29.861 82.3601L5.50102 85.5201Z'
          fill='#263238'
        />
        <path
          d='M80.7207 40.6301H71.4207C71.2889 40.6276 71.1632 40.5741 71.07 40.4808C70.9768 40.3876 70.9233 40.2619 70.9207 40.1301V37.3401C70.9234 35.2111 70.1194 33.1602 68.6707 31.6001C67.3981 30.2327 66.53 28.5389 66.1632 26.7072C65.7964 24.8756 65.9453 22.9781 66.5932 21.2261C67.2412 19.4741 68.3627 17.9363 69.833 16.7841C71.3033 15.6319 73.0646 14.9104 74.9207 14.7001C76.9793 14.472 79.0581 14.8823 80.8757 15.8753C82.6932 16.8684 84.1615 18.3961 85.0816 20.2517C86.0017 22.1072 86.3292 24.2007 86.0196 26.2485C85.7099 28.2964 84.7783 30.1995 83.3507 31.7001C81.9397 33.2229 81.1759 35.2346 81.2207 37.3101V40.1001C81.2248 40.1683 81.2149 40.2365 81.1916 40.3007C81.1684 40.3649 81.1322 40.4236 81.0853 40.4732C81.0385 40.5229 80.982 40.5624 80.9193 40.5894C80.8566 40.6164 80.789 40.6302 80.7207 40.6301ZM71.9207 39.6301H80.2207V37.3401C80.177 35.0071 81.0411 32.7483 82.6307 31.0401C83.9302 29.6898 84.7811 27.9712 85.0671 26.1191C85.3531 24.2671 85.0604 22.3719 84.2289 20.6924C83.3974 19.013 82.0676 17.6312 80.4213 16.7359C78.775 15.8405 76.8924 15.4753 75.0307 15.6901C72.9695 15.95 71.057 16.8999 69.6044 18.3852C68.1518 19.8705 67.2447 21.8037 67.0307 23.8701C66.9032 25.1477 67.0504 26.4378 67.4627 27.6538C67.8749 28.8698 68.5425 29.9835 69.4207 30.9201C71.0456 32.6624 71.9465 34.9577 71.9407 37.3401L71.9207 39.6301Z'
          fill='#263238'
        />
        <path d='M80.9115 41.3398H71.3115V43.9298H80.9115V41.3398Z' fill='#263238' />
        <path
          d='M71.3115 44.6201H80.9115V45.3701C80.9115 45.6117 80.864 45.851 80.7715 46.0743C80.679 46.2975 80.5435 46.5003 80.3726 46.6712C80.2018 46.8421 79.9989 46.9776 79.7757 47.0701C79.5524 47.1625 79.3132 47.2101 79.0715 47.2101H73.1516C72.9099 47.2101 72.6706 47.1625 72.4474 47.0701C72.2241 46.9776 72.0213 46.8421 71.8505 46.6712C71.6796 46.5003 71.5441 46.2975 71.4516 46.0743C71.3591 45.851 71.3115 45.6117 71.3115 45.3701V44.6201Z'
          fill='#263238'
        />
        <path
          d='M75.6611 10.78C75.5948 10.78 75.5312 10.7537 75.4843 10.7068C75.4375 10.6599 75.4111 10.5963 75.4111 10.53V5.25C75.4111 5.1837 75.4375 5.12011 75.4843 5.07322C75.5312 5.02634 75.5948 5 75.6611 5C75.7274 5 75.791 5.02634 75.8379 5.07322C75.8848 5.12011 75.9111 5.1837 75.9111 5.25V10.53C75.9111 10.5963 75.8848 10.6599 75.8379 10.7068C75.791 10.7537 75.7274 10.78 75.6611 10.78Z'
          fill='#263238'
        />
        <path
          d='M94.9615 25.8501H88.3516C88.2853 25.8501 88.2217 25.8238 88.1748 25.7769C88.1279 25.73 88.1016 25.6664 88.1016 25.6001C88.1016 25.5338 88.1279 25.4702 88.1748 25.4233C88.2217 25.3764 88.2853 25.3501 88.3516 25.3501H94.9615C95.0279 25.3501 95.0915 25.3764 95.1383 25.4233C95.1852 25.4702 95.2115 25.5338 95.2115 25.6001C95.2115 25.6664 95.1852 25.73 95.1383 25.7769C95.0915 25.8238 95.0279 25.8501 94.9615 25.8501Z'
          fill='#263238'
        />
        <path
          d='M62.9811 25.8501H56.3711C56.3048 25.8501 56.2412 25.8238 56.1943 25.7769C56.1475 25.73 56.1211 25.6664 56.1211 25.6001C56.1211 25.5338 56.1475 25.4702 56.1943 25.4233C56.2412 25.3764 56.3048 25.3501 56.3711 25.3501H62.9811C63.0474 25.3501 63.111 25.3764 63.1579 25.4233C63.2048 25.4702 63.2311 25.5338 63.2311 25.6001C63.2311 25.6664 63.2048 25.73 63.1579 25.7769C63.111 25.8238 63.0474 25.8501 62.9811 25.8501Z'
          fill='#263238'
        />
        <path
          d='M65.8906 16.6002C65.8371 16.5972 65.7853 16.5799 65.7406 16.5502L62.3106 14.1802C62.2549 14.143 62.2163 14.0853 62.2031 14.0197C62.19 13.954 62.2035 13.8859 62.2406 13.8302C62.2778 13.7745 62.3355 13.7358 62.4011 13.7227C62.4668 13.7095 62.5349 13.723 62.5906 13.7602L66.0306 16.1402C66.0577 16.1591 66.0807 16.1832 66.0983 16.2111C66.116 16.239 66.128 16.2701 66.1336 16.3027C66.1391 16.3352 66.1382 16.3685 66.1309 16.4007C66.1235 16.4329 66.1098 16.4633 66.0906 16.4902C66.0679 16.5225 66.038 16.5492 66.0033 16.5683C65.9687 16.5873 65.9302 16.5982 65.8906 16.6002Z'
          fill='#263238'
        />
        <path
          d='M85.1811 16.6C85.1398 16.5996 85.0993 16.5895 85.0628 16.5703C85.0263 16.5512 84.9948 16.5237 84.9711 16.49C84.9524 16.4625 84.9393 16.4316 84.9327 16.399C84.9261 16.3665 84.9261 16.3329 84.9326 16.3003C84.9391 16.2677 84.9521 16.2368 84.9707 16.2092C84.9893 16.1817 85.0132 16.1582 85.0411 16.14L88.5011 13.76C88.5563 13.7359 88.6183 13.7317 88.6763 13.7482C88.7343 13.7647 88.7848 13.8009 88.8191 13.8506C88.8534 13.9002 88.8693 13.9602 88.8642 14.0203C88.8591 14.0804 88.8332 14.1369 88.7911 14.18L85.3511 16.55C85.3029 16.5878 85.242 16.6056 85.1811 16.6Z'
          fill='#263238'
        />
      </g>
      <defs>
        <clipPath id='clip0_202_895'>
          <rect width='100' height='100' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
