export const Upwork = ({ width = 178, height = 79 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 178 79'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M48.7982 32.6676C43.8336 32.6676 40.547 28.9312 39.6302 27.4839C40.8065 18.3281 44.2489 15.4332 48.7982 15.4332C53.2959 15.4332 56.7901 18.934 56.7901 24.0503C56.7901 29.1667 53.2959 32.6676 48.7982 32.6676ZM48.7982 9.81189C40.7028 9.81189 36.1706 14.9452 34.8732 20.2299C33.4029 17.537 32.3131 13.9353 31.4655 10.5861H20.2735V24.1346C20.2735 29.049 17.9728 32.6844 13.4753 32.6844C8.97777 32.6844 6.40035 29.049 6.40035 24.1346L6.45223 10.5861H0V24.1346C0 28.0897 1.31466 31.6746 3.71912 34.2327C6.19277 36.8751 9.56591 38.2551 13.4753 38.2551C21.2595 38.2551 26.6912 32.4488 26.6912 24.1346V15.0293C27.5041 18.0251 29.4416 23.7811 33.1433 28.8303L29.6838 48.0001H36.2399L38.5231 34.4179C39.2669 35.0237 40.0626 35.5623 40.9102 36.0505C43.1071 37.3969 45.6153 38.1543 48.2102 38.2383C48.2102 38.2383 48.6079 38.2551 48.8156 38.2551C56.842 38.2551 63.2249 32.2132 63.2249 24.0503C63.2249 15.8876 56.8246 9.81189 48.7982 9.81189Z'
        fill='#14A800'
      />
      <path
        d='M141.5 13.4816V10.5868H135.169V37.3975H141.587V26.8954C141.587 20.4326 142.192 15.7874 151.1 16.3933V10.267C146.949 9.96406 143.714 10.8056 141.5 13.4816Z'
        fill='#14A800'
      />
      <path
        d='M96.9417 10.5879L92.2884 29.3369L87.3238 10.5879H79.1592L74.1772 29.3369L69.5413 10.5879H63.0371L70.1641 37.4156H77.8791L83.2415 18.3635L88.552 37.4156H96.267L103.809 10.5879H96.9417Z'
        fill='#14A800'
      />
      <path
        d='M117.942 32.6844C113.375 32.6844 109.95 29.1838 109.95 24.0503C109.95 18.9171 113.392 15.4332 117.942 15.4332C122.439 15.4332 125.933 18.934 125.933 24.0503C125.933 29.1667 122.439 32.6844 117.942 32.6844ZM117.942 9.81189C109.863 9.81189 103.532 15.9045 103.532 24.0503C103.532 32.1962 109.863 38.2383 117.942 38.2383C125.968 38.2383 132.351 32.1962 132.351 24.0503C132.351 15.9045 125.985 9.81189 117.942 9.81189Z'
        fill='#14A800'
      />
      <path
        d='M160.304 24.219H161.792L170.752 37.4139H178L167.69 22.7883C172.69 20.8865 176.046 15.9215 176.046 10.5863H169.645C169.645 16.376 164.957 18.5639 160.304 18.5639V0H153.869V37.4139H160.287L160.304 24.219Z'
        fill='#14A800'
      />
      <path
        d='M13.4848 53L18.0686 60.9773L26.9697 62.9311L20.9015 69.8152L21.8189 79L13.4848 75.2773L5.15075 79L6.06818 69.8152L0 62.9311L8.90109 60.9773L13.4848 53Z'
        fill='#14A800'
      />
      <path
        d='M51.2427 53L55.8264 60.9773L64.7275 62.9311L58.6593 69.8152L59.5767 79L51.2427 75.2773L42.9086 79L43.826 69.8152L37.7578 62.9311L46.6589 60.9773L51.2427 53Z'
        fill='#14A800'
      />
      <path
        d='M88.9995 53L93.5832 60.9773L102.484 62.9311L96.4162 69.8152L97.3336 79L88.9995 75.2773L80.6654 79L81.5828 69.8152L75.5146 62.9311L84.4157 60.9773L88.9995 53Z'
        fill='#14A800'
      />
      <path
        d='M126.757 53L131.341 60.9773L140.242 62.9311L134.174 69.8152L135.091 79L126.757 75.2773L118.423 79L119.341 69.8152L113.272 62.9311L122.174 60.9773L126.757 53Z'
        fill='#14A800'
      />
      <path
        d='M164.515 53L169.099 60.9773L178 62.9311L171.932 69.8152L172.849 79L164.515 75.2773L156.181 79L157.098 69.8152L151.03 62.9311L159.931 60.9773L164.515 53Z'
        fill='#14A800'
      />
    </svg>
  );
};
