import AnchorLink from 'react-anchor-link-smooth-scroll';
import { useBreakpoint } from 'use-breakpoint';

import Button from '../../components/Button';
import Container from '../../components/Container';
import Logo from '../../components/Logo';
import { BREAKPOINTS } from '../../constants';
import { HeaderWrapper, StyledHeader } from './styles';

const Header = ({ sxWrapper, handleClickHireUs }) => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  const isMobile = ['xxxs', 'xxs', 'xs', 'sm', 'md', 'lg'].includes(breakpoint);
  return (
    <HeaderWrapper sx={sxWrapper}>
      <Container>
        <StyledHeader>
          <Logo />
          <AnchorLink href='#footer'>
            <Button title='Hire us' onClick={isMobile ? null : handleClickHireUs} />
          </AnchorLink>
        </StyledHeader>
      </Container>
    </HeaderWrapper>
  );
};
export default Header;
