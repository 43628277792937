import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import { styled } from '@mui/system';

import palette from '../../theme/palette/index';

export const InputWrapper = styled(Box)({
  marginTop: 60,
  width: '100%',
  maxWidth: 364,
  minWidth: 280,
  '@media (max-width: 640px)': {
    maxWidth: 'unset',
    minWidth: 'unset',
    marginTop: 54,
  },
  '&:last-child': {
    marginLeft: 20,
    '@media (max-width: 640px)': {
      marginLeft: 0,
    },
  },
  '&:first-of-type': {
    maxWidth: 280,
    '@media (max-width: 640px)': {
      maxWidth: 'unset',
    },
  },
});

export const InputBase = styled(Input)({
  '&.MuiInputBase-root.MuiInput-root': {
    width: '100% ',
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid #15151580',
    paddingBottom: 11,
  },
  '&.MuiInputBase-root.MuiInput-root.Mui-error': {
    borderColor: 'red',
  },
  '&.MuiInputBase-root.MuiInput-root:before, &.MuiInputBase-root.MuiInput-root:after, &.MuiInputBase-root.MuiInput-root:hover:not(.Mui-disabled):before':
    {
      content: '" "',
      borderBottom: 'none',
    },
  '& .MuiInputBase-input.MuiInput-input::placeholder, & .MuiInputBase-input.MuiInput-input': {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '26px',
    letterSpacing: '0.43px',
    height: 'unset',
    color: palette.primary,
    opacity: 1,
    padding: 0,
  },
});

export const Error = styled(Box)({
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '14px',
  color: 'red',
  opacity: 1,
  paddingTop: 10,
});
