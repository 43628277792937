import {
  AwsIcon,
  ChakraIcon,
  GraphqlIcon,
  JavascriptIcon,
  MaterialIcon,
  MysqlIcon,
  NodeIcon,
  PostgresqlIcon,
  ReactIcon,
  ReactNativeIcon,
  StripeIcon,
  WebsocketsIcon,
} from '../assets/icons';
import Work1 from '../assets/images/our_work/our_work_1.png';
import Work2 from '../assets/images/our_work/our_work_2.png';
import Work3 from '../assets/images/our_work/our_work_3.png';
import Work4 from '../assets/images/our_work/our_work_4.png';
import Work5 from '../assets/images/our_work/our_work_5.png';
import Work6 from '../assets/images/our_work/our_work_6.png';
import Work7 from '../assets/images/our_work/our_work_7.png';
import Work8 from '../assets/images/our_work/our_work_8.png';
import Work9 from '../assets/images/our_work/our_work_9.png';
import Work10 from '../assets/images/our_work/our_work_10.png';

export const OUR_WORK_LEFT_LIST = [
  {
    id: 1,
    image: Work1,
    title: 'Yacht.tour',
    subTitle: 'Yacht rental service design',
    text: (
      <>
        <p>
          Prior to developing the design for the Yacht Tour project, we analyzed the user segment to
          figure out what to focus on to increase conversion. On the homepage, the focus was on the
          yacht note and the daily rental price. A convenient filter by country and region, as well
          as highlighting the number of boards available for rent in a specific country, helps the
          user make his choice as quickly as possible.
        </p>
        <p>Convenient and pleasant booking UI. The design is created in Figma.</p>
      </>
    ),
  },
  {
    id: 2,
    image: Work2,
    title: 'Foodeist for business',
    subTitle: '',
    text: (
      <>
        <p>
          Design created in Figma has hover and popup effects. Main requirements for viewing are an
          intuitive interface, a well-thought-out UX/UI, which is easily navigated by a user with
          minimal skill while working with similar applications, namely:
        </p>
        <ul>
          <li>Easy to manage orders</li>
          <li>Effectively manage the seating of guests in the hall</li>
          <li>Navigate to the menu</li>
          <li>Monitor clients payments</li>
        </ul>
        <p>The design is suitable for all devices.</p>
      </>
    ),
  },
  {
    id: 3,
    image: Work3,
    title: 'Banking App',
    subTitle: 'Сross-platform banking application for iOS and Android',
    text: (
      <>
        <p>
          The design of mobile interfaces is a constant balance between uniqueness, attractiveness,
          and recognition.
        </p>
        <p>
          First, the user needs to quickly understand the functionality required, i.e. navigation,
          buttons, and icons must be user-friendly. At the same time, the project's individuality
          must be preserved. We achieved all in this UI/UX design created in Figma.
        </p>
      </>
    ),
  },
  {
    id: 4,
    image: Work4,
    title: 'Fit time mobile and desktop design',
    subTitle: 'Сross-platform banking application for iOS and Android',
    text: (
      <>
        <p>
          This is design of a platform made for gym, workout, sports and other trainers to share
          their knowledge and lessons in videos, articles etc.
        </p>
        <p>
          Personal pages with free and subscription based content can offer high quality content for
          any level user.
        </p>
        <p>
          Various filters by trainers, training types, muscle groups, equipment and difficulty level
          will help to find suitable video or article as fast as possible.
        </p>
      </>
    ),
  },
  {
    id: 5,
    image: Work5,
    title: 'Web & Mobile development for Entertainment',
    subTitle: 'HYPT - platform about entertainment and nightlife in Dubai',
    text: (
      <>
        <p>
          We developed a web service and mobile app for Dubai guests and residents that tells about
          the city's best entertainments and venues. A vast catalog of the venues, events, and
          attractions with option to buy or book tickets for a visit. In addition we added social
          network features like chat, and posts with comment like and share options.
        </p>
      </>
    ),
    technologies: [
      {
        id: 1,
        icon: <JavascriptIcon />,
      },
      {
        id: 2,
        icon: <ReactIcon />,
      },
      {
        id: 3,
        icon: <ReactNativeIcon />,
      },
      {
        id: 4,
        icon: <NodeIcon />,
      },
      {
        id: 5,
        icon: <GraphqlIcon />,
      },
      {
        id: 6,
        icon: <ChakraIcon />,
      },
    ],
  },
];

export const OUR_WORK_RIGHT_LIST = [
  {
    id: 1,
    image: Work6,
    title: 'Flexible CRM for E-commerce',
    subTitle: '',
    text: (
      <>
        <p>
          We've developed custom CRM system. All orders from various sales channels are configured
          to display in one place.
        </p>
        <p>
          Furthermore, we developed modules for warehouse and parcel management. All processes like
          goods supply, orders delivering or forwarding are managed from the same unified interface.
        </p>
        <p>
          We also automated the changes synchronization between all channels (order status, payment
          status, product number change, etc).
        </p>
      </>
    ),
    technologies: [
      {
        id: 1,
        icon: <JavascriptIcon />,
      },
      {
        id: 2,
        icon: <MysqlIcon />,
      },
      {
        id: 3,
        icon: <ReactIcon />,
      },
      {
        id: 4,
        icon: <NodeIcon />,
      },
      {
        id: 5,
        icon: <ReactNativeIcon />,
      },
      {
        id: 6,
        icon: <GraphqlIcon />,
      },
      {
        id: 7,
        icon: <MaterialIcon />,
      },
    ],
  },
  {
    id: 2,
    image: Work7,
    title: 'Web development for Business Management',
    subTitle: 'ScrumPokerPro - task manager for distributed and remote teams',
    text: (
      <>
        <p>
          We developed a free application that will help development teams to estimate their
          projects with ability to vote. Target audience - developers, designers, marketers.
        </p>
      </>
    ),
    technologies: [
      {
        id: 1,
        icon: <JavascriptIcon />,
      },
      {
        id: 2,
        icon: <ReactIcon />,
      },
      {
        id: 3,
        icon: <ReactNativeIcon />,
      },
      {
        id: 4,
        icon: <ChakraIcon />,
      },
      {
        id: 5,
        icon: <WebsocketsIcon />,
      },
    ],
  },
  {
    id: 3,
    image: Work8,
    title: 'Web Development for FinTech',
    subTitle: 'Simplewealth - private asset management for everyone',
    text: (
      <>
        <p>Simplewealth has digitized almost everything needed for investment growth.</p>
        <p>
          We developed a part of the Simplewealth service: user authorization and onboarding as well
          as the app's main page. We also implemented parsing and analysis of documents (assets)
          supplied by adjacent modules of the project, structured received data into a suitable
          format and stored them in the database.
        </p>
      </>
    ),
    technologies: [
      {
        id: 1,
        icon: <JavascriptIcon />,
      },
      {
        id: 2,
        icon: <ReactIcon />,
      },
      {
        id: 3,
        icon: <ReactNativeIcon />,
      },
      {
        id: 4,
        icon: <NodeIcon />,
      },
      {
        id: 5,
        icon: <GraphqlIcon />,
      },
      {
        id: 6,
        icon: <ChakraIcon />,
      },
    ],
  },
  {
    id: 4,
    image: Work9,
    title: 'Mobile development for HoReCa',
    subTitle: 'xTips - service for receiving cashless tips',
    text: (
      <>
        <p>
          To develop mobile app for service workers to receive tips, accumulate them, distribute
          among team members and withdraw them to their accounts. Project languages: English,
          Estonian and Russian.
        </p>
      </>
    ),
    technologies: [
      {
        id: 1,
        icon: <JavascriptIcon />,
      },
      {
        id: 2,
        icon: <ReactIcon />,
      },
      {
        id: 3,
        icon: <NodeIcon />,
      },
      {
        id: 4,
        icon: <GraphqlIcon />,
      },
      {
        id: 5,
        icon: <ChakraIcon />,
      },
      {
        id: 6,
        icon: <PostgresqlIcon />,
      },
      {
        id: 7,
        icon: <StripeIcon />,
      },
    ],
  },
  {
    id: 5,
    image: Work10,
    title: 'Web Development for Healthcare',
    subTitle:
      'Berry is a browser extension, that enhances your shopping so you can find exactly what you want',
    text: (
      <>
        <p>
          Berry start-up was created by a team of students at Princeton University. The essence of
          its creation is to do the shopping for products on the Internet healthy and affordable for
          people who has to choose products with a specific composition (based on medical
          indications or personal preferences).
        </p>
        <p>
          Our team faced the task of finalizing the existing website and developing a browser
          extension that would work in tandem with the site.
        </p>
      </>
    ),
    technologies: [
      {
        id: 1,
        icon: <JavascriptIcon />,
      },
      {
        id: 2,
        icon: <ReactIcon />,
      },
      {
        id: 3,
        icon: <ReactNativeIcon />,
      },
      {
        id: 4,
        icon: <NodeIcon />,
      },
      {
        id: 5,
        icon: <AwsIcon />,
      },
    ],
  },
];

export const OUR_WORK_LIST = [
  {
    id: 1,
    image: Work1,
    title: 'Yacht.tour',
    subTitle: 'Yacht rental service design',
    text: (
      <>
        <p>
          Prior to developing the design for the Yacht Tour project, we analyzed the user segment to
          figure out what to focus on to increase conversion. On the homepage, the focus was on the
          yacht note and the daily rental price. A convenient filter by country and region, as well
          as highlighting the number of boards available for rent in a specific country, helps the
          user make his choice as quickly as possible.
        </p>
        <p>Convenient and pleasant booking UI. The design is created in Figma.</p>
      </>
    ),
  },
  {
    id: 2,
    image: Work6,
    title: 'Flexible CRM for E-commerce',
    subTitle: '',
    text: (
      <>
        <p>
          We've developed custom CRM system. All orders from various sales channels are configured
          to display in one place.
        </p>
        <p>
          Furthermore, we developed modules for warehouse and parcel management. All processes like
          goods supply, orders delivering or forwarding are managed from the same unified interface.
        </p>
        <p>
          We also automated the changes synchronization between all channels (order status, payment
          status, product number change, etc).
        </p>
      </>
    ),
    technologies: [
      {
        id: 1,
        icon: <JavascriptIcon />,
      },
      {
        id: 2,
        icon: <MysqlIcon />,
      },
      {
        id: 3,
        icon: <ReactIcon />,
      },
      {
        id: 4,
        icon: <NodeIcon />,
      },
      {
        id: 5,
        icon: <ReactNativeIcon />,
      },
      {
        id: 6,
        icon: <GraphqlIcon />,
      },
      {
        id: 7,
        icon: <MaterialIcon />,
      },
    ],
  },
  {
    id: 3,
    image: Work2,
    title: 'Foodeist for business',
    subTitle: '',
    text: (
      <>
        <p>
          Design created in Figma has hover and popup effects. Main requirements for viewing are an
          intuitive interface, a well-thought-out UX/UI, which is easily navigated by a user with
          minimal skill while working with similar applications, namely:
        </p>
        <ul>
          <li>Easy to manage orders</li>
          <li>Effectively manage the seating of guests in the hall</li>
          <li>Navigate to the menu</li>
          <li>Monitor clients payments</li>
        </ul>
        <p>The design is suitable for all devices.</p>
      </>
    ),
  },
  {
    id: 4,
    image: Work7,
    title: 'Web development for Business Management',
    subTitle: 'ScrumPokerPro - task manager for distributed and remote teams',
    text: (
      <>
        <p>
          We developed a free application that will help development teams to estimate their
          projects with ability to vote. Target audience - developers, designers, marketers.
        </p>
      </>
    ),
    technologies: [
      {
        id: 1,
        icon: <JavascriptIcon />,
      },
      {
        id: 2,
        icon: <ReactIcon />,
      },
      {
        id: 3,
        icon: <ReactNativeIcon />,
      },
      {
        id: 4,
        icon: <ChakraIcon />,
      },
      {
        id: 5,
        icon: <WebsocketsIcon />,
      },
    ],
  },
  {
    id: 5,
    image: Work3,
    title: 'Banking App',
    subTitle: 'Сross-platform banking application for iOS and Android',
    text: (
      <>
        <p>
          The design of mobile interfaces is a constant balance between uniqueness, attractiveness,
          and recognition.
        </p>
        <p>
          First, the user needs to quickly understand the functionality required, i.e. navigation,
          buttons, and icons must be user-friendly. At the same time, the project's individuality
          must be preserved. We achieved all in this UI/UX design created in Figma.
        </p>
      </>
    ),
  },
  {
    id: 6,
    image: Work8,
    title: 'Web Development for FinTech',
    subTitle: 'Simplewealth - private asset management for everyone',
    text: (
      <>
        <p>Simplewealth has digitized almost everything needed for investment growth.</p>
        <p>
          We developed a part of the Simplewealth service: user authorization and onboarding as well
          as the app's main page. We also implemented parsing and analysis of documents (assets)
          supplied by adjacent modules of the project, structured received data into a suitable
          format and stored them in the database.
        </p>
      </>
    ),
    technologies: [
      {
        id: 1,
        icon: <JavascriptIcon />,
      },
      {
        id: 2,
        icon: <ReactIcon />,
      },
      {
        id: 3,
        icon: <ReactNativeIcon />,
      },
      {
        id: 4,
        icon: <NodeIcon />,
      },
      {
        id: 5,
        icon: <GraphqlIcon />,
      },
      {
        id: 6,
        icon: <ChakraIcon />,
      },
    ],
  },
  {
    id: 7,
    image: Work4,
    title: 'Fit time mobile and desktop design',
    subTitle: 'Сross-platform banking application for iOS and Android',
    text: (
      <>
        <p>
          This is design of a platform made for gym, workout, sports and other trainers to share
          their knowledge and lessons in videos, articles etc.
        </p>
        <p>
          Personal pages with free and subscription based content can offer high quality content for
          any level user.
        </p>
        <p>
          Various filters by trainers, training types, muscle groups, equipment and difficulty level
          will help to find suitable video or article as fast as possible.
        </p>
      </>
    ),
  },
  {
    id: 8,
    image: Work9,
    title: 'Mobile development for HoReCa',
    subTitle: 'xTips - service for receiving cashless tips',
    text: (
      <>
        <p>
          To develop mobile app for service workers to receive tips, accumulate them, distribute
          among team members and withdraw them to their accounts. Project languages: English,
          Estonian and Russian.
        </p>
      </>
    ),
    technologies: [
      {
        id: 1,
        icon: <JavascriptIcon />,
      },
      {
        id: 2,
        icon: <ReactIcon />,
      },
      {
        id: 3,
        icon: <NodeIcon />,
      },
      {
        id: 4,
        icon: <GraphqlIcon />,
      },
      {
        id: 5,
        icon: <ChakraIcon />,
      },
      {
        id: 6,
        icon: <PostgresqlIcon />,
      },
      {
        id: 7,
        icon: <StripeIcon />,
      },
    ],
  },
  {
    id: 9,
    image: Work5,
    title: 'Web & Mobile development for Entertainment',
    subTitle: 'HYPT - platform about entertainment and nightlife in Dubai',
    text: (
      <>
        <p>
          We developed a web service and mobile app for Dubai guests and residents that tells about
          the city's best entertainments and venues. A vast catalog of the venues, events, and
          attractions with option to buy or book tickets for a visit. In addition we added social
          network features like chat, and posts with comment like and share options.
        </p>
      </>
    ),
    technologies: [
      {
        id: 1,
        icon: <JavascriptIcon />,
      },
      {
        id: 2,
        icon: <ReactIcon />,
      },
      {
        id: 3,
        icon: <ReactNativeIcon />,
      },
      {
        id: 4,
        icon: <NodeIcon />,
      },
      {
        id: 5,
        icon: <GraphqlIcon />,
      },
      {
        id: 6,
        icon: <ChakraIcon />,
      },
    ],
  },
  {
    id: 10,
    image: Work10,
    title: 'Web Development for Healthcare',
    subTitle:
      'Berry is a browser extension, that enhances your shopping so you can find exactly what you want',
    text: (
      <>
        <p>
          Berry start-up was created by a team of students at Princeton University. The essence of
          its creation is to do the shopping for products on the Internet healthy and affordable for
          people who has to choose products with a specific composition (based on medical
          indications or personal preferences).
        </p>
        <p>
          Our team faced the task of finalizing the existing website and developing a browser
          extension that would work in tandem with the site.
        </p>
      </>
    ),
    technologies: [
      {
        id: 1,
        icon: <JavascriptIcon />,
      },
      {
        id: 2,
        icon: <ReactIcon />,
      },
      {
        id: 3,
        icon: <ReactNativeIcon />,
      },
      {
        id: 4,
        icon: <NodeIcon />,
      },
      {
        id: 5,
        icon: <AwsIcon />,
      },
    ],
  },
];
