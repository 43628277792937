export const JiraIcon = ({ color = '#5A32FB', width = 40, height = 40 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M20.0263 2.75L4.47632 18.2513C4.0142 18.717 3.75488 19.3464 3.75488 20.0025C3.75488 20.6586 4.0142 21.2881 4.47632 21.7538L20.0263 37.25L20.9113 36.3713C25.4601 31.84 28.2426 29.065 29.8876 27.4213L31.5826 25.7238C31.6895 25.6189 31.7929 25.5105 31.8926 25.3988L31.9901 25.3063L31.9826 25.2963L32.0601 25.1988L35.5151 21.7513C35.9901 21.2838 36.2501 20.6625 36.2501 19.9988C36.2501 19.3363 35.9901 18.7163 35.5176 18.2488C33.4388 16.1738 31.0426 13.77 28.6326 11.3538C25.9326 8.64625 23.2163 5.92125 20.9113 3.62625L20.0263 2.75ZM20.0263 6.27875C22.1263 8.37375 24.5013 10.7538 26.8638 13.12C29.2663 15.53 31.6563 17.9263 33.7326 19.9975C32.0401 21.685 30.8476 22.875 30.0326 23.735C29.8763 23.8763 29.7188 24.0325 29.5563 24.1925C29.2763 24.4675 29.0013 24.7325 28.9401 24.79L28.9626 24.815C25.9863 27.7854 23.0084 30.7542 20.0288 33.7213L6.24132 20.02L20.0263 6.27875ZM20.0263 13.6575L13.6601 20L20.0263 26.3425L26.3963 20L20.0263 13.6575ZM20.0263 17.185L22.8538 20L20.0263 22.815L17.2013 20L20.0263 17.185Z'
        fill={color}
      />
    </svg>
  );
};
