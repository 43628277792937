import Lottie from 'lottie-react';
import React, { useEffect, useRef, useState } from 'react';

function isInViewport(element) {
  if (!element?.animationContainerRef?.current) return false;

  const element_ = element.animationContainerRef.current;

  const rect = element_.getBoundingClientRect();
  const isInView =
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth);

  return isInView;
}

const LottieIcon = ({ options, replayInView = false, disableOnHover = false }) => {
  const _lottieHeartReference = useRef(null);
  const [isElementInViewPort, setElementInViewPort] = useState(false);

  const handleScroll = () => {
    const value = isInViewport(_lottieHeartReference?.current);
    setElementInViewPort(value);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const onMouseEnter = () => {
    _lottieHeartReference?.current?.stop();
    _lottieHeartReference?.current?.play();
  };

  useEffect(() => {
    if (replayInView && isElementInViewPort) {
      _lottieHeartReference?.current?.stop();
      _lottieHeartReference?.current?.play();
    }
  }, [replayInView, isElementInViewPort]);

  return (
    <Lottie
      {...options}
      lottieRef={_lottieHeartReference}
      {...(!disableOnHover && {
        onMouseEnter,
      })}
    />
  );
};

export default LottieIcon;
