export const ArrowIcon = ({ color = '#5A32FB', width = 8, height = 82 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 8 82'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4.5 1C4.5 0.723858 4.27614 0.5 4 0.5C3.72386 0.5 3.5 0.723858 3.5 1L4.5 1ZM3.64644 81.3536C3.84171 81.5488 4.15829 81.5488 4.35355 81.3536L7.53553 78.1716C7.73079 77.9763 7.73079 77.6597 7.53553 77.4645C7.34027 77.2692 7.02369 77.2692 6.82842 77.4645L4 80.2929L1.17157 77.4645C0.976307 77.2692 0.659725 77.2692 0.464463 77.4645C0.269201 77.6597 0.269201 77.9763 0.464463 78.1716L3.64644 81.3536ZM3.5 1L3.5 81L4.5 81L4.5 1L3.5 1Z'
        fill={color}
      />
    </svg>
  );
};
