import React from 'react';

export const StrapiFullLogoIcon = () => {
  return (
    <svg
      width='480'
      height='116'
      viewBox='0 0 480 116'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_157_214)'>
        <path
          d='M462.465 17.0227C464.429 18.991 466.749 19.975 469.424 19.975C472.182 19.975 474.543 18.991 476.508 17.0227C478.472 15.0546 479.454 12.7304 479.454 10.0503C479.454 7.37021 478.472 5.02517 476.508 3.0151C474.543 1.00502 472.182 0 469.424 0C466.749 0 464.429 1.00502 462.465 3.0151C460.5 5.02517 459.519 7.37021 459.519 10.0503C459.519 12.7304 460.5 15.0546 462.465 17.0227Z'
          fill='#212067'
        />
        <path
          d='M250.946 42.719C250.946 42.9247 250.779 43.0915 250.573 43.0915H236.777V69.2221C236.777 71.3998 237.321 72.991 238.408 73.9962C239.494 75.0013 241.082 75.5665 243.172 75.6924C245.084 75.8073 247.415 75.7996 250.167 75.6689L250.237 75.6656L250.364 75.6596L250.553 75.6497C250.767 75.6388 250.946 75.8089 250.946 76.0223V89.9949C250.946 90.1847 250.803 90.344 250.614 90.3648C250.522 90.3746 250.43 90.385 250.361 90.3921C239.553 91.5466 231.912 90.4786 227.437 87.1873C222.881 83.837 220.603 77.8488 220.603 69.2221V43.0915H210.068C209.862 43.0915 209.695 42.9247 209.695 42.719V27.8861C209.695 27.6804 209.862 27.5135 210.068 27.5135H220.603V17.1382C220.603 16.8431 220.778 16.5757 221.048 16.4565L236.254 9.75093C236.5 9.64215 236.777 9.8225 236.777 10.0918V27.5135H250.573C250.779 27.5135 250.946 27.6804 250.946 27.8861V42.719Z'
          fill='#212067'
        />
        <path
          d='M280.275 38.3172C281.779 34.297 284.266 31.2819 287.735 29.2719C290.909 27.4323 294.417 26.4346 298.256 26.2785C298.442 26.271 298.726 26.2654 298.956 26.2618C299.164 26.2585 299.333 26.4259 299.333 26.6331V43.68C299.333 44.0359 299.023 44.3098 298.668 44.2777C294.004 43.855 289.816 44.8833 286.105 47.3624C282.218 49.9588 280.275 54.272 280.275 60.302V89.9547C280.275 90.1605 280.108 90.3274 279.902 90.3274H264.474C264.268 90.3274 264.101 90.1605 264.101 89.9547V27.8857C264.101 27.6799 264.268 27.513 264.474 27.513H279.902C280.108 27.513 280.275 27.6799 280.275 27.8857V38.3172Z'
          fill='#212067'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M356.752 27.5127C356.546 27.5127 356.379 27.6795 356.379 27.8853V34.9248C351.531 28.8108 344.719 25.7539 335.942 25.7539C327.583 25.7539 320.416 28.9574 314.439 35.3645C308.463 41.7716 305.475 49.6234 305.475 58.9199C305.475 68.2164 308.463 76.0683 314.439 82.4756C320.416 88.8824 327.583 92.0861 335.942 92.0861C344.719 92.0861 351.531 89.0291 356.379 82.915V89.9549C356.379 90.1606 356.546 90.327 356.752 90.327H372.18C372.386 90.327 372.554 90.1606 372.554 89.9549V27.8853C372.554 27.6795 372.386 27.5127 372.18 27.5127H356.752ZM326.537 71.7347C329.797 75.0013 333.935 76.6346 338.95 76.6346C343.966 76.6346 348.124 74.9805 351.425 71.6723C354.727 68.3641 356.378 64.1137 356.378 58.9205C356.378 53.7281 354.727 49.4777 351.425 46.1694C348.124 42.8612 343.966 41.2071 338.95 41.2071C333.935 41.2071 329.797 42.8612 326.537 46.1694C323.277 49.4777 321.647 53.7281 321.647 58.9205C321.647 64.1137 323.277 68.3849 326.537 71.7347Z'
          fill='#212067'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M442.804 35.3645C436.828 28.9574 429.618 25.7539 421.176 25.7539C412.399 25.7539 405.628 28.8108 400.864 34.9248V27.8853C400.864 27.6795 400.697 27.5127 400.491 27.5127H385.063C384.857 27.5127 384.689 27.6795 384.689 27.8853V115.08C384.689 115.286 384.857 115.453 385.063 115.453H400.491C400.697 115.453 400.864 115.286 400.864 115.08V82.915C405.628 89.0291 412.399 92.0861 421.176 92.0861C429.618 92.0861 436.828 88.8824 442.804 82.4756C448.78 76.0683 451.768 68.2164 451.768 58.9199C451.768 49.6234 448.78 41.7716 442.804 35.3645ZM405.752 71.7347C409.012 75.0013 413.15 76.6346 418.165 76.6346C423.18 76.6346 427.339 74.9805 430.641 71.6723C433.942 68.3641 435.593 64.1137 435.593 58.9205C435.593 53.7281 433.942 49.4777 430.641 46.1694C427.339 42.8612 423.18 41.2071 418.165 41.2071C413.15 41.2071 409.012 42.8612 405.752 46.1694C402.492 49.4777 400.863 53.7281 400.863 58.9205C400.863 64.1137 402.492 68.3849 405.752 71.7347Z'
          fill='#212067'
        />
        <path
          d='M461.775 90.3282C461.568 90.3282 461.401 90.1613 461.401 89.9556V27.8863C461.401 27.6805 461.568 27.5137 461.775 27.5137H477.202C477.408 27.5137 477.576 27.6805 477.576 27.8863V89.9556C477.576 90.1613 477.408 90.3282 477.202 90.3282H461.775Z'
          fill='#212067'
        />
        <path
          d='M173.062 49.435C170.847 48.3881 169.739 47.0271 169.739 45.3521C169.739 43.7607 170.429 42.5045 171.808 41.5831C173.187 40.6619 174.922 40.2013 177.011 40.2013C181.018 40.2013 184.046 41.7663 186.092 44.8965C186.235 45.1149 186.513 45.2033 186.752 45.0975L200.398 39.0483C200.601 38.9582 200.681 38.712 200.568 38.5209C200.418 38.2669 200.219 37.9358 200.088 37.7285C197.749 34.0443 194.723 31.2997 190.929 29.2087C186.749 26.9055 182.11 25.7539 177.011 25.7539C170.241 25.7539 164.578 27.5336 160.022 31.0931C155.467 34.6526 153.189 39.5312 153.189 45.7289C153.189 49.8328 154.297 53.2457 156.511 55.9675C158.727 58.6897 161.422 60.6787 164.599 61.935C167.775 63.1913 170.951 64.238 174.128 65.0757C177.304 65.9134 180 66.8557 182.215 67.9024C184.43 68.9491 185.537 70.3105 185.537 71.9854C185.537 75.5869 182.653 77.3876 176.886 77.3876C171.396 77.3876 167.591 75.3543 165.473 71.2888C165.291 70.9392 164.874 70.7794 164.514 70.9397L150.906 76.981C150.714 77.0658 150.63 77.2924 150.721 77.4811C150.777 77.595 150.835 77.7142 150.877 77.7991C155.633 87.3236 164.302 92.0862 176.886 92.0862C184.075 92.0862 190.072 90.3484 194.878 86.8722C199.685 83.3966 202.088 78.4343 202.088 71.9854C202.088 67.7142 200.98 64.1543 198.765 61.3068C196.55 58.4593 193.855 56.4282 190.678 55.2141C187.502 53.9995 184.325 52.9945 181.149 52.1988C177.973 51.4031 175.277 50.4819 173.062 49.435Z'
          fill='#212067'
        />
        <path
          d='M0 40.2133C0 21.2566 0 11.7782 5.89742 5.88908C11.7949 0 21.2867 0 40.2703 0H75.8942C94.8778 0 104.369 0 110.267 5.88908C116.164 11.7782 116.164 21.2566 116.164 40.2133V75.7868C116.164 94.7435 116.164 104.222 110.267 110.111C104.369 116 94.8778 116 75.8942 116H40.2703C21.2867 116 11.7949 116 5.89742 110.111C0 104.222 0 94.7435 0 75.7868V40.2133Z'
          fill='#4945FF'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M80.1539 35.1875H41.0449V55.1009H60.9867V75.014H80.9281V35.9609C80.9281 35.5337 80.5813 35.1875 80.1539 35.1875Z'
          fill='white'
        />
        <path d='M60.9863 55.0996H60.2119V55.8729H60.9863V55.0996Z' fill='white' />
        <path
          d='M41.0449 55.0996H60.2119C60.6399 55.0996 60.9867 55.446 60.9867 55.8728V75.0128H41.8194C41.3917 75.0128 41.0449 74.6669 41.0449 74.2396V55.0996Z'
          fill='#9593FF'
        />
        <path
          d='M60.9863 75.0137H80.9277L61.6471 94.2669C61.4033 94.5104 60.9863 94.3381 60.9863 93.9939V75.0137Z'
          fill='#9593FF'
        />
        <path
          d='M41.0441 55.1009H22.0374C21.6925 55.1009 21.5197 54.6844 21.7636 54.4408L41.0441 35.1875V55.1009Z'
          fill='#9593FF'
        />
      </g>
      <defs>
        <clipPath id='clip0_157_214'>
          <rect width='480' height='116' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
