import Typography from '@mui/material/Typography';

import { ArrowTechnologyStackIcon } from '../../assets/icons/ArrowTechnologyStackIcon';
import { CursorIcon } from '../../assets/icons/technologiesList/CursorIcon';
import { TECHNOLOGY_STACK_ICONS } from '../../MOCK';
import Container from '../Container';
import { StyledTechnologyStack, TechnologyStackContent, TechnologyStackInfo } from './styles';
import TechnologyStackItem from './TechnologyStackItem';

const TechnologyStack = () => {
  return (
    <StyledTechnologyStack>
      <Container direction='column'>
        <Typography variant='h2'>Technology stack we use</Typography>
        <TechnologyStackContent>
          {TECHNOLOGY_STACK_ICONS.map((item) => {
            return <TechnologyStackItem key={item.id} {...item} />;
          })}
        </TechnologyStackContent>
        <TechnologyStackInfo>
          <div className='firstArrowIcon'>
            <ArrowTechnologyStackIcon />
          </div>
          <div className='cursorIcon'>
            <CursorIcon />
          </div>
          <div className='secondArrowIcon'>
            <ArrowTechnologyStackIcon />
          </div>
        </TechnologyStackInfo>
      </Container>
    </StyledTechnologyStack>
  );
};

export default TechnologyStack;
