import Typography from '@mui/material/Typography';
import React, { useRef } from 'react';
import useBreakpoint from 'use-breakpoint';

import { TheBiggestArrowIcon } from '../../assets/icons/TheBiggestArrowIcon';
import { BREAKPOINTS } from '../../constants';
import { WhyMalevichItem } from './styles';

const WhyMalevichItems = ({ item, currentSlideNumber }) => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  const isTablet = ['xxxs', 'xxs', 'xs', 'sm', 'md', 'lg'].includes(breakpoint);
  const reference = useRef();

  return (
    <WhyMalevichItem
      ref={reference}
      initial={{
        x: !isTablet ? '-50%' : '0%',
        opacity: !isTablet ? 0 : 1,
        scale: !isTablet ? 0 : 1,
      }}
      animate={{
        ...(currentSlideNumber === 10 && {
          x: '0%',
          opacity: 1,
          scale: 1,
        }),
      }}
      transition={{
        type: 'tween',
        ease: 'easeIn',
        delay: 0.5,
      }}
      viewport={{
        once: true,
        amount: 1,
      }}
    >
      <Typography variant='body2'>{item.text}</Typography>
      <TheBiggestArrowIcon />
    </WhyMalevichItem>
  );
};

export default WhyMalevichItems;
