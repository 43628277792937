export const StartIcon = ({ color = '#151515', width = 42, height = 42 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 42 42'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M21.0001 31.955L8.65734 38.864L11.4136 24.99L1.02734 15.386L15.0746 13.72L21.0001 0.875L26.9256 13.72L40.9728 15.386L30.5866 24.99L33.3428 38.864L21.0001 31.955ZM21.0001 27.944L28.4323 32.1037L26.7716 23.751L33.0243 17.9672L24.5666 16.9645L21.0001 9.23125L17.4336 16.9662L8.97584 17.9672L15.2286 23.751L13.5678 32.1037L21.0001 27.944Z'
        fill={color}
      />
    </svg>
  );
};
