import { ArrowLongIcon, Clutch, Upwork, UpworkPro } from '../../assets/icons';
import Container from '../Container';
import {
  PlatformArrow,
  PlatformContent,
  PlatformItem,
  PlatformTitle,
  StyledPlatform,
} from './styles';

const Platform = () => {
  return (
    <Container>
      <StyledPlatform>
        <PlatformTitle variant='h3'>Why are we the best at our work?</PlatformTitle>
        <PlatformArrow>
          <ArrowLongIcon />
        </PlatformArrow>
        <PlatformContent>
          <PlatformItem>
            <a href='https://www.upwork.com/ag/malevich' target='_blank' rel='noreferrer'>
              <Upwork />
            </a>
            <a href='https://www.upwork.com/ag/malevich' target='_blank' rel='noreferrer'>
              <UpworkPro />
            </a>
          </PlatformItem>
          <PlatformItem
            sx={{
              a: {
                '&:nth-of-type(1), &:nth-of-type(1) svg': {
                  width: '208px',
                  height: '93px',
                },
              },
            }}
          >
            <a href='https://clutch.co/profile/malevich#summary' target='_blank' rel='noreferrer'>
              <Clutch />
            </a>
          </PlatformItem>
        </PlatformContent>
      </StyledPlatform>
    </Container>
  );
};

export default Platform;
