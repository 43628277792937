import React from 'react';

import palette from '../../theme/palette';

export const SendIcon = ({ color = palette.secondary, width = 16, height = 17 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M14.6667 13.524C14.6654 13.6991 14.5954 13.8667 14.4717 13.9906C14.3479 14.1144 14.1804 14.1846 14.0053 14.186H1.99467C1.81921 14.1859 1.651 14.116 1.52699 13.9919C1.40299 13.8678 1.33333 13.6995 1.33333 13.524V12.8527H13.3333V5.0527L8 9.8527L1.33333 3.8527V2.8527C1.33333 2.67589 1.40357 2.50632 1.5286 2.3813C1.65362 2.25627 1.82319 2.18604 2 2.18604L14 2.18604C14.1768 2.18604 14.3464 2.25627 14.4714 2.3813C14.5964 2.50632 14.6667 2.67589 14.6667 2.8527V13.524ZM2.956 3.51937L8 8.05937L13.044 3.51937L2.956 3.51937ZM0 10.186H5.33333V11.5194H0L0 10.186ZM0 6.8527H3.33333V8.18604H0L0 6.8527Z'
        fill={color}
      />
    </svg>
  );
};
