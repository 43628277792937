import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import { Form } from 'formik';

import theme from '../../theme';
import palette from '../../theme/palette';

export const SForm = styled(Form)({
  marginTop: 50,
  marginBottom: 0,

  [theme.breakpoints.down('lg')]: {
    marginTop: 44,
  },
});

export const SFormTitle = styled(Box)({
  display: 'flex',
  fontSize: 32,
  fontWeight: 400,
  lineHeight: '42px',

  '& span': {
    fontSize: 32,
    fontWeight: 600,
    lineHeight: '42px',
  },

  [theme.breakpoints.down('lg')]: {
    marginTop: 40,
    fontSize: 24,
    lineHeight: '32px',

    '& span': {
      fontSize: 24,
      lineHeight: '32px',
    },
  },

  [theme.breakpoints.down('md')]: {
    marginTop: 0,
  },
});

export const SFormTitleArrow = styled(Box)({
  display: 'flex',
  marginRight: 26,

  [theme.breakpoints.down('lg')]: {
    marginRight: 16,
  },
});

export const SCheckboxGroup = styled(Box)({
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
});

export const SServicesContainer = styled('label')({
  '&': {
    backgroundColor: palette.porcelain,
    padding: '10.5px 22px',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '21px',
    margin: '16px 15px 0px 0px',
    cursor: 'pointer',

    '&:last-child': {
      marginRight: 0,
    },
  },

  '& input': {
    width: 0,
    height: 0,
    opacity: 0,
  },

  '&.categoryActive': {
    backgroundColor: palette.black,
    color: palette.secondary,
  },
});

export const SNameEmailWrapper = styled(Box)({
  display: 'flex',
  width: '100%',

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
});

export const SDetailContainer = styled(TextField)({
  '&.MuiFormControl-root.MuiTextField-root': {
    marginTop: 60,
    width: '100%',

    [theme.breakpoints.down('md')]: {
      marginTop: 54,
    },
  },

  '& .MuiInputBase-root.MuiOutlinedInput-root': {
    padding: 0,
  },

  '& .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline, & .MuiInputBase-root.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline':
    {
      borderBottom: '1px solid #15151580',
    },

  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
    borderBottom: '1px solid #15151580',
    borderRadius: 0,
    padding: 0,
  },

  '& .MuiInputBase-input.MuiOutlinedInput-input::-webkit-input-placeholder, & .MuiInputBase-input.MuiOutlinedInput-input':
    {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '26px',
      letterSpacing: '0.43px',
      height: 'unset',
      color: palette.primary,
      opacity: 1,
      padding: '0px 0px 11px 0px',
    },
});

export const SButtonSend = styled(Button)({
  '&.MuiButtonBase-root.MuiButton-root': {
    justifyContent: 'space-between',
    width: 264,
    height: 60,
    marginTop: 32,
    padding: '0 26px',
    color: palette.secondary,
    fontSize: 16,
    lineHeight: '24px',
    textTransform: 'uppercase',
    backgroundColor: palette.purpleBlue,
    border: `1px solid ${palette.purpleBlue} `,
    borderRadius: 0,

    '&:hover': {
      backgroundColor: palette.secondary,
      color: palette.purpleBlue,

      '& svg path': {
        fill: palette.purpleBlue,
      },
    },

    [theme.breakpoints.down('xl')]: {
      width: '342px',
      margin: '26px 0px 36px',
    },

    '@media (max-width: 575px)': {
      width: '100%',
    },

    [theme.breakpoints.down('sm')]: {
      padding: '0 16px',
    },
  },
});

export const SFooterTitle = styled(Typography)({
  fontWeight: 600,
  lineHeight: '26px',
  textTransform: 'uppercase',
});
