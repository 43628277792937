import React from 'react';

export const BitCoinIcon = () => {
  return (
    <svg width='42' height='42' viewBox='0 0 42 42' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M21 38.5C11.3348 38.5 3.5 30.6652 3.5 21C3.5 11.3348 11.3348 3.5 21 3.5C30.6652 3.5 38.5 11.3348 38.5 21C38.5 30.6652 30.6652 38.5 21 38.5ZM21 35C24.713 35 28.274 33.525 30.8995 30.8995C33.525 28.274 35 24.713 35 21C35 17.287 33.525 13.726 30.8995 11.1005C28.274 8.475 24.713 7 21 7C17.287 7 13.726 8.475 11.1005 11.1005C8.475 13.726 7 17.287 7 21C7 24.713 8.475 28.274 11.1005 30.8995C13.726 33.525 17.287 35 21 35ZM19.25 28H14V14H19.25V10.5H22.75V14H24.5C25.3125 14 26.1089 14.2263 26.8001 14.6534C27.4912 15.0806 28.0498 15.6917 28.4131 16.4184C28.7765 17.1452 28.9303 17.9587 28.8573 18.7679C28.7844 19.5771 28.4875 20.35 28 21C28.4875 21.65 28.7844 22.4229 28.8573 23.2321C28.9303 24.0413 28.7765 24.8548 28.4131 25.5816C28.0498 26.3083 27.4912 26.9194 26.8001 27.3466C26.1089 27.7737 25.3125 28 24.5 28H22.75V31.5H19.25V28ZM17.5 22.75V24.5H24.5C24.7321 24.5 24.9546 24.4078 25.1187 24.2437C25.2828 24.0796 25.375 23.8571 25.375 23.625C25.375 23.3929 25.2828 23.1704 25.1187 23.0063C24.9546 22.8422 24.7321 22.75 24.5 22.75H17.5ZM17.5 17.5V19.25H24.5C24.7321 19.25 24.9546 19.1578 25.1187 18.9937C25.2828 18.8296 25.375 18.6071 25.375 18.375C25.375 18.1429 25.2828 17.9204 25.1187 17.7563C24.9546 17.5922 24.7321 17.5 24.5 17.5H17.5Z'
        fill='#151515'
      />
    </svg>
  );
};
