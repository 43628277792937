export const BookIcon = ({ color = '#5A32FB', width = 40, height = 40 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.833 8.3335H34.1663V36.6668H10.833V8.3335Z'
        stroke={color}
        strokeWidth='3'
        strokeLinejoin='round'
      />
      <path
        d='M29.1663 8.3335V3.3335H6.66634C6.44533 3.3335 6.23337 3.42129 6.07709 3.57757C5.92081 3.73385 5.83301 3.94582 5.83301 4.16683V31.6668H10.833M17.4997 18.3335H27.4997M17.4997 25.0002H27.4997'
        stroke={color}
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
