import { Typography } from '@mui/material';
import React, { useState } from 'react';

import { PlusInCircle } from '../../assets/icons';
import ModalCard from './ModalCard';
import {
  OurWorkDescriptionWrapper,
  OurWorkItemSubTitle,
  OurWorkItemTechnologiesList,
  OurWorkItemTechnology,
  OurWorkItemText,
  OurWorkItemTitle,
  OurWorkItemWrapper,
  ReadMoreWrapper,
} from './styles';

const OutWorkSliderContent = ({ item }) => {
  const [toggleMore, setToggleMore] = useState(false);

  const handleToggleMore = () => {
    setToggleMore((previous) => !previous);
  };
  return (
    <>
      <OurWorkItemWrapper>
        <img src={item.image} alt={item.title} />
        <OurWorkDescriptionWrapper>
          <div className='ourWorkDescription'>
            <div>
              <OurWorkItemTitle variant='subtitle2'>{item.title}</OurWorkItemTitle>
              {item.subTitle !== '' && (
                <OurWorkItemSubTitle variant='body2'>{item.subTitle}</OurWorkItemSubTitle>
              )}
              <OurWorkItemText className='cutText'>{item.text}</OurWorkItemText>
            </div>
            <OurWorkItemTechnologiesList>
              {item?.technologies?.map((technology) => {
                return (
                  <OurWorkItemTechnology key={technology.id}>
                    {technology.icon}
                  </OurWorkItemTechnology>
                );
              })}
            </OurWorkItemTechnologiesList>
          </div>
          <ReadMoreWrapper onClick={handleToggleMore}>
            <Typography variant='body2'>Read More</Typography>
            <PlusInCircle />
          </ReadMoreWrapper>
        </OurWorkDescriptionWrapper>
      </OurWorkItemWrapper>
      {toggleMore && <ModalCard close={handleToggleMore} open={toggleMore} item={item} />}
    </>
  );
};

export default OutWorkSliderContent;
