import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { ArrowIcon } from '../../assets/icons';
import BackgroundTitle from '../../components/BackgroundTitle';
import Container from '../../components/Container';
import FooterForm from '../../components/Form';
import {
  ContactsDescription,
  FooterArrowHeading,
  FooterContent,
  FooterInfo,
  FooterLinks,
  FooterTitle,
  LeftBlock,
  RightBlock,
  StyledFooter,
  StyledLink,
} from './styles';

const Footer = () => {
  return (
    <StyledFooter id='footer'>
      <BackgroundTitle maxWidth='925px'>Ready to partner with us?</BackgroundTitle>
      <Container direction='column'>
        <Typography variant='h2' className='title'>
          Ready TO PARTNER WITH US?
        </Typography>
        <FooterContent>
          <LeftBlock>
            <Box>
              <FooterTitle variant='subtitle1'>Contacts</FooterTitle>
              <FooterInfo>
                <Typography variant='body2' fontWeight='500'>
                  Ukraine, Dnipro, st. Kalinovaya, 53
                </Typography>
                <Typography variant='body2' fontWeight='500'>
                  <a href='tel:+380506456530'>+38 050 645 65 30</a>
                </Typography>
                <Typography variant='body2' fontWeight='500'>
                  <a href='mailto:info@malevich.com.ua'>info@malevich.com.ua</a>
                </Typography>
              </FooterInfo>
              <FooterLinks>
                <Typography variant='body2' fontWeight='500'>
                  <a href='https://www.upwork.com/ag/malevich/' target='_blank' rel='noreferrer'>
                    upwork
                  </a>
                </Typography>
                <Typography variant='body2' fontWeight='500'>
                  <a
                    href='https://clutch.co/profile/malevich#summary'
                    target='_blank'
                    rel='noreferrer'
                  >
                    clutch
                  </a>
                </Typography>
              </FooterLinks>
            </Box>
            <Box>
              <ContactsDescription>
                The text and graphic content of the site belongs to Malevich and cannot be used by
                other resources.
              </ContactsDescription>
              <StyledLink href='https://malevichstudio.com'>malevich.com.ua</StyledLink>
            </Box>
          </LeftBlock>
          <RightBlock>
            <FooterArrowHeading>
              <ArrowIcon />
              <Typography variant='h4'>
                <strong>Start</strong> your project with
                <strong>Malevich. Let’s build something</strong> great together
              </Typography>
            </FooterArrowHeading>
            <FooterForm />
          </RightBlock>
        </FooterContent>
      </Container>
    </StyledFooter>
  );
};

export default Footer;
