/* eslint-disable unicorn/prevent-abbreviations */
import { useCallback, useEffect, useRef, useState } from 'react';

const useDragList = () => {
  const dragListReference = useRef(null);

  const [isDown, setIsDown] = useState(false);
  const [startX, setStartX] = useState();
  const [scrollLeft, setScrollLeft] = useState();

  const handleMouseDown = useCallback((e) => {
    if (dragListReference.current) {
      dragListReference.current.classList.add('active');

      setIsDown(true);
      setStartX(e.pageX - dragListReference.current.offsetLeft);
      setScrollLeft(dragListReference.current.scrollLeft);
    }
  }, []);

  const handleMouseLeave = useCallback(() => {
    if (dragListReference.current) {
      dragListReference.current.classList.remove('active');

      setIsDown(false);
    }
  }, []);

  const handleMouseUp = useCallback(() => {
    if (dragListReference.current) {
      dragListReference.current.classList.remove('active');

      setIsDown(false);
    }
  }, []);

  const handleMouseMove = useCallback(
    (e) => {
      e.preventDefault();
      if (!isDown) return;

      if (dragListReference.current) {
        const x = e.pageX - dragListReference.current.offsetLeft;
        const walk = (x - startX) * 3; // scroll-fast
        dragListReference.current.scrollLeft = scrollLeft - walk;
      }
    },
    [isDown, scrollLeft, startX],
  );

  useEffect(() => {
    const reference = dragListReference.current;

    if (dragListReference.current) {
      dragListReference.current.addEventListener('mousedown', handleMouseDown);
      dragListReference.current.addEventListener('mouseleave', handleMouseLeave);
      dragListReference.current.addEventListener('mouseup', handleMouseUp);
      dragListReference.current.addEventListener('mousemove', handleMouseMove);
    }

    return () => {
      if (reference) {
        reference.removeEventListener('mousedown', handleMouseDown);
        reference.removeEventListener('mouseleave', handleMouseLeave);
        reference.removeEventListener('mouseup', handleMouseUp);
        reference.removeEventListener('mousemove', handleMouseMove);
      }
    };
  }, [handleMouseMove, handleMouseDown, handleMouseLeave, handleMouseUp]);

  return dragListReference;
};

export default useDragList;
