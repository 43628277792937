import palette from '../../theme/palette';

export const PostgresqlIcon = ({ color = palette.amethystSmoke, width = 72, height = 76 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 72 76'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M47.366 34.2919C47.66 32.2379 47.5716 31.9363 49.3942 32.2691L49.858 32.3037C51.2597 32.3575 53.0929 32.1148 54.17 31.6945C56.4888 30.7914 57.8625 29.2834 55.577 29.6795C50.362 30.5825 50.0033 29.0988 50.0033 29.0988C55.5096 22.2391 57.8117 13.5309 55.8255 11.3989C50.3961 5.58615 41.0249 8.33521 40.8586 8.40715L40.8088 8.41581C39.778 8.23641 38.6248 8.12981 37.3298 8.11248C34.9699 8.07955 33.1796 8.63248 31.8208 9.49655C31.8208 9.49655 15.0855 3.70721 15.8642 16.7765C16.0305 19.5568 20.6111 37.8131 26.0737 32.2985C28.0705 30.2818 30.0008 28.5771 30.0008 28.5771C31.0073 29.1354 32.1596 29.3823 33.3091 29.286L33.4019 29.2193C33.373 29.4706 33.3844 29.7133 33.4395 30.0045C32.0325 31.3244 32.4455 31.5558 29.6315 32.0429C26.7851 32.5351 28.4572 33.4122 29.5492 33.6419C30.8731 33.9192 33.9356 34.3135 36.005 31.8825L35.9228 32.1599C36.474 32.5308 36.4373 34.8249 36.516 36.4646C36.5948 38.1043 36.726 39.634 37.1232 40.5362C37.5205 41.4384 37.9913 43.7602 41.689 43.0963C44.7795 42.5399 47.1429 41.74 47.359 34.2953'
        fill={color}
      />
      <path
        d='M47.366 34.2919C47.66 32.2379 47.5716 31.9363 49.3942 32.2691L49.858 32.3037C51.2597 32.3575 53.0929 32.1148 54.17 31.6945C56.4887 30.7914 57.8625 29.2834 55.577 29.6795C50.362 30.5825 50.0032 29.0988 50.0032 29.0988C55.5096 22.2391 57.8117 13.5309 55.8255 11.3989C50.3961 5.58615 41.0249 8.33521 40.8586 8.40715L40.8088 8.41581C39.778 8.23641 38.6247 8.12981 37.3297 8.11248C34.9699 8.07955 33.1796 8.63248 31.8208 9.49655C31.8208 9.49655 15.0855 3.70721 15.8642 16.7765C16.0305 19.5568 20.6111 37.8131 26.0737 32.2985C28.0705 30.2818 30.0008 28.5771 30.0008 28.5771C31.0073 29.1354 32.1596 29.3823 33.3091 29.286L33.4019 29.2193C33.373 29.4706 33.3844 29.7133 33.4395 30.0045C32.0325 31.3244 32.4455 31.5558 29.6315 32.0429C26.7851 32.5351 28.4572 33.4122 29.5493 33.6419C30.8731 33.9192 33.9356 34.3135 36.005 31.8825L35.9228 32.1599C36.474 32.5308 36.4373 34.8249 36.516 36.4646C36.5948 38.1043 36.726 39.634 37.1232 40.5362C37.5205 41.4384 37.9913 43.7602 41.689 43.0963C44.7795 42.5399 47.1429 41.74 47.359 34.2953'
        stroke={color}
        strokeWidth='4.108'
      />
      <path
        d='M55.5759 29.6781C50.3609 30.5811 50.0022 29.0974 50.0022 29.0974C55.5085 22.2369 57.8107 13.5286 55.8244 11.3975C50.4047 5.58472 41.0247 8.33466 40.8584 8.40659L40.8077 8.41526C39.7769 8.23586 38.6237 8.12926 37.3278 8.11192C34.9679 8.07986 33.1777 8.63192 31.8197 9.49599C31.8197 9.49599 15.0835 3.70666 15.8623 16.776C16.0285 19.5563 20.6083 37.8135 26.0718 32.298C28.0685 30.2813 29.9979 28.5765 29.9979 28.5765C31.0045 29.1348 32.1568 29.3817 33.3063 29.2855L33.3999 29.2187C33.371 29.4701 33.3842 29.7127 33.4375 30.0039C32.0305 31.3239 32.4435 31.5553 29.6313 32.0423C26.784 32.5346 28.457 33.4117 29.549 33.6413C30.8729 33.9187 33.9354 34.313 36.0048 31.882L35.9225 32.1593C36.4738 32.5303 36.8614 34.5713 36.7975 36.4233C36.7337 38.2754 36.689 39.5433 37.1239 40.5365C37.5588 41.5297 37.9919 43.7605 41.6914 43.0967C44.7819 42.5403 46.384 41.0999 46.6063 38.6957C46.7638 36.9867 47.1225 37.2397 47.1444 35.7118L47.4314 34.989C47.7622 32.6724 47.4839 31.9253 49.3879 32.2729L49.8517 32.3075C51.2534 32.3613 53.0874 32.1186 54.1637 31.6983C56.4824 30.7952 57.8562 29.2872 55.5707 29.6833L55.5759 29.6781Z'
        fill={color}
      />
      <path
        d='M36.2368 31.0064C36.0933 35.3164 36.2718 39.6575 36.7749 40.7131C37.2781 41.7687 38.3534 43.8201 42.0529 43.1545C45.1434 42.5981 46.2678 41.5217 46.7552 39.1453C47.1052 37.3972 47.8078 32.5413 47.8962 31.5472M31.8023 9.39697C31.8023 9.39697 15.0461 3.65097 15.8336 16.7264C16.0086 19.5066 20.5796 37.7638 26.0448 32.2397C28.0416 30.223 29.8467 28.6413 29.8467 28.6413M40.8586 8.34831C40.2784 8.50084 50.1747 5.31151 55.7983 11.3444C57.7846 13.4764 55.4833 22.1846 49.9761 29.0452'
        stroke='white'
        strokeWidth='1.369'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M49.9675 29.0439C49.9675 29.0439 50.3176 30.5259 55.5413 29.6228C57.8268 29.2268 56.4513 30.7348 54.1325 31.6387C52.2303 32.3797 47.9655 32.5695 47.8964 31.546C47.717 28.9026 50.1408 29.706 49.9658 29.0439C49.8083 28.4476 48.7233 27.8626 48.0058 26.4032C47.3802 25.1292 39.4221 15.3601 50.2126 16.8109C50.6081 16.7416 47.3977 8.19275 37.2975 8.05409C27.1974 7.91542 27.5325 18.4801 27.5325 18.4801'
        stroke='white'
        strokeWidth='1.369'
        strokeLinecap='round'
        strokeLinejoin='bevel'
      />
      <path
        d='M33.4114 29.9487C32.0027 31.2687 32.4174 31.5001 29.6034 31.9871C26.7562 32.4811 28.4292 33.3565 29.5212 33.5861C30.8451 33.8635 33.9076 34.2578 35.9769 31.8251C36.6069 31.0841 35.9734 29.9011 35.1081 29.6012C34.6898 29.4556 34.1307 29.2736 33.4106 29.9479L33.4114 29.9487Z'
        stroke='white'
        strokeWidth='1.369'
        strokeLinejoin='round'
      />
      <path
        d='M33.3183 29.926C33.1766 29.1495 33.6219 28.2273 34.0997 27.1457C34.8172 25.5251 36.4727 23.9027 35.1497 18.759C34.1627 14.9257 27.5398 17.9617 27.5372 18.4817C27.5346 19.0017 27.8364 21.1163 27.4261 23.5777C26.8906 26.7913 29.8612 29.5083 33.2816 29.2309'
        stroke='white'
        strokeWidth='1.369'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M31.7417 18.4023C31.712 18.5799 32.1294 19.0523 32.6727 19.1164C33.2161 19.1805 33.679 18.8096 33.7096 18.6328C33.7402 18.456 33.3229 18.2601 32.7786 18.196C32.2344 18.1319 31.7706 18.2255 31.7417 18.4023Z'
        fill='white'
        stroke='white'
        strokeWidth='0.456'
      />
      <path
        d='M48.271 18.0408C48.3008 18.2185 47.8843 18.6908 47.34 18.755C46.7958 18.8191 46.332 18.4482 46.3023 18.2696C46.2725 18.0911 46.6899 17.897 47.2333 17.8328C47.7767 17.7687 48.2395 17.8614 48.2702 18.0391L48.271 18.0408Z'
        fill='white'
        stroke='white'
        strokeWidth='0.228'
      />
      <path
        d='M50.216 16.8115C50.3053 18.2043 49.859 19.1515 49.8021 20.6353C49.719 22.7898 51.0254 25.2546 49.0566 27.7246'
        stroke='white'
        strokeWidth='1.369'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.51697 68.6979C6.51697 69.1086 6.41797 69.4936 6.21997 69.8529C6.0293 70.2122 5.72497 70.5019 5.30697 70.7219C4.8963 70.9419 4.37564 71.0519 3.74497 71.0519H2.45797V73.9999H0.917969V66.3219H3.74497C4.33897 66.3219 4.84497 66.4246 5.26297 66.6299C5.68097 66.8352 5.99264 67.1176 6.19797 67.4769C6.41064 67.8362 6.51697 68.2432 6.51697 68.6979ZM3.67897 69.8089C4.1043 69.8089 4.41964 69.7136 4.62497 69.5229C4.8303 69.3249 4.93297 69.0499 4.93297 68.6979C4.93297 67.9499 4.51497 67.5759 3.67897 67.5759H2.45797V69.8089H3.67897Z'
        fill={color}
      />
      <path
        d='M11.3244 74.0769C10.6057 74.0769 9.94569 73.9082 9.34435 73.5709C8.74302 73.2336 8.26635 72.7679 7.91435 72.1739C7.56235 71.5726 7.38635 70.8942 7.38635 70.1389C7.38635 69.3909 7.56235 68.7199 7.91435 68.1259C8.26635 67.5246 8.74302 67.0552 9.34435 66.7179C9.94569 66.3806 10.6057 66.2119 11.3244 66.2119C12.0504 66.2119 12.7104 66.3806 13.3044 66.7179C13.9057 67.0552 14.3787 67.5246 14.7234 68.1259C15.0754 68.7199 15.2514 69.3909 15.2514 70.1389C15.2514 70.8942 15.0754 71.5726 14.7234 72.1739C14.3787 72.7679 13.9057 73.2336 13.3044 73.5709C12.703 73.9082 12.043 74.0769 11.3244 74.0769ZM11.3244 72.7019C11.7864 72.7019 12.1934 72.5992 12.5454 72.3939C12.8974 72.1812 13.1724 71.8806 13.3704 71.4919C13.5684 71.1032 13.6674 70.6522 13.6674 70.1389C13.6674 69.6256 13.5684 69.1782 13.3704 68.7969C13.1724 68.4082 12.8974 68.1112 12.5454 67.9059C12.1934 67.7006 11.7864 67.5979 11.3244 67.5979C10.8624 67.5979 10.4517 67.7006 10.0924 67.9059C9.74035 68.1112 9.46535 68.4082 9.26735 68.7969C9.06935 69.1782 8.97035 69.6256 8.97035 70.1389C8.97035 70.6522 9.06935 71.1032 9.26735 71.4919C9.46535 71.8806 9.74035 72.1812 10.0924 72.3939C10.4517 72.5992 10.8624 72.7019 11.3244 72.7019Z'
        fill={color}
      />
      <path
        d='M19.1981 74.0769C18.6627 74.0769 18.1787 73.9852 17.7461 73.8019C17.3207 73.6186 16.9834 73.3546 16.7341 73.0099C16.4847 72.6652 16.3564 72.2582 16.3491 71.7889H17.9991C18.0211 72.1042 18.1311 72.3536 18.3291 72.5369C18.5344 72.7202 18.8131 72.8119 19.1651 72.8119C19.5244 72.8119 19.8067 72.7276 20.0121 72.5589C20.2174 72.3829 20.3201 72.1556 20.3201 71.8769C20.3201 71.6496 20.2504 71.4626 20.1111 71.3159C19.9717 71.1692 19.7957 71.0556 19.5831 70.9749C19.3777 70.8869 19.0917 70.7916 18.7251 70.6889C18.2264 70.5422 17.8194 70.3992 17.5041 70.2599C17.1961 70.1132 16.9284 69.8969 16.7011 69.6109C16.4811 69.3176 16.3711 68.9289 16.3711 68.4449C16.3711 67.9902 16.4847 67.5942 16.7121 67.2569C16.9394 66.9196 17.2584 66.6629 17.6691 66.4869C18.0797 66.3036 18.5491 66.2119 19.0771 66.2119C19.8691 66.2119 20.5107 66.4062 21.0021 66.7949C21.5007 67.1762 21.7757 67.7116 21.8271 68.4009H20.1331C20.1184 68.1369 20.0047 67.9206 19.7921 67.7519C19.5867 67.5759 19.3117 67.4879 18.9671 67.4879C18.6664 67.4879 18.4244 67.5649 18.2411 67.7189C18.0651 67.8729 17.9771 68.0966 17.9771 68.3899C17.9771 68.5952 18.0431 68.7676 18.1751 68.9069C18.3144 69.0389 18.4831 69.1489 18.6811 69.2369C18.8864 69.3176 19.1724 69.4129 19.5391 69.5229C20.0377 69.6696 20.4447 69.8162 20.7601 69.9629C21.0754 70.1096 21.3467 70.3296 21.5741 70.6229C21.8014 70.9162 21.9151 71.3012 21.9151 71.7779C21.9151 72.1886 21.8087 72.5699 21.5961 72.9219C21.3834 73.2739 21.0717 73.5562 20.6611 73.7689C20.2504 73.9742 19.7627 74.0769 19.1981 74.0769Z'
        fill={color}
      />
      <path
        d='M28.6252 66.3219V67.5649H26.5792V73.9999H25.0392V67.5649H22.9932V66.3219H28.6252Z'
        fill={color}
      />
      <path
        d='M35.2448 68.6319C35.0688 68.3092 34.8268 68.0636 34.5188 67.8949C34.2108 67.7262 33.8515 67.6419 33.4408 67.6419C32.9862 67.6419 32.5828 67.7446 32.2308 67.9499C31.8788 68.1552 31.6038 68.4486 31.4058 68.8299C31.2078 69.2112 31.1088 69.6512 31.1088 70.1499C31.1088 70.6632 31.2078 71.1106 31.4058 71.4919C31.6112 71.8732 31.8935 72.1666 32.2528 72.3719C32.6122 72.5772 33.0302 72.6799 33.5068 72.6799C34.0935 72.6799 34.5738 72.5259 34.9478 72.2179C35.3218 71.9026 35.5675 71.4662 35.6848 70.9089H33.0448V69.7319H37.2028V71.0739C37.1002 71.6092 36.8802 72.1042 36.5428 72.5589C36.2055 73.0136 35.7692 73.3802 35.2338 73.6589C34.7058 73.9302 34.1118 74.0659 33.4518 74.0659C32.7112 74.0659 32.0402 73.9009 31.4388 73.5709C30.8448 73.2336 30.3755 72.7679 30.0308 72.1739C29.6935 71.5799 29.5248 70.9052 29.5248 70.1499C29.5248 69.3946 29.6935 68.7199 30.0308 68.1259C30.3755 67.5246 30.8448 67.0589 31.4388 66.7289C32.0402 66.3916 32.7075 66.2229 33.4408 66.2229C34.3062 66.2229 35.0578 66.4356 35.6958 66.8609C36.3338 67.2789 36.7738 67.8692 37.0158 68.6319H35.2448Z'
        fill={color}
      />
      <path
        d='M42.4522 73.9999L40.7582 71.0079H40.0322V73.9999H38.4922V66.3219H41.3742C41.9682 66.3219 42.4742 66.4282 42.8922 66.6409C43.3102 66.8462 43.6219 67.1286 43.8272 67.4879C44.0399 67.8399 44.1462 68.2359 44.1462 68.6759C44.1462 69.1819 43.9995 69.6402 43.7062 70.0509C43.4129 70.4542 42.9765 70.7329 42.3972 70.8869L44.2342 73.9999H42.4522ZM40.0322 69.8529H41.3192C41.7372 69.8529 42.0489 69.7539 42.2542 69.5559C42.4595 69.3506 42.5622 69.0682 42.5622 68.7089C42.5622 68.3569 42.4595 68.0856 42.2542 67.8949C42.0489 67.6969 41.7372 67.5979 41.3192 67.5979H40.0322V69.8529Z'
        fill={color}
      />
      <path
        d='M47.2291 67.5649V69.4899H49.8141V70.7109H47.2291V72.7459H50.1441V73.9999H45.6891V66.3109H50.1441V67.5649H47.2291Z'
        fill={color}
      />
      <path
        d='M54.3446 74.0769C53.8092 74.0769 53.3252 73.9852 52.8926 73.8019C52.4672 73.6186 52.1299 73.3546 51.8806 73.0099C51.6312 72.6652 51.5029 72.2582 51.4956 71.7889H53.1456C53.1676 72.1042 53.2776 72.3536 53.4756 72.5369C53.6809 72.7202 53.9596 72.8119 54.3116 72.8119C54.6709 72.8119 54.9532 72.7276 55.1586 72.5589C55.3639 72.3829 55.4666 72.1556 55.4666 71.8769C55.4666 71.6496 55.3969 71.4626 55.2576 71.3159C55.1182 71.1692 54.9422 71.0556 54.7296 70.9749C54.5242 70.8869 54.2382 70.7916 53.8716 70.6889C53.3729 70.5422 52.9659 70.3992 52.6506 70.2599C52.3426 70.1132 52.0749 69.8969 51.8476 69.6109C51.6276 69.3176 51.5176 68.9289 51.5176 68.4449C51.5176 67.9902 51.6312 67.5942 51.8586 67.2569C52.0859 66.9196 52.4049 66.6629 52.8156 66.4869C53.2262 66.3036 53.6956 66.2119 54.2236 66.2119C55.0156 66.2119 55.6572 66.4062 56.1486 66.7949C56.6472 67.1762 56.9222 67.7116 56.9736 68.4009H55.2796C55.2649 68.1369 55.1512 67.9206 54.9386 67.7519C54.7332 67.5759 54.4582 67.4879 54.1136 67.4879C53.8129 67.4879 53.5709 67.5649 53.3876 67.7189C53.2116 67.8729 53.1236 68.0966 53.1236 68.3899C53.1236 68.5952 53.1896 68.7676 53.3216 68.9069C53.4609 69.0389 53.6296 69.1489 53.8276 69.2369C54.0329 69.3176 54.3189 69.4129 54.6856 69.5229C55.1842 69.6696 55.5912 69.8162 55.9066 69.9629C56.2219 70.1096 56.4932 70.3296 56.7206 70.6229C56.9479 70.9162 57.0616 71.3012 57.0616 71.7779C57.0616 72.1886 56.9552 72.5699 56.7426 72.9219C56.5299 73.2739 56.2182 73.5562 55.8076 73.7689C55.3969 73.9742 54.9092 74.0769 54.3446 74.0769Z'
        fill={color}
      />
      <path
        d='M64.2667 75.3529L63.1007 73.9559C62.778 74.0366 62.448 74.0769 62.1107 74.0769C61.392 74.0769 60.732 73.9082 60.1307 73.5709C59.5293 73.2336 59.0527 72.7679 58.7007 72.1739C58.3487 71.5726 58.1727 70.8942 58.1727 70.1389C58.1727 69.3909 58.3487 68.7199 58.7007 68.1259C59.0527 67.5246 59.5293 67.0552 60.1307 66.7179C60.732 66.3806 61.392 66.2119 62.1107 66.2119C62.8367 66.2119 63.4967 66.3806 64.0907 66.7179C64.692 67.0552 65.165 67.5246 65.5097 68.1259C65.8617 68.7199 66.0377 69.3909 66.0377 70.1389C66.0377 70.8209 65.891 71.4442 65.5977 72.0089C65.3117 72.5662 64.9193 73.0209 64.4207 73.3729L66.1807 75.3529H64.2667ZM59.7567 70.1389C59.7567 70.6522 59.8557 71.1032 60.0537 71.4919C60.2517 71.8806 60.5267 72.1812 60.8787 72.3939C61.238 72.5992 61.6487 72.7019 62.1107 72.7019C62.5727 72.7019 62.9797 72.5992 63.3317 72.3939C63.6837 72.1812 63.9587 71.8806 64.1567 71.4919C64.3547 71.1032 64.4537 70.6522 64.4537 70.1389C64.4537 69.6256 64.3547 69.1782 64.1567 68.7969C63.9587 68.4082 63.6837 68.1112 63.3317 67.9059C62.9797 67.7006 62.5727 67.5979 62.1107 67.5979C61.6487 67.5979 61.238 67.7006 60.8787 67.9059C60.5267 68.1112 60.2517 68.4082 60.0537 68.7969C59.8557 69.1782 59.7567 69.6256 59.7567 70.1389Z'
        fill={color}
      />
      <path d='M68.8949 72.7789H71.4249V73.9999H67.3549V66.3219H68.8949V72.7789Z' fill={color} />
    </svg>
  );
};
