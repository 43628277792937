import React from 'react';

export const MinusCircle = () => {
  return (
    <svg width='36' height='36' viewBox='0 0 36 36' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M13.3335 17.333L22.6668 17.333L22.6668 18.6663L13.3335 18.6663L13.3335 17.333Z'
        fill='white'
      />
      <rect x='0.5' y='0.5' width='35' height='35' rx='17.5' stroke='white' />
    </svg>
  );
};
