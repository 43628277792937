import React from 'react';

export const CodeSlashIcon = () => {
  return (
    <svg width='41' height='42' viewBox='0 0 41 42' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M41 21L31.336 30.8997L28.9204 28.4253L36.1688 21L28.9204 13.5747L31.336 11.1002L41 21ZM4.83117 21L12.0796 28.4253L9.66404 30.8997L0 21L9.66404 11.1002L12.0779 13.5747L4.83117 21ZM16.7212 36.75H13.0858L24.2788 5.25H27.9142L16.7212 36.75Z'
        fill='#151515'
      />
    </svg>
  );
};
