import { gql } from '@apollo/client';

export const GET_CATEGORIES = gql`
  query Categories {
    categories(pagination: { limit: -1 }) {
      data {
        id
        attributes {
          title
        }
      }
    }
  }
`;
