export const ServerIcon = ({ color = '#5A32FB', width = 40, height = 40 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11.25 8.75C11.9404 8.75 12.5 8.19036 12.5 7.5C12.5 6.80964 11.9404 6.25 11.25 6.25C10.5596 6.25 10 6.80964 10 7.5C10 8.19036 10.5596 8.75 11.25 8.75Z'
        fill={color}
      />
      <path
        d='M32.5 12.5H7.5C6.83716 12.4993 6.20166 12.2357 5.73296 11.767C5.26427 11.2983 5.00066 10.6628 5 10V5C5.00066 4.33716 5.26427 3.70166 5.73296 3.23296C6.20166 2.76427 6.83716 2.50066 7.5 2.5H32.5C33.1628 2.50066 33.7983 2.76427 34.267 3.23296C34.7357 3.70166 34.9993 4.33716 35 5V10C34.9993 10.6628 34.7357 11.2983 34.267 11.767C33.7983 12.2357 33.1628 12.4993 32.5 12.5ZM7.5 5V10H32.5V5H7.5Z'
        fill={color}
      />
      <path
        d='M11.25 21.25C11.9404 21.25 12.5 20.6904 12.5 20C12.5 19.3096 11.9404 18.75 11.25 18.75C10.5596 18.75 10 19.3096 10 20C10 20.6904 10.5596 21.25 11.25 21.25Z'
        fill={color}
      />
      <path
        d='M32.5 25H7.5C6.83716 24.9993 6.20166 24.7357 5.73296 24.267C5.26427 23.7983 5.00066 23.1628 5 22.5V17.5C5.00066 16.8372 5.26427 16.2017 5.73296 15.733C6.20166 15.2643 6.83716 15.0007 7.5 15H32.5C33.1628 15.0007 33.7983 15.2643 34.267 15.733C34.7357 16.2017 34.9993 16.8372 35 17.5V22.5C34.9993 23.1628 34.7357 23.7983 34.267 24.267C33.7983 24.7357 33.1628 24.9993 32.5 25ZM7.5 17.5V22.5H32.5V17.5H7.5Z'
        fill={color}
      />
      <path
        d='M11.25 33.75C11.9404 33.75 12.5 33.1904 12.5 32.5C12.5 31.8096 11.9404 31.25 11.25 31.25C10.5596 31.25 10 31.8096 10 32.5C10 33.1904 10.5596 33.75 11.25 33.75Z'
        fill='#5A32FB'
      />
      <path
        d='M32.5 37.5H7.5C6.83716 37.4993 6.20166 37.2357 5.73296 36.767C5.26427 36.2983 5.00066 35.6628 5 35V30C5.00066 29.3372 5.26427 28.7017 5.73296 28.233C6.20166 27.7643 6.83716 27.5007 7.5 27.5H32.5C33.1628 27.5007 33.7983 27.7643 34.267 28.233C34.7357 28.7017 34.9993 29.3372 35 30V35C34.9993 35.6628 34.7357 36.2983 34.267 36.767C33.7983 37.2357 33.1628 37.4993 32.5 37.5ZM7.5 30V35H32.5V30H7.5Z'
        fill={color}
      />
    </svg>
  );
};
