import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import malevichAnimation from '../../assets/animations/malevichAnimation.json';
import { PROJECTS_COUNTER } from '../../MOCK';
import BackgroundTitle from '../BackgroundTitle';
import Container from '../Container';
import LottieIcon from '../LottieIcon';
import {
  CounterBlock,
  CounterBlockItem,
  CounterBlockQuantity,
  Description,
  RightBlock,
  StyledWhoWeAre,
  WhoWeAreContent,
} from './styles';

const WhoWeAre = () => {
  const logoAnimation = {
    animationData: malevichAnimation,
    loop: 0,
    autoplay: true,
  };

  return (
    <StyledWhoWeAre>
      <BackgroundTitle maxWidth='740px'>Who we are</BackgroundTitle>
      <Container direction='column'>
        <Typography variant='h2' className='title'>
          Who we are
        </Typography>
        <WhoWeAreContent>
          <Box>
            <Description variant='body2'>
              Malevich company was established in 2007 in Ukraine. Now we have more than 50
              employees including React.js and React Native developers, DevOps and Quality Assurance
              engineers, designers, project and product managers.
            </Description>
            <CounterBlock>
              {PROJECTS_COUNTER.map((item) => {
                return (
                  <CounterBlockItem key={item.id}>
                    <CounterBlockQuantity variant='h2'>{item.quantity}</CounterBlockQuantity>
                    <Typography variant='body1'>{item.text}</Typography>
                  </CounterBlockItem>
                );
              })}
            </CounterBlock>
          </Box>
          <RightBlock>
            <LottieIcon options={logoAnimation} replayInView disableOnHover />
          </RightBlock>
        </WhoWeAreContent>
      </Container>
    </StyledWhoWeAre>
  );
};

export default WhoWeAre;
