import React, { useContext, useReducer } from 'react';

export const ModalContext = React.createContext({
  modals: {},
  updateModalState: () => {},
});

export const useModalContext = () => {
  return useContext(ModalContext);
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_MODAL_STATE': {
      return {
        ...state,
        [action.payload.modalName]: action.payload.modalData,
      };
    }
    default: {
      return state;
    }
  }
};

const initialValues = {
  modalError: {
    isOpen: false,
  },
  modalSuccess: {
    isOpen: false,
  },
};

export const ModalProvider = ({ children }) => {
  const [modals, dispatch] = useReducer(reducer, initialValues);

  const updateModalState = (modalName, modalData) => {
    dispatch({
      type: 'UPDATE_MODAL_STATE',
      payload: {
        modalName,
        modalData,
      },
    });
  };

  return (
    <ModalContext.Provider
      value={{
        modals,
        updateModalState,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};
