import { LogoIcon } from '../../assets/icons';
import { StyledLogo } from './styles';

const Logo = () => {
  return (
    <StyledLogo>
      <LogoIcon />
    </StyledLogo>
  );
};

export default Logo;
