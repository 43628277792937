import Typography from '@mui/material/Typography';
import React, { useEffect, useRef } from 'react';

import { useModalContext } from '../../../context/modals';
import { ModalBackdrop, ModalOverlay, ModalText, ModalWrapper } from '../styles';

const ModalError = () => {
  const intervalErrorId = useRef();

  const { modals, updateModalState } = useModalContext();

  const toggleErrorModal = () => {
    updateModalState('modalError', {
      isOpen: false,
    });
  };

  useEffect(() => {
    if (modals.modalError.isOpen) {
      intervalErrorId.current = setTimeout(() => {
        updateModalState('modalError', {
          isOpen: false,
        });
      }, 3000);
    } else {
      clearTimeout(intervalErrorId.current);
    }
  }, [modals.modalError.isOpen]);

  return (
    <>
      {modals.modalError.isOpen && (
        <ModalOverlay>
          <ModalWrapper className='error'>
            <Typography variant='h2'>Error</Typography>
            <ModalText variant='body1'>
              Failed to send your request. Try contacting us by phone.
            </ModalText>
          </ModalWrapper>
          <ModalBackdrop onClick={toggleErrorModal} />
        </ModalOverlay>
      )}
    </>
  );
};

export default ModalError;
