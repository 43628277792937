import React from 'react';

import {
  OurWorkItem,
  OurWorkItemSubTitle,
  OurWorkItemTechnologiesList,
  OurWorkItemTechnology,
  OurWorkItemText,
  OurWorkItemTitle,
} from './styles';

const OurWorkItems = ({ item, ...rest }) => {
  return (
    <OurWorkItem {...rest}>
      <img src={item.image} alt={item.title} />
      <div className='info'>
        <div>
          <OurWorkItemTitle variant='subtitle2'>{item.title}</OurWorkItemTitle>
          {item.subTitle !== '' && (
            <OurWorkItemSubTitle variant='body2'>{item.subTitle}</OurWorkItemSubTitle>
          )}
          <OurWorkItemText>{item.text}</OurWorkItemText>
        </div>
        <OurWorkItemTechnologiesList>
          {item?.technologies?.map((technology) => {
            return (
              <OurWorkItemTechnology key={technology.id}>{technology.icon}</OurWorkItemTechnology>
            );
          })}
        </OurWorkItemTechnologiesList>
      </div>
    </OurWorkItem>
  );
};

export default OurWorkItems;
