import { gql } from '@apollo/client';

export const CREATE_CALLBACK = gql`
  mutation createCallback($data: CallbackInput!) {
    createCallback(data: $data) {
      data {
        id
      }
    }
  }
`;
