import palette from '../../theme/palette';

export const ListIcon = ({ color = palette.primary, width = 40, height = 40 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11.6667 28.3333H15V25H11.6667V28.3333ZM11.6667 21.6667H15V18.3333H11.6667V21.6667ZM11.6667 15H15V11.6667H11.6667V15ZM18.3333 28.3333H28.3333V25H18.3333V28.3333ZM18.3333 21.6667H28.3333V18.3333H18.3333V21.6667ZM18.3333 15H28.3333V11.6667H18.3333V15ZM5 35V5H35V35H5ZM8.33333 31.6667H31.6667V8.33333H8.33333V31.6667Z'
        fill={color}
      />
    </svg>
  );
};
