import { styled } from '@mui/system';

import palette from '../../theme/palette';

export const styles = {
  arrowButton: () => {
    const position = {
      top: 'auto',
      left: 'auto',
      right: 'auto',
      bottom: 'auto',
    };

    return {
      width: '20px',
      height: '20px',
      backgroundColor: 'transparent',
      opacity: 1,
      zIndex: 10,
      border: 'none',
      cursor: 'pointer',

      '&:hover': {
        backgroundColor: 'transparent',
        opacity: 1,
      },

      '& svg': {
        minWidth: '14px',
        minHeight: '16px',

        '& path': {
          fill: palette.secondary,
        },
      },

      ...position,
    };
  },

  sliderArrows: {
    background: 'transparent',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    position: 'absolute',
    right: '0px',
    width: '236px',
    zIndex: 1,
    color: '#ffffff',
    padding: '22px 0',

    '& .arrowsBlock': {
      display: 'flex',
      alignItems: 'center',
      marginRight: '26px',
    },

    '#mal-prev-arrow, #ourwork-prev-arrow, #technologies-prev-arrow, #outcome-prev-arrow': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: '16px',
    },

    '#mal-next-arrow, #ourwork-next-arrow, #technologies-next-arrow, #outcome-next-arrow': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },

    '& .range': {
      display: 'flex',
      alignItems: 'center',

      '& input[type=range]': {
        overflow: 'hidden',
        width: '56px',
        height: '4px',
        margin: '0 16px',
        backgroundColor: palette.secondary,
        WebkitAppearance: 'none',

        '&:focus': {
          outline: 'none',
        },
      },

      "& input[type='range']::-webkit-slider-runnable-track": {
        height: '4px',
        color: palette.primary,
        WebkitAppearance: 'none',
      },

      "& input[type='range']::-webkit-slider-thumb": {
        width: '4px',
        height: '4px',
        background: palette.primary,
        boxShadow: `-80px 0 0 80px ${palette.primary}`,
        cursor: 'none',
        WebkitAppearance: 'none',
      },

      "& input[type='range']::-moz-range-progress": {
        backgroundColor: palette.primary,
      },

      "& input[type='range']::-moz-range-track": {
        height: '4px',
        backgroundColor: palette.secondary,
      },

      '& input[type=range]::-moz-range-thumb ': {
        width: '4px',
        height: '4px',
        background: palette.primary,
        boxShadow: `-80px 0 0 80px ${palette.primary}`,
        border: 'none',
      },

      "& input[type='range']::-ms-fill-lower": {
        backgroundColor: palette.primary,
      },

      "& input[type='range']::-ms-fill-upper": {
        backgroundColor: palette.secondary,
      },
    },
  },
};

export const SliderAdaptiveContainer = styled('div')({
  overflow: 'hidden',
  display: 'flex',
  position: 'relative',
  marginRight: '-16px',
  paddingRight: '16px',

  '@media(max-width: 641px)': {
    marginTop: '26px',
  },
  '@media(min-width: 641px)': {
    display: 'none',
  },
});

export const SliderBackground = styled('div')({
  position: 'absolute',
  top: '120px',
  right: 0,
  width: '252px',
  height: '100%',
  background: palette.purpleBlue,
  zIndex: -1,

  '@media(max-width: 481px)': {
    '&.outcome': {
      minHeight: '480px',
    },
  },
});
