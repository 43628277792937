import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';

import theme from '../../theme';
import palette from '../../theme/palette';

export const Wrapper = styled(Box)({
  margin: '80px 0px 64px',

  [theme.breakpoints.down('xl')]: {
    margin: '40px 0px 64px',
  },

  [theme.breakpoints.down('lg')]: {
    marginTop: 0,
  },
});

export const OurExpertiseContainer = styled(Box)({
  display: 'flex',
  marginTop: '35px',
  position: 'relative',
  overflowX: 'scroll',
  overflowY: 'hidden',
  whiteSpace: 'nowrap',
  transition: 'all 0.2s',
  willChange: 'transform',
  userSelect: 'none',
  cursor: 'move',
  scrollbarColor: 'transparent transparent',
  scrollbarWidth: 'thin',

  '&::-webkit-scrollbar': {
    width: '3px',
    height: '3px',
  },

  '&::-webkit-scrollbar-thumb': {
    background: 'transparent',
  },

  '&::-webkit-scrollbar-track': {
    background: 'transparent',
  },

  '& > .active': {
    cursor: 'grabbing',
  },

  [theme.breakpoints.down('xl')]: {
    marginTop: '56px',
  },

  [theme.breakpoints.down('lg')]: {
    marginTop: '24px',
  },
});

export const Content = styled(Box)({
  display: 'inline-block',
  flexShrink: 0,
  float: 'left',
  maxWidth: '364px',
  width: '100%',
  marginRight: '44px',
  padding: '24px',
  color: palette.primary,
  backgroundColor: palette.secondary,
  border: `1px solid ${palette.amethystSmoke}`,

  '&:hover': {
    backgroundColor: palette.primary,
    color: '#ffffff',

    '& svg path': {
      fill: palette.purpleBlue,
    },

    '& .headerContent': {
      borderBottom: `1px solid ${palette.secondary}`,
    },
  },

  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
    height: '100%',
    color: palette.secondary,
    backgroundColor: palette.primary,

    '& svg path': {
      fill: palette.purpleBlue,
    },

    '& .headerContent': {
      borderBottom: `1px solid ${palette.secondary}`,
    },
  },

  [theme.breakpoints.down('xs')]: {
    padding: '16px',
    fontSize: '14px',
    lineHeight: '20px',

    '& .advantagesItemTitle': {
      marginBottom: '16px',
      paddingBottom: '16px',
    },
  },
});

export const HeaderContent = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingBottom: '24px',
  marginBottom: '24px',
  borderBottom: '1px solid #000000',

  span: {
    maxWidth: '258px',
    width: '100%',
    whiteSpace: 'break-spaces',
  },

  svg: {
    minHeight: '42px',
  },
});

export const OurExpertiseTitle = styled(Typography)({
  textTransform: 'uppercase',
});

export const DescriptionList = styled(List)({
  display: 'flex',
  flexDirection: 'column',

  li: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '22px',
    letterSpacing: '0.15px',
    whiteSpace: 'break-spaces',
    marginBottom: '16px',
  },
});

export const Tooltip = styled('span')({
  display: 'inline-block',
  position: 'absolute',
  padding: '.5em 1em',
  backgroundColor: palette.mediumBlack,
  borderRadius: 100,
  color: palette.secondary,
  fontSize: 14,
  fontWeight: 500,
  lineHeight: '21px',

  [theme.breakpoints.down('lg')]: {
    display: 'none',
  },
});

export const OurExpertiseAdaptiveContainer = styled(Box)({
  overflow: 'hidden',
  display: 'flex',
  position: 'relative',
  marginRight: '-16px',
  paddingRight: '16px',

  '@media(max-width: 641px)': {
    marginTop: '26px',
  },
  '@media(min-width: 641px)': {
    display: 'none',
  },
});

export const OurExpertiseSliderBackground = styled('div')({
  position: 'absolute',
  top: '90px',
  right: 0,
  width: '252px',
  height: '100%',
  background: palette.purpleBlue,
  zIndex: -1,

  '@media(max-width: 481px)': {
    '&.outcome': {
      minHeight: '480px',
    },
  },
});
