import palette from '../../theme/palette';

export const WebsocketsIcon = ({ color = palette.amethystSmoke, width = 76, height = 76 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 76 76'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_417_576)'>
        <path
          d='M54.0261 39.6075H61.971V19.8393L53.0198 10.5634L47.4018 16.3853L54.0261 23.2498V39.6075ZM61.992 43.7349H50.4204H34.2789L27.6546 36.8703L30.4637 33.9594L35.935 39.6292H47.1921L36.1027 28.1158L38.9327 25.1832L50.0221 36.6748V25.0094L44.5718 19.3613L47.3598 16.4721L33.5872 2.13477H20.0031H5.91602L13.84 10.3462V10.3679H13.882H30.275L36.0818 16.3853L27.5917 25.1832L21.785 19.1658V14.4953H13.84V22.5764L27.5917 36.8269L21.9946 42.627L30.9458 51.9029H44.5298H69.916L61.992 43.7349Z'
          fill={color}
        />
      </g>
      <path
        d='M11.3425 68.2448L9.1975 75.9228H7.3825L5.9415 70.4558L4.4345 75.9228L2.6305 75.9338L0.5625 68.2448H2.2125L3.5655 74.2068L5.1275 68.2448H6.8435L8.3175 74.1738L9.6815 68.2448H11.3425Z'
        fill={color}
      />
      <path
        d='M14.0381 69.4878V71.4128H16.6231V72.6338H14.0381V74.6688H16.9531V75.9228H12.4981V68.2338H16.9531V69.4878H14.0381Z'
        fill={color}
      />
      <path
        d='M22.8585 71.9848C23.2912 72.0654 23.6469 72.2818 23.9255 72.6338C24.2042 72.9858 24.3435 73.3891 24.3435 73.8438C24.3435 74.2544 24.2409 74.6174 24.0355 74.9328C23.8375 75.2408 23.5479 75.4828 23.1665 75.6588C22.7852 75.8348 22.3342 75.9228 21.8135 75.9228H18.5025V68.2448H21.6705C22.1912 68.2448 22.6385 68.3291 23.0125 68.4978C23.3939 68.6664 23.6799 68.9011 23.8705 69.2018C24.0685 69.5024 24.1675 69.8434 24.1675 70.2248C24.1675 70.6721 24.0465 71.0461 23.8045 71.3468C23.5699 71.6474 23.2545 71.8601 22.8585 71.9848ZM20.0425 71.4128H21.4505C21.8172 71.4128 22.0995 71.3321 22.2975 71.1708C22.4955 71.0021 22.5945 70.7638 22.5945 70.4558C22.5945 70.1478 22.4955 69.9094 22.2975 69.7408C22.0995 69.5721 21.8172 69.4878 21.4505 69.4878H20.0425V71.4128ZM21.5935 74.6688C21.9675 74.6688 22.2572 74.5808 22.4625 74.4048C22.6752 74.2288 22.7815 73.9794 22.7815 73.6568C22.7815 73.3268 22.6715 73.0701 22.4515 72.8868C22.2315 72.6961 21.9345 72.6008 21.5605 72.6008H20.0425V74.6688H21.5935Z'
        fill={color}
      />
      <path
        d='M28.3719 75.9998C27.8366 75.9998 27.3526 75.9081 26.9199 75.7248C26.4946 75.5414 26.1572 75.2774 25.9079 74.9328C25.6586 74.5881 25.5302 74.1811 25.5229 73.7118H27.1729C27.1949 74.0271 27.3049 74.2764 27.5029 74.4598C27.7082 74.6431 27.9869 74.7348 28.3389 74.7348C28.6982 74.7348 28.9806 74.6504 29.1859 74.4818C29.3912 74.3058 29.4939 74.0784 29.4939 73.7998C29.4939 73.5724 29.4242 73.3854 29.2849 73.2388C29.1456 73.0921 28.9696 72.9784 28.7569 72.8978C28.5516 72.8098 28.2656 72.7144 27.8989 72.6118C27.4002 72.4651 26.9932 72.3221 26.6779 72.1828C26.3699 72.0361 26.1022 71.8198 25.8749 71.5338C25.6549 71.2404 25.5449 70.8518 25.5449 70.3678C25.5449 69.9131 25.6586 69.5171 25.8859 69.1798C26.1132 68.8424 26.4322 68.5858 26.8429 68.4098C27.2536 68.2264 27.7229 68.1348 28.2509 68.1348C29.0429 68.1348 29.6846 68.3291 30.1759 68.7178C30.6746 69.0991 30.9496 69.6344 31.0009 70.3238H29.3069C29.2922 70.0598 29.1786 69.8434 28.9659 69.6748C28.7606 69.4988 28.4856 69.4108 28.1409 69.4108C27.8402 69.4108 27.5982 69.4878 27.4149 69.6418C27.2389 69.7958 27.1509 70.0194 27.1509 70.3128C27.1509 70.5181 27.2169 70.6904 27.3489 70.8298C27.4882 70.9618 27.6569 71.0718 27.8549 71.1598C28.0602 71.2404 28.3462 71.3358 28.7129 71.4458C29.2116 71.5924 29.6186 71.7391 29.9339 71.8858C30.2492 72.0324 30.5206 72.2524 30.7479 72.5458C30.9752 72.8391 31.0889 73.2241 31.0889 73.7008C31.0889 74.1114 30.9826 74.4928 30.7699 74.8448C30.5572 75.1968 30.2456 75.4791 29.8349 75.6918C29.4242 75.8971 28.9366 75.9998 28.3719 75.9998Z'
        fill={color}
      />
      <path
        d='M36.138 75.9998C35.4194 75.9998 34.7594 75.8311 34.158 75.4938C33.5567 75.1564 33.08 74.6908 32.728 74.0968C32.376 73.4954 32.2 72.8171 32.2 72.0618C32.2 71.3138 32.376 70.6428 32.728 70.0488C33.08 69.4474 33.5567 68.9781 34.158 68.6408C34.7594 68.3034 35.4194 68.1348 36.138 68.1348C36.864 68.1348 37.524 68.3034 38.118 68.6408C38.7194 68.9781 39.1924 69.4474 39.537 70.0488C39.889 70.6428 40.065 71.3138 40.065 72.0618C40.065 72.8171 39.889 73.4954 39.537 74.0968C39.1924 74.6908 38.7194 75.1564 38.118 75.4938C37.5167 75.8311 36.8567 75.9998 36.138 75.9998ZM36.138 74.6248C36.6 74.6248 37.007 74.5221 37.359 74.3168C37.711 74.1041 37.986 73.8034 38.184 73.4148C38.382 73.0261 38.481 72.5751 38.481 72.0618C38.481 71.5484 38.382 71.1011 38.184 70.7198C37.986 70.3311 37.711 70.0341 37.359 69.8288C37.007 69.6234 36.6 69.5208 36.138 69.5208C35.676 69.5208 35.2654 69.6234 34.906 69.8288C34.554 70.0341 34.279 70.3311 34.081 70.7198C33.883 71.1011 33.784 71.5484 33.784 72.0618C33.784 72.5751 33.883 73.0261 34.081 73.4148C34.279 73.8034 34.554 74.1041 34.906 74.3168C35.2654 74.5221 35.676 74.6248 36.138 74.6248Z'
        fill={color}
      />
      <path
        d='M40.9868 72.0728C40.9868 71.3174 41.1554 70.6428 41.4927 70.0488C41.8374 69.4474 42.3031 68.9818 42.8898 68.6518C43.4838 68.3144 44.1474 68.1458 44.8807 68.1458C45.7388 68.1458 46.4904 68.3658 47.1357 68.8058C47.7811 69.2458 48.2321 69.8544 48.4888 70.6318H46.7178C46.5418 70.2651 46.2924 69.9901 45.9697 69.8068C45.6544 69.6234 45.2878 69.5318 44.8698 69.5318C44.4224 69.5318 44.0228 69.6381 43.6707 69.8508C43.3261 70.0561 43.0548 70.3494 42.8568 70.7308C42.6661 71.1121 42.5708 71.5594 42.5708 72.0728C42.5708 72.5788 42.6661 73.0261 42.8568 73.4148C43.0548 73.7961 43.3261 74.0931 43.6707 74.3058C44.0228 74.5111 44.4224 74.6138 44.8698 74.6138C45.2878 74.6138 45.6544 74.5221 45.9697 74.3388C46.2924 74.1481 46.5418 73.8694 46.7178 73.5028H48.4888C48.2321 74.2874 47.7811 74.8998 47.1357 75.3398C46.4977 75.7724 45.7461 75.9888 44.8807 75.9888C44.1474 75.9888 43.4838 75.8238 42.8898 75.4938C42.3031 75.1564 41.8374 74.6908 41.4927 74.0968C41.1554 73.5028 40.9868 72.8281 40.9868 72.0728Z'
        fill={color}
      />
      <path
        d='M54.2881 75.9228L51.4941 72.5018V75.9228H49.9541V68.2448H51.4941V71.6878L54.2881 68.2448H56.1471L52.9791 72.0508L56.2351 75.9228H54.2881Z'
        fill={color}
      />
      <path
        d='M58.9381 69.4878V71.4128H61.5231V72.6338H58.9381V74.6688H61.8531V75.9228H57.3981V68.2338H61.8531V69.4878H58.9381Z'
        fill={color}
      />
      <path
        d='M68.6275 68.2448V69.4878H66.5816V75.9228H65.0415V69.4878H62.9955V68.2448H68.6275Z'
        fill={color}
      />
      <path
        d='M72.5522 75.9998C72.0168 75.9998 71.5329 75.9081 71.1002 75.7248C70.6749 75.5414 70.3375 75.2774 70.0882 74.9328C69.8388 74.5881 69.7105 74.1811 69.7032 73.7118H71.3532C71.3752 74.0271 71.4852 74.2764 71.6832 74.4598C71.8885 74.6431 72.1672 74.7348 72.5192 74.7348C72.8785 74.7348 73.1609 74.6504 73.3662 74.4818C73.5715 74.3058 73.6742 74.0784 73.6742 73.7998C73.6742 73.5724 73.6045 73.3854 73.4652 73.2388C73.3258 73.0921 73.1498 72.9784 72.9372 72.8978C72.7318 72.8098 72.4458 72.7144 72.0792 72.6118C71.5805 72.4651 71.1735 72.3221 70.8582 72.1828C70.5502 72.0361 70.2825 71.8198 70.0552 71.5338C69.8352 71.2404 69.7252 70.8518 69.7252 70.3678C69.7252 69.9131 69.8388 69.5171 70.0662 69.1798C70.2935 68.8424 70.6125 68.5858 71.0232 68.4098C71.4338 68.2264 71.9032 68.1348 72.4312 68.1348C73.2232 68.1348 73.8648 68.3291 74.3562 68.7178C74.8548 69.0991 75.1298 69.6344 75.1812 70.3238H73.4872C73.4725 70.0598 73.3588 69.8434 73.1462 69.6748C72.9408 69.4988 72.6658 69.4108 72.3212 69.4108C72.0205 69.4108 71.7785 69.4878 71.5952 69.6418C71.4192 69.7958 71.3312 70.0194 71.3312 70.3128C71.3312 70.5181 71.3972 70.6904 71.5292 70.8298C71.6685 70.9618 71.8372 71.0718 72.0352 71.1598C72.2405 71.2404 72.5265 71.3358 72.8932 71.4458C73.3918 71.5924 73.7989 71.7391 74.1142 71.8858C74.4295 72.0324 74.7008 72.2524 74.9282 72.5458C75.1555 72.8391 75.2692 73.2241 75.2692 73.7008C75.2692 74.1114 75.1628 74.4928 74.9502 74.8448C74.7375 75.1968 74.4258 75.4791 74.0152 75.6918C73.6045 75.8971 73.1168 75.9998 72.5522 75.9998Z'
        fill={color}
      />
      <defs>
        <clipPath id='clip0_417_576'>
          <rect width='64' height='50' fill='white' transform='translate(5.91602 2.13477)' />
        </clipPath>
      </defs>
    </svg>
  );
};
