export const UpworkPro = ({ width = 184, height = 58 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 184 58'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M34.9929 24.256C31.4328 24.256 29.076 21.5592 28.4185 20.5145C29.2621 13.9061 31.7306 11.8166 34.9929 11.8166C38.2181 11.8166 40.7238 14.3434 40.7238 18.0363C40.7238 21.7292 38.2181 24.256 34.9929 24.256ZM34.9929 7.75928C29.1877 7.75928 25.9377 11.4644 25.0073 15.2788C23.953 13.3351 23.1715 10.7355 22.5637 8.31808H14.538V18.0971C14.538 21.6443 12.8882 24.2682 9.66305 24.2682C6.43789 24.2682 4.58964 21.6443 4.58964 18.0971L4.62685 8.31808H0V18.0971C0 20.9518 0.942737 23.5393 2.66695 25.3857C4.44079 27.2929 6.85965 28.289 9.66305 28.289C15.245 28.289 19.1401 24.0981 19.1401 18.0971V11.5251C19.723 13.6874 21.1124 17.842 23.7669 21.4864L21.286 35.3227H25.9874L27.6246 25.5194C28.158 25.9566 28.7286 26.3454 29.3364 26.6978C30.9118 27.6696 32.7104 28.2162 34.5712 28.2769C34.5712 28.2769 34.8564 28.289 35.0053 28.289C40.761 28.289 45.3381 23.9281 45.3381 18.0363C45.3381 12.1446 40.7485 7.75928 34.9929 7.75928Z'
        fill='#14A800'
      />
      <path
        d='M101.469 10.408V8.31854H96.9287V27.6699H101.531V20.0898C101.531 15.425 101.965 12.0722 108.353 12.5095V8.08773C105.376 7.86907 103.056 8.47645 101.469 10.408Z'
        fill='#14A800'
      />
      <path
        d='M69.5159 8.31934L66.179 21.852L62.6189 8.31934H56.7641L53.1916 21.852L49.8672 8.31934H45.2031L50.3138 27.683H55.8462L59.6915 13.9316L63.4997 27.683H69.0321L74.4405 8.31934H69.5159Z'
        fill='#14A800'
      />
      <path
        d='M84.575 24.2682C81.3003 24.2682 78.8442 21.7415 78.8442 18.0363C78.8442 14.3312 81.3127 11.8166 84.575 11.8166C87.8002 11.8166 90.306 14.3434 90.306 18.0363C90.306 21.7292 87.8002 24.2682 84.575 24.2682ZM84.575 7.75928C78.7821 7.75928 74.2422 12.1568 74.2422 18.0363C74.2422 23.9158 78.7821 28.2769 84.575 28.2769C90.3307 28.2769 94.908 23.9158 94.908 18.0363C94.908 12.1568 90.3431 7.75928 84.575 7.75928Z'
        fill='#14A800'
      />
      <path
        d='M114.952 18.158H116.019L122.445 27.6818H127.642L120.249 17.1254C123.834 15.7527 126.24 12.1691 126.24 8.31822H121.651C121.651 12.4971 118.289 14.0763 114.952 14.0763V0.677246H110.338V27.6818H114.94L114.952 18.158Z'
        fill='#14A800'
      />
      <path
        d='M146.377 14.5056C146.377 15.4977 146.134 16.4277 145.649 17.2958C145.181 18.1639 144.434 18.8636 143.409 19.3951C142.402 19.9266 141.124 20.1923 139.577 20.1923H136.42V27.314H132.643V8.76573H139.577C141.035 8.76573 142.276 9.01375 143.301 9.50979C144.327 10.0058 145.091 10.6879 145.595 11.5559C146.116 12.424 146.377 13.4072 146.377 14.5056ZM139.416 17.1895C140.459 17.1895 141.232 16.9592 141.736 16.4986C142.24 16.0203 142.492 15.3559 142.492 14.5056C142.492 12.6986 141.466 11.7951 139.416 11.7951H136.42V17.1895H139.416Z'
        fill='#9A9AAF'
      />
      <path
        d='M158.234 27.314L154.078 20.086H152.298V27.314H148.52V8.76573H155.59C157.047 8.76573 158.288 9.02261 159.313 9.53636C160.339 10.0324 161.103 10.7145 161.607 11.5825C162.129 12.4329 162.389 13.3895 162.389 14.4524C162.389 15.6748 162.03 16.7821 161.31 17.7741C160.591 18.7485 159.52 19.4217 158.099 19.7937L162.605 27.314H158.234ZM152.298 17.2958H155.455C156.48 17.2958 157.245 17.0566 157.748 16.5783C158.252 16.0823 158.504 15.4002 158.504 14.5322C158.504 13.6818 158.252 13.0263 157.748 12.5657C157.245 12.0874 156.48 11.8483 155.455 11.8483H152.298V17.2958Z'
        fill='#9A9AAF'
      />
      <path
        d='M174.009 27.5C172.246 27.5 170.627 27.0925 169.152 26.2776C167.677 25.4627 166.508 24.3378 165.644 22.9028C164.781 21.4501 164.349 19.8114 164.349 17.9867C164.349 16.1797 164.781 14.5587 165.644 13.1238C166.508 11.6711 167.677 10.5373 169.152 9.72238C170.627 8.90746 172.246 8.5 174.009 8.5C175.79 8.5 177.409 8.90746 178.866 9.72238C180.342 10.5373 181.502 11.6711 182.347 13.1238C183.211 14.5587 183.643 16.1797 183.643 17.9867C183.643 19.8114 183.211 21.4501 182.347 22.9028C181.502 24.3378 180.342 25.4627 178.866 26.2776C177.391 27.0925 175.772 27.5 174.009 27.5ZM174.009 24.1783C175.143 24.1783 176.141 23.9303 177.005 23.4343C177.868 22.9205 178.543 22.1942 179.028 21.2552C179.514 20.3163 179.757 19.2268 179.757 17.9867C179.757 16.7466 179.514 15.666 179.028 14.7448C178.543 13.8058 177.868 13.0883 177.005 12.5923C176.141 12.0963 175.143 11.8483 174.009 11.8483C172.876 11.8483 171.869 12.0963 170.987 12.5923C170.124 13.0883 169.449 13.8058 168.963 14.7448C168.478 15.666 168.235 16.7466 168.235 17.9867C168.235 19.2268 168.478 20.3163 168.963 21.2552C169.449 22.1942 170.124 22.9205 170.987 23.4343C171.869 23.9303 172.876 24.1783 174.009 24.1783Z'
        fill='#9A9AAF'
      />
      <path
        d='M40.59 41.2028V43.4944H36.8117V55.358H33.9678V43.4944H30.1895V41.2028H40.59Z'
        fill='#5A32FB'
      />
      <path
        d='M48.8403 55.5C47.5131 55.5 46.2943 55.189 45.1839 54.5671C44.0734 53.9452 43.1931 53.0867 42.5431 51.9916C41.8931 50.883 41.568 49.6324 41.568 48.2399C41.568 46.8608 41.8931 45.6238 42.5431 44.5287C43.1931 43.42 44.0734 42.5548 45.1839 41.9329C46.2943 41.311 47.5131 41 48.8403 41C50.181 41 51.3998 41.311 52.4967 41.9329C53.6072 42.5548 54.4807 43.42 55.1172 44.5287C55.7672 45.6238 56.0922 46.8608 56.0922 48.2399C56.0922 49.6324 55.7672 50.883 55.1172 51.9916C54.4807 53.0867 53.6072 53.9452 52.4967 54.5671C51.3863 55.189 50.1674 55.5 48.8403 55.5ZM48.8403 52.965C49.6935 52.965 50.4451 52.7758 51.0951 52.3972C51.7451 52.0051 52.253 51.4508 52.6186 50.7343C52.9843 50.0177 53.1671 49.1862 53.1671 48.2399C53.1671 47.2935 52.9843 46.4688 52.6186 45.7657C52.253 45.0492 51.7451 44.5016 51.0951 44.1231C50.4451 43.7445 49.6935 43.5552 48.8403 43.5552C47.9871 43.5552 47.2288 43.7445 46.5652 44.1231C45.9151 44.5016 45.4073 45.0492 45.0417 45.7657C44.676 46.4688 44.4932 47.2935 44.4932 48.2399C44.4932 49.1862 44.676 50.0177 45.0417 50.7343C45.4073 51.4508 45.9151 52.0051 46.5652 52.3972C47.2288 52.7758 47.9871 52.965 48.8403 52.965Z'
        fill='#5A32FB'
      />
      <path
        d='M68.1413 45.5832C68.1413 46.3403 67.9585 47.0501 67.5929 47.7126C67.2408 48.3751 66.6788 48.9091 65.9068 49.3147C65.1485 49.7203 64.187 49.9231 63.0223 49.9231H60.6456V55.358H57.8017V41.2028H63.0223C64.1192 41.2028 65.0537 41.3921 65.8256 41.7706C66.5975 42.1492 67.173 42.6697 67.5522 43.3322C67.945 43.9946 68.1413 44.745 68.1413 45.5832ZM62.9004 47.6315C63.6859 47.6315 64.2682 47.4557 64.6474 47.1042C65.0266 46.7392 65.2162 46.2322 65.2162 45.5832C65.2162 44.2042 64.4443 43.5147 62.9004 43.5147H60.6456V47.6315H62.9004Z'
        fill='#5A32FB'
      />
      <path
        d='M81.5011 55.358L78.3728 49.842H77.0321V55.358H74.1883V41.2028H79.5104C80.6073 41.2028 81.5418 41.3988 82.3137 41.7909C83.0856 42.1695 83.6611 42.69 84.0403 43.3524C84.433 44.0014 84.6294 44.7315 84.6294 45.5427C84.6294 46.4755 84.3586 47.3205 83.8169 48.0776C83.2752 48.8212 82.4694 49.335 81.3996 49.6189L84.7919 55.358H81.5011ZM77.0321 47.7126H79.4088C80.1807 47.7126 80.7563 47.5301 81.1355 47.165C81.5147 46.7865 81.7043 46.266 81.7043 45.6035C81.7043 44.9545 81.5147 44.4543 81.1355 44.1028C80.7563 43.7378 80.1807 43.5552 79.4088 43.5552H77.0321V47.7126Z'
        fill='#5A32FB'
      />
      <path
        d='M95.4895 52.6608H89.8424L88.9079 55.358H85.9219L91.0206 41.1825H94.3317L99.4304 55.358H96.424L95.4895 52.6608ZM94.7176 50.3895L92.666 44.4678L90.6143 50.3895H94.7176Z'
        fill='#5A32FB'
      />
      <path
        d='M110.579 41.2028V43.4944H106.801V55.358H103.957V43.4944H100.178V41.2028H110.579Z'
        fill='#5A32FB'
      />
      <path
        d='M115.091 43.4944V47.0434H119.865V49.2944H115.091V53.0462H120.475V55.358H112.248V41.1825H120.475V43.4944H115.091Z'
        fill='#5A32FB'
      />
      <path
        d='M127.609 41.2028C129.099 41.2028 130.406 41.4935 131.53 42.0748C132.667 42.6562 133.541 43.4876 134.15 44.5692C134.773 45.6373 135.085 46.8811 135.085 48.3007C135.085 49.7203 134.773 50.9641 134.15 52.0322C133.541 53.0867 132.667 53.9047 131.53 54.486C130.406 55.0674 129.099 55.358 127.609 55.358H122.653V41.2028H127.609ZM127.508 52.9448C128.997 52.9448 130.148 52.5392 130.961 51.728C131.773 50.9168 132.18 49.7744 132.18 48.3007C132.18 46.827 131.773 45.6779 130.961 44.8531C130.148 44.0149 128.997 43.5958 127.508 43.5958H125.497V52.9448H127.508Z'
        fill='#5A32FB'
      />
      <path
        d='M148.502 45.5391C148.502 46.281 148.319 46.9766 147.952 47.6257C147.599 48.2749 147.036 48.7982 146.263 49.1957C145.503 49.5931 144.539 49.7919 143.372 49.7919H140.99V55.1177H138.14V41.2466H143.372C144.471 41.2466 145.408 41.4321 146.181 41.803C146.955 42.174 147.532 42.684 147.912 43.3332C148.305 43.9824 148.502 44.7177 148.502 45.5391ZM143.25 47.5462C144.037 47.5462 144.62 47.374 145 47.0295C145.38 46.6718 145.57 46.175 145.57 45.5391C145.57 44.1877 144.797 43.5121 143.25 43.5121H140.99V47.5462H143.25Z'
        fill='#9A9AAF'
      />
      <path d='M153.376 52.9119H158.058V55.1177H150.526V41.2466H153.376V52.9119Z' fill='#9A9AAF' />
      <path
        d='M162.679 41.2466V49.8316C162.679 50.7722 162.93 51.4943 163.432 51.9977C163.935 52.4879 164.64 52.733 165.55 52.733C166.473 52.733 167.185 52.4879 167.687 51.9977C168.19 51.4943 168.441 50.7722 168.441 49.8316V41.2466H171.311V49.8117C171.311 50.9908 171.047 51.9911 170.517 52.8125C170.001 53.6207 169.302 54.2301 168.42 54.6408C167.552 55.0515 166.581 55.2569 165.509 55.2569C164.45 55.2569 163.487 55.0515 162.618 54.6408C161.763 54.2301 161.084 53.6207 160.582 52.8125C160.08 51.9911 159.829 50.9908 159.829 49.8117V41.2466H162.679Z'
        fill='#9A9AAF'
      />
      <path
        d='M178.971 55.2569C177.981 55.2569 177.085 55.0913 176.284 54.76C175.497 54.4288 174.872 53.9519 174.411 53.3292C173.95 52.7065 173.712 51.9712 173.698 51.1233H176.752C176.793 51.693 176.997 52.1435 177.363 52.4747C177.743 52.8059 178.259 52.9715 178.91 52.9715C179.575 52.9715 180.098 52.8191 180.478 52.5144C180.858 52.1965 181.048 51.7858 181.048 51.2823C181.048 50.8716 180.919 50.5338 180.661 50.2688C180.403 50.0038 180.077 49.7985 179.684 49.6527C179.304 49.4938 178.775 49.3215 178.096 49.1361C177.173 48.8711 176.42 48.6127 175.836 48.361C175.266 48.096 174.771 47.7052 174.35 47.1885C173.943 46.6586 173.739 45.9564 173.739 45.082C173.739 44.2606 173.95 43.5452 174.37 42.9358C174.791 42.3263 175.381 41.8626 176.141 41.5447C176.902 41.2135 177.77 41.0479 178.747 41.0479C180.213 41.0479 181.401 41.3989 182.31 42.1011C183.233 42.79 183.742 43.7572 183.837 45.0025H180.702C180.675 44.5256 180.464 44.1347 180.071 43.83C179.691 43.5121 179.182 43.3531 178.544 43.3531C177.987 43.3531 177.539 43.4922 177.2 43.7704C176.874 44.0486 176.712 44.4527 176.712 44.9827C176.712 45.3536 176.834 45.6649 177.078 45.9167C177.336 46.1551 177.648 46.3539 178.014 46.5129C178.394 46.6586 178.924 46.8308 179.602 47.0295C180.525 47.2945 181.279 47.5595 181.862 47.8245C182.446 48.0894 182.948 48.4869 183.369 49.0168C183.789 49.5468 184 50.2423 184 51.1035C184 51.8454 183.803 52.5343 183.409 53.1702C183.016 53.8061 182.439 54.3162 181.679 54.7004C180.919 55.0714 180.016 55.2569 178.971 55.2569Z'
        fill='#9A9AAF'
      />
      <path
        d='M22.1662 41.5993L16.6892 44.8631L11.4374 38.0878C11.4162 38.0606 11.3888 38.0384 11.3573 38.0232C11.3258 38.0079 11.2911 38 11.2558 38C11.2206 38 11.1859 38.0079 11.1543 38.0232C11.1228 38.0384 11.0954 38.0606 11.0743 38.0878L5.82528 44.8631L0.345475 41.5993C0.185049 41.5041 -0.0232232 41.632 0.00210721 41.8144L2.15238 57.6189C2.18333 57.834 2.37472 58 2.60269 58H19.9146C20.1398 58 20.334 57.8367 20.3621 57.6189L22.5124 41.8144C22.5349 41.632 22.3294 41.5041 22.1662 41.5993ZM11.2572 54.0584C9.50945 54.0584 8.08813 52.6837 8.08813 50.9933C8.08813 49.3029 9.50945 47.9282 11.2572 47.9282C13.005 47.9282 14.4264 49.3029 14.4264 50.9933C14.4264 52.6837 13.005 54.0584 11.2572 54.0584ZM11.2572 49.6785C10.5086 49.6785 9.90066 50.2665 9.90066 50.9933C9.90066 51.7174 10.5086 52.3081 11.2572 52.3081C12.0059 52.3081 12.6138 51.7201 12.6138 50.9933C12.6138 50.2692 12.0059 49.6785 11.2572 49.6785Z'
        fill='#5A32FB'
      />
    </svg>
  );
};
