import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';

import theme from '../../theme';
import palette from '../../theme/palette';

export const StyledWorkPrinciples = styled(Box)({
  position: 'relative',
  marginBottom: '64px',

  '& .title': {
    marginTop: '128px',
  },

  [theme.breakpoints.down('xl')]: {
    marginTop: '48px',

    '& .title': {
      marginTop: '98px',
    },
  },

  [theme.breakpoints.down('lg')]: {
    '& .title': {
      marginTop: '118px',
    },
  },
});

export const WorkPrinciplesContent = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  marginTop: '-12px',
  [theme.breakpoints.down('xl')]: {
    marginTop: '0px',
  },
  '@media (min-width: 1201px) and (min-height: 753px)': {
    marginTop: '0px',
  },
});

export const WorkPrinciplesItem = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '340px',
  width: '100%',
  marginTop: '16px',

  '@media (min-width: 1201px) and (min-height: 753px)': {
    marginTop: '13px',
  },

  [theme.breakpoints.down('xl')]: {
    marginTop: '40px',
    maxWidth: '48%',
  },

  [theme.breakpoints.down('lg')]: {
    maxWidth: '100%',
  },
});

export const WorkPrinciplesTop = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  height: '52px',
  marginBottom: '24px',
});

export const WorkPrinciplesTitle = styled(Typography)({
  marginLeft: '-12px',
  fontWeight: 500,
  lineHeight: '26px',

  '& strong': {
    fontWeight: 600,
    lineHeight: '26px',
  },
});

export const WorkPrinciplesNumber = styled(Box)({
  color: palette.purpleBlue,
  fontSize: '48px',
  fontWeight: 900,
  lineHeight: '40px',
  letterSpacing: '0.15px',
  opacity: 0.15,
});

export const WorkPrinciplesText = styled(Typography)({
  paddingTop: '16px',
  fontSize: '14px',
  lineHeight: '24px',
  borderTop: `1px solid ${palette.amethystSmoke}`,

  [theme.breakpoints.down('xl')]: {
    fontSize: '16px',
    lineHeight: '26px',
  },
});
