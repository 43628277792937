import { useInView } from 'framer-motion';
import { useRef } from 'react';

import { StyledBackgroundTitle } from './styles';

const BackgroundTitle = ({ maxWidth, ...properties }) => {
  const reference = useRef();

  const isInView = useInView(reference, {
    once: true,
  });

  return (
    <StyledBackgroundTitle
      ref={reference}
      initial={{
        top: 0,
        left: '-100%',
      }}
      animate={{
        ...(isInView && {
          left: 80,
          maxWidth,
        }),
      }}
      transition={{
        ease: 'linear',
        duration: 1.75,
        type: 'tween',
      }}
      viewport={{
        once: true,
        amount: 1,
      }}
      {...properties}
    />
  );
};

export default BackgroundTitle;
