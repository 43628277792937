import clsx from 'clsx';
import { Field, Formik } from 'formik';

import { SendIcon } from '../../assets/icons';
import { useModalContext } from '../../context/modals';
import { useCreateCallbackMutation } from '../../graphql/hooks/useMutations';
import { useGetCategories } from '../../graphql/hooks/useQueries';
import palette from '../../theme/palette';
import CustomInput from '../Input';
import { schema } from './schema';
import {
  SButtonSend,
  SCheckboxGroup,
  SDetailContainer,
  SFooterTitle,
  SForm,
  SNameEmailWrapper,
  SServicesContainer,
} from './styles';

const FooterForm = () => {
  const [createCallback] = useCreateCallbackMutation();

  const { updateModalState } = useModalContext();

  const { data } = useGetCategories();

  const handleFormSubmit = (values, { resetForm }) => {
    createCallback({
      variables: {
        data: {
          name: values.name,
          email: values.email,
          projectDetail: values.detail,
          categories: values.categories,
        },
      },
    })
      .then((response) => {
        if (response?.data?.createCallback?.data?.id) {
          updateModalState('modalSuccess', {
            isOpen: true,
          });
          resetForm();
        }
        if (response?.errors?.[0]?.message) {
          updateModalState('modalError', {
            isOpen: true,
          });
        }
      })
      .catch((error) => {
        if (error?.message) {
          updateModalState('modalError', {
            isOpen: true,
          });
        }
      });
  };

  return (
    <>
      <Formik
        initialValues={{
          name: '',
          email: '',
          detail: '',
          categories: [],
        }}
        validationSchema={schema}
        onSubmit={handleFormSubmit}
      >
        {({ values }) => {
          return (
            <SForm noValidate>
              <SFooterTitle variant='body1'>SERVICES</SFooterTitle>
              <SCheckboxGroup>
                {data?.categories?.data?.map((item) => {
                  return (
                    <SServicesContainer
                      key={item.id}
                      className={clsx({
                        categoryActive: values.categories.includes(item.id.toString()),
                      })}
                    >
                      <Field type='checkbox' name='categories' value={item.id} />
                      {item?.attributes?.title}
                    </SServicesContainer>
                  );
                })}
              </SCheckboxGroup>

              <SNameEmailWrapper>
                <Field name='name'>
                  {({ field, meta }) => {
                    return (
                      <CustomInput
                        placeholder='Name'
                        touched={meta.touched}
                        error={meta.error}
                        {...field}
                      />
                    );
                  }}
                </Field>
                <Field name='email'>
                  {({ field, meta }) => {
                    return (
                      <CustomInput
                        inputType='email'
                        placeholder='Email'
                        touched={meta.touched}
                        error={meta.error}
                        {...field}
                      />
                    );
                  }}
                </Field>
              </SNameEmailWrapper>
              <Field name='detail'>
                {({ field }) => {
                  return (
                    <SDetailContainer
                      placeholder='Project detail'
                      multiline
                      maxRows={4}
                      name={field.name}
                      value={field.value}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                    />
                  );
                }}
              </Field>
              <div>
                <SButtonSend type='submit' endIcon={<SendIcon color={palette.secondary} />}>
                  Send
                </SButtonSend>
              </div>
            </SForm>
          );
        }}
      </Formik>
    </>
  );
};

export default FooterForm;
