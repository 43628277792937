import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import React, { useEffect, useRef } from 'react';
import useBreakpoint from 'use-breakpoint';

import {
  PwaIcon,
  SmartphoneFillIcon,
  StatisticsPwaIcon,
  StatisticsPwaMobIcon,
  StrapiFullLogoIcon,
} from '../../assets/icons';
import { BREAKPOINTS } from '../../constants';
import { FIRST_LIST, LAST_LIST, SECOND_LIST } from '../../MOCK';
import theme from '../../theme';
import BackgroundTitle from '../BackgroundTitle';
import Container from '../Container';
import {
  AchieveGoalsBottomMenu,
  AchieveGoalsTitle,
  AchieveGoalsWrapper,
  BottomMenuContent,
  FirstLeft,
  FirstRight,
  FirstRightList,
  LastLeft,
  LastRight,
  MainContainer,
  MobileFirstWrapper,
  PriceBadge,
  SecondDescriptionWrapper,
  SecondLeft,
  SecondList,
  SecondRight,
  SourceWrapper,
} from './styles';

const AchieveGoals = () => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  const isMobile = ['xxxs', 'xxs', 'xs', 'sm'].includes(breakpoint);

  const referenceStrapi = useRef();
  const referencePWA = useRef();
  const referenceMobileFirst = useRef();

  useEffect(() => {
    let observer;

    if (referenceStrapi.current && referencePWA.current && referenceMobileFirst.current) {
      const options = {
        threshold: 0.16,
      };
      observer = new IntersectionObserver((entries) => {
        for (const entry of entries) {
          const navElement = document.querySelector(`a[href="#${entry.target.id}"]`);

          if (entry.isIntersecting) {
            if (!navElement.classList.contains('active')) {
              navElement.classList.add('active');
            }
          } else if (navElement.classList.contains('active')) {
            navElement.classList.remove('active');
          }
        }
      }, options);

      observer.observe(referenceStrapi.current);
      observer.observe(referencePWA.current);
      observer.observe(referenceMobileFirst.current);
    }
    return () => observer.disconnect();
  }, []);

  const listInnerReference = useRef();

  const goalsScrollIsDisabled = useRef(true);

  // eslint-disable-next-line unicorn/prevent-abbreviations
  const onWheel = (e) => {
    if (goalsScrollIsDisabled.current) {
      e.stopPropagation();
    }
  };

  const onScroll = () => {
    if (listInnerReference.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerReference.current;
      if (Math.ceil(scrollTop + clientHeight) === scrollHeight + 1) {
        goalsScrollIsDisabled.current = false;
      } else if (Math.ceil(scrollTop + clientHeight) === scrollHeight) {
        goalsScrollIsDisabled.current = false;
      } else if (scrollTop === 0) {
        goalsScrollIsDisabled.current = false;
      } else {
        goalsScrollIsDisabled.current = true;
      }
    }
  };

  return (
    <AchieveGoalsWrapper onWheel={onWheel} onScroll={onScroll} ref={listInnerReference}>
      <BackgroundTitle
        maxWidth='1080px'
        sx={{
          position: 'sticky',
          height: '576px',
          top: '72px',
          [theme.breakpoints.down('xl')]: {
            position: 'relative',
            top: 'unset',
            height: 'unset',
          },
        }}
      >
        What do we use to achieve the goals?
      </BackgroundTitle>
      {/* First Section */}
      <Container
        direction='column'
        sx={{
          marginTop: '-488px',
          [theme.breakpoints.down('xl')]: {
            marginTop: '-300px',
          },
        }}
        ref={referenceStrapi}
        id='strapi'
      >
        <AchieveGoalsTitle variant='h2'>
          What do we use to achieve <span>the goals</span>?
        </AchieveGoalsTitle>
        <MainContainer>
          <FirstLeft>
            <Typography variant='subtitle2'>01. Modern technology. Strapi.js</Typography>
            <Box
              sx={{
                svg: {
                  maxWidth: '480px',
                  [theme.breakpoints.down('xl')]: {
                    width: '100%',
                    height: 'auto',
                  },
                },
              }}
            >
              <StrapiFullLogoIcon />
            </Box>
            <Typography variant='body1' className='description'>
              Since 2007, we have been engaged in the development of sites on various CMS such as
              Joomla and WordPress. These were corporate sites, online stores, landing pages, and
              business sites.
            </Typography>
            <Typography variant='body1' className='description'>
              In recent years, we've moved to React.js, Node.js, and continuous development
              projects. But we constantly have requests for sites on CMS and experience in their
              implementation. We were looking for a modern solution for performing such tasks and
              found Strapi.
            </Typography>
            <Typography variant='body1' className='description' fontWeight={700}>
              Strapi is a customized CMS builder for dashboard, API and database.
            </Typography>
            <Typography variant='body1' className='description'>
              Strapi is no code like dashboard and API builder but it comes with a powerful Node.js
              framework with NPM and can be used for any custom solutions. Each part of Strapi's
              backend can be customized: requests, routes, policies, middlewares, controllers,
              responses, webhooks, etc.
            </Typography>
            <Typography variant='body1' className='description'>
              Strapi is a flexible CMS, adapted to your content, the interface of the control panel
              will not be overloaded with a lot of unused fields and useless features. Additionally,
              Strapi CMS can be rapidly updated without affecting the interface
            </Typography>
          </FirstLeft>
          <FirstRight>
            <Typography variant='body2' fontWeight={500}>
              We use Strapi+React technology to create websites. This stack will boost delivery of
              your digital content to the client. You will receive:
            </Typography>
            <FirstRightList>
              {FIRST_LIST.map((item) => {
                return <ListItem key={item.id}>{item.description}</ListItem>;
              })}
            </FirstRightList>
          </FirstRight>
        </MainContainer>
      </Container>

      {/* End Of First Section */}
      {/* Second Section */}
      <Container ref={referencePWA} id='pwa'>
        <MainContainer
          sx={{
            [theme.breakpoints.down('xl')]: {
              flexDirection: 'column-reverse',
            },
          }}
        >
          <SecondLeft>
            <Typography
              variant='body2'
              sx={{
                marginBottom: '24px',
              }}
            >
              Key advantages:
            </Typography>
            <SecondList>
              {SECOND_LIST.map((item) => {
                return <ListItem key={item.id}>{item.description}</ListItem>;
              })}
            </SecondList>
            <PriceBadge>$ 10.4bn</PriceBadge>
            <Typography variant='body2' maxWidth='545px'>
              Emergen Research estimates that the market for Progressive Web Apps will reach in the
              next 5-6 years
            </Typography>
            <SourceWrapper>
              Source:
              <Link href='https://www.emergenresearch.com/industry-report/progressive-web-application-market'>
                Emergen Research
              </Link>
            </SourceWrapper>
            <Box
              sx={{
                svg: {
                  [theme.breakpoints.down('xl')]: {
                    width: '100%',
                    height: 'auto',
                    marginBottom: '0px',
                  },
                },
              }}
            >
              {isMobile ? <StatisticsPwaMobIcon /> : <StatisticsPwaIcon />}
            </Box>
            <Typography
              variant='body3'
              sx={{
                display: 'block',
                marginBottom: '24px',
              }}
            >
              Statistics and benefits of PWAs
            </Typography>
            <SecondDescriptionWrapper>
              <Typography variant='body1' className='description'>
                Pages load two to four times faster with using Progressive Web Apps
              </Typography>
              <Typography variant='body1' className='description'>
                On average, a site using PWA can improve speed performance by 63%
              </Typography>
              <Typography variant='body1' className='description'>
                52% average increase in conversions
              </Typography>
            </SecondDescriptionWrapper>
            <SourceWrapper>
              Source:
              <Link href='https://www.thinkwithgoogle.com/intl/en-cee/marketing-strategies/app-and-mobile/'>
                Think with Google
              </Link>
            </SourceWrapper>
          </SecondLeft>
          <SecondRight>
            <Typography variant='subtitle2'>
              02. Innovate technology. PWA (Progressive Web App)
            </Typography>
            <Box
              sx={{
                [theme.breakpoints.down('xl')]: {
                  display: 'flex',
                  justifyContent: 'center',
                },
              }}
            >
              <PwaIcon />
            </Box>
            <Typography variant='body1'>
              PWA (Progressive Web App) is a technology in web development that visually and
              functionally transforms a website into an application (a mobile application in a
              browser). PWA websites are built using HTML, JavaScript, and CSS, and in the browser
              look like a usual website, but interact with the visitor like a mobile app.
            </Typography>
          </SecondRight>
        </MainContainer>
      </Container>
      {/* End Of Second Section */}
      {/* Last Section */}
      <Container ref={referenceMobileFirst} id='mobileFirst'>
        <MainContainer>
          <LastLeft>
            <Typography variant='subtitle2'>03. User-centric technology.</Typography>
            <MobileFirstWrapper>
              <SmartphoneFillIcon />
              Mobile First
            </MobileFirstWrapper>
            <Typography
              variant='body3'
              sx={{
                display: 'block',
                marginBottom: '24px',
              }}
            >
              We start the development of the project with the mobile version of the site. Why?
            </Typography>
            <Typography variant='body1' className='description'>
              Not all visual and functional elements of desktop sites can be transferred to the
              mobile version, and at the same time, we have to preserve the same user experience and
              interaction level with the site.
            </Typography>
            <Typography variant='body1' className='description'>
              For smartphone screen you will have to remove a significant part of the beautiful, but
              not functional elements that were developed by the UI designer for the desktop
              version.
            </Typography>
            <Typography variant='body1' className='description'>
              The client wants to see the visualization of the content (i.e. the loaded page) in 1-2
              seconds.
            </Typography>
            <Typography variant='body1' className='description'>
              In order to get the target action from the user, you need to show elements that lead
              him to conversion first, and after that - banners and animations.
            </Typography>
            <Typography variant='body1' className='description'>
              It will be a big mistake to start development of an online store with desktop version.
              Many customers around the world make purchases, search for information, and order
              services from a mobile phone.
            </Typography>
          </LastLeft>
          <LastRight>
            {LAST_LIST.map((item) => {
              return (
                <Box
                  key={item.id}
                  sx={{
                    marginBottom: '64px',
                    [theme.breakpoints.down('xl')]: {
                      marginBottom: '40px',
                    },
                  }}
                >
                  <Typography variant='body2'>{item.description}</Typography>
                  <img src={isMobile ? item.imgMob : item.img} alt='Preview' />
                  <SourceWrapper
                    sx={{
                      justifyContent: 'flex-end',
                    }}
                  >
                    source:
                    <Link href={item.link}>statista</Link>
                  </SourceWrapper>
                </Box>
              );
            })}
          </LastRight>
        </MainContainer>
      </Container>
      {/* End Of Last Section */}
      <AchieveGoalsBottomMenu>
        <BottomMenuContent href='#strapi' underline='none' color='unset'>
          Strapi
        </BottomMenuContent>
        <BottomMenuContent href='#pwa' underline='none' color='unset'>
          PWA
        </BottomMenuContent>
        <BottomMenuContent href='#mobileFirst' underline='none' color='unset'>
          Mobile First
        </BottomMenuContent>
      </AchieveGoalsBottomMenu>
    </AchieveGoalsWrapper>
  );
};

export default AchieveGoals;
