import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';

import theme from '../../theme';
import palette from '../../theme/palette';

export const StyledPrices = styled(Box)({
  margin: '64px 0px',
  '@media (min-width: 1201px) and (max-height: 853px)': {
    marginTop: '120px',
  },
  [theme.breakpoints.down('xl')]: {
    margin: '48px 0 64px',
  },
});

export const PricesContent = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  width: '100%',
  margin: '8px 0 25px',

  [theme.breakpoints.down('xl')]: {
    margin: '8px 0 48px',
  },
  [theme.breakpoints.down('lg')]: {
    margin: '12px 0 32px',
  },
});

export const PricesItem = styled(Box)({
  maxWidth: '580px',
  width: '100%',
  minHeight: '228px',
  marginTop: '20px',
  padding: '24px',
  border: `1px solid ${palette.amethystSmoke}`,

  '& .pricesTop': {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 16px 24px',
    borderBottom: `1px solid ${palette.amethystSmoke}`,
  },

  '& .icon': {
    height: '40px',
  },

  '& .price': {
    marginLeft: '16px',
    fontSize: '36px',
    fontWeight: 600,
    lineHeight: '56px',
  },

  '& .text': {
    marginTop: '24px',
    fontSize: '20px',
    fontWeight: 400,
    lineHeight: '26px',
  },

  '&:hover': {
    backgroundColor: palette.primary,

    '& .price': {
      color: palette.secondary,
    },

    '& .icon': {
      '& svg path': {
        fill: palette.purpleBlue,
      },
    },

    '& .text': {
      color: palette.secondary,
    },
  },

  [theme.breakpoints.down('xl')]: {
    maxWidth: '48%',
    marginTop: '40px',
  },

  [theme.breakpoints.down('lg')]: {
    maxWidth: '100%',
    minHeight: 'auto',
    marginTop: '20px',
    padding: '24px 16px 40px',

    '& .price': {
      fontSize: '28px',
    },

    '& .icon': {
      width: '32px',
      height: '32px',

      '& svg': {
        width: '100%',
        height: '100%',
      },
    },
  },

  [theme.breakpoints.down('xs')]: {
    '& .pricesTop': {
      padding: '8px 0 24px',
    },
  },
});

export const PricesDescription = styled(Typography)({
  padding: '8px',
  color: palette.amethystSmoke,
  lineHeight: '26px',
});
