import Typography from '@mui/material/Typography';

import { WORK_PRINCIPLES } from '../../MOCK';
import theme from '../../theme';
import BackgroundTitle from '../BackgroundTitle';
import Container from '../Container';
import {
  StyledWorkPrinciples,
  WorkPrinciplesContent,
  WorkPrinciplesItem,
  WorkPrinciplesNumber,
  WorkPrinciplesText,
  WorkPrinciplesTitle,
  WorkPrinciplesTop,
} from './styles';

const WhoWeAre = () => {
  return (
    <StyledWorkPrinciples>
      <BackgroundTitle
        maxWidth='860px'
        sx={{
          marginTop: '30px',
          [theme.breakpoints.down('xl')]: {
            marginTop: '0px',
          },
          '@media (min-width: 1201px) and (min-height: 753px)': {
            marginTop: '86px',
          },
        }}
      >
        It works like this
      </BackgroundTitle>
      <Container direction='column'>
        <Typography variant='h2' className='title'>
          It works like this
        </Typography>
        <WorkPrinciplesContent>
          {WORK_PRINCIPLES.map((item) => {
            return (
              <WorkPrinciplesItem key={item.id}>
                <WorkPrinciplesTop>
                  <WorkPrinciplesNumber>0{item.id}</WorkPrinciplesNumber>
                  <WorkPrinciplesTitle variant='subtitle2'>{item.title}</WorkPrinciplesTitle>
                </WorkPrinciplesTop>
                <WorkPrinciplesText>{item.text}</WorkPrinciplesText>
              </WorkPrinciplesItem>
            );
          })}
        </WorkPrinciplesContent>
      </Container>
    </StyledWorkPrinciples>
  );
};

export default WhoWeAre;
