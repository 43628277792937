import palette from '../../theme/palette';

export const ChakraIcon = ({ color = palette.amethystSmoke, width = 61, height = 76 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 61 76'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M30.6514 2.146C44.4585 2.146 55.6514 13.3389 55.6514 27.146C55.6514 40.9531 44.4585 52.146 30.6514 52.146C16.8442 52.146 5.65137 40.9531 5.65137 27.146C5.65137 13.3389 16.8442 2.146 30.6514 2.146ZM36.2252 11.2892L19.184 28.2131C18.9375 28.4579 19.1109 28.8783 19.4581 28.8783H31.0556C31.3733 28.8783 31.5569 29.2386 31.3705 29.4958L22.278 42.0392C22.0015 42.4205 22.5141 42.8734 22.8585 42.552L42.0663 24.6256C42.3244 24.3845 42.154 23.952 41.8009 23.952H30.8398C30.5445 23.952 30.3567 23.6357 30.4984 23.3766L36.841 11.7516C37.056 11.3578 36.5435 10.973 36.2252 11.2892Z'
        fill={color}
      />
      <path
        d='M0.740234 72.073C0.740234 71.3177 0.908901 70.643 1.24623 70.049C1.5909 69.4477 2.05657 68.982 2.64323 68.652C3.23723 68.3147 3.9009 68.146 4.63423 68.146C5.49223 68.146 6.2439 68.366 6.88924 68.806C7.53457 69.246 7.98557 69.8547 8.24223 70.632H6.47123C6.29523 70.2653 6.0459 69.9903 5.72323 69.807C5.4079 69.6237 5.04123 69.532 4.62323 69.532C4.1759 69.532 3.77623 69.6383 3.42423 69.851C3.07957 70.0563 2.80823 70.3497 2.61023 70.731C2.41957 71.1123 2.32423 71.5597 2.32423 72.073C2.32423 72.579 2.41957 73.0263 2.61023 73.415C2.80823 73.7963 3.07957 74.0933 3.42423 74.306C3.77623 74.5113 4.1759 74.614 4.62323 74.614C5.04123 74.614 5.4079 74.5223 5.72323 74.339C6.0459 74.1483 6.29523 73.8697 6.47123 73.503H8.24223C7.98557 74.2877 7.53457 74.9 6.88924 75.34C6.25124 75.7727 5.49957 75.989 4.63423 75.989C3.9009 75.989 3.23723 75.824 2.64323 75.494C2.05657 75.1567 1.5909 74.691 1.24623 74.097C0.908901 73.503 0.740234 72.8283 0.740234 72.073Z'
        fill={color}
      />
      <path
        d='M16.0766 68.245V75.923H14.5366V72.656H11.2476V75.923H9.70759V68.245H11.2476V71.402H14.5366V68.245H16.0766Z'
        fill={color}
      />
      <path
        d='M22.4504 74.46H19.3924L18.8864 75.923H17.2694L20.0304 68.234H21.8234L24.5844 75.923H22.9564L22.4504 74.46ZM22.0324 73.228L20.9214 70.016L19.8104 73.228H22.0324Z'
        fill={color}
      />
      <path
        d='M30.1004 75.923L27.3064 72.502V75.923H25.7664V68.245H27.3064V71.688L30.1004 68.245H31.9594L28.7914 72.051L32.0474 75.923H30.1004Z'
        fill={color}
      />
      <path
        d='M37.1703 75.923L35.4763 72.931H34.7503V75.923H33.2103V68.245H36.0923C36.6863 68.245 37.1923 68.3513 37.6103 68.564C38.0283 68.7693 38.34 69.0517 38.5453 69.411C38.758 69.763 38.8643 70.159 38.8643 70.599C38.8643 71.105 38.7177 71.5633 38.4243 71.974C38.131 72.3773 37.6947 72.656 37.1153 72.81L38.9523 75.923H37.1703ZM34.7503 71.776H36.0373C36.4553 71.776 36.767 71.677 36.9723 71.479C37.1777 71.2737 37.2803 70.9913 37.2803 70.632C37.2803 70.28 37.1777 70.0087 36.9723 69.818C36.767 69.62 36.4553 69.521 36.0373 69.521H34.7503V71.776Z'
        fill={color}
      />
      <path
        d='M45.1152 74.46H42.0572L41.5512 75.923H39.9342L42.6952 68.234H44.4882L47.2492 75.923H45.6212L45.1152 74.46ZM44.6972 73.228L43.5862 70.016L42.4752 73.228H44.6972Z'
        fill={color}
      />
      <path
        d='M52.7203 68.245V72.997C52.7203 73.5177 52.856 73.9173 53.1273 74.196C53.3987 74.4673 53.78 74.603 54.2713 74.603C54.77 74.603 55.155 74.4673 55.4263 74.196C55.6977 73.9173 55.8333 73.5177 55.8333 72.997V68.245H57.3843V72.986C57.3843 73.6387 57.2413 74.1923 56.9553 74.647C56.6767 75.0943 56.299 75.4317 55.8223 75.659C55.353 75.8863 54.8287 76 54.2493 76C53.6773 76 53.1567 75.8863 52.6873 75.659C52.2253 75.4317 51.8587 75.0943 51.5873 74.647C51.316 74.1923 51.1803 73.6387 51.1803 72.986V68.245H52.7203Z'
        fill={color}
      />
      <path d='M60.5622 68.245V75.923H59.0222V68.245H60.5622Z' fill={color} />
    </svg>
  );
};
