export const WORK_PRINCIPLES = [
  {
    id: 1,
    title: 'Training your sales managers',
    text: "Investing in your employees is essential to your business' success, especially when it comes to technology. We provide learning materials for technologies we and the client prioritize due to their flexibility and ability to constantly modernize the project. Our experts will demonstrate all advantages of key technologies for project development.",
  },
  {
    id: 2,
    title: 'Periodic seminars',
    text: 'with sales and project managers. Online meetings of both teams to analyze complex technology cases or non-standard solutions for specific client requests.',
  },
  {
    id: 3,
    title: (
      <>
        We <strong>do not</strong> contact your client directly
      </>
    ),
    text: 'Though it is still an option to involve any technical expert in the communication.',
  },
  {
    id: 4,
    title: 'Briefing the client',
    text: 'You and the client fill out the brief provided by us. It will help us make a preliminary assessment of the project. After that, you can add your dividends and present it to the client.',
  },
  {
    id: 5,
    title: 'Project development',
    text: 'Process is controlled by our Project manager, you can communicate with them or with the entire team working on the project. You will have access to all tasks in Jira (project management platform). We publish all intermediate results on our demo server so the project will be live in no time. In addition, we use a Code Review process, which allows us to do our work with the highest quality possible. ',
  },
  {
    id: 6,
    title: 'Implementation and support',
    text: 'Quality engineering, validation of the project, technical support for special conditions.',
  },
];
