import Statistic2007 from '../assets/images/Statistic_2007.png';
import Statistic20162021 from '../assets/images/Statistic_2016_2021.png';
import Statistic20162027 from '../assets/images/Statistic_2016_2027.png';
import Statistic2021 from '../assets/images/Statistic_2021.png';
import Statistic2007Mob from '../assets/images/Statistic2007Mob.png';
import Statistic20162021Mob from '../assets/images/Statistic20162021Mob.png';
import Statistic20162027Mob from '../assets/images/Statistic20162027Mob.png';

export const FIRST_LIST = [
  {
    id: 1,
    description:
      '● The interactive interface of any complexity, as in apps. At the same time, the solution remains stable and expandable.',
  },
  {
    id: 2,
    description: '● A website that flexibly integrates with CRM systems and APIs.',
  },
  {
    id: 3,
    description:
      '● Ability to configure offline access to the website. If the user has already interacted with the website, it may operate partly without the Internet. ',
  },
  {
    id: 4,
    description:
      '● Transitions between pages without reloading and using animation (as in Facebook, for example).',
  },
  {
    id: 5,
    description:
      '● The content management system of a new level. Edit and create content directly in the admin panel interface, allowing you to format and sort it, and then add it to your website. The interface is maximally customized, and there are not a lot of unused fields.',
  },
  {
    id: 6,
    description:
      '● Strapi allows you to market projects much faster than with full-scale development from scratch. Ideal for prototyping, rapidly scalable. Projects on Strapi have no limit to modernization, they can always be updated rapidly.',
  },
  {
    id: 7,
    description:
      '● A website that generates pages in advance and gives them to the user instantly over repeated visits to the resource.',
  },
  {
    id: 8,
    description:
      '● Flexible distribution of roles. You can manage which types of content each administrator can access. And also set rights to create, read, update and delete each type of content.',
  },
];

export const SECOND_LIST = [
  {
    id: 1,
    description: '● no need to download the application from the App Store or Google Play',
  },
  {
    id: 2,
    description: '● the page loads rapidly',
  },
  {
    id: 3,
    description:
      '● almost not taking the memory of the device on which it is uploaded, this is a significant advantage over traditional applications for use',
  },
  {
    id: 4,
    description: '● works on any devices (even old computers)',
  },
  {
    id: 5,
    description: '● all updates to the PWA website are automatic',
  },
  {
    id: 6,
    description:
      '● it is possible to configure offline access to the latest version of the content on the web resource (the saved version of the website opens), in the absence of the Internet (for example, Google Docs is an online office, but with the ability to interact with it offline).',
  },
];

export const LAST_LIST = [
  {
    id: 1,
    description:
      'Millions of smartphone subscriptions worldwide from 2016 to 2021, with forecasts from 2022 to 2027.',
    img: Statistic20162027,
    imgMob: Statistic20162027Mob,
    link: 'https://www.statista.com/statistics/330695/number-of-smartphone-users-worldwide/',
  },
  {
    id: 2,
    description: 'Millions of smartphones sold to end users worldwide from 2007 to 2021',
    img: Statistic2007,
    imgMob: Statistic2007Mob,
    link: 'https://www.statista.com/statistics/263437/global-smartphone-sales-to-end-users-since-2007/',
  },
  {
    id: 3,
    description:
      'In-store mobile shopping activities of smartphone users in the United Kingdom (UK) in 2021',
    img: Statistic2021,
    imgMob: Statistic2021,
    link: 'https://www.statista.com/statistics/243597/mobile-shopping-activities-of-uk-smartphone-users/',
  },
  {
    id: 4,
    description: 'Mobile Commerce Sales (2016-2021), in trillion US Dollars',
    img: Statistic20162021,
    imgMob: Statistic20162021Mob,
    link: 'https://www.statista.com/statistics/243597/mobile-shopping-activities-of-uk-smartphone-users/',
  },
];
