export const DesignerIcon = ({ width = 115, height = 100 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 115 100'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_203_1101)'>
        <path
          d='M109.61 0.535156H50.1324C50.1324 0.535156 46.6337 4.11256 50.7447 6.15054H110.747L109.61 0.535156Z'
          fill='white'
        />
        <path
          d='M110.765 6.58771H50.7624C50.6919 6.58814 50.6226 6.57005 50.5613 6.53523C50.0185 6.32375 49.5356 5.98293 49.1546 5.54241C48.7735 5.10188 48.5058 4.57496 48.3746 4.00743C48.051 2.09191 49.7653 0.307575 49.8353 0.228855C49.9207 0.148127 50.0327 0.101459 50.1502 0.0976562H109.628C109.728 0.0992331 109.825 0.13463 109.903 0.198121C109.981 0.261612 110.035 0.349482 110.056 0.447522L111.211 6.06291C111.221 6.12607 111.217 6.19062 111.201 6.25233C111.184 6.31404 111.154 6.37153 111.114 6.42105C111.073 6.47057 111.023 6.51101 110.966 6.53973C110.909 6.56844 110.846 6.58479 110.782 6.58771H110.765ZM50.8674 5.71304H110.223L109.252 0.972327H50.3426C50.0015 1.38342 49.0306 2.64295 49.2405 3.85874C49.3464 4.27415 49.549 4.65866 49.8317 4.98091C50.1144 5.30315 50.4693 5.55402 50.8674 5.71304Z'
          fill='#263238'
        />
        <path
          d='M110.765 6.1507C110.765 6.1507 106.89 5.96702 107.878 2.51207C108.867 -0.942879 112.077 0.535316 113.31 2.02226C114.543 3.5092 114.788 6.70174 114.045 11.39C113.301 16.0782 114.045 67.1502 114.045 67.1502H110.1L110.765 6.1507Z'
          fill='white'
        />
        <path
          d='M114.079 67.5873H110.135C110.077 67.5873 110.019 67.5757 109.965 67.5532C109.912 67.5306 109.863 67.4976 109.822 67.4561C109.782 67.4146 109.75 67.3653 109.728 67.3112C109.707 67.2572 109.696 67.1993 109.697 67.1412L110.353 6.53526C109.361 6.41796 108.451 5.92688 107.808 5.16203C107.543 4.75648 107.376 4.2945 107.322 3.81304C107.267 3.33158 107.326 2.84401 107.493 2.38932C107.586 1.8263 107.84 1.30237 108.225 0.881459C108.61 0.460547 109.11 0.160859 109.662 0.0189672C110.425 -0.0508701 111.192 0.0728408 111.894 0.378636C112.596 0.684432 113.209 1.16242 113.677 1.76831C114.998 3.35146 115.278 6.62273 114.552 11.4859C113.826 16.0867 114.552 66.6601 114.552 67.1674C114.553 67.2842 114.509 67.397 114.429 67.4823C114.382 67.5245 114.327 67.5558 114.266 67.5739C114.206 67.5921 114.142 67.5966 114.079 67.5873ZM110.581 66.7126H113.642C113.563 61.3596 112.942 15.8155 113.642 11.3285C114.517 5.59062 113.791 3.25525 112.995 2.30186C112.623 1.82684 112.141 1.44902 111.591 1.20073C111.042 0.952437 110.44 0.841067 109.837 0.876144C109.12 1.02484 108.613 1.61087 108.324 2.62548C108.198 2.95229 108.148 3.30391 108.18 3.65287C108.212 4.00184 108.324 4.33872 108.508 4.63723C109.108 5.27681 109.932 5.65923 110.808 5.70433C110.921 5.70885 111.028 5.75691 111.106 5.83844C111.184 5.91997 111.228 6.02864 111.228 6.14166L110.581 66.7126Z'
          fill='#263238'
        />
        <path d='M56.6226 70.3862H52.2842V158.544H56.6226V70.3862Z' fill='#B2B2B2' />
        <path
          d='M56.6222 158.99H52.2489C52.1336 158.988 52.0236 158.941 51.9421 158.86C51.8606 158.778 51.8138 158.668 51.8115 158.553V70.3861C51.8138 70.2708 51.8606 70.1608 51.9421 70.0793C52.0236 69.9978 52.1336 69.951 52.2489 69.9487H56.6222C56.7382 69.9487 56.8495 69.9948 56.9315 70.0768C57.0135 70.1588 57.0595 70.2701 57.0595 70.3861V158.553C57.0595 158.669 57.0135 158.78 56.9315 158.862C56.8495 158.944 56.7382 158.99 56.6222 158.99ZM52.7212 158.116H56.2198V70.8234H52.7212V158.116Z'
          fill='#263238'
        />
        <path d='M108.752 70.3862H104.414V158.544H108.752V70.3862Z' fill='#B2B2B2' />
        <path
          d='M108.753 158.99H104.38C104.264 158.988 104.155 158.941 104.073 158.86C103.991 158.778 103.945 158.668 103.942 158.553V70.3861C103.945 70.2708 103.991 70.1608 104.073 70.0793C104.155 69.9978 104.264 69.951 104.38 69.9487H108.753C108.869 69.9487 108.98 69.9948 109.062 70.0768C109.144 70.1588 109.19 70.2701 109.19 70.3861V158.553C109.19 158.669 109.144 158.78 109.062 158.862C108.98 158.944 108.869 158.99 108.753 158.99ZM104.852 158.116H108.351V70.8234H104.852V158.116Z'
          fill='#263238'
        />
        <path d='M110.756 6.1416H50.7539V94.2997H110.756V6.1416Z' fill='white' />
        <path
          d='M110.765 94.7461H50.7625C50.6465 94.7461 50.5353 94.7 50.4533 94.618C50.3713 94.536 50.3252 94.4248 50.3252 94.3088V6.15071C50.3252 6.03473 50.3713 5.92349 50.4533 5.84147C50.5353 5.75945 50.6465 5.71338 50.7625 5.71338H110.765C110.881 5.71338 110.992 5.75945 111.074 5.84147C111.156 5.92349 111.202 6.03473 111.202 6.15071V94.3263C111.198 94.4392 111.15 94.546 111.068 94.6243C110.987 94.7025 110.878 94.7462 110.765 94.7461ZM51.1999 93.8714H110.328V6.58805H51.1999V93.8714Z'
          fill='#263238'
        />
        <path d='M113.187 92.813H47.8926V97.0289H113.187V92.813Z' fill='#B2B2B2' />
        <path
          d='M113.205 97.4573H47.8934C47.7774 97.4573 47.6661 97.4112 47.5841 97.3292C47.5021 97.2472 47.4561 97.136 47.4561 97.02V92.8128C47.4561 92.6968 47.5021 92.5856 47.5841 92.5036C47.6661 92.4216 47.7774 92.3755 47.8934 92.3755H113.205C113.321 92.3755 113.432 92.4216 113.514 92.5036C113.596 92.5856 113.642 92.6968 113.642 92.8128V97.02C113.642 97.136 113.596 97.2472 113.514 97.3292C113.432 97.4112 113.321 97.4573 113.205 97.4573ZM48.3482 96.5827H112.768V93.2502H48.3307L48.3482 96.5827Z'
          fill='#263238'
        />
        <path
          d='M87.4022 65.287H73.285C73.1691 65.287 73.0578 65.2409 72.9758 65.1589C72.8938 65.0769 72.8477 64.9656 72.8477 64.8496V60.6162C72.8564 57.3034 71.6058 54.1111 69.349 51.6858C67.4563 49.6517 66.1654 47.1321 65.6201 44.4076C65.0747 41.6832 65.2963 38.8608 66.2602 36.2549C67.224 33.6489 68.8924 31.3617 71.0793 29.6478C73.2662 27.934 75.886 26.8608 78.6468 26.5478C81.725 26.1872 84.8393 26.7878 87.5627 28.2672C90.2861 29.7466 92.4851 32.0323 93.8581 34.8108C95.231 37.5894 95.7108 40.7246 95.2315 43.7866C94.7521 46.8485 93.3372 49.6872 91.1808 51.9132C88.979 54.2871 87.7843 57.4228 87.8483 60.66V64.8846C87.8394 64.996 87.7883 65.0997 87.7053 65.1745C87.6224 65.2493 87.5139 65.2896 87.4022 65.287ZM73.7224 64.4123H86.9649V60.625C86.8968 57.1591 88.1798 53.8027 90.5423 51.266C92.5596 49.1672 93.88 46.4971 94.3236 43.62C94.7671 40.7429 94.3121 37.7991 93.0205 35.1902C91.729 32.5813 89.664 30.4345 87.1072 29.0428C84.5504 27.651 81.6264 27.0821 78.7343 27.4137C75.5297 27.8115 72.5537 29.2809 70.2895 31.5832C68.0252 33.8856 66.6057 36.8857 66.2615 40.0964C66.0637 42.0827 66.293 44.0882 66.9342 45.9785C67.5753 47.8688 68.6135 49.6 69.9788 51.0561C72.3845 53.6446 73.7219 57.0474 73.7224 60.5812V64.4123Z'
          fill='#263238'
        />
        <path
          d='M87.691 71.0773H73.1102C72.9942 71.0773 72.883 71.0312 72.801 70.9492C72.719 70.8672 72.6729 70.7559 72.6729 70.64V66.7039C72.6729 66.5879 72.719 66.4767 72.801 66.3947C72.883 66.3127 72.9942 66.2666 73.1102 66.2666H87.691C87.807 66.2666 87.9182 66.3127 88.0002 66.3947C88.0822 66.4767 88.1283 66.5879 88.1283 66.7039V70.64C88.1283 70.7559 88.0822 70.8672 88.0002 70.9492C87.9182 71.0312 87.807 71.0773 87.691 71.0773ZM73.5475 70.2026H87.2536V67.1413H73.5475V70.2026Z'
          fill='#263238'
        />
        <path
          d='M86.0903 76.0543H74.7196C74.176 76.052 73.6554 75.8344 73.2718 75.4492C72.8883 75.064 72.6728 74.5425 72.6729 73.9989V71.681C72.6729 71.565 72.719 71.4538 72.801 71.3717C72.883 71.2897 72.9942 71.2437 73.1102 71.2437H87.691C87.807 71.2437 87.9182 71.2897 88.0002 71.3717C88.0822 71.4538 88.1283 71.565 88.1283 71.681V73.9989C88.1283 74.541 87.9142 75.0612 87.5325 75.4462C87.1508 75.8311 86.6324 76.0497 86.0903 76.0543ZM73.5563 72.1183V73.9989C73.5563 74.3105 73.6795 74.6095 73.8991 74.8307C74.1186 75.0519 74.4167 75.1774 74.7284 75.1797H86.0991C86.4107 75.1774 86.7088 75.0519 86.9284 74.8307C87.1479 74.6095 87.2711 74.3105 87.2711 73.9989V72.1183H73.5563Z'
          fill='#263238'
        />
        <path
          d='M79.7314 20.1277C79.6734 20.1277 79.6178 20.1046 79.5768 20.0636C79.5357 20.0226 79.5127 19.967 79.5127 19.909V13.6376C79.5127 13.5796 79.5357 13.524 79.5768 13.483C79.6178 13.442 79.6734 13.4189 79.7314 13.4189C79.7894 13.4189 79.845 13.442 79.886 13.483C79.927 13.524 79.95 13.5796 79.95 13.6376V19.909C79.9479 19.9663 79.9242 20.0207 79.8836 20.0612C79.8431 20.1018 79.7887 20.1255 79.7314 20.1277Z'
          fill='#263238'
        />
        <path
          d='M105.507 43.0003H98.9911C98.9331 43.0003 98.8775 42.9773 98.8365 42.9363C98.7955 42.8953 98.7725 42.8397 98.7725 42.7817C98.7746 42.7244 98.7984 42.67 98.8389 42.6294C98.8795 42.5889 98.9338 42.5652 98.9911 42.563H105.507C105.565 42.5652 105.619 42.5889 105.66 42.6294C105.7 42.67 105.724 42.7244 105.726 42.7817C105.726 42.8397 105.703 42.8953 105.662 42.9363C105.621 42.9773 105.565 43.0003 105.507 43.0003Z'
          fill='#263238'
        />
        <path
          d='M60.4617 43.0003H54.7851C54.7271 43.0003 54.6714 42.9773 54.6304 42.9363C54.5894 42.8953 54.5664 42.8397 54.5664 42.7817C54.5664 42.7237 54.5894 42.668 54.6304 42.627C54.6714 42.586 54.7271 42.563 54.7851 42.563H60.4617C60.5197 42.563 60.5753 42.586 60.6163 42.627C60.6573 42.668 60.6803 42.7237 60.6803 42.7817C60.6803 42.8397 60.6573 42.8953 60.6163 42.9363C60.5753 42.9773 60.5197 43.0003 60.4617 43.0003Z'
          fill='#263238'
        />
        <path
          d='M64.8787 28.953C64.8357 28.9649 64.7904 28.9649 64.7474 28.953L59.4994 25.3406C59.4758 25.324 59.4557 25.3029 59.4402 25.2785C59.4248 25.2541 59.4143 25.2269 59.4094 25.1984C59.4045 25.17 59.4053 25.1408 59.4118 25.1127C59.4182 25.0845 59.4302 25.0579 59.4469 25.0344C59.4627 25.0111 59.483 24.9911 59.5066 24.9758C59.5303 24.9604 59.5568 24.95 59.5845 24.9451C59.6123 24.9402 59.6407 24.9409 59.6682 24.9473C59.6956 24.9536 59.7215 24.9654 59.7443 24.982L64.9924 28.5943C65.0286 28.6214 65.0555 28.659 65.0694 28.702C65.0833 28.745 65.0836 28.7913 65.0702 28.8344C65.0568 28.8776 65.0303 28.9155 64.9945 28.943C64.9586 28.9705 64.9151 28.9862 64.8699 28.988L64.8787 28.953Z'
          fill='#263238'
        />
        <path
          d='M94.1808 28.9531C94.1447 28.9527 94.1092 28.9438 94.0773 28.9271C94.0454 28.9104 94.0179 28.8863 93.9971 28.8568C93.9805 28.834 93.9687 28.8081 93.9624 28.7807C93.9561 28.7532 93.9553 28.7248 93.9602 28.697C93.9651 28.6693 93.9755 28.6428 93.9909 28.6191C94.0062 28.5955 94.0262 28.5752 94.0495 28.5594L99.2976 24.9471C99.3451 24.9134 99.4041 24.9001 99.4615 24.9099C99.5189 24.9197 99.5701 24.952 99.6037 24.9995C99.6374 25.0471 99.6507 25.1061 99.6409 25.1635C99.631 25.2209 99.5988 25.272 99.5512 25.3057L94.3032 28.9181C94.2673 28.9427 94.2243 28.955 94.1808 28.9531Z'
          fill='#263238'
        />
        <path
          d='M31.5206 81.0225H6.08512L4.70312 150.279H10.8696L18.8029 97.6325L26.7361 150.279H32.9025L31.5206 81.0225Z'
          fill='#263238'
        />
        <path
          d='M32.9027 150.716H26.7363C26.6324 150.717 26.5318 150.68 26.4529 150.613C26.3741 150.545 26.3225 150.451 26.3077 150.349L18.803 100.571L11.3071 150.349C11.2905 150.451 11.2384 150.544 11.16 150.611C11.0815 150.678 10.9818 150.715 10.8785 150.716H4.74702C4.68966 150.717 4.63281 150.705 4.58009 150.683C4.52736 150.66 4.47996 150.627 4.44091 150.585C4.39822 150.544 4.36448 150.496 4.34188 150.441C4.31928 150.387 4.3083 150.329 4.30969 150.27L5.62169 81.0136C5.62398 80.8991 5.67109 80.7901 5.75286 80.71C5.83463 80.6298 5.94454 80.5849 6.05903 80.585H31.5032C31.617 80.5871 31.7257 80.6327 31.807 80.7124C31.8883 80.792 31.9361 80.8998 31.9406 81.0136L33.34 150.305C33.3421 150.422 33.2981 150.535 33.2175 150.62C33.174 150.656 33.1238 150.683 33.0697 150.699C33.0157 150.716 32.9589 150.722 32.9027 150.716ZM27.1124 149.841H32.4566L31.1009 81.4596H6.49636L5.13188 149.841H10.4849L18.3569 97.5711C18.3569 97.4551 18.403 97.3438 18.485 97.2618C18.567 97.1798 18.6782 97.1337 18.7942 97.1337C18.9102 97.1337 19.0215 97.1798 19.1035 97.2618C19.1855 97.3438 19.2316 97.4551 19.2316 97.5711L27.1124 149.841Z'
          fill='#263238'
        />
        <path
          d='M23.3429 43.5425L18.8296 44.4959L14.325 43.5425L2.99805 48.0295L6.08563 81.0221H31.5823L34.6699 48.0295L23.3429 43.5425Z'
          fill='#91B3FA'
        />
        <path
          d='M31.582 81.4594H6.08535C5.97656 81.46 5.87142 81.42 5.79056 81.3472C5.70969 81.2744 5.65889 81.1741 5.64802 81.0658L2.5604 48.0733C2.55174 47.9774 2.57434 47.8813 2.62479 47.7994C2.67523 47.7174 2.75082 47.6539 2.8403 47.6184L14.1585 43.1401C14.2412 43.1145 14.3296 43.1145 14.4122 43.1401L18.8293 44.076L23.2551 43.1401C23.3346 43.1141 23.4205 43.1141 23.5 43.1401L34.827 47.6184C34.9165 47.6539 34.9921 47.7174 35.0425 47.7994C35.093 47.8813 35.1156 47.9774 35.1069 48.0733L32.0193 81.0658C32.0066 81.1734 31.9552 81.2726 31.8747 81.345C31.7943 81.4174 31.6902 81.4581 31.582 81.4594ZM6.47891 80.5848H31.1796L34.1972 48.3182L23.3076 43.9973L18.9342 44.9245C18.8733 44.9332 18.8115 44.9332 18.7506 44.9245L14.3772 43.9973L3.46134 48.3182L6.47891 80.5848Z'
          fill='#263238'
        />
        <path
          d='M2.99772 48.0298L0.46115 66.0742C0.325265 67.5798 0.694567 69.0877 1.51077 70.3601C2.15802 71.3573 2.89276 72.3544 3.3301 72.4768C4.20477 72.7392 8.3332 72.092 8.84051 71.3223C9.34782 70.5526 10.3799 64.0188 10.3799 64.0188L9.99508 53.5227'
          fill='#91B3FA'
        />
        <path
          d='M3.96017 72.9751C3.70701 72.9833 3.45393 72.9569 3.20796 72.8964C2.88433 72.8089 2.3333 72.459 1.14375 70.596C0.270014 69.2425 -0.121399 67.6338 0.0329034 66.0302L2.56071 47.9683C2.57695 47.8523 2.63858 47.7475 2.73208 47.677C2.82558 47.6064 2.94329 47.5759 3.05928 47.5922C3.17527 47.6084 3.28007 47.67 3.35061 47.7635C3.42114 47.857 3.45162 47.9747 3.43538 48.0907L0.898819 66.1352C0.773154 67.5338 1.11791 68.9344 1.87845 70.1149C3.01552 71.8643 3.40916 72.048 3.4529 72.0567C4.32757 72.3104 8.14113 71.5756 8.48225 71.0858C8.82337 70.596 9.45313 67.1148 9.95169 63.9485L9.55808 53.5224C9.55574 53.4654 9.56483 53.4085 9.58477 53.3551C9.60471 53.3016 9.63509 53.2527 9.67419 53.2111C9.7133 53.1696 9.76031 53.1362 9.81246 53.1131C9.86461 53.09 9.92086 53.0775 9.9779 53.0763C10.0362 53.0701 10.0952 53.0764 10.1508 53.0948C10.2065 53.1132 10.2575 53.1433 10.3006 53.183C10.3437 53.2228 10.3778 53.2713 10.4006 53.3253C10.4233 53.3793 10.4343 53.4376 10.4327 53.4962L10.8176 63.9922C10.6427 65.1905 9.74174 70.7534 9.20819 71.5581C8.67464 72.3628 5.55207 72.9751 3.96017 72.9751Z'
          fill='#263238'
        />
        <path
          d='M16.0225 50.3912L17.8593 54.9482L16.4073 74.5933L18.734 78.853L21.4454 74.4009L19.215 55.1407L21.1568 51.1697L18.629 47.4873L16.0225 50.3912Z'
          fill='#263238'
        />
        <path
          d='M18.7414 79.2993C18.665 79.2947 18.5909 79.2716 18.5253 79.232C18.4598 79.1924 18.4049 79.1374 18.3653 79.0719L16.0387 74.8035C16.0039 74.7296 15.986 74.649 15.9862 74.5673L17.4382 55.0184L15.6189 50.5926C15.5861 50.5174 15.5762 50.4342 15.5901 50.3534C15.6041 50.2726 15.6415 50.1976 15.6976 50.1378L18.3216 47.2251C18.3672 47.179 18.4218 47.1428 18.482 47.1187C18.5422 47.0947 18.6067 47.0832 18.6715 47.0852C18.7372 47.0882 18.8015 47.1063 18.8591 47.1382C18.9167 47.17 18.9663 47.2148 19.0038 47.2689L21.5316 50.96C21.5718 51.0258 21.5931 51.1015 21.5931 51.1786C21.5931 51.2558 21.5718 51.3314 21.5316 51.3973L19.6511 55.2546L21.864 74.3836C21.8749 74.4811 21.8534 74.5795 21.8028 74.6635L19.0913 79.1244C19.0506 79.1787 18.9978 79.2228 18.937 79.2531C18.8763 79.2835 18.8093 79.2993 18.7414 79.2993ZM16.8609 74.4974L18.759 77.996L21.0069 74.3137L18.7939 55.2021C18.7816 55.1179 18.797 55.032 18.8376 54.9572L20.657 51.2136L18.5928 48.2047L16.5373 50.4964L18.2866 54.791C18.3036 54.857 18.3036 54.9262 18.2866 54.9922L16.8609 74.4974Z'
          fill='#263238'
        />
        <path
          d='M23.2213 43.543L18.6992 49.2546L14.4658 43.543V38.3999H23.2213V43.543Z'
          fill='white'
        />
        <path
          d='M18.7421 49.718C18.6744 49.7167 18.6079 49.7003 18.5474 49.67C18.4869 49.6398 18.4338 49.5964 18.3922 49.543L14.1588 43.8314C14.1068 43.7539 14.0793 43.6624 14.0801 43.569V38.3997C14.0801 38.2837 14.1262 38.1725 14.2082 38.0905C14.2902 38.0085 14.4014 37.9624 14.5174 37.9624H23.2641C23.3801 37.9624 23.4914 38.0085 23.5734 38.0905C23.6554 38.1725 23.7015 38.2837 23.7015 38.3997V43.5428C23.7002 43.6413 23.6664 43.7367 23.6053 43.814L19.092 49.5255C19.0526 49.5826 19.0005 49.6297 18.9398 49.6631C18.8791 49.6965 18.8114 49.7153 18.7421 49.718ZM14.946 43.4291L18.7421 48.5372L22.8093 43.3941V38.8371H14.9373L14.946 43.4291Z'
          fill='#263238'
        />
        <path
          d='M13.9835 42.7378L12.7852 44.2247L14.1409 52.6478L18.6979 49.2541L23.6573 52.5166L24.4707 44.2247L23.2899 43.0352L18.6279 47.4873L13.9835 42.7378Z'
          fill='#91B3FA'
        />
        <path
          d='M14.1412 53.0849H13.975C13.9065 53.0541 13.8467 53.0069 13.801 52.9473C13.7552 52.8878 13.7248 52.8179 13.7127 52.7438L12.3569 44.2945C12.3446 44.2333 12.346 44.1702 12.3611 44.1097C12.3763 44.0491 12.4047 43.9927 12.4444 43.9446L13.6514 42.4664C13.689 42.4185 13.7367 42.3795 13.7912 42.3523C13.8456 42.325 13.9054 42.3102 13.9663 42.309C14.0279 42.305 14.0897 42.3148 14.1472 42.3374C14.2046 42.3601 14.2563 42.3952 14.2987 42.4402L18.672 46.8747L23.0454 42.7201C23.1271 42.6399 23.2371 42.595 23.3515 42.595C23.466 42.595 23.5759 42.6399 23.6577 42.7201L24.8472 43.9184C24.8925 43.9638 24.927 44.0188 24.9482 44.0794C24.9694 44.1399 24.9767 44.2045 24.9697 44.2682L24.1562 52.5601C24.1464 52.6338 24.1191 52.704 24.0765 52.7649C24.0338 52.8258 23.9772 52.8756 23.9113 52.91C23.8436 52.9445 23.7686 52.9625 23.6926 52.9625C23.6166 52.9625 23.5417 52.9445 23.474 52.91L18.742 49.7874L14.3687 52.9974C14.3034 53.0489 14.2241 53.0794 14.1412 53.0849ZM13.2666 44.3382L14.4736 51.8604L18.4621 48.8952C18.5343 48.8419 18.6217 48.8132 18.7114 48.8132C18.8011 48.8132 18.8885 48.8419 18.9607 48.8952L23.334 51.7554L23.99 44.3819L23.2553 43.6472L18.8819 47.8019C18.7987 47.8826 18.6874 47.9277 18.5714 47.9277C18.4555 47.9277 18.3442 47.8826 18.261 47.8019L13.9663 43.4285L13.2666 44.3382Z'
          fill='#263238'
        />
        <path
          d='M25.7393 26.0049C25.7393 26.0049 28.0396 26.7746 28.4507 28.1128C28.8618 29.4511 26.7014 32.6436 26.7014 32.6436L25.9754 27.7017L25.7393 26.0049Z'
          fill='#263238'
        />
        <path
          d='M26.7193 33.0991H26.6143C26.5274 33.0819 26.4478 33.0382 26.3868 32.974C26.3257 32.9098 26.286 32.8282 26.2732 32.7404L25.3198 26.1017C25.3121 26.0281 25.3227 25.9538 25.3509 25.8854C25.379 25.8169 25.4237 25.7566 25.481 25.7097C25.5382 25.6629 25.6063 25.631 25.6789 25.617C25.7516 25.603 25.8265 25.6072 25.8971 25.6294C26.1508 25.7168 28.4162 26.5041 28.8798 28.0172C29.3433 29.5304 27.3141 32.5743 27.0779 32.9241C27.0368 32.9802 26.9827 33.0254 26.9202 33.0559C26.8577 33.0864 26.7888 33.1012 26.7193 33.0991ZM26.2995 26.7227L26.9729 31.411C27.6289 30.2651 28.2412 28.8832 28.0488 28.2621C27.8564 27.6411 26.9817 27.0376 26.2995 26.7052V26.7227Z'
          fill='#263238'
        />
        <path
          d='M23.2368 24.4395C24.219 24.675 25.0973 25.2247 25.7384 26.0052C27.4265 28.1044 26.7792 30.8159 26.6131 33.3612C26.5081 34.8044 25.992 42.6152 24.7588 43.3412C24.0209 43.6975 23.2121 43.8826 22.3928 43.8826C21.5734 43.8826 20.7646 43.6975 20.0268 43.3412C18.0102 42.416 16.1392 41.202 14.4726 39.7375C13.7554 38.9153 13.7554 35.8365 13.7554 35.8277C13.7554 35.4429 13.1956 34.7082 13.0557 34.3321C12.7307 33.4885 12.5161 32.6066 12.4172 31.7081C12.2693 30.2765 12.6155 28.8375 13.3983 27.6299C14.1811 26.4223 15.3534 25.5188 16.7205 25.0693C18.7938 24.2658 21.0481 24.0479 23.2368 24.4395Z'
          fill='white'
        />
        <path
          d='M22.5914 44.3211C21.6528 44.308 20.7258 44.1119 19.8624 43.7438C17.7848 42.7947 15.8598 41.5419 14.1508 40.0265C13.3636 39.1518 13.2761 36.3704 13.2761 35.8281C13.1863 35.567 13.066 35.3174 12.9175 35.0846C12.8 34.8916 12.6976 34.6899 12.6113 34.4811C12.2616 33.604 12.0349 32.6826 11.9378 31.7434C11.7875 30.2146 12.1609 28.6802 12.9968 27.3914C13.8326 26.1026 15.0814 25.1359 16.5386 24.6498C18.0343 24.1425 20.9119 23.3815 23.3085 24.0113C24.3893 24.2815 25.3532 24.8954 26.055 25.7606C27.5769 27.6499 27.3408 29.9415 27.1221 32.1632C27.1221 32.583 27.0347 33.0379 27.0084 33.414V33.5802C26.3437 42.9304 25.3291 43.5164 24.9442 43.7438C24.2242 44.1395 23.4127 44.3386 22.5914 44.3211ZM21.3668 24.6498C19.8429 24.7028 18.3371 24.9981 16.906 25.5245C15.629 25.9383 14.5331 26.7787 13.8021 27.9046C13.0712 29.0305 12.7496 30.3735 12.8912 31.7084C12.9876 32.5715 13.1964 33.4184 13.5123 34.2274C13.5875 34.3869 13.6722 34.5417 13.7659 34.691C14.0121 35.043 14.1738 35.4471 14.2382 35.8718C14.2382 37.0876 14.4306 39.0119 14.8505 39.4929C16.467 40.9147 18.2821 42.0934 20.2385 42.9916C20.9202 43.3215 21.6678 43.4928 22.4252 43.4928C23.1825 43.4928 23.9301 43.3215 24.6118 42.9916C24.778 42.8954 25.6352 41.9858 26.2387 33.5277V33.3615C26.2387 32.9417 26.3087 32.4868 26.3524 32.0845C26.5711 29.9415 26.7723 27.9298 25.4778 26.3204C24.8781 25.5808 24.0457 25.0659 23.1161 24.8597C22.5437 24.719 21.9563 24.6485 21.3668 24.6498Z'
          fill='#263238'
        />
        <path
          d='M23.8324 24.0985C23.8324 24.0985 26.2028 24.7195 26.404 26.1627C26.6051 27.6059 24.9695 29.1454 21.0598 29.8626C17.15 30.5798 16.5289 30.4836 16.5289 30.4836C16.5289 30.4836 17.9722 31.3583 17.4561 31.9181L16.2228 33.1514L16.4327 35.1106C16.4327 35.1106 15.5056 36.2389 15.2956 35.4168C15.0857 34.5946 14.6834 33.5625 14.421 33.4662C14.1586 33.37 13.1002 34.3409 13.1002 34.3409C12.787 32.8376 12.6752 31.2992 12.7678 29.7664C12.9778 27.606 13.7737 26.6438 16.7651 25.0694C19.7565 23.495 23.8324 24.0985 23.8324 24.0985Z'
          fill='#263238'
        />
        <path
          d='M15.5849 36.1515C15.5357 36.1605 15.4853 36.1605 15.4361 36.1515C15.292 36.1177 15.1615 36.0412 15.0616 35.9319C14.9617 35.8227 14.8971 35.6858 14.8764 35.5392C14.7467 34.9816 14.5376 34.4455 14.2553 33.9473C13.9397 34.1555 13.6466 34.3958 13.3807 34.6646C13.324 34.717 13.2549 34.7541 13.1799 34.7725C13.105 34.7908 13.0266 34.7898 12.9521 34.7695C12.8779 34.7474 12.8111 34.7057 12.7587 34.6487C12.7064 34.5917 12.6704 34.5217 12.6547 34.4459C12.3247 32.9005 12.207 31.3173 12.3048 29.7402C12.5323 27.4048 13.4419 26.3202 16.5382 24.6933C19.6345 23.0664 23.7455 23.6612 23.9204 23.6874H23.9904C24.0954 23.6874 26.6144 24.3959 26.8856 26.1103C27.0955 27.5885 25.7135 29.469 21.1827 30.2999C19.4334 30.6148 18.3838 30.781 17.684 30.8597C17.871 31.0587 17.9957 31.308 18.0427 31.577C18.0569 31.6977 18.0438 31.8201 18.0045 31.9352C17.9651 32.0502 17.9004 32.1549 17.8153 32.2417L16.7219 33.3263L16.9056 35.0757C16.9139 35.132 16.9095 35.1894 16.8929 35.2439C16.8762 35.2983 16.8477 35.3484 16.8094 35.3905C16.6668 35.5931 16.4849 35.7648 16.2745 35.8955C16.0641 36.0263 15.8296 36.1133 15.5849 36.1515ZM14.369 33.0114C14.4299 33.0022 14.4919 33.0022 14.5527 33.0114C15.165 33.2126 15.5936 34.6121 15.7423 35.1719C15.8425 35.0985 15.9362 35.0165 16.0222 34.927L15.8297 33.1776C15.8249 33.1119 15.8341 33.046 15.8567 32.9842C15.8793 32.9223 15.9149 32.866 15.961 32.819L17.1942 31.5857C16.9524 31.28 16.6558 31.0219 16.3195 30.8248C16.2346 30.7707 16.1715 30.6885 16.1411 30.5926C16.1107 30.4966 16.115 30.393 16.1534 30.2999C16.1886 30.2047 16.2558 30.1247 16.3436 30.0736C16.4314 30.0225 16.5343 30.0036 16.6345 30.0201C18.1072 29.9426 19.5704 29.7377 21.0078 29.4078C24.9263 28.6818 26.1509 27.2211 26.0022 26.1977C25.8535 25.1744 24.3753 24.6671 23.7718 24.5096C23.4569 24.4571 19.6783 23.9936 16.9581 25.3843C13.9405 26.9762 13.3719 27.8684 13.1883 29.7577C13.1026 30.9913 13.1672 32.2307 13.3807 33.4488C13.6557 33.2023 14.0017 33.0492 14.369 33.0114Z'
          fill='#263238'
        />
        <path d='M23.2207 38.3994L24.5589 38.1895L23.6318 34.6995L23.9379 32.9502' fill='white' />
        <path
          d='M23.2206 38.618C23.1626 38.618 23.107 38.595 23.066 38.554C23.025 38.513 23.002 38.4573 23.002 38.3993C23.002 38.3414 23.025 38.2857 23.066 38.2447C23.107 38.2037 23.1626 38.1807 23.2206 38.1807L24.314 38.0145L23.4393 34.752C23.4345 34.7201 23.4345 34.6876 23.4393 34.6558L23.7542 32.9064C23.7648 32.8497 23.7974 32.7995 23.845 32.7667C23.8925 32.7339 23.951 32.7213 24.0078 32.7315C24.0635 32.7441 24.1124 32.7772 24.1448 32.8242C24.1772 32.8712 24.1907 32.9286 24.1827 32.9851L23.8766 34.6907L24.7513 38.1369C24.7639 38.1644 24.7704 38.1942 24.7704 38.2244C24.7704 38.2546 24.7639 38.2844 24.7513 38.3119C24.7346 38.3374 24.7127 38.3591 24.6871 38.3757C24.6615 38.3923 24.6327 38.4033 24.6026 38.4081L23.2643 38.618H23.2206Z'
          fill='#263238'
        />
        <path
          d='M24.6631 34.1305C24.6631 34.6116 24.8467 35.0052 25.0742 35.0052C25.3016 35.0052 25.4853 34.6116 25.4853 34.1305C25.4853 33.6495 25.3016 33.2559 25.0742 33.2559C24.8467 33.2559 24.6631 33.6495 24.6631 34.1305Z'
          fill='#263238'
        />
        <path
          d='M21.0605 34.5944C21.0605 35.0755 21.2442 35.4691 21.4716 35.4691C21.699 35.4691 21.8827 35.0755 21.8827 34.5944C21.8827 34.1133 21.699 33.7197 21.4716 33.7197C21.2442 33.7197 21.0605 34.1133 21.0605 34.5944Z'
          fill='#263238'
        />
        <path
          d='M24.0417 39.4321C24.0417 39.4321 22.8084 40.8665 20.543 39.2222L24.0417 39.4321Z'
          fill='white'
        />
        <path
          d='M22.5906 40.2273C21.7891 40.1792 21.0248 39.8722 20.4127 39.3526C20.3663 39.3178 20.3356 39.266 20.3274 39.2086C20.3192 39.1512 20.3341 39.0928 20.3689 39.0465C20.4037 39.0001 20.4556 38.9694 20.513 38.9612C20.5704 38.953 20.6287 38.9679 20.6751 39.0027C22.7481 40.5159 23.8676 39.2564 23.8764 39.2389C23.9172 39.1988 23.9722 39.1764 24.0294 39.1764C24.0867 39.1764 24.1416 39.1988 24.1825 39.2389C24.2034 39.2585 24.2201 39.2822 24.2315 39.3086C24.2429 39.3349 24.2488 39.3633 24.2488 39.3919C24.2488 39.4206 24.2429 39.449 24.2315 39.4753C24.2201 39.5017 24.2034 39.5254 24.1825 39.545C23.9757 39.7587 23.7285 39.9291 23.4552 40.0463C23.1819 40.1634 22.888 40.2249 22.5906 40.2273Z'
          fill='#263238'
        />
        <path
          d='M25.7919 32.3288C25.7919 32.3288 25.2758 31.5066 24.3486 32.2326L25.7919 32.3288Z'
          fill='white'
        />
        <path
          d='M25.792 32.5478C25.7559 32.5474 25.7204 32.5385 25.6885 32.5218C25.6566 32.5051 25.6291 32.481 25.6083 32.4515C25.5587 32.3763 25.4945 32.3119 25.4193 32.2623C25.3441 32.2127 25.2596 32.1789 25.1709 32.1629C25.047 32.1514 24.922 32.1666 24.8043 32.2073C24.6867 32.248 24.5791 32.3134 24.4887 32.3991C24.4464 32.4397 24.39 32.4624 24.3313 32.4624C24.2726 32.4624 24.2162 32.4397 24.1738 32.3991C24.1338 32.3582 24.1113 32.3032 24.1113 32.246C24.1113 32.1888 24.1338 32.1338 24.1738 32.0929C24.3136 31.9665 24.4787 31.8714 24.6583 31.8141C24.8378 31.7567 25.0275 31.7385 25.2147 31.7605C25.5084 31.8242 25.7657 32 25.9319 32.2504C25.9477 32.2742 25.9584 32.3011 25.9634 32.3292C25.9685 32.3574 25.9676 32.3863 25.9611 32.4142C25.9545 32.442 25.9424 32.4682 25.9253 32.4912C25.9083 32.5142 25.8867 32.5334 25.862 32.5478C25.8388 32.552 25.8151 32.552 25.792 32.5478Z'
          fill='#263238'
        />
        <path
          d='M22.3987 32.4339C21.9295 32.2613 21.4144 32.2596 20.9441 32.4292C20.4738 32.5988 20.0783 32.9287 19.8271 33.361'
          fill='white'
        />
        <path
          d='M19.8268 33.58C19.7922 33.5879 19.7564 33.5879 19.7218 33.58C19.6965 33.5663 19.6742 33.5477 19.6561 33.5253C19.6379 33.5029 19.6244 33.4771 19.6163 33.4495C19.6082 33.4218 19.6056 33.3929 19.6087 33.3642C19.6118 33.3356 19.6205 33.3079 19.6344 33.2826C19.9095 32.8037 20.3431 32.4358 20.8605 32.2426C21.378 32.0493 21.9466 32.0429 22.4683 32.2243C22.5228 32.244 22.5672 32.2846 22.5918 32.337C22.6164 32.3895 22.6192 32.4496 22.5995 32.5041C22.5796 32.5589 22.5395 32.6039 22.4875 32.6299C22.4354 32.656 22.3753 32.661 22.3196 32.6441C21.9009 32.4866 21.4399 32.4828 21.0187 32.6334C20.5974 32.7839 20.2433 33.0791 20.0192 33.4663C19.9997 33.5002 19.9716 33.5285 19.9379 33.5484C19.9042 33.5683 19.8659 33.5792 19.8268 33.58Z'
          fill='#263238'
        />
        <path
          d='M15.8991 36.694C15.8991 36.694 16.0216 33.519 14.3772 33.4665C12.7328 33.414 13.0564 34.3411 13.1614 35.7844C13.1596 36.2532 13.3022 36.7112 13.5698 37.0962C13.8374 37.4811 14.217 37.7744 14.6571 37.936'
          fill='white'
        />
        <path
          d='M14.6584 38.3468H14.536C14.0086 38.1583 13.5519 37.8122 13.228 37.3553C12.9041 36.8983 12.7286 36.3528 12.7254 35.7928C12.7254 35.6091 12.7254 35.4342 12.7254 35.268C12.6554 34.5158 12.5942 33.8598 13.0053 33.4224C13.1983 33.2536 13.426 33.1293 13.6724 33.0582C13.9188 32.9872 14.1778 32.9712 14.431 33.0113C15.0608 33.0113 15.8742 33.3962 16.2241 34.9881C16.3434 35.5485 16.3962 36.1209 16.3815 36.6937C16.3815 36.8097 16.3355 36.9209 16.2534 37.0029C16.1714 37.0849 16.0602 37.131 15.9442 37.131C15.8282 37.131 15.717 37.0849 15.635 37.0029C15.553 36.9209 15.5068 36.8097 15.5068 36.6937C15.5068 35.9852 15.3844 33.956 14.4048 33.921C13.8712 33.921 13.705 33.9997 13.6438 34.0522C13.5826 34.1047 13.5476 34.6994 13.6438 35.2242C13.6438 35.3904 13.6438 35.5741 13.6438 35.7665C13.6394 36.1436 13.7501 36.513 13.9611 36.8256C14.172 37.1381 14.4733 37.3789 14.8246 37.5159C14.9406 37.5321 15.0454 37.5938 15.1159 37.6873C15.1864 37.7808 15.217 37.8985 15.2007 38.0144C15.1845 38.1304 15.1228 38.2352 15.0293 38.3058C14.9358 38.3763 14.8182 38.4068 14.7022 38.3906L14.6584 38.3468Z'
          fill='#263238'
        />
        <path
          d='M57.0527 56.4175L59.6767 55.158L62.7556 52.0879L65.607 52.5165L67.54 56.365L66.9628 57.1522L64.7498 56.7236C64.1735 57.5428 63.5545 58.3311 62.8955 59.0853C62.5369 59.3651 57.9624 59.5138 57.9624 59.5138L57.0527 56.4175Z'
          fill='white'
        />
        <path
          d='M57.919 59.9508C57.8232 59.9502 57.7303 59.9183 57.6545 59.8598C57.5786 59.8013 57.5241 59.7196 57.4992 59.6271L56.6245 56.5308C56.594 56.4331 56.6003 56.3275 56.6422 56.2341C56.6841 56.1407 56.7587 56.0658 56.8519 56.0235L59.371 54.7902L62.3886 51.7726C62.438 51.7252 62.4975 51.6895 62.5626 51.6683C62.6278 51.6471 62.6968 51.6409 62.7647 51.6501L65.6248 52.0787C65.7005 52.0895 65.7723 52.1193 65.8334 52.1651C65.8946 52.211 65.9431 52.2715 65.9747 52.3411L67.899 56.1984C67.9417 56.2666 67.9643 56.3454 67.9643 56.4258C67.9643 56.5063 67.9417 56.5851 67.899 56.6533L67.3304 57.4405C67.2801 57.5059 67.2125 57.5561 67.1353 57.5855C67.0581 57.6148 66.9742 57.6222 66.8931 57.6066L64.9514 57.2305C64.4226 58.0123 63.8379 58.7548 63.202 59.4522C63.0883 59.5484 62.7297 59.8283 58.0152 59.977L57.919 59.9508ZM57.5691 56.6445L58.2514 59.0673C59.7161 59.0578 61.178 58.9379 62.6247 58.7087C62.7734 58.5863 63.6481 57.4405 64.3741 56.4696C64.4219 56.4009 64.4888 56.3477 64.5666 56.3166C64.6443 56.2856 64.7294 56.2779 64.8114 56.2946L66.7532 56.6707L66.9981 56.3296L65.2924 52.9097L62.8784 52.551L59.9657 55.4637C59.9286 55.4977 59.8875 55.5271 59.8433 55.5512L57.5691 56.6445Z'
          fill='#263238'
        />
        <path
          d='M34.6699 48.0298L42.9443 61.2723L57.0527 56.4179L57.9798 60.1177L42.7519 71.5847C42.4254 71.8309 42.0508 72.0058 41.6524 72.0981C41.2541 72.1904 40.8408 72.1981 40.4392 72.1206C40.0377 72.0431 39.657 71.8822 39.3216 71.6482C38.9862 71.4143 38.7036 71.1125 38.4922 70.7625L33.4541 62.4356L34.6699 48.0298Z'
          fill='#91B3FA'
        />
        <path
          d='M40.9938 72.6164C40.7797 72.6134 40.5662 72.5929 40.3553 72.5552C39.8934 72.4652 39.4555 72.2794 39.0698 72.0097C38.6841 71.74 38.3593 71.3925 38.1162 70.9896L33.0781 62.6539C33.0352 62.5733 33.0141 62.4828 33.0168 62.3915L34.2326 47.9682C34.2398 47.8768 34.2755 47.7899 34.3347 47.7199C34.3939 47.6499 34.4736 47.6002 34.5626 47.578C34.6515 47.5558 34.7452 47.562 34.8304 47.596C34.9156 47.6299 34.988 47.6897 35.0373 47.7671L43.128 60.7209L56.9129 55.9802C56.9676 55.9545 57.0273 55.9411 57.0878 55.9411C57.1483 55.9411 57.208 55.9545 57.2627 55.9802C57.3692 56.0415 57.4477 56.1419 57.4814 56.2601L58.3998 59.96C58.4209 60.0429 58.4172 60.1301 58.3892 60.211C58.3612 60.2918 58.3102 60.3627 58.2423 60.4148L43.0143 71.9342C42.4337 72.3768 41.7239 72.6165 40.9938 72.6164ZM33.9003 62.3303L38.8596 70.5347C39.0394 70.8323 39.2795 71.0888 39.5646 71.2877C39.8497 71.4866 40.1734 71.6235 40.5147 71.6894C40.856 71.7554 41.2073 71.749 41.546 71.6706C41.8847 71.5922 42.2031 71.4436 42.4808 71.2345L57.4727 59.9425L56.7379 56.9861L43.0756 61.6918C42.9814 61.7229 42.8795 61.7218 42.786 61.6885C42.6925 61.6552 42.6128 61.5918 42.5595 61.5081L34.9849 49.3764L33.9003 62.3303Z'
          fill='#263238'
        />
        <path
          d='M68.275 58.4645C68.1248 58.4641 67.9772 58.4249 67.8464 58.3508L58.7586 53.2865C58.5556 53.1705 58.407 52.9786 58.3455 52.7531C58.284 52.5275 58.3146 52.2868 58.4306 52.0838C58.5466 51.8808 58.7385 51.7322 58.964 51.6707C59.1896 51.6092 59.4303 51.6398 59.6333 51.7558L68.7299 56.8201C68.9022 56.9131 69.0384 57.0613 69.1166 57.2408C69.1948 57.4204 69.2104 57.621 69.161 57.8105C69.1116 58.0001 69 58.1675 68.8441 58.2861C68.6882 58.4047 68.4971 58.4675 68.3012 58.4645H68.275Z'
          fill='#91B3FA'
        />
        <path
          d='M68.2747 58.9015C68.0513 58.9003 67.8318 58.8432 67.6362 58.7353L58.5484 53.671C58.3979 53.5854 58.2657 53.4711 58.1594 53.3344C58.0532 53.1978 57.9749 53.0415 57.929 52.8746C57.8831 52.7077 57.8706 52.5334 57.892 52.3616C57.9135 52.1899 57.9686 52.024 58.0542 51.8736C58.1397 51.7231 58.2541 51.5909 58.3908 51.4847C58.5274 51.3784 58.6836 51.3001 58.8505 51.2542C59.0174 51.2083 59.1917 51.1957 59.3635 51.2172C59.5353 51.2387 59.7011 51.2938 59.8516 51.3794L68.9394 56.4437C69.0907 56.5262 69.2237 56.6385 69.3304 56.7738C69.4372 56.9091 69.5154 57.0646 69.5604 57.2309C69.6158 57.4259 69.6253 57.6311 69.5881 57.8303C69.5509 58.0296 69.4681 58.2175 69.346 58.3794C69.224 58.5412 69.0662 58.6726 68.8848 58.7632C68.7035 58.8538 68.5036 58.9011 68.3009 58.9015H68.2747ZM59.1868 52.0878C59.1465 52.0794 59.1048 52.0794 59.0644 52.0878C59.0079 52.1013 58.955 52.1271 58.9097 52.1634C58.8643 52.1997 58.8275 52.2456 58.802 52.2978C58.7476 52.3992 58.7346 52.5177 58.7655 52.6286C58.7965 52.7394 58.8691 52.834 58.9682 52.8925L68.0648 57.9481C68.1662 58.0025 68.2847 58.0156 68.3955 57.9846C68.5064 57.9536 68.601 57.881 68.6595 57.782C68.7033 57.6755 68.7033 57.5561 68.6595 57.4496C68.6445 57.3937 68.6181 57.3416 68.582 57.2965C68.5458 57.2513 68.5008 57.2141 68.4496 57.1872L59.3618 52.1228C59.3063 52.1 59.2469 52.0881 59.1868 52.0878Z'
          fill='#263238'
        />
        <path
          d='M60.9007 55.333C60.9007 55.333 64.9242 53.3825 65.5014 53.8373C66.0787 54.2921 65.8426 54.5283 65.274 55.333C64.6714 55.9202 63.9121 56.3211 63.0873 56.4876L61.7053 58.6742L59.7461 57.9833'
          fill='white'
        />
        <path
          d='M61.7063 59.1113C61.657 59.1196 61.6068 59.1196 61.5576 59.1113L59.5983 58.4203C59.4924 58.3785 59.4069 58.2971 59.3601 58.1934C59.3132 58.0896 59.3086 57.9717 59.3474 57.8646C59.3861 57.7576 59.465 57.6698 59.5674 57.6201C59.6698 57.5703 59.7876 57.5624 59.8957 57.5981L61.5226 58.1666L62.7121 56.2773C62.7789 56.1813 62.8785 56.1129 62.992 56.0849C63.7137 55.9482 64.3812 55.6084 64.9163 55.1053L65.0212 54.9566C65.1751 54.7778 65.3019 54.5774 65.3974 54.3618C65.3974 54.3618 65.3537 54.3093 65.2312 54.2044C63.7875 54.5277 62.3953 55.0487 61.094 55.7525C60.9896 55.8036 60.8692 55.811 60.7593 55.7733C60.6494 55.7356 60.559 55.6557 60.508 55.5514C60.4569 55.447 60.4495 55.3266 60.4872 55.2167C60.5249 55.1068 60.6047 55.0164 60.7091 54.9653C63.543 53.5921 65.2487 53.1023 65.7735 53.5221C66.6482 54.2131 66.2196 54.7904 65.7735 55.4639L65.6598 55.6126C65.0528 56.2613 64.2626 56.7098 63.3944 56.8983L62.1086 58.9276C62.0646 58.9919 62.0039 59.043 61.933 59.0753C61.8621 59.1077 61.7837 59.1201 61.7063 59.1113Z'
          fill='#263238'
        />
        <path
          d='M19.6167 46.3328L20.0365 43.3414L26.6665 42.353L27.6987 44.0236L26.6665 44.7934C26.5971 46.044 26.4274 47.2871 26.1592 48.5107C25.8968 48.8956 22.5643 49.9189 22.5643 49.9189L20.3864 51.6683L17.7012 48.1696L19.6167 46.3328Z'
          fill='white'
        />
        <path
          d='M20.3775 52.1492C20.3192 52.1433 20.2627 52.1255 20.2116 52.0969C20.1604 52.0683 20.1157 52.0296 20.0801 51.983L17.3861 48.4843C17.316 48.4045 17.2773 48.3019 17.2773 48.1957C17.2773 48.0895 17.316 47.9868 17.3861 47.907L19.2055 46.0877L19.6078 43.245C19.62 43.151 19.6624 43.0635 19.7287 42.9956C19.7949 42.9278 19.8814 42.8833 19.9751 42.8689L26.6139 41.9243C26.6986 41.9102 26.7855 41.9218 26.8635 41.9577C26.9415 41.9936 27.0069 42.052 27.0512 42.1255L28.0746 43.7961C28.1318 43.8882 28.1527 43.9983 28.1333 44.1049C28.1139 44.2116 28.0556 44.3073 27.9696 44.3734L27.095 45.0206C27.0622 46.2851 26.8682 47.5401 26.5177 48.7555C26.299 49.0878 25.0395 49.6301 22.7828 50.3124L20.6662 52.0617C20.5827 52.1229 20.4809 52.1537 20.3775 52.1492ZM18.2696 48.2919L20.4475 51.0908L22.2843 49.5864C22.3262 49.5459 22.3772 49.5159 22.433 49.4989C23.5986 49.1833 24.732 48.7587 25.818 48.2307C26.0212 47.0732 26.1584 45.9051 26.2291 44.732C26.2342 44.671 26.2515 44.6117 26.28 44.5576C26.3086 44.5035 26.3478 44.4557 26.3952 44.4171L27.1037 43.8836L26.439 42.8077L20.4212 43.6824L20.0451 46.3064C20.0259 46.3972 19.9838 46.4815 19.9227 46.5513L18.2696 48.2919Z'
          fill='#263238'
        />
        <path
          d='M17.6934 48.2573L1.53831 66.6254C1.53831 66.6254 1.15344 70.3428 2.56166 72.1358C3.96988 73.9289 7.80969 72.1358 8.20329 72.0134C8.5969 71.8909 21.1572 51.2137 21.1572 51.2137L17.6934 48.2573Z'
          fill='#91B3FA'
        />
        <path
          d='M4.51966 73.3334C4.08751 73.3659 3.6538 73.2957 3.25399 73.1284C2.85418 72.9612 2.49961 72.7018 2.21927 72.3713C0.714837 70.4645 1.08223 66.7034 1.09972 66.546C1.12805 66.4631 1.17967 66.3902 1.2484 66.336L17.3948 47.968C17.4695 47.8805 17.5756 47.8259 17.6902 47.8161C17.8048 47.8062 17.9186 47.842 18.0071 47.9155L21.5057 50.8369C21.5873 50.9024 21.6422 50.9954 21.6599 51.0985C21.6776 51.2017 21.6571 51.3077 21.602 51.3967C8.94552 72.2051 8.61313 72.3188 8.41195 72.3888L8.23701 72.4587C7.06442 72.983 5.80297 73.2798 4.51966 73.3334ZM1.95688 66.7734C1.95688 67.4381 1.76448 70.3858 2.91029 71.829C3.40011 72.4587 4.65959 72.9311 7.8434 71.6453H7.95711C8.7618 70.6394 15.8291 59.1462 20.5874 51.3179L17.7359 48.8426L1.95688 66.7734Z'
          fill='#263238'
        />
      </g>
      <defs>
        <clipPath id='clip0_203_1101'>
          <rect width='115' height='100' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
