import { CartIcon } from '../assets/icons/CartIcon';
import { NewspaperIcon } from '../assets/icons/NewspaperIcon';
import { StartIcon } from '../assets/icons/StartIcon';
import { WindowIcon } from '../assets/icons/WindowIcon';

export const PRICES_LIST = [
  {
    id: 1,
    icon: <WindowIcon />,
    price: '$1,000-$1,500',
    text: 'information website or landing page (design included)',
  },
  {
    id: 2,
    icon: <NewspaperIcon />,
    price: '$1500-$2000',
    text: 'catalog website without a shopping cart (design included)',
  },
  {
    id: 3,
    icon: <CartIcon />,
    price: '$2,000+',
    text: 'online store with a shopping cart (online payment, integration with various services, CRM, marketplaces, IP telephony)',
  },
  {
    id: 4,
    icon: <StartIcon />,
    price: '$3,000+',
    text: 'any complexity mobile app for various areas: E-commerce, Healthcare, Edtech, Fintech, etc., Startups projects with continuous development (CI/CD)',
  },
];
