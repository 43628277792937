export const EyeIcon = ({ color = '#5A32FB', width = 40, height = 40 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M20.0003 15C21.3264 15 22.5982 15.5268 23.5359 16.4645C24.4735 17.4021 25.0003 18.6739 25.0003 20C25.0003 21.3261 24.4735 22.5979 23.5359 23.5355C22.5982 24.4732 21.3264 25 20.0003 25C18.6742 25 17.4025 24.4732 16.4648 23.5355C15.5271 22.5979 15.0003 21.3261 15.0003 20C15.0003 18.6739 15.5271 17.4021 16.4648 16.4645C17.4025 15.5268 18.6742 15 20.0003 15ZM20.0003 7.5C28.3337 7.5 35.4503 12.6833 38.3337 20C35.4503 27.3167 28.3337 32.5 20.0003 32.5C11.667 32.5 4.55033 27.3167 1.66699 20C4.55033 12.6833 11.667 7.5 20.0003 7.5ZM5.30033 20C6.64742 22.7505 8.73917 25.0679 11.3378 26.6888C13.9364 28.3096 16.9377 29.1689 20.0003 29.1689C23.063 29.1689 26.0642 28.3096 28.6629 26.6888C31.2615 25.0679 33.3532 22.7505 34.7003 20C33.3532 17.2495 31.2615 14.9321 28.6629 13.3112C26.0642 11.6904 23.063 10.8311 20.0003 10.8311C16.9377 10.8311 13.9364 11.6904 11.3378 13.3112C8.73917 14.9321 6.64742 17.2495 5.30033 20Z'
        fill={color}
      />
    </svg>
  );
};
