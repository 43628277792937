export const WindowIcon = ({ color = '#151515', width = 42, height = 42 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 42 42'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M35 5.25C35.966 5.25 36.75 6.034 36.75 7V35C36.75 35.966 35.966 36.75 35 36.75H7C6.034 36.75 5.25 35.966 5.25 35L5.25 7C5.25 6.034 6.034 5.25 7 5.25L35 5.25ZM19.5808 23.0265L8.75 24.9375V33.25H21.3815L19.579 23.0265H19.5808ZM33.25 8.75H20.6168L24.9357 33.25H33.25V8.75ZM17.0625 8.75H8.75V21.3815L18.9735 19.579L17.0625 8.75Z'
        fill={color}
      />
    </svg>
  );
};
