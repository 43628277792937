import Typography from '@mui/material/Typography';

import { ADVANTAGES_LIST } from '../../MOCK';
import Container from '../Container';
import AdvantagesItems from './AdvantagesItems';
import { AdvantagesContent, AdvantagesSubTitle, StyledAdvantages } from './styles';

const Advantages = ({ currentSlideNumber }) => {
  return (
    <StyledAdvantages>
      <Container direction='column'>
        <Typography variant='h2'>Why are we all here?</Typography>
        <AdvantagesSubTitle variant='subtitle2'>
          We'll help you earn more money faster
        </AdvantagesSubTitle>
        <AdvantagesContent>
          {ADVANTAGES_LIST.map((item, index) => {
            return (
              <AdvantagesItems
                key={item.id}
                item={item}
                odd={index % 2 === 0}
                currentSlideNumber={currentSlideNumber}
              />
            );
          })}
        </AdvantagesContent>
      </Container>
    </StyledAdvantages>
  );
};

export default Advantages;
