import Box from '@mui/material/Box';
import { styled } from '@mui/system';
import { motion } from 'framer-motion';

import theme from '../../theme';

export const StyledWhyMalevich = styled(Box)({
  position: 'relative',

  '& .title': {
    marginTop: '64px',
  },

  '@media (min-width: 1201px) and (min-height: 753px)': {
    marginTop: '84px',
  },
  '@media (min-width: 1201px) and (max-height: 853px)': {
    '& .title': {
      marginTop: '0px',
    },
  },
  [theme.breakpoints.down('xl')]: {
    '& .title': {
      marginTop: '98px',
    },
  },

  [theme.breakpoints.down('lg')]: {
    '& .title': {
      marginTop: '118px',
    },
  },
});

export const WhyMalevichContent = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  margin: '10px 0 0px',

  [theme.breakpoints.down('xl')]: {
    flexDirection: 'column',
    margin: '32px 0',
  },
});

export const WhyMalevichItem = styled(motion.div)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  maxWidth: '340px',
  width: '100%',
  height: '275px',
  padding: '16px 24px',

  '& svg': {
    marginTop: '24px',
  },

  [theme.breakpoints.down('xl')]: {
    maxWidth: '100%',
    height: 'auto',
    marginBottom: '32px',
    padding: '16px 0',

    '& svg': {
      marginTop: '8px',
    },

    '&:last-of-type': {
      marginBottom: 0,
    },
  },

  [theme.breakpoints.down('xs')]: {
    padding: '16px 0',
  },
});

export const WhyMalevichImage = styled('img')({
  height: '315px',
  width: 'max-content',
  display: 'block',
  margin: '0px auto',
  '@media (min-width: 1201px) and (min-height: 753px)': {
    height: '486px',
  },
  '@media (min-width: 1201px) and (max-height: 853px)': {
    height: '330px',
  },
  [theme.breakpoints.down('xl')]: {
    height: 'unset',
    width: '100%',
  },
  [theme.breakpoints.down('sm')]: {
    width: 'calc(100% + 32px)',
    marginLeft: '-16px',
  },
});
