import React from 'react';

export const PriceTagIcon = () => {
  return (
    <svg width='42' height='42' viewBox='0 0 42 42' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M19.0747 3.67505L36.398 6.1513L38.8725 23.4763L22.7865 39.5623C22.4583 39.8904 22.0133 40.0747 21.5492 40.0747C21.0852 40.0747 20.6402 39.8904 20.312 39.5623L2.98699 22.2373C2.65891 21.9091 2.47461 21.4641 2.47461 21C2.47461 20.536 2.65891 20.091 2.98699 19.7628L19.0747 3.67505ZM20.312 7.38855L6.69874 21L21.5492 35.8488L35.1607 22.2373L33.3057 9.24355L20.312 7.38855ZM24.022 18.5255C23.3655 17.8688 22.9967 16.9782 22.9969 16.0496C22.997 15.5898 23.0876 15.1345 23.2637 14.7097C23.4397 14.2849 23.6977 13.899 24.0229 13.5739C24.348 13.2489 24.7341 12.991 25.1589 12.8151C25.5837 12.6392 26.0391 12.5488 26.4989 12.5488C27.4275 12.549 28.318 12.9181 28.9745 13.5748C29.631 14.2315 29.9997 15.1222 29.9996 16.0508C29.9994 16.9794 29.6304 17.8699 28.9736 18.5264C28.3169 19.1829 27.4262 19.5517 26.4976 19.5515C25.569 19.5513 24.6785 19.1823 24.022 18.5255Z'
        fill='#151515'
      />
    </svg>
  );
};
