import palette from '../../theme/palette';

export const MysqlIcon = ({ color = palette.amethystSmoke, width = 39, height = 77 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 39 77'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M34.6889 18.3968C34.7014 18.3968 34.7014 18.3843 34.7139 18.3843C35.3629 18.1347 35.987 17.8601 36.611 17.5605V21.592C36.5861 21.6294 36.4862 21.8541 36.0619 22.241C35.4877 22.7528 34.4643 23.3893 32.8167 24.0383C28.4733 25.561 23.8926 26.2974 19.287 26.2101C19.2621 26.2101 19.2496 26.2101 19.2246 26.2101C14.619 26.2974 10.0384 25.5735 5.69491 24.0508C4.04738 23.4018 3.0364 22.7652 2.46226 22.2535C2.02542 21.8666 1.92557 21.6419 1.91309 21.6045V17.5855C2.52467 17.8975 3.16121 18.1721 3.81024 18.4093C3.82272 18.4093 3.8352 18.4218 3.8352 18.4218C8.77779 20.1941 14.0074 21.0553 19.2621 20.9679C24.5167 21.0553 29.7463 20.1816 34.6889 18.4093V18.3968Z'
        stroke={color}
        strokeWidth='3'
        strokeMiterlimit='22.9256'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M36.611 43.1221V45.9928C36.611 46.4421 36.3864 47.0412 35.6125 47.7526C34.8387 48.4641 33.653 49.1755 32.0429 49.7996C28.8477 51.0477 24.3295 51.859 19.2621 51.859C14.1947 51.859 9.67644 51.0477 6.48123 49.7996C4.87115 49.1755 3.68543 48.4641 2.91159 47.7526C2.15023 47.0412 1.91309 46.4546 1.91309 45.9928V43.1221C2.18767 43.2469 2.46226 43.3592 2.74933 43.4715H2.76181C2.78678 43.484 2.81174 43.4965 2.84918 43.509C2.86166 43.509 2.87414 43.5215 2.88663 43.5215C2.93655 43.5464 3.01144 43.5714 3.08633 43.5964L3.09881 43.6088C8.29102 45.4561 13.7578 46.3547 19.2745 46.2549C24.7788 46.3422 30.2581 45.4561 35.4378 43.6088C35.4628 43.5964 35.4752 43.5964 35.5002 43.5839L35.7998 43.4715C36.0743 43.3592 36.3489 43.2469 36.6235 43.1221H36.611Z'
        stroke={color}
        strokeWidth='3'
        strokeMiterlimit='22.9256'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M36.6106 7.72509C36.6106 8.17441 36.3859 8.77352 35.6121 9.48495C34.8383 10.1964 33.6525 10.9078 32.0425 11.5319C28.8473 12.78 24.329 13.5913 19.2492 13.5913C14.1818 13.5913 9.66353 12.78 6.45584 11.5319C4.85824 10.9078 3.66004 10.1964 2.8862 9.48495C2.12484 8.77352 1.8877 8.18689 1.8877 7.72509C1.8877 7.27576 2.11236 6.67666 2.8862 5.96523C3.66004 5.25379 4.84576 4.54236 6.45584 3.9183C9.65105 2.67017 14.1693 1.85889 19.2492 1.85889C24.3166 1.85889 28.8348 2.67017 32.0425 3.9183C33.6401 4.54236 34.8383 5.25379 35.6121 5.96523C36.3735 6.67666 36.6106 7.26328 36.6106 7.72509V7.72509Z'
        stroke={color}
        strokeWidth='3'
        strokeMiterlimit='22.9256'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M34.6889 31.1649C34.7014 31.1649 34.7014 31.1524 34.7139 31.1524C35.3629 30.9028 35.987 30.6282 36.611 30.3286V34.3601C36.5861 34.3975 36.4862 34.6222 36.0619 35.0091C35.4877 35.5208 34.4643 36.1574 32.8167 36.8064C28.4733 38.3291 23.8926 39.0655 19.287 38.9781C19.2621 38.9781 19.2496 38.9781 19.2246 38.9781C14.619 39.0655 10.0384 38.3416 5.69491 36.8189C4.04738 36.1699 3.0364 35.5333 2.46226 35.0216C2.02542 34.6347 1.92557 34.41 1.91309 34.3725V30.3536C2.52467 30.6656 3.16121 30.9402 3.81024 31.1773C3.82272 31.1773 3.8352 31.1898 3.8352 31.1898C8.77779 32.9622 14.0074 33.8234 19.2621 33.736C24.5167 33.8234 29.7463 32.9497 34.6889 31.1773V31.1649Z'
        stroke={color}
        strokeWidth='3'
        strokeMiterlimit='22.9256'
        strokeLinejoin='round'
      />
      <path
        d='M9.1669 67.9689V75.6469H7.6269V70.6529L5.5699 75.6469H4.4039L2.3359 70.6529V75.6469H0.795898V67.9689H2.5449L4.9869 73.6779L7.4289 67.9689H9.1669Z'
        fill={color}
      />
      <path
        d='M16.9445 67.9689L14.3485 72.9739V75.6469H12.8085V72.9739L10.2015 67.9689H11.9395L13.5895 71.4779L15.2285 67.9689H16.9445Z'
        fill={color}
      />
      <path
        d='M20.6336 75.7239C20.0983 75.7239 19.6143 75.6322 19.1816 75.4489C18.7563 75.2656 18.4189 75.0016 18.1696 74.6569C17.9203 74.3122 17.792 73.9052 17.7846 73.4359H19.4346C19.4566 73.7512 19.5666 74.0006 19.7646 74.1839C19.9699 74.3672 20.2486 74.4589 20.6006 74.4589C20.9599 74.4589 21.2423 74.3746 21.4476 74.2059C21.653 74.0299 21.7556 73.8026 21.7556 73.5239C21.7556 73.2966 21.6859 73.1096 21.5466 72.9629C21.4073 72.8162 21.2313 72.7026 21.0186 72.6219C20.8133 72.5339 20.5273 72.4386 20.1606 72.3359C19.6619 72.1892 19.255 72.0462 18.9396 71.9069C18.6316 71.7602 18.3639 71.5439 18.1366 71.2579C17.9166 70.9646 17.8066 70.5759 17.8066 70.0919C17.8066 69.6372 17.9203 69.2412 18.1476 68.9039C18.375 68.5666 18.6939 68.3099 19.1046 68.1339C19.5153 67.9506 19.9846 67.8589 20.5126 67.8589C21.3046 67.8589 21.9463 68.0532 22.4376 68.4419C22.9363 68.8232 23.2113 69.3586 23.2626 70.0479H21.5686C21.5539 69.7839 21.4403 69.5676 21.2276 69.3989C21.0223 69.2229 20.7473 69.1349 20.4026 69.1349C20.1019 69.1349 19.8599 69.2119 19.6766 69.3659C19.5006 69.5199 19.4126 69.7436 19.4126 70.0369C19.4126 70.2422 19.4786 70.4146 19.6106 70.5539C19.75 70.6859 19.9186 70.7959 20.1166 70.8839C20.322 70.9646 20.608 71.0599 20.9746 71.1699C21.4733 71.3166 21.8803 71.4632 22.1956 71.6099C22.511 71.7566 22.7823 71.9766 23.0096 72.2699C23.2369 72.5632 23.3506 72.9482 23.3506 73.4249C23.3506 73.8356 23.2443 74.2169 23.0316 74.5689C22.8189 74.9209 22.5073 75.2032 22.0966 75.4159C21.6859 75.6212 21.1983 75.7239 20.6336 75.7239Z'
        fill={color}
      />
      <path
        d='M30.5557 76.9999L29.3897 75.6029C29.0671 75.6836 28.7371 75.7239 28.3997 75.7239C27.6811 75.7239 27.0211 75.5552 26.4197 75.2179C25.8184 74.8806 25.3417 74.4149 24.9897 73.8209C24.6377 73.2196 24.4617 72.5412 24.4617 71.7859C24.4617 71.0379 24.6377 70.3669 24.9897 69.7729C25.3417 69.1716 25.8184 68.7022 26.4197 68.3649C27.0211 68.0276 27.6811 67.8589 28.3997 67.8589C29.1257 67.8589 29.7857 68.0276 30.3797 68.3649C30.9811 68.7022 31.4541 69.1716 31.7987 69.7729C32.1507 70.3669 32.3267 71.0379 32.3267 71.7859C32.3267 72.4679 32.1801 73.0912 31.8867 73.6559C31.6007 74.2132 31.2084 74.6679 30.7097 75.0199L32.4697 76.9999H30.5557ZM26.0457 71.7859C26.0457 72.2992 26.1447 72.7502 26.3427 73.1389C26.5407 73.5276 26.8157 73.8282 27.1677 74.0409C27.5271 74.2462 27.9377 74.3489 28.3997 74.3489C28.8617 74.3489 29.2687 74.2462 29.6207 74.0409C29.9727 73.8282 30.2477 73.5276 30.4457 73.1389C30.6437 72.7502 30.7427 72.2992 30.7427 71.7859C30.7427 71.2726 30.6437 70.8252 30.4457 70.4439C30.2477 70.0552 29.9727 69.7582 29.6207 69.5529C29.2687 69.3476 28.8617 69.2449 28.3997 69.2449C27.9377 69.2449 27.5271 69.3476 27.1677 69.5529C26.8157 69.7582 26.5407 70.0552 26.3427 70.4439C26.1447 70.8252 26.0457 71.2726 26.0457 71.7859Z'
        fill={color}
      />
      <path d='M35.1839 74.4259H37.7139V75.6469H33.6439V67.9689H35.1839V74.4259Z' fill={color} />
    </svg>
  );
};
