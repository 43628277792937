import { useEffect, useState } from 'react';

import { throttle } from './utils';

let ticking = false;
const isFirefox = /firefox/i.test(navigator.userAgent);
const isIe = /msie/i.test(navigator.userAgent) || /trident.*rv:11\./i.test(navigator.userAgent);
const scrollSensitivitySetting = 30;
const slideDurationSetting = 800;

const slideDurationTimeout = (slideDuration) => {
  setTimeout(() => {
    ticking = false;
  }, slideDuration);
};

const currentSlideTransition = ({ currentSlide }) => {
  const $currentSlide = document.querySelectorAll('.slide-background')[currentSlide];
  $currentSlide.style.transform = 'translate3d(0,-15vh,0)';
};

const previousItem = ({ currentSlide }) => {
  const $previousSlide = document.querySelectorAll('.slide-background')[currentSlide + 1];
  $previousSlide.style.transform = 'translate3d(0,30vh,0)';
  const $longArrow = document.querySelector('.long-vertical-arrow');
  $longArrow.style.height = '115vh';
  currentSlideTransition({
    currentSlide,
  });
};

const nextItem = ({ currentSlide }) => {
  const $previousSlide = document.querySelectorAll('.slide-background')[currentSlide - 1];
  $previousSlide.style.transform = 'translate3d(0,-130vh,0)';
  const $previewHeaderShadow = document.querySelector('.slide-background-translate');
  $previewHeaderShadow.style.boxShadow = 'none';
  currentSlideTransition({
    currentSlide,
  });
};

const skipToItem = ({ targetSlide }) => {
  const slideList = [...document.querySelectorAll('.slide-background')];
  const $previousSlides = slideList.slice(0, targetSlide);

  for (const previous of $previousSlides) {
    previous.style.transform = 'translate3d(0,-130vh,0)';
  }
  const $previewHeaderShadow = document.querySelector('.slide-background-translate');
  $previewHeaderShadow.style.boxShadow = 'none';
  currentSlideTransition({
    currentSlide: targetSlide,
  });
};

const parallaxScroll =
  ({ updateCurrentSlide, currentSlide }) =>
  (event_) => {
    let delta;
    if (isFirefox) {
      delta = event_.detail * -120;
    } else if (isIe) {
      delta = -event_.deltaY;
    } else {
      delta = event_.wheelDelta;
    }

    if (ticking !== true) {
      if (delta <= -scrollSensitivitySetting) {
        ticking = true;
        if (currentSlide !== document.querySelectorAll('.slide-background')?.length - 1) {
          updateCurrentSlide((previous) => previous + 1);
          nextItem({
            currentSlide: currentSlide + 1,
          });
        }
        slideDurationTimeout(slideDurationSetting);
      }
      if (delta >= scrollSensitivitySetting) {
        ticking = true;
        if (currentSlide !== 0) {
          updateCurrentSlide((previous) => previous - 1);
          previousItem({
            currentSlide: currentSlide - 1,
          });
        }
        slideDurationTimeout(slideDurationSetting);
      }
    }
  };

export const useParallaxScroll = ({ skip }) => {
  const [slide, setSlide] = useState(0);
  useEffect(() => {
    const handler = throttle(
      parallaxScroll({
        updateCurrentSlide: setSlide,
        currentSlide: slide,
      }),
      60,
    );
    const mousewheelEvent = isFirefox ? 'DOMMouseScroll' : 'wheel';

    if (!skip) window.addEventListener(mousewheelEvent, handler, false);

    return () => {
      window.removeEventListener(mousewheelEvent, handler, false);
    };
  }, [slide]);

  const parallaxScrollTo = (currentSlide_) => {
    ticking = true;
    setSlide(currentSlide_);
    skipToItem({
      targetSlide: currentSlide_,
    });
    slideDurationTimeout(slideDurationSetting);
  };

  return {
    currentSlide: slide,
    parallaxScrollTo,
  };
};
