import Box from '@mui/material/Box';
import { styled } from '@mui/system';

import theme from '../../theme';

export const StyledTechnologyStack = styled(Box)({
  padding: '64px 0',

  [theme.breakpoints.down('lg')]: {
    padding: 0,
  },

  '@media (min-width: 1201px) and (min-height: 753px)': {
    h2: {
      marginTop: '48px',
    },
  },
});

export const TechnologyStackContent = styled(Box)({
  position: 'relative',
  width: '100%',
  height: '576px',
  marginTop: '40px',

  [theme.breakpoints.down('xl')]: {
    marginTop: '48px',
    overflowX: 'scroll',
    overflowY: 'hidden',
    flexWrap: 'nowrap',
    width: 'auto',
    whiteSpace: 'nowrap',
    scrollbarWidth: 'none',

    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },

  [theme.breakpoints.down('lg')]: {
    marginTop: '32px',
  },
});

export const StyledTechnologyStackItem = styled(Box)({
  position: 'absolute',
  top: '24px',
  left: 0,

  '&:hover': {
    '& svg': {
      '& rect': {
        fill: 'none',
        mixBlendMode: 'unset',
      },
    },
  },

  '&:nth-of-type(2)': {
    top: '44px',
    left: '275px',
  },

  '&:nth-of-type(3)': {
    top: '22px',
    left: '511px',
  },

  '&:nth-of-type(4)': {
    top: '4px',
    left: '667px',
  },

  '&:nth-of-type(5)': {
    top: '29px',
    left: '932px',
  },

  '&:nth-of-type(6)': {
    top: '135px',
    left: '131px',
  },

  '&:nth-of-type(7)': {
    top: '151px',
    left: '420px',
  },

  '&:nth-of-type(8)': {
    top: '171px',
    left: '527px',
  },

  '&:nth-of-type(9)': {
    top: '101px',
    left: '723px',
  },

  '&:nth-of-type(10)': {
    top: '129px',
    left: '1067px',
  },

  '&:nth-of-type(11)': {
    top: '218px',
    left: '57px',
  },

  '&:nth-of-type(12)': {
    top: '231px',
    left: '298px',
  },

  '&:nth-of-type(13)': {
    top: '281px',
    left: '514px',
  },

  '&:nth-of-type(14)': {
    top: '187px',
    left: '729px',
  },

  '&:nth-of-type(15)': {
    top: '274px',
    left: '707px',
  },

  '&:nth-of-type(16)': {
    top: '201px',
    left: '900px',
  },

  '&:nth-of-type(17)': {
    top: '299px',
    left: '16px',
  },

  '&:nth-of-type(18)': {
    top: '382px',
    left: '129px',
  },

  '&:nth-of-type(19)': {
    top: '381px',
    left: '448px',
  },

  '&:nth-of-type(20)': {
    top: '358px',
    left: '707px',
  },

  '&:nth-of-type(21)': {
    top: '337px',
    left: '877px',
  },

  '&:nth-of-type(22)': {
    top: '493px',
    left: '43px',
  },

  '&:nth-of-type(23)': {
    top: '494px',
    left: '219px',
  },

  '&:nth-of-type(24)': {
    top: '451px',
    left: '343px',
  },

  '&:nth-of-type(25)': {
    top: '476px',
    left: '554px',
  },

  '&:nth-of-type(26)': {
    top: '411px',
    left: '738px',
  },

  '&:nth-of-type(27)': {
    top: '525px',
    left: '692px',
  },

  '&:nth-of-type(28)': {
    top: '472px',
    left: '943px',
  },
});

export const TechnologyStackInfo = styled(Box)({
  display: 'none',

  [theme.breakpoints.down('xl')]: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '16px 0',

    '& .firstArrowIcon': {
      transform: 'rotate(180deg)',
    },

    '& .cursorIcon': {
      margin: '0 12px',
    },
  },

  '@media (max-width: 375px)': {
    '& .firstArrowIcon svg, & .secondArrowIcon svg': {
      width: '100%',
    },

    '& .cursorIcon': {
      margin: '0 6px',
    },
  },
});
