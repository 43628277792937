import { Link } from '@mui/material';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';

import theme from '../../theme';
import palette from '../../theme/palette';

export const AchieveGoalsWrapper = styled(Box)({
  position: 'relative',
  marginBottom: '68px',
  marginTop: '200px',

  '@media (min-width: 1201px) and (min-height: 753px)': {
    overflowY: 'scroll',
    scrollbarColor: 'transparent transparent',
    scrollbarWidth: 'thin',

    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px transparent',
      borderRadius: '3px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'transparent',
      borderRadius: '3px',
    },
  },
  [theme.breakpoints.down('xl')]: {
    marginTop: '0px',
    marginBottom: '0px',
  },
});

export const AchieveGoalsTitle = styled(Typography)({
  maxWidth: '504px',
  marginBottom: '48px',

  span: {
    color: palette.purpleBlue,
  },

  [theme.breakpoints.down('xl')]: {
    maxWidth: 'unset',
    fontSize: '32px',
    lineHeight: '38px',
    marginBottom: '32px',
  },
});

export const MainContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  zIndex: 1,

  h6: {
    fontWeight: 600,
    lineHeight: '26px',
  },

  svg: {
    margin: '40px 0px',
    [theme.breakpoints.down('xl')]: {
      margin: '32px 0px',
    },
  },

  p: {
    lineHeight: '26px',
    '&.description': {
      marginBottom: '24px',
      '&:last-child': {
        marginBottom: '0px',
      },
    },
  },
  [theme.breakpoints.down('xl')]: {
    flexDirection: 'column',

    h6: {
      fontSize: '20px',
      lineHeight: '26px',
    },
  },
});

export const FirstLeft = styled(Box)({
  maxWidth: '504px',
  position: 'sticky',
  top: '72px',
  height: '856px',
  [theme.breakpoints.down('xl')]: {
    maxWidth: 'unset',
    position: 'relative',
    top: 'unset',
    height: 'unset',
  },
});

export const FirstRight = styled(List)({
  maxWidth: '580px',
  width: '100%',
  marginTop: '899px',
  [theme.breakpoints.down('xl')]: {
    maxWidth: 'unset',
    marginTop: '40px',
  },
});
export const FirstRightList = styled(List)({
  padding: '0px',
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(265px, 1fr))',
  gap: '40px 50px',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  marginTop: '40px',
  '& .MuiListItem-root': {
    maxWidth: '265px',
    width: '100%',
    padding: 0,
  },
  [theme.breakpoints.down('xl')]: {
    marginTop: '24px',
    gridTemplateColumns: '1fr',
    gap: '24px 50px',
    padding: '0px',
    '& .MuiListItem-root': {
      maxWidth: 'unset',
    },
  },
});

export const SecondRight = styled(Box)({
  maxWidth: '504px',
  position: 'sticky',
  top: '72px',
  height: '404px',
  marginTop: '125px',
  [theme.breakpoints.down('xl')]: {
    maxWidth: 'unset',
    position: 'relative',
    top: 'unset',
    marginTop: '48px',
    height: 'unset',
  },
});

export const SecondLeft = styled(Box)({
  marginTop: '552px',
  [theme.breakpoints.down('xl')]: {
    marginTop: '32px',
  },
});

export const SecondList = styled(List)({
  maxWidth: '580px',
  width: '100%',
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(265px, 1fr))',
  gap: '40px 50px',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  '& .MuiListItem-root': {
    maxWidth: '265px',
    width: '100%',
    padding: 0,
  },
  [theme.breakpoints.down('xl')]: {
    maxWidth: 'unset',
    gridTemplateColumns: '1fr',
    gap: '24px 50px',
    padding: '0px',
    '& .MuiListItem-root': {
      maxWidth: 'unset',
    },
  },
});

export const PriceBadge = styled(Box)({
  fontSize: '48px',
  fontWeight: 500,
  lineHeight: '72px',
  letterSpacing: '-0.02em',
  color: '#ffffff',
  width: '241px',
  height: '88px',
  borderRadius: '12px',
  backgroundColor: palette.purpleBlue,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '106px 0px 32px 0px',
  [theme.breakpoints.down('xl')]: {
    marginTop: '56px',
  },
});

export const SourceWrapper = styled(Box)({
  fontSize: '14px',
  fontWeight: 300,
  lineHeight: '26px',
  letterSpacing: '0.15px',
  display: 'flex',
  alignItems: 'center',
  '& a': {
    fontWeight: 400,
    color: palette.purpleBlue,
    marginLeft: '6px',
  },
});

export const SecondDescriptionWrapper = styled(Box)({
  maxWidth: '460px',
  marginBottom: '24px',
  [theme.breakpoints.down('xl')]: {
    maxWidth: 'unset',
  },
});

export const LastLeft = styled(Box)({
  maxWidth: '454px',
  position: 'sticky',
  top: '72px',
  height: '808px',
  marginTop: '112px',
  [theme.breakpoints.down('xl')]: {
    maxWidth: 'unset',
    position: 'relative',
    top: 'unset',
    height: 'unset',
    marginTop: '48px',
  },
});

export const MobileFirstWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  fontSize: '64px',
  fontWeight: 700,
  lineHeight: '80px',
  letterSpacing: '0.15px',
  marginBottom: '40px',
  [theme.breakpoints.down('xl')]: {
    marginBottom: '0px',
    fontSize: '48px',
    lineHeight: '64px',
  },
});

export const LastRight = styled(Box)({
  maxWidth: '680px',
  marginTop: '960px',

  img: {
    margin: '16px 0px',
    width: '100%',
  },
  [theme.breakpoints.down('xl')]: {
    maxWidth: 'unset',
    marginTop: '40px',
  },
});

export const AchieveGoalsBottomMenu = styled(Box)({
  display: 'none',
  [theme.breakpoints.down('lg')]: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'sticky',
    bottom: '0px',
    zIndex: 1,
    height: '54px',
    borderTop: '1px solid #9A9AAF',
    background: palette.secondary,
    '& .active': {
      backgroundColor: palette.purpleBlue,
      color: palette.secondary,
    },
  },
});

export const BottomMenuContent = styled(Link)({
  fontSize: '18px',
  fontWeight: 600,
  lineHeight: '38px',
  letterSpacing: '-0.02em',
  color: palette.primary,
  height: '54px',
  minWidth: '75.5px',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '8px',
});
