import {
  BarChartIcon,
  BitCoinIcon,
  BrushIcon,
  CodeSlashIcon,
  PriceTagIcon,
  SmartphoneIcon,
} from '../assets/icons';

export const OUR_EXPERTISE = [
  {
    id: 1,
    title: 'Web Development',
    description: [
      'Online stores',
      'Corporate Websites',
      'B2B solutions',
      'Marketplaces',
      'Services for specific niches',
      'Edtech product (educational applications, electronic books and readers, chats with tasks, conference video application)',
    ],
    Icon: CodeSlashIcon,
  },
  {
    id: 2,
    title: 'Enterprise Software, ERP, CRM',
    description: [
      'Custom CRM systems',
      'Mobile version of the CRM system with API connectionM',
      'Task manager',
      'Dashboards',
      'Survey constructors',
      'Data transfer between CRM systems',
    ],
    Icon: BarChartIcon,
  },
  {
    id: 3,
    title: 'E-commerce',
    description: [
      'Online stores from scratch development',
      'Existing online stores redesign',
      'Development of personal areas for b2c and b2b clients',
      'Telegram bots development',
      'Services for parcels sending and tracking automation',
      'CRM and payment systems integration for your site',
      'WMS (Warehouse Management System) integration',
    ],
    Icon: PriceTagIcon,
  },
  {
    id: 4,
    title: 'Mobile Development',
    description: ['Android App', 'iOS App', 'Cross Platform App'],
    Icon: SmartphoneIcon,
  },
  {
    id: 5,
    title: 'UX/UI Design',
    description: [
      'Product and service catalog design',
      'Existing web product UI/UX audit',
      'UI/UX redesign',
      'Corporate identity',
      'MVP (Minimum Viable Product)',
    ],
    Icon: BrushIcon,
  },
  {
    id: 6,
    title: 'Blockchain',
    description: [
      'NFT-related projects',
      'Smart contracts development',
      'Exchangers development (cryptocurrency/fiat)',
      'Crypto exchange development',
    ],
    Icon: BitCoinIcon,
  },
  {
    id: 7,
    title: 'DevOps',
    description: [
      'Continue Integration',
      'Continue Development configuration',
      'Kubernetes',
      'Docker Swarm',
      'Digital Ocean',
      'Amazone Web Services',
      'Cloudflare',
    ],
    Icon: SmartphoneIcon,
  },
];
