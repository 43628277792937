export const TechnologyTypescriptIcon = ({ width = 56, height = 56 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 56 56'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g>
        <rect width='56' height='56' fill='white' />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M0 0V28V56H56V0H0ZM12.2783 30.3799V28.0979L12.3203 28.1119V25.8159L22.1203 25.7739C27.5103 25.7739 31.9483 25.7879 31.9483 25.8299C32.0043 25.8579 32.0043 26.8799 32.0043 28.1399V30.3799H24.7243V51.0999H19.5583V30.3799H12.2783ZM48.4963 27.7619C47.5163 26.7399 46.4243 26.0959 44.9963 25.7599L45.0243 25.7319C44.0583 25.4799 41.7483 25.3959 40.7683 25.5919C37.7443 26.1519 35.6303 28.1119 35.0283 30.9119C34.8323 31.7099 34.9023 33.6979 35.1123 34.5099C35.3923 35.4479 35.9943 36.5819 36.6523 37.3099C37.8003 38.5139 39.0323 39.2979 41.9303 40.5299C44.4503 41.6499 45.3463 42.1539 45.7943 42.7699C46.1163 43.2599 46.2143 43.5539 46.2143 44.1979C46.2143 44.8979 45.9903 45.4019 45.4723 45.8779C44.2683 46.9699 41.8323 47.0959 40.0123 46.1579C39.4103 45.8219 38.3743 44.8419 37.9123 44.1139L37.5483 43.6239L35.9663 44.5479L33.8663 45.7659L33.3343 46.1019C33.2783 46.1999 34.2723 47.6979 34.7903 48.2579C36.1063 49.6999 38.2483 50.8199 40.4883 51.2399C41.5383 51.4219 43.7783 51.4499 44.7583 51.2819C47.8803 50.7639 50.0643 49.1819 50.9603 46.8439C51.7583 44.7019 51.4923 41.8459 50.3023 40.0259C49.2523 38.4019 47.5163 37.2679 43.5123 35.5459C41.3423 34.5939 40.6423 34.1319 40.2643 33.3619C40.0963 32.9979 40.0123 32.7459 40.0123 32.2979C40.0123 30.8279 41.1323 29.9459 42.8123 30.0579C43.9743 30.1419 44.7163 30.5899 45.4443 31.6259C45.6683 31.9899 45.8923 32.2279 45.9483 32.1859C47.4183 31.2759 49.8403 29.6379 49.8403 29.5539C49.7843 29.3019 49.0143 28.3219 48.4963 27.7619Z'
          fill='#407BFF'
        />
        <rect
          width='56'
          height='56'
          fill='#5A32FB'
          style={{
            mixBlendMode: 'color',
          }}
        />
      </g>
      <defs>
        <clipPath id='clip0_1036_212'>
          <rect width='56' height='56' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
