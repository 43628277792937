export const WHY_MALEVICH_LIST = [
  {
    id: 1,
    text: 'We are a team of proactive developers from Ukraine. For more than 10 years, we have been working on the highly competitive development market in Ukraine and the world.',
  },
  {
    id: 2,
    text: 'We do not promote projects. We build partnership relations with you exclusively in matters of website and application development.',
  },
  {
    id: 3,
    text: 'Our office has all backup sources. Despite the difficult situation in the country, we complete all projects on time. Our projects and our faith in victory are the foundation on which we hold firm.',
  },
];
