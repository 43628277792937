import * as yup from 'yup';

export const messages = {
  PROMPT_MAIL: 'Enter your email',
  REQUIRE_MESSAGE: 'Required field',
  INCORRECT_SYMBOL: 'Forbidden symbol',
};

export const schema = yup.object().shape({
  name: yup.string(messages.INCORRECT_SYMBOL),
  email: yup
    .string(messages.PROMPT_MAIL)
    .email(messages.PROMPT_MAIL)
    .required(messages.REQUIRE_MESSAGE),
  detail: yup.string(messages.INCORRECT_SYMBOL),
  services: yup.array().of(yup.string()),
});
