import Typography from '@mui/material/Typography';
import useBreakpoint from 'use-breakpoint';

import { BREAKPOINTS } from '../../constants';
import { OUR_TEAM_LIST } from '../../MOCK';
import Container from '../Container';
import {
  OurTeamIcon,
  OurTeamItem,
  OurTeamTitle,
  OutTeamInner,
  OutTeamWrapper,
  StyledOurTeam,
} from './styles';

const OurTeam = ({ currentSlideNumber }) => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  const isTablet = ['xxxs', 'xxs', 'xs', 'sm', 'md', 'lg'].includes(breakpoint);

  return (
    <Container>
      <StyledOurTeam>
        <Typography variant='h2'>Our team for each project:</Typography>
        <OutTeamWrapper>
          <OutTeamInner
            initial={{
              y: !isTablet ? '-50%' : '0%',
              width: !isTablet ? '400px' : '100%',
              scale: !isTablet ? 0 : 1,
            }}
            animate={{
              ...(currentSlideNumber === 6 && {
                justifyContent: 'space-between',
                y: '0%',
                width: '100%',
                scale: 1,
              }),
            }}
            transition={{
              type: 'tween',
              duration: 2,
            }}
            viewport={{
              once: true,
              amount: 1,
            }}
          >
            {OUR_TEAM_LIST.map((item) => {
              return (
                <OurTeamItem key={item.id}>
                  <OurTeamIcon>{item.icon}</OurTeamIcon>
                  <OurTeamTitle variant='body1'>{item.title}</OurTeamTitle>
                </OurTeamItem>
              );
            })}
          </OutTeamInner>
        </OutTeamWrapper>
      </StyledOurTeam>
    </Container>
  );
};

export default OurTeam;
