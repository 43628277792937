import React from 'react';

export const BrushIcon = () => {
  return (
    <svg width='42' height='42' viewBox='0 0 42 42' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M15.75 8.75V19.7277H12.25V8.75H8.75V24.5H33.25V8.75H15.75ZM35 28H7L7 31.5H35V28ZM5.25 24.5V7C5.25 6.53587 5.43437 6.09075 5.76256 5.76256C6.09075 5.43437 6.53587 5.25 7 5.25L35 5.25C35.4641 5.25 35.9092 5.43437 36.2374 5.76256C36.5656 6.09075 36.75 6.53587 36.75 7V24.5H38.5V33.25C38.5 33.7141 38.3156 34.1593 37.9874 34.4874C37.6592 34.8156 37.2141 35 36.75 35H22.75V40.25H19.25V35H5.25C4.78587 35 4.34075 34.8156 4.01256 34.4874C3.68437 34.1593 3.5 33.7141 3.5 33.25L3.5 24.5H5.25Z'
        fill='#151515'
      />
    </svg>
  );
};
