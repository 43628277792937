import { ThemeProvider } from '@emotion/react';
import { CssBaseline } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';
import useBreakpoint from 'use-breakpoint';

import { BREAKPOINTS } from './constants';
import { useParallaxScroll } from './hooks/slider-parallax';
import Layout from './layout';
import theme from './theme';

const App = () => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  const isTablet = ['xxxs', 'xxs', 'xs', 'sm', 'md', 'lg'].includes(breakpoint);

  const skip = !!isTablet;

  const { currentSlide: currentSlideNumber, parallaxScrollTo } = useParallaxScroll({
    skip,
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Layout currentSlideNumber={currentSlideNumber} parallaxScrollTo={parallaxScrollTo} />
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
