import React from 'react';

export const StatisticsPwaMobIcon = () => {
  return (
    <svg
      width='375'
      height='361'
      viewBox='0 0 375 361'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect opacity='0.4' x='24' y='278' width='41.1667' height='32' fill='#5A32FB' />
      <rect opacity='0.5' x='81.1665' y='238' width='41.1667' height='72' fill='#5A32FB' />
      <rect opacity='0.6' x='138.333' y='182' width='41.1667' height='128' fill='#5A32FB' />
      <rect opacity='0.7' x='195.5' y='142' width='41.1667' height='168' fill='#5A32FB' />
      <rect opacity='0.8' x='252.667' y='102' width='41.1667' height='208' fill='#5A32FB' />
      <rect x='309.833' y='22' width='41.1667' height='288' fill='#5A32FB' />
      <path
        d='M267.992 0.517709C267.725 0.034049 267.117 -0.141931 266.633 0.124678L258.751 4.46928C258.268 4.73589 258.092 5.34409 258.358 5.82777C258.625 6.31145 259.233 6.48741 259.717 6.2208L266.723 2.35894L270.585 9.36509C270.851 9.84877 271.46 10.0247 271.943 9.75812C272.427 9.49151 272.603 8.88329 272.336 8.39963L267.992 0.517709ZM0.00568734 207.471C61.8224 207.119 121.184 182.016 168.902 143.809C216.616 105.603 252.753 54.2433 268.077 1.27837L266.155 0.722527C250.962 53.2373 215.091 104.263 167.652 142.247C120.216 180.23 61.2783 205.122 -0.00568734 205.471L0.00568734 207.471Z'
        fill='#EF4335'
      />
    </svg>
  );
};
