import { CheckIcon } from '../../assets/icons/CheckIcon';
import { INFRASTRUCTURE_LIST } from '../../MOCK';
import Container from '../Container';
import {
  InfrastructureContent,
  InfrastructureItem,
  InfrastructureItemCheck,
  InfrastructureItemIcon,
  InfrastructureItemTitle,
  InfrastructureTitle,
  StyledInfrastructure,
} from './styles';

const Infrastructure = () => {
  return (
    <Container>
      <StyledInfrastructure>
        <InfrastructureTitle variant='h2'>Malevich infrastructure includes</InfrastructureTitle>
        <InfrastructureContent>
          {INFRASTRUCTURE_LIST.map((item) => {
            return (
              <InfrastructureItem key={item.id}>
                <InfrastructureItemIcon>{item.icon}</InfrastructureItemIcon>
                <InfrastructureItemTitle variant='h4'>{item.title}</InfrastructureItemTitle>
                <InfrastructureItemCheck
                  initial={{
                    opacity: 0,
                  }}
                  whileInView={{
                    opacity: 1,
                  }}
                  transition={{
                    type: 'tween',
                    delay: 0.3,
                    duration: 1,
                    ease: 'easeIn',
                  }}
                  viewport={{
                    once: true,
                    amount: 1,
                  }}
                >
                  <CheckIcon />
                </InfrastructureItemCheck>
              </InfrastructureItem>
            );
          })}
        </InfrastructureContent>
      </StyledInfrastructure>
    </Container>
  );
};

export default Infrastructure;
