import { Typography } from '@mui/material';
import { useInView } from 'framer-motion';
import React, { useRef } from 'react';

import homeAnimation from '../../assets/animations/homeTopAnimation.json';
import { LongArrowIcon } from '../../assets/icons';
import Container from '../Container';
import LottieIcon from '../LottieIcon';
import {
  ArrowContainer,
  DescriptionSection,
  HomeTopAnimation,
  ImgContainer,
  InfoBlockText,
  PreviewHeaderAnimate,
  PreviewHeaderBottom,
  PreviewHeaderContainer,
  PreviewHeaderContent,
  PreviewHeaderWrapper,
} from './styles';

const PreviewHeader = (rest) => {
  const topAnimation = {
    animationData: homeAnimation,
    loop: 0,
    autoplay: true,
  };
  const reference = useRef();

  const isInView = useInView(reference, {
    once: true,
  });
  return (
    <PreviewHeaderContainer {...rest}>
      <Container>
        <PreviewHeaderWrapper>
          <PreviewHeaderBottom
            initial={{
              y: '-100%',
            }}
            animate={{
              y: '0%',
            }}
            transition={{
              type: 'tween',
              duration: 1,
              delay: 2.5,
            }}
            viewport={{
              once: true,
              amount: 1,
            }}
          >
            <ArrowContainer>
              <LongArrowIcon />
              <Typography variant='subtitle2'>explore</Typography>
            </ArrowContainer>
          </PreviewHeaderBottom>
          <PreviewHeaderContent>
            <DescriptionSection>
              <Typography variant='h1'>Malevich</Typography>
              <Typography variant='subtitle2'>
                Scalable and user-centric web solution. Mobile App, Web Development and Business
                Automation
              </Typography>
            </DescriptionSection>
            <ImgContainer>
              <HomeTopAnimation>
                <LottieIcon options={topAnimation} replayInView disableOnHover />
              </HomeTopAnimation>
            </ImgContainer>
          </PreviewHeaderContent>
        </PreviewHeaderWrapper>
        <PreviewHeaderAnimate
          ref={reference}
          initial={{
            scale: 1,
            y: 0,
            x: 0,
          }}
          animate={{
            ...(isInView && {
              y: '-100%',
              x: '-100%',
              scale: 0,
            }),
          }}
          transition={{
            type: 'tween',
            duration: 1.3,
            delay: 1.8,
          }}
          viewport={{
            once: true,
            amount: 1,
          }}
        >
          <Container direction='column'>
            <InfoBlockText
              initial={{
                y: 50,
                opacity: 0,
              }}
              whileInView={{
                y: 0,
                opacity: 1,
              }}
              transition={{
                type: 'tween',
                delay: 0,
                duration: 0.5,
                ease: 'easeIn',
              }}
              viewport={{
                once: true,
                amount: 1,
              }}
            >
              <Typography variant='h2' fontWeight={500}>
                Welcome to
              </Typography>
            </InfoBlockText>
            <InfoBlockText
              initial={{
                y: 50,
                opacity: 0,
              }}
              whileInView={{
                y: 0,
                opacity: 1,
              }}
              transition={{
                type: 'tween',
                delay: 0.6,
                duration: 0.5,
                ease: 'easeIn',
              }}
              viewport={{
                once: true,
                amount: 1,
              }}
            >
              <Typography variant='h1' fontWeight={600}>
                Malevich
              </Typography>
            </InfoBlockText>
          </Container>
        </PreviewHeaderAnimate>
      </Container>
    </PreviewHeaderContainer>
  );
};

export default PreviewHeader;
