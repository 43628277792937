import Box from '@mui/material/Box';
import { styled } from '@mui/system';
import { motion } from 'framer-motion';

import theme from '../../theme';
import palette from '../../theme/palette';

export const PreviewHeaderWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  height: '100vh',
  scrollSnapAlign: 'start',
  '@media (min-width: 1201px) and (min-height: 753px)': {
    height: '130vh',
  },
});

export const ArrowContainer = styled(Box)({
  display: 'flex',
  alignItems: 'flex-end',
  marginRight: '4px',

  h6: {
    transform: 'rotate(-90deg)',
    marginLeft: '-25px',
    marginBottom: '70px',
  },

  [theme.breakpoints.down('md')]: {
    marginRight: '0px',
    position: 'relative',

    h6: {
      maxWidth: '10px',
      position: 'absolute',
      left: '13px',
      bottom: '34px',
      margin: '0px',
    },
  },
});

export const PreviewHeaderAnimate = styled(motion.div)({
  width: '100%',
  height: '130vh',
  backgroundColor: palette.primary,
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
});

export const DescriptionSection = styled(Box)({
  maxWidth: '727px',
  width: '100%',
  h1: {
    marginBottom: '24px',
  },
  [theme.breakpoints.down('md')]: {
    marginLeft: '13px',
    h1: {
      fontSize: '56px',
      fontWeight: 600,
      lineHeight: '64px',
    },
    h6: {
      fontSize: '18px',
      fontWeight: 400,
      lineHeight: ' 28px',
      marginBottom: '64px',
    },
  },
});

export const PreviewHeaderContent = styled(Box)({
  display: 'flex',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'center',
  },
});

export const ImgContainer = styled(Box)({
  flexShrink: 0,
  marginLeft: 15,

  '& img': {
    width: 284,
  },

  '@media (max-width: 1148px)': {
    marginLeft: 0,
  },
});

export const HomeTopAnimation = styled(Box)({
  width: 364,
  height: 468,
  [theme.breakpoints.down('md')]: {
    width: '100%',
    height: 'auto',
    maxWidth: '256px',
  },
});

export const InfoBlockText = styled(motion.div)({
  marginBottom: '24px',
  color: '#ffffff',

  '&:last-child': {
    marginBottom: 0,
  },
  [theme.breakpoints.down('md')]: {
    marginBottom: '8px',
    h2: {
      fontWeight: 300,
      fontSize: '32px',
      lineHeight: '48px',
    },
    h1: {
      fontWeight: 600,
      fontSize: '56px',
      lineHeight: '64px',
    },
  },
});

export const PreviewHeaderContainer = styled(Box)({});

export const PreviewHeaderBottom = styled(motion.div)({
  height: '100vh',

  svg: {
    height: '100vh',
  },
  '@media (min-width: 1201px) and (min-height: 753px)': {
    height: '130vh',
  },
});
