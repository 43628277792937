import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import { motion } from 'framer-motion';

import theme from '../../theme';

export const StyledInfoBlock = styled(Box)({
  overflowY: 'hidden',
  maxWidth: '980px',
  width: '100%',
  height: '300px',
  margin: '210px auto 0',

  '&.bottomText': {
    height: '100px',
    margin: '0 auto 200px',
  },

  [theme.breakpoints.down('xl')]: {
    height: 'auto',
    marginTop: '48px',

    '&.bottomText': {
      height: 'auto',
      marginBottom: 0,
    },
  },
});

export const InfoBlockText = styled(motion.div)({
  display: 'block',
  marginBottom: '40px',

  '&:last-child': {
    marginBottom: 0,
  },

  [theme.breakpoints.down('xl')]: {
    '&:last-child': {
      marginBottom: '40px',
    },

    '&.bottomInfoBlockText': {
      marginBottom: 0,
    },
  },
});

export const InfoBlockTitle = styled(Typography)({
  [theme.breakpoints.down('md')]: {
    fontSize: '24px',
    lineHeight: '36px',

    '& strong': {
      fontSize: '24px',
      fontWeight: 600,
      lineHeight: '36px',
    },

    '& span': {
      fontSize: '24px',
      fontWeight: 600,
      lineHeight: '36px',
    },
  },
});
