import Box from '@mui/material/Box';
import { styled } from '@mui/system';

import theme from '../../theme';

export const StyledTeamBanner = styled(Box)({
  margin: '80px 0 24px',

  '& img': {
    width: '100%',
  },

  '@media (min-width: 1201px) and (max-height: 853px)': {
    marginTop: '94px',
  },
  [theme.breakpoints.down('xl')]: {
    margin: '64px 0 24px',
  },

  [theme.breakpoints.down('lg')]: {
    margin: '32px 0 0',
  },

  [theme.breakpoints.down('sm')]: {
    '& img': {
      width: 'calc(100% + 32px)',
      marginLeft: '-16px',
    },
  },
});
