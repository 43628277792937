import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import { motion } from 'framer-motion';

import theme from '../../theme';

export const StyledInfrastructure = styled(Box)({
  width: '100%',
  margin: '0px',

  [theme.breakpoints.down('xl')]: {
    margin: '48px 0 64px',
  },

  [theme.breakpoints.down('lg')]: {
    margin: '0 0 48px',
  },
});

export const InfrastructureTitle = styled(Typography)({
  width: '100%',
});

export const InfrastructureContent = styled(Box)({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(492px, 1fr))',
  gap: '5px',
  justifyContent: 'space-between',
  width: '100%',

  '@media (min-width: 1201px) and (max-height: 853px)': {
    grid: '0px',
  },

  [theme.breakpoints.down('xl')]: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '100%',
    marginTop: '40px',
  },

  [theme.breakpoints.down('lg')]: {
    marginTop: '24px',
  },
});

export const InfrastructureItem = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  maxWidth: '492px',
  width: '100%',
  '@media (min-width: 1201px) and (min-height: 753px)': {
    height: '104px',
  },
  '@media (min-width: 1201px) and (max-height: 853px)': {
    height: '90px',
  },
  [theme.breakpoints.down('xl')]: {
    padding: '16px 0',
    height: 'unset',
  },
});

export const InfrastructureItemIcon = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '80px',
  height: '80px',

  [theme.breakpoints.down('xl')]: {
    width: 'auto',
    height: 'auto',
  },
});

export const InfrastructureItemTitle = styled(Typography)({
  fontSize: '28px',
  lineHeight: '36px',
  maxWidth: '340px',
  width: '100%',
  margin: '0 80px 0 32px',
  fontWeight: 500,

  [theme.breakpoints.down('xl')]: {
    maxWidth: '100%',
  },

  [theme.breakpoints.down('lg')]: {
    margin: '0 26px 0 16px',
  },
});

export const InfrastructureItemCheck = styled(motion.div)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginLeft: 'auto',

  [theme.breakpoints.down('lg')]: {
    '& svg': {
      width: '24px',
      height: '24px',
    },
  },
});
