import Box from '@mui/material/Box';
import { styled } from '@mui/system';

import palette from '../theme/palette';

export const StyledLayout = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  '@media (min-width: 1201px) and (min-height: 753px)': {
    '& .slide-background': {
      height: '130vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      overflow: 'hidden',
      willChange: 'transform',
      WebkitBackfaceVisibility: 'hidden',
      backfaceVisibility: 'hidden',
      position: 'fixed',
      width: '100%',
      WebkitTransform: 'translateY(20vh)',
      MsTransform: 'translateY(20vh)',
      transform: 'translateY(20vh)',
      WebkitTransition: 'all 1.4s cubic-bezier(0.22, 0.44, 0, 1)',
      transition: 'all 1.4s cubic-bezier(0.22, 0.44, 0, 1)',
      background: palette.secondary,
      boxShadow: '0px 24px 48px -12px rgba(21, 21, 21, 0.18)',
      '&.slide-background-translate': {
        WebkitTransform: 'translateY(0vh)',
        MsTransform: 'translateY(0vh)',
        transform: 'translateY(0vh)',
      },
    },
  },
});

export const Main = styled('div')({
  flex: '1 1 auto',
});

export const SlideSection = styled(Box)({});

export const SlideSectionCenter = styled(Box)({
  '@media (min-width: 1201px) and (min-height: 753px)': {
    '&.slide-background': {
      justifyContent: 'center',
    },
  },
});
