import palette from '../../theme/palette';

export const ReceiptIcon = ({ color = palette.primary, width = 40, height = 40 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M32.5 5.83325L30 3.33325L27.5 5.83325L25 3.33325L22.5 5.83325L20 3.33325L17.5 5.83325L15 3.33325L12.5 5.83325L10 3.33325V26.6666H5V31.6666C5 34.4333 7.23333 36.6666 10 36.6666H30C32.7667 36.6666 35 34.4333 35 31.6666V3.33325L32.5 5.83325ZM25 33.3333H10C9.08333 33.3333 8.33333 32.5833 8.33333 31.6666V29.9999H25V33.3333ZM31.6667 31.6666C31.6667 32.5833 30.9167 33.3333 30 33.3333C29.0833 33.3333 28.3333 32.5833 28.3333 31.6666V26.6666H13.3333V8.33325H31.6667V31.6666Z'
        fill={color}
      />
      <path
        d='M15 11.6667H25V15.0001H15V11.6667ZM26.6667 11.6667H30V15.0001H26.6667V11.6667ZM15 16.6667H25V20.0001H15V16.6667ZM26.6667 16.6667H30V20.0001H26.6667V16.6667Z'
        fill={color}
      />
    </svg>
  );
};
