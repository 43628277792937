import { forwardRef } from 'react';

import { StyledContainer } from './styles';

const Container = forwardRef(({ className, direction, ...properties }, reference) => {
  return (
    <StyledContainer
      ref={reference}
      className={direction === 'column' ? 'column' : ''}
      {...properties}
    />
  );
});

export default Container;
