export const ShieldIcon = ({ color = '#5A32FB', width = 40, height = 40 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M20.0003 36.6668C16.1392 35.6946 12.952 33.479 10.4387 30.0202C7.92421 26.5624 6.66699 22.7224 6.66699 18.5002V8.3335L20.0003 3.3335L33.3337 8.3335V18.5002C33.3337 22.7224 32.077 26.5624 29.5637 30.0202C27.0492 33.479 23.8614 35.6946 20.0003 36.6668ZM20.0003 33.1668C22.8892 32.2502 25.2781 30.4168 27.167 27.6668C29.0559 24.9168 30.0003 21.8613 30.0003 18.5002V10.6252L20.0003 6.87516L10.0003 10.6252V18.5002C10.0003 21.8613 10.9448 24.9168 12.8337 27.6668C14.7225 30.4168 17.1114 32.2502 20.0003 33.1668Z'
        fill={color}
      />
    </svg>
  );
};
