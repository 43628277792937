import React, { useRef } from 'react';
import useBreakpoint from 'use-breakpoint';

import { BREAKPOINTS } from '../../constants';
import { AdvantagesItem, AdvantagesItemWrapper } from './styles';

const AdvantagesItems = ({ item, odd, currentSlideNumber }) => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  const isTablet = ['xxxs', 'xxs', 'xs', 'sm', 'md', 'lg'].includes(breakpoint);
  const reference = useRef();

  return (
    <AdvantagesItemWrapper
      initial={{
        x: !isTablet ? (odd ? '-100%' : '100%') : '0%',
      }}
      animate={{
        ...(currentSlideNumber === 4 && {
          x: '0%',
        }),
      }}
      transition={{
        ease: 'linear',
        duration: 1.25,
        delay: 0.1,
      }}
      viewport={{
        once: true,
        amount: 1,
      }}
    >
      <AdvantagesItem ref={reference}>
        <div className='icon'>{item.icon}</div>
        <div className='line' />
        <div className='text'>{item.text}</div>
      </AdvantagesItem>
    </AdvantagesItemWrapper>
  );
};

export default AdvantagesItems;
