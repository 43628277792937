import React from 'react';

import palette from '../../theme/palette';

export const SliderSmallArrow = ({ width = 20, height = 20 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13.4766 9.16664L9.00665 4.69664L10.185 3.51831L16.6666 9.99998L10.185 16.4816L9.00665 15.3033L13.4766 10.8333H3.33331V9.16664H13.4766Z'
        fill={palette.primary}
      />
    </svg>
  );
};
