export const RefreshIcon = ({ color = '#5A32FB', width = 40, height = 40 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.10467 7.38851C12.1289 4.76795 15.998 3.32793 19.9997 3.33351C29.2047 3.33351 36.6663 10.7952 36.6663 20.0002C36.6663 23.5602 35.5497 26.8602 33.6497 29.5668L28.333 20.0002H33.333C33.3332 17.3862 32.5651 14.8299 31.1241 12.6489C29.6831 10.468 27.6329 8.75878 25.2283 7.73372C22.8237 6.70866 20.1709 6.41302 17.5996 6.88354C15.0283 7.35407 12.6521 8.57001 10.7663 10.3802L9.10467 7.38851ZM30.8947 32.6118C27.8704 35.2324 24.0014 36.6724 19.9997 36.6668C10.7947 36.6668 3.33301 29.2052 3.33301 20.0002C3.33301 16.4402 4.44967 13.1402 6.34967 10.4335L11.6663 20.0002H6.66634C6.66613 22.6141 7.43426 25.1705 8.87524 27.3514C10.3162 29.5323 12.3665 31.2416 14.771 32.2666C17.1756 33.2917 19.8285 33.5873 22.3997 33.1168C24.971 32.6463 27.3473 31.4303 29.233 29.6202L30.8947 32.6118Z'
        fill={color}
      />
    </svg>
  );
};
