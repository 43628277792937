import palette from '../../theme/palette';

export const MaterialIcon = ({ color = palette.amethystSmoke, width = 64, height = 74 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 64 74'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_412_280)'>
        <path
          d='M0.650391 27.2724V0.00537109L24.2754 13.6389V22.7279L8.52539 13.6389V31.8169L0.650391 27.2724Z'
          fill={color}
        />
        <path
          d='M24.2754 13.6389L47.9004 0.00537109V27.2724L32.1504 36.3614L24.2754 31.8169L40.0254 22.7279V13.6389L24.2754 22.7279V13.6389Z'
          fill={color}
        />
        <path d='M24.2754 31.8169V40.9059L40.0254 49.9949V40.9059L24.2754 31.8169Z' fill={color} />
        <path
          d='M40.0254 49.9949L63.6504 36.3614V18.1834L55.7754 22.7279V31.8169L40.0254 40.9059V49.9949ZM55.7754 13.6389V4.54987L63.6504 0.00537109V9.09437L55.7754 13.6389Z'
          fill={color}
        />
      </g>
      <path
        d='M8.74014 66.011V73.689H7.20014V68.695L5.14314 73.689H3.97714L1.90914 68.695V73.689H0.369141V66.011H2.11814L4.56014 71.72L7.00214 66.011H8.74014Z'
        fill={color}
      />
      <path
        d='M15.1207 72.226H12.0627L11.5567 73.689H9.93969L12.7007 66H14.4937L17.2547 73.689H15.6267L15.1207 72.226ZM14.7027 70.994L13.5917 67.782L12.4807 70.994H14.7027Z'
        fill={color}
      />
      <path
        d='M23.6617 66.011V67.254H21.6157V73.689H20.0757V67.254H18.0297V66.011H23.6617Z'
        fill={color}
      />
      <path
        d='M26.4754 67.254V69.179H29.0604V70.4H26.4754V72.435H29.3904V73.689H24.9354V66H29.3904V67.254H26.4754Z'
        fill={color}
      />
      <path
        d='M34.8998 73.689L33.2058 70.697H32.4798V73.689H30.9398V66.011H33.8218C34.4158 66.011 34.9218 66.1173 35.3398 66.33C35.7578 66.5353 36.0695 66.8177 36.2748 67.177C36.4875 67.529 36.5938 67.925 36.5938 68.365C36.5938 68.871 36.4472 69.3293 36.1538 69.74C35.8605 70.1433 35.4242 70.422 34.8448 70.576L36.6818 73.689H34.8998ZM32.4798 69.542H33.7668C34.1848 69.542 34.4965 69.443 34.7018 69.245C34.9072 69.0397 35.0098 68.7573 35.0098 68.398C35.0098 68.046 34.9072 67.7747 34.7018 67.584C34.4965 67.386 34.1848 67.287 33.7668 67.287H32.4798V69.542Z'
        fill={color}
      />
      <path d='M39.6767 66.011V73.689H38.1367V66.011H39.6767Z' fill={color} />
      <path
        d='M46.0562 72.226H42.9982L42.4922 73.689H40.8752L43.6362 66H45.4292L48.1902 73.689H46.5622L46.0562 72.226ZM45.6382 70.994L44.5272 67.782L43.4162 70.994H45.6382Z'
        fill={color}
      />
      <path d='M50.9123 72.468H53.4423V73.689H49.3723V66.011H50.9123V72.468Z' fill={color} />
      <path
        d='M56.0891 66.011V70.763C56.0891 71.2837 56.2248 71.6833 56.4961 71.962C56.7674 72.2333 57.1488 72.369 57.6401 72.369C58.1388 72.369 58.5238 72.2333 58.7951 71.962C59.0664 71.6833 59.2021 71.2837 59.2021 70.763V66.011H60.7531V70.752C60.7531 71.4047 60.6101 71.9583 60.3241 72.413C60.0454 72.8603 59.6678 73.1977 59.1911 73.425C58.7218 73.6523 58.1974 73.766 57.6181 73.766C57.0461 73.766 56.5254 73.6523 56.0561 73.425C55.5941 73.1977 55.2274 72.8603 54.9561 72.413C54.6848 71.9583 54.5491 71.4047 54.5491 70.752V66.011H56.0891Z'
        fill={color}
      />
      <path d='M63.931 66.011V73.689H62.391V66.011H63.931Z' fill={color} />
      <defs>
        <clipPath id='clip0_412_280'>
          <rect width='63' height='50' fill='white' transform='translate(0.650391)' />
        </clipPath>
      </defs>
    </svg>
  );
};
