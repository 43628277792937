export const TechnologyDigitalOceanIcon = ({ width = 74, height = 74 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 74 74'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g>
        <rect width='74' height='74' fill='white' />
        <path
          d='M36.9998 46.3022V39.153C44.567 39.153 50.441 31.6485 47.5354 23.6841C46.4693 20.7576 44.1071 18.3954 41.1597 17.3293C33.1953 14.4446 25.6907 20.2977 25.6907 27.8649H18.5625C18.5625 15.8033 30.2269 6.39655 42.8738 10.3474C48.3924 12.0824 52.8032 16.4723 54.5173 21.9909C58.4681 34.6378 49.0614 46.3022 36.9998 46.3022Z'
          fill='#0080FF'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M37.0208 39.1949H29.8926V32.0667H37.0208V39.1949Z'
          fill='#0080FF'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M29.8925 44.6508H24.4365V39.1948H29.8925V44.6508Z'
          fill='#0080FF'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M24.4364 39.1949H19.8584V34.6169H24.4364V39.1949Z'
          fill='#0080FF'
        />
        <path
          d='M6.52179 53.5351C5.60202 52.887 4.4314 52.5735 3.09355 52.5735H0.166992V61.8757H3.11445C4.4523 61.8757 5.62292 61.5413 6.5427 60.8515C7.04439 60.4961 7.44157 59.9944 7.73422 59.3673C8.00598 58.7402 8.1523 57.9876 8.1523 57.1724C8.1523 56.3571 8.00598 55.6255 7.73422 54.9983C7.44157 54.3712 7.04439 53.8695 6.52179 53.5351ZM1.88112 54.1413H2.80089C3.82518 54.1413 4.66134 54.3503 5.30937 54.7475C6.0201 55.1865 6.37547 56.0017 6.37547 57.1724C6.37547 58.3848 6.0201 59.2418 5.30937 59.7017C4.70315 60.0989 3.86699 60.3079 2.82179 60.3079H1.88112V54.1413Z'
          fill='#0080FF'
        />
        <path
          d='M10.1796 52.448C9.88691 52.448 9.65697 52.5525 9.44793 52.7407C9.25979 52.9288 9.15527 53.1587 9.15527 53.4514C9.15527 53.744 9.25979 53.974 9.44793 54.183C9.63606 54.3712 9.88691 54.4757 10.1796 54.4757C10.4513 54.4757 10.7022 54.3712 10.9112 54.183C11.0993 53.9949 11.2039 53.744 11.2039 53.4514C11.2039 53.1587 11.0993 52.9288 10.9112 52.7407C10.7022 52.5525 10.4513 52.448 10.1796 52.448Z'
          fill='#0080FF'
        />
        <path d='M10.9952 55.312H9.34375V61.8759H10.9952V55.312Z' fill='#0080FF' />
        <path
          d='M16.9944 55.8555C16.4927 55.4165 15.9492 55.1448 15.343 55.1448C14.4232 55.1448 13.6707 55.4583 13.0854 56.0855C12.5001 56.6917 12.1865 57.486 12.1865 58.4476C12.1865 59.3674 12.4792 60.1617 13.0645 60.7888C13.6498 61.3951 14.4232 61.7086 15.3221 61.7086C15.9492 61.7086 16.5136 61.5414 16.9526 61.186V61.3323C16.9526 61.8758 16.8063 62.2939 16.5136 62.5866C16.221 62.8792 15.8238 63.0256 15.3221 63.0256C14.5696 63.0256 14.0888 62.7329 13.5035 61.9386L12.3747 63.0256L12.3956 63.0674C12.6464 63.4018 13.0018 63.7363 13.5035 64.0499C13.9843 64.3634 14.6114 64.5307 15.343 64.5307C16.3255 64.5307 17.1199 64.238 17.7052 63.6318C18.2905 63.0256 18.5831 62.2312 18.5831 61.2487V55.2911H16.9526V55.8555H16.9944ZM16.5554 59.7436C16.2628 60.0781 15.8865 60.2244 15.4266 60.2244C14.9458 60.2244 14.5905 60.0572 14.2978 59.7436C14.0052 59.4092 13.8797 58.9911 13.8797 58.4685C13.8797 57.925 14.0261 57.5069 14.2978 57.1725C14.5696 56.838 14.9668 56.6917 15.4266 56.6917C15.9074 56.6917 16.2628 56.8589 16.5554 57.1725C16.8481 57.5069 16.9944 57.9459 16.9944 58.4685C16.9944 58.9911 16.8481 59.4092 16.5554 59.7436Z'
          fill='#0080FF'
        />
        <path d='M21.6768 55.312H20.0254V61.8759H21.6768V55.312Z' fill='#0080FF' />
        <path
          d='M20.8622 52.448C20.5695 52.448 20.3396 52.5525 20.1305 52.7407C19.9424 52.9288 19.8379 53.1587 19.8379 53.4514C19.8379 53.744 19.9424 53.974 20.1305 54.183C20.3187 54.3712 20.5695 54.4757 20.8622 54.4757C21.1339 54.4757 21.3848 54.3712 21.5938 54.183C21.782 53.9949 21.8865 53.744 21.8865 53.4514C21.8865 53.1587 21.782 52.9288 21.5938 52.7407C21.3848 52.5525 21.1548 52.448 20.8622 52.448Z'
          fill='#0080FF'
        />
        <path
          d='M25.2938 53.5352H23.6633V55.312H22.7227V56.8171H23.6633V59.5346C23.6633 60.3916 23.8306 60.9979 24.165 61.3532C24.4995 61.7086 25.1057 61.8758 25.9419 61.8758C26.2136 61.8758 26.4854 61.8758 26.7362 61.8549H26.8198V60.3498L26.2554 60.3707C25.8582 60.3707 25.6074 60.308 25.482 60.1617C25.3566 60.0154 25.2938 59.7227 25.2938 59.2837V56.7962H26.8407V55.2911H25.2938V53.5352Z'
          fill='#0080FF'
        />
        <path d='M36.2266 52.5525H34.5752V61.8757H36.2266V52.5525Z' fill='#0080FF' />
        <path
          d='M52.908 59.5345C52.6153 59.869 52.3018 60.1617 52.0718 60.308C51.8419 60.4543 51.5492 60.5379 51.2147 60.5379C50.734 60.5379 50.3368 60.3707 50.0232 59.9944C49.7097 59.6391 49.5424 59.1583 49.5424 58.6148C49.5424 58.0504 49.7097 57.5905 50.0232 57.2351C50.3368 56.8797 50.734 56.6916 51.2147 56.6916C51.7373 56.6916 52.3018 57.0261 52.7825 57.5905L53.8696 56.5453C53.1588 55.6255 52.2599 55.1865 51.1729 55.1865C50.2741 55.1865 49.4797 55.521 48.8317 56.169C48.1837 56.817 47.8701 57.6323 47.8701 58.6148C47.8701 59.5973 48.2046 60.4125 48.8317 61.0605C49.4797 61.7086 50.2532 62.043 51.1729 62.043C52.3645 62.043 53.326 61.5204 53.995 60.5797L52.908 59.5345Z'
          fill='#0080FF'
        />
        <path
          d='M59.7014 56.2316C59.4715 55.8972 59.1579 55.6463 58.7607 55.4582C58.3636 55.27 57.9037 55.1655 57.402 55.1655C56.4822 55.1655 55.7297 55.5 55.1653 56.1689C54.6218 56.8378 54.3291 57.6531 54.3291 58.6356C54.3291 59.639 54.6427 60.4542 55.228 61.0814C55.8342 61.7085 56.6285 62.022 57.611 62.022C58.7189 62.022 59.6387 61.5621 60.3285 60.6842L60.3703 60.6424L59.2833 59.5972C59.1788 59.7226 59.0325 59.848 58.9071 59.9734C58.7398 60.1198 58.5935 60.2452 58.4263 60.3288C58.1754 60.4542 57.9037 60.5169 57.5901 60.5169C57.1302 60.5169 56.754 60.3915 56.4613 60.1198C56.1896 59.8689 56.0223 59.5345 55.9805 59.1164H60.3494L60.3703 58.5102C60.3703 58.0921 60.3076 57.674 60.2031 57.2977C60.0986 56.9215 59.9314 56.5661 59.7014 56.2316ZM56.085 57.7785C56.1686 57.465 56.315 57.1932 56.524 57.0051C56.754 56.7751 57.0466 56.6706 57.3811 56.6706C57.7783 56.6706 58.0918 56.7751 58.3009 57.0051C58.489 57.2141 58.6144 57.465 58.6353 57.7785H56.085Z'
          fill='#0080FF'
        />
        <path
          d='M66.0354 55.8136C65.5338 55.3955 64.8439 55.1655 63.9869 55.1655C63.4434 55.1655 62.9208 55.291 62.4609 55.5209C62.0219 55.7299 61.6038 56.1062 61.3321 56.5661L61.353 56.587L62.4191 57.5904C62.8581 56.9006 63.3388 56.6497 63.9869 56.6497C64.3422 56.6497 64.614 56.7333 64.8439 56.9215C65.053 57.1096 65.1784 57.3395 65.1784 57.6322V57.9458C64.7603 57.8203 64.3631 57.7576 63.966 57.7576C63.1507 57.7576 62.4818 57.9458 61.9801 58.322C61.4784 58.6983 61.2275 59.2627 61.2275 59.9734C61.2275 60.6006 61.4366 61.0813 61.8756 61.4576C62.3145 61.813 62.8371 62.0011 63.4643 62.0011C64.0914 62.0011 64.6767 61.7503 65.2202 61.3113V61.8548H66.8507V57.6322C66.788 56.8588 66.5371 56.2316 66.0354 55.8136ZM63.1089 59.3672C63.297 59.2418 63.5688 59.1791 63.9032 59.1791C64.3004 59.1791 64.7185 59.2627 65.1575 59.409V60.0571C64.8021 60.3915 64.3213 60.5588 63.736 60.5588C63.4434 60.5588 63.2343 60.496 63.0671 60.3706C62.9208 60.2452 62.8371 60.0989 62.8371 59.8898C62.8371 59.6599 62.9208 59.4926 63.1089 59.3672Z'
          fill='#0080FF'
        />
        <path
          d='M73.143 55.939C72.6831 55.4164 72.035 55.1655 71.2198 55.1655C70.5718 55.1655 70.0283 55.3537 69.6311 55.7299V55.3328H68.0215V61.8757H69.6729V58.2593C69.6729 57.7576 69.7983 57.3604 70.0283 57.0887C70.2582 56.8169 70.5718 56.6706 71.0108 56.6706C71.387 56.6706 71.6588 56.796 71.8678 57.0469C72.0768 57.2977 72.1814 57.6531 72.1814 58.0712V61.8757H73.8328V58.0712C73.8328 57.1723 73.6028 56.4407 73.143 55.939Z'
          fill='#0080FF'
        />
        <path
          d='M32.5052 55.8136C32.0035 55.3955 31.3136 55.1655 30.4566 55.1655C29.9131 55.1655 29.3905 55.291 28.9306 55.5209C28.4916 55.7299 28.0735 56.1062 27.8018 56.5661L27.8227 56.587L28.8888 57.5904C29.3278 56.9006 29.8086 56.6497 30.4566 56.6497C30.812 56.6497 31.0837 56.7333 31.3137 56.9215C31.5227 57.1096 31.6481 57.3395 31.6481 57.6322V57.9458C31.23 57.8203 30.8329 57.7576 30.4357 57.7576C29.6204 57.7576 28.9515 57.9458 28.4498 58.322C27.9481 58.6983 27.6973 59.2627 27.6973 59.9734C27.6973 60.6006 27.9063 61.0813 28.3453 61.4576C28.7843 61.813 29.3069 62.0011 29.934 62.0011C30.5611 62.0011 31.1464 61.7503 31.6899 61.3113V61.8548H33.3204V57.6322C33.2577 56.8588 33.0069 56.2316 32.5052 55.8136ZM29.5786 59.3672C29.7668 59.2418 30.0385 59.1791 30.373 59.1791C30.7701 59.1791 31.1882 59.2627 31.6272 59.409V60.0571C31.2718 60.3915 30.7911 60.5588 30.2057 60.5588C29.9131 60.5588 29.704 60.496 29.5368 60.3706C29.3905 60.2452 29.3069 60.0989 29.3069 59.8898C29.3069 59.6599 29.3905 59.4926 29.5786 59.3672Z'
          fill='#0080FF'
        />
        <path
          d='M42.2259 62.0221C39.5711 62.0221 37.418 59.869 37.418 57.2142C37.418 54.5594 39.5711 52.4062 42.2259 52.4062C44.8807 52.4062 47.0338 54.5594 47.0338 57.2142C47.0338 59.869 44.8807 62.0221 42.2259 62.0221ZM42.2259 54.0995C40.5118 54.0995 39.1112 55.5 39.1112 57.2142C39.1112 58.9283 40.5118 60.3289 42.2259 60.3289C43.94 60.3289 45.3406 58.9283 45.3406 57.2142C45.3406 55.5 43.94 54.0995 42.2259 54.0995Z'
          fill='#0080FF'
        />
        <rect
          width='74'
          height='74'
          fill='#5A32FB'
          style={{
            mixBlendMode: 'color',
          }}
        />
      </g>
      <defs>
        <clipPath id='clip0_352_742'>
          <rect width='74' height='74' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
